import React from 'react'
import { Form } from 'core/pages'
import schema from '_schema/sobiescianaAttribute'
import {schema as schemaparent}  from '_schema/sobiesciana'
import routes from '../routes'

export const New = ({ schema: definitions, ...rest }) => {
  const pid = rest.match.params.pid

  const defaultData = { parent: `${schemaparent.endpoint}/${pid}`  }

  return (
    <Form
      defaultData={defaultData}
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      method="POST"
      url={schema.endpoint}
      collectionPath={routes().attribute_index.path.replace(':pid',pid)}
      editPath={routes().attribute_edit.path.replace(':pid',pid)}
      storeCollectionId={schema.endpoint}
      width={'75%'}
    />
  )
}
