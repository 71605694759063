import { fetchData } from 'core/_helpers/fetchData'
import schema from 'core/_schema/profile'

const get = () =>
  fetchData(schema.profile.get).then(
    response => ({ response }),
    error => ({ error })
  )

export const profileService = {
  get,
}
