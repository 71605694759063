import React from 'react'
import { Route } from 'react-router-dom'
import CollectionsBookmarkOutlinedIcon from '@material-ui/icons/CollectionsBookmarkOutlined'
import { Collection } from './Collection'
import { SchemableComponent } from 'core/components/SchemableComponent'
import { Edit } from './Edit'
import { addRoutesConfig } from 'core/_helpers/addRoutesConfig'
import schema from '_schema/iartCollection'

const basePath = '/iart_collections'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: CollectionsBookmarkOutlinedIcon,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={Edit}
            uniqueKey={true}
            {...props}
          />
        ),
        path: `${basePath}/:id`,
        exact: true,
      },
    },
    {
      title: 'Iart Collections',
      resourceAccess: schema.endpoint,
      indexPath: basePath,
      // parent: `${basePath}/:id`,
    }
  )

export default routes
