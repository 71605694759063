import photoSchema from 'core/_schema/photo'

const schema = {
  endpoint: '/api/home_banner_items',
  resource: {
    definition: 'HomeBannerItem-homeBannerItem.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          // author: {},
          description: {
            type: 'textarea',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
        },
      },
      photo: {
        type: 'image',
        description: 'T_GENERAL_MEDIA_PHOTO',
        endpoint: photoSchema.endpoint.single,
        endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/api/home_banner_item_thumbs',
        },
        validate: ['maxSize'],
        ofmEnable: true
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
