import React from 'react'
import PropTypes from 'prop-types'
import YouTube from 'react-youtube'
import { makeStyles } from '@material-ui/styles'
import { StringType } from './StringType'

const useStyles = makeStyles({
  youtube: {
    marginTop: 20,
  },
})

export const YoutubeType = ({
  name,
  type = 'string',
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setAdditional = null,
  setError,
  syncWithAdditional = false,
  fullWidth = false,
}) => {
  const classes = useStyles()

  return (
    <>
      <StringType
        name={name}
        type={type}
        label={label}
        hint={hint}
        initialValue={initialValue}
        value={value}
        compareValue={compareValue}
        compare={compare}
        error={error}
        renderError={renderError}
        disabled={disabled}
        validators={validators}
        setValue={setValue}
        setAdditional={setAdditional}
        setError={setError}
        syncWithAdditional={syncWithAdditional}
        fullWidth={fullWidth}
      />
      {value && (
        <YouTube
          videoId={value}
          opts={{ width: 640, height: 390 }}
          className={classes.youtube}
        />
      )}
    </>
  )
}

YoutubeType.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  compareValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  compare: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setAdditional: PropTypes.func,
  setError: PropTypes.func.isRequired,
  syncWithAdditional: PropTypes.bool,
  fullWidth: PropTypes.bool,
}
