export const processError = error =>
  error.response
    ? error
    : {
        response: {
          title: error.name || 'Error',
          detail: error.toString(),
          violations: [],
        },
      }
