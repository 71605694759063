import _block from 'core/pages/Bip/_block'
import photoSchema from 'core/_schema/photo'
import fileSchema from 'core/_schema/file'

const schema = {
  endpoint: '/api/bips',
  // endpointResourceAccess: '/api/news',
  resource: {
    definition: 'Bip-bip.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          subtitle: {},
          shortLead: {
            type: 'textarea',
          },
          text: {
            type: 'tinymce',
          },
          photoAlt: {},
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {
            type: 'slug',
            routes: {
              pl: '/bip/:slug',
              en: '/en/bip/:slug',
              // he: '/he/:slug'
            },
          },
        },
      },

      pageDate: {
        type: 'date'
      },
      // archive: {},

      // type: {
      //   type: 'media[type]',
      // },
      // youtube: {
      //   type: 'media[youtube]',
      //   description: 'T_GENERAL_YOUTUBE_CODE',
      // },

      // photo: {
      //   type: 'media[image]',
      //   description: 'T_GENERAL_MEDIA_PHOTO',
      //   endpoint: photoSchema.endpoint.single,
      //   endpointYoutube: photoSchema.endpoint.youtube,
      //   thumbs: {
      //     endpoint: '/api/bip_thumbs',
      //   },
      //   validate: ['maxSize'],
      //   ytProperty: 'youtube',
      // },


    },
  },
  subresources: {
    block: {
      endpoint: '/api/bip_blocks?pagination=false',
      definition: 'BipBlock-bipBlock.read',
      types: _block(
        photoSchema.endpoint.single,
        '/api/bip_block_thumbs',
        fileSchema.endpoint.single
      ).types,
    },
  },
}

export default schema
