const schema = {
  endpoint: '/api/order_emails',
  resource: {
    definition: 'OrderEmail-orderEmail.read',
    properties: {
      title: {
        label: 'Nazwa',
        hint: 'widoczna tylko w PA',
      },
      translations: {
        type: 'translation',
        properties: {
          text: {
            label: 'Głowny text',
            type: 'tinymce',
          },
        },
      },
    },
  },
}

export default schema
