import _block from 'pages/News/_block'
import photoSchema from 'core/_schema/photo'
import fileSchema from 'core/_schema/file'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { schema as schematag } from '_schema/tagEntry'

const schema = {
  endpoint: '/api/pages',
  endpointResourceAccess: '/api/news',
  resource: {
    definition: 'Page-page.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          // subtitle: {},
          text: {
            type: 'tinymce',
          },
          photoAlt: {},
          photoDesc: { type : 'textarea' },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {
            type: 'slug',
            routes: {
              pl: '/aktualnosc/:slug',
              en: '/en/news/:slug',
            },
          },
          preview: {
            label: 'Podgląd',
            description:'',
            type: 'preview',
            routes: {
              pl: '/aktualnosc/:slug',
              en: '/en/news/:slug',
            },
          },
        },
      },

      tags: {
        type: 'collection',
        endpoint: '/api/page_tags',
        definition: 'PageTag-pageTag.read',
        description: 'T_NEWS_TAGS',
        additionalProperties: {
          '$ref':'#/definitions/PageTag-pageTag.write_pageTag.create',
        },

        titleAccessor: function(resource, handleSuccess) {
          if(resource["@titleAccessor"]) return resource["@titleAccessor"]
          const setResource = (res) => {
            resource["@titleAccessor"] = res.translations && res.translations[process.env.REACT_APP_LOCALE] ? (res.translations[process.env.REACT_APP_LOCALE].title ?? '--') : '--'
            if(handleSuccess) {
              handleSuccess(resource)
            }
          }
          if(resource.tag) {
            fetchDataHandleAuthError(resource.tag,'GET',null,setResource)
          } else {
            return '--'
          }
        },

        properties: {
          tag: {
            type: 'resourcetag',
            endpoint: `/api/tag_entries?pagination=false`,
            titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.titleWithName`,//'uuid',
            storeCollectionId: schematag.extraStoreCollectionId,
            label: 'T_TAG_ENTRY'
          },
          stat: {}
        },
      },

      pageDate: {
        type: 'date'
      },
      isEducation: {},

      // type: {
      //   type: 'media[type]',
      // },
      // youtube: {
      //   type: 'media[youtube]',
      //   description: 'T_GENERAL_YOUTUBE_CODE',
      // },
      photo: {
        type: 'media[image]',
        description: 'T_GENERAL_MEDIA_PHOTO',
        endpoint: photoSchema.endpoint.single,
        endpointYoutube: photoSchema.endpoint.youtube,
        thumbs: {
          endpoint: '/api/page_thumbs',
        },
        validate: ['maxSize'],
        ytProperty: 'youtube',
        ofmEnable: true
      },


    },
  },
  subresources: {
    block: {
      endpoint: '/api/page_blocks?pagination=false',
      definition: 'PageBlock-pageBlock.read',
      types: _block(
        photoSchema.endpoint.single,
        '/api/page_block_thumbs',
        fileSchema.endpoint.single
      ).types,
    },
  },
}

export default schema
