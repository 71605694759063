import packageGallerySchema from 'core/_schema/packageGallery'
import photoSchema from 'core/_schema/photo'

export const packageGallery = (anchor = true) => {
  let b = {
    name: 'packageGallery',
    label: 'T_GENERAL_BLOCK_TYPE_PACKAGE_GALLERY',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          text: {
            type: 'tinymce',
          },
        },
      },
      packageGallery: {
        type: 'resource',
        endpoint: `${packageGallerySchema.endpoint}?pagination=false&embeded=false`,
        titleAccessor: 'title',
      },

      packageGalleryEmbeded: {
        type: 'collectionpackageembeded',
        endpoint: '/api/package_galeries',
        definition: 'PackageGallery-packageGallery.read',
        description: 'lub załącz zdjęcia',
        dialogTitle: 'Zdjęcia',
        titleAccessor: 'title',
        additionalProperties: {
          $ref:
            '#/definitions/PackageGallery-packageGallery.write_packageGallery.create',
        },

        properties: {
          items: {
            type: 'embeddedcollection',
            endpoint: '/api/package_gallery_items',
            definition: 'PackageGalleryItem-packageGalleryItem.read',
            description: '',
            statable: true,
            additionalProperties: {
              $ref:
                '#/definitions/PackageGalleryItem-packageGalleryItem.write_packageGalleryItem.create',
            },

            titleAccessor: function(resource, handleSuccess) {
              if (resource.translations[process.env.REACT_APP_LOCALE].title) {
                return resource.translations[process.env.REACT_APP_LOCALE].title
              } else {
                return '--' //translate('T_GENERAL_MISSING_TITLE').replace('<','‹ ').replace('>',' ›')
              }
            },

            properties: {
              translations: {
                type: 'translation',
                properties: {
                  title: {},
                  author: {},
                  description: {
                    type: 'textarea',
                  },
                  alt: {
                    description: 'T_GENERAL_ALT',
                  },
                },
              },
              photo: {
                type: 'image',
                description: 'T_GENERAL_MEDIA_PHOTO',
                endpoint: photoSchema.endpoint.single,
                endpointMultiple: photoSchema.endpoint.multiple,
                thumbs: {
                  endpoint: '/api/package_gallery_item_thumbs',
                },
                validate: ['maxSize'],
                ofmEnable: true,
              },
            },
          },
        },
      },

      galleryType: {
        type: 'choice',
        choices: {
          tile: 'T_GENERAL_BLOCK_GALLERY_TYPE_TILE',
          slider: 'T_GENERAL_BLOCK_GALLERY_TYPE_SLIDER',
        },
        validate: ['required'],
      },
      anchor: {},
    },
  }

  if (!anchor) {
    delete b.properties.anchor
  }
  return b
}
