import _block from 'pages/Pasazs/_block'
import photoSchema from 'core/_schema/photo'
import fileSchema from 'core/_schema/file'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'

const schema = {
  endpoint: '/api/pasazs',
  resource: {
    definition: 'Pasaz-pasaz.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          shortLead: {
            type: 'textarea',
          },
          // text: {
          //   type: 'tinymce',
          // },
          photoAlt: {},
          photoDesc: { type: 'textarea' },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {
            type: 'slug',
            routes: {
              pl: '/pasaz-wiedzy/:slug',
              en: '/en/knowledge/:slug',
            },
          },
          preview: {
            label: 'Podgląd',
            description: '',
            type: 'preview',
            routes: {
              pl: '/pasaz-wiedzy/:slug',
              en: '/en/knowledge/:slug',
            },
          },
        },
      },

      author: {
        label: 'Autor/Autorzy',
        hint: 'można wpisać gdy brak tagów autorów',
      },

      publishDate: {
        type: 'date',
        validate: ['required'],
      },

      tags: {
        type: 'collection',
        endpoint: '/api/pasaz_tags',
        definition: 'PasazTag-pasazTag.read',
        description: 'Tagi pasażu',
        additionalProperties: {
          $ref: '#/definitions/PasazTag-pasazTag.write_pasazTag.create',
        },
        titleAccessor: 'titlePa',
        properties: {
          // tag: { //dont use this , 100tys tags kills PA
          //   type: 'resource',
          //   endpoint: `/api/tag_pasaz_entry_form?pagination=false`,
          //   titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,//'uuid',
          //   storeCollectionId: 'tag_pasaz_entry_form',
          //   label: 'T_TAG_ENTRY'
          // },
          tag: {
            type: 'pasaztagajax',
            endpoint: `/api/tag_pasaz_entry_form_ajax`,
            endpointTags: '/api/tag_pasaz_entries',
            label: 'T_TAG_ENTRY',
          },
          stat: {},
        },
      },

      topics: {
        type: 'multipleResource',
        endpoint: `/api/pasaz_topics?pagination=false`,
        titleAccessor: `title`, //'uuid',
        label: 'Tematy',
      },

      pasazType: {
        type: 'choice',
        choices: {
          1: 'Artykuł',
          2: 'Księga',
        },
        label: 'Typ',
        validate: ['required'],
      },

      sourceName: {
        type: 'choice',
        choices: {
          1: 'Silva Rerum',
          3075: 'Ad Villam Novam',
          3077: 'Studia Wilanowskie',
        },
        label: 'Źródło pochodzenia',
        validate: ['required'],
      },

      linked: {
        type: 'collection',
        endpoint: '/api/pasaz_linkeds',
        definition: 'PasazLinked-pasazLinked.read',
        description: 'W księdze',
        additionalProperties: {
          $ref:
            '#/definitions/PasazLinked-pasazLinked.write_pasazLinked.create',
        },
        titleAccessor: function(resource, handleSuccess) {
          if (resource['@titleAccessor']) return resource['@titleAccessor']
          const setResource = res => {
            resource['@titleAccessor'] = res.title ? res.title ?? '--' : '--'
            if (handleSuccess) {
              handleSuccess(resource)
            }
          }
          if (resource.child) {
            fetchDataHandleAuthError(resource.child, 'GET', null, setResource)
          } else {
            return '--'
          }
        },
        properties: {
          child: {
            type: 'resource',
            endpoint: `/api/pasazs_form?pagination=false`,
            titleAccessor: `title`, //'uuid',
            storeCollectionId: 'pasazeses_form',
            label: 'Artykuł',
          },
          stat: {},
        },
      },

      recomended: {
        label: 'Polecana księga',
        hint:
          'pokazywana na stronie głównej pasażu wiedzy, jeśli powyższy typ to "Księga"',
      },

      // type: {
      //   type: 'media[type]',
      // },
      // youtube: {
      //   type: 'media[youtube]',
      //   description: 'T_GENERAL_YOUTUBE_CODE',
      // },
      photo: {
        type: 'media[image]',
        description: 'Zdjęcie główne',
        endpoint: photoSchema.endpoint.single,
        endpointYoutube: photoSchema.endpoint.youtube,
        // thumbs: {
        // endpoint: '/api/pasaz_thumbs',// tu nie ma do cropowania thumbów
        // },
        validate: ['maxSize'],
        ytProperty: 'youtube',
        ofmEnable: true,
      },

      images: {
        type: 'embeddedcollection',
        endpoint: '/api/pasaz_images',
        definition: 'PasazImage-PasazImage.read',
        description: 'Dodatkowe zdjęcia',
        additionalProperties: {
          $ref: '#/definitions/PasazImage-PasazImage.write_PasazImage.create',
        },

        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',

        properties: {
          translations: {
            type: 'translation',
            properties: {
              title: {},
              description: {
                type: 'textarea',
              },
              alt: {
                description: 'T_GENERAL_ALT',
              },
            },
          },
          photo: {
            type: 'image',
            description: 'T_GENERAL_MEDIA_PHOTO',
            endpoint: photoSchema.endpoint.single,
            endpointMultiple: photoSchema.endpoint.multiple,
            // thumbs: {
            //   endpoint: '/api/pasaz_image_thumbs', // tu nie ma do cropowania thumbów
            // },
            validate: ['maxSize'],
            ofmEnable: true,
          },
          stat: {
            description: 'T_GENERAL_PUBLICATION',
          },
        },
      },

      recommended: {
        type: 'collection',
        endpoint: '/api/pasaz_recommendeds',
        definition: 'PasazRecommended-pasazRecommended.read',
        description: 'Polecane',
        additionalProperties: {
          $ref:
            '#/definitions/PasazRecommended-pasazRecommended.write_pasazRecommended.create',
        },
        titleAccessor: function(resource, handleSuccess) {
          if (resource['@titleAccessor']) return resource['@titleAccessor']
          const setResource = res => {
            resource['@titleAccessor'] = res.title ? res.title ?? '--' : '--'
            if (handleSuccess) {
              handleSuccess(resource)
            }
          }
          if (resource.recommended) {
            fetchDataHandleAuthError(
              resource.recommended,
              'GET',
              null,
              setResource
            )
          } else {
            return '--'
          }
        },
        properties: {
          recommended: {
            type: 'resource',
            endpoint: `/api/pasazs_form?pagination=false`,
            titleAccessor: `title`, //'uuid',
            storeCollectionId: 'pasazeses_form',
            label: 'Artykuł',
          },
          stat: {},
        },
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/pasaz_blocks?pagination=false',
      definition: 'PasazBlock-pasazBlock.read',
      types: _block(
        photoSchema.endpoint.single,
        '/api/pasaz_block_thumbs',
        fileSchema.endpoint.single
      ).types,
    },
  },
}

export default schema
