import React from 'react'
import { Form } from 'core/pages'
import { SidebarNotStatable } from 'core/components/resource/sidebar'
import schema from '_schema/sobiescianaAttribute'
import routes from '../routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`
  const pid = rest.match.params.pid

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().attribute_index.path.replace(':pid', pid)}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor={`translations.${process.env.REACT_APP_LOCALE}.title`}
      SidebarComponent={SidebarNotStatable}
      width="100%"
    />
  )
}
