export const addProperties = (
  profile,
  properties,
  resource,
  schema
) => {
  let modifiedProperties = JSON.parse(JSON.stringify(properties))

  if (!profile.isModerator) {
    delete modifiedProperties.stat
  }

  if (profile.isOpenform) {
    return {
      ...modifiedProperties,
      idName: {
        type: 'string',
        disabled: true,
        description: {
          text: 'T_GENERAL_OPENFORM_PAGE_IDNAME',
          color: '#cc0000',
        },
      },
    }
  }

  return modifiedProperties
}
