import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { getSchemaByRef } from 'core/_helpers/getSchemaByRef'
import { EmbeddedCollection } from 'core/components/embedded'
import { StaticCollection } from 'core/components/static'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 30,
    marginTop: 30,
  },
}))

export const EmbeddedcollectionType = ({
  uuid,
  label,
  value,
  properties,
  definitionRef = null,
  endpoint,
  formUrl,
  titleAccessor = null,
  disabled = false,
  classes = {},
  statable = false,
  addTitle = null,
  expandTitle = null,
  panelTitle = null,
  types = null,
  width = '100%',
  ...rest
}) => {
  const schema = useSelector(state => state.schema)
  const definitionSchema = useMemo(
    () => (definitionRef ? getSchemaByRef(schema, definitionRef) : schema),
    [schema, definitionRef]
  )

  const defaultClasses = useStyles()

  return (
    <div className={clsx(defaultClasses.root, classes.root)}>
      {disabled ? (
        <StaticCollection
          items={value || []}
          properties={properties}
          definitionSchema={definitionSchema}
        />
      ) : uuid && (
        <EmbeddedCollection
          endpoint={endpoint}
          pid={uuid}
          parentIri={formUrl}
          properties={properties}
          definitionSchema={definitionSchema}
          alignButtonEvenly={true}
          titleAccessor={titleAccessor}
          disabled={!uuid || disabled}
          headerTitle={translate(label.text || label)}
          statable={statable}
          addTitle={addTitle}
          expandTitle={expandTitle}
          panelTitle={panelTitle}
          types={types}
          formWidth={width}
          {...rest}
        />
      )}
    </div>
  )
}

EmbeddedcollectionType.propTypes = {
  uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      [process.env.REACT_APP_RESOURCE_ID]: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]).isRequired,
    })
  ),
  properties: PropTypes.object.isRequired,
  definitionRef: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  formUrl: PropTypes.string.isRequired,
  titleAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.func,
  ]),
  disabled: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
    label: PropTypes.string,
    labelButton: PropTypes.string,
    dialogContent: PropTypes.string,
  }),
}
