import React, { useMemo } from 'react'
import { Paper } from 'core/components/Paper'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { operations } from 'core/components/table/_columns/operations'
import { translate } from 'core/_helpers/translate'
import { publish } from 'core/components/table/_mass'
import schema from '_schema/educationCategory'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [

      {
        header: translate('Title'),
        accessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        filterName: 'translations.title',
        filterable: true,
        // Cell: MainCell,
        width: '73%',
      },
      // {
      //   header: `${translate('T_GENERAL_TRANSLATION')} HE`,
      //   accessor: `translations.he.title`,
      //   width: '16%',
      // },
      {
        ...operations(
          `translations.${process.env.REACT_APP_LOCALE}.title`,
          true,
          true,
          true,
          true
        ),
        width: '13%',
      },
    ],
    []
  )

  const defaultFilters = {
    'type': '4'
  }

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_NEW_CATEGORY_BUTTON')}
        buttonPath={routes().new.path}
      >
        {translate('Education category happen')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultFilters={defaultFilters}
        defaultSorters={{ ord: 'desc' }}
        selectable={[publish.on(), publish.off()]}
        orderable={true}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        storeCollectionId={`education_category_visit`}
      />
    </Paper>
  )
}
