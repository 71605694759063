// import React from 'react'
// import PropTypes from 'prop-types'
// import { useSelector } from 'react-redux'
// import { EditableCell as OriginalEditableCell } from 'core/components/table/cells/editable/EditableCell'

// export const EditableCell = ({
//   resource,
//   accessor,
//   propertySchema,
//   tableState,
//   tableStateDispatch,
// }) => {
//   const profile = useSelector(state => state.profile)

//   return (
//     <OriginalEditableCell
//       resource={resource}
//       accessor={accessor}
//       propertySchema={propertySchema}
//       disabled={
//         profile[process.env.REACT_APP_RESOURCE_ID] ===
//         resource[process.env.REACT_APP_RESOURCE_ID]
//       }
//       tableState={tableState}
//       tableStateDispatch={tableStateDispatch}
//     />
//   )
// }

// EditableCell.propTypes = {
//   resource: PropTypes.shape({
//     '@id': PropTypes.string.isRequired,
//   }).isRequired,
//   accessor: PropTypes.string,
//   propertySchema: PropTypes.shape({
//     validate: PropTypes.arrayOf(PropTypes.string),
//   }),
//   tableState: PropTypes.object,
//   tableStateDispatch: PropTypes.func.isRequired,
// }

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { constants } from 'core/components/table/_state'
import { prop } from 'core/_helpers/prop'
import { InputCell } from 'core/components/table/cells/editable/InputCell'
import { TextCell } from 'core/components/table/cells/editable/TextCell'

const useStyles = makeStyles(theme => ({
  disabled: {
    color: theme.palette.disabled,
  },
}))

export const EditableCell = ({
  resource,
  accessor,
  disabled = false,
  propertySchema,
  tableStateDispatch,
  tableState,
}) => {
  const [isInput, setIsInput] = useState(false)

  const onSuccess = value => {
    tableStateDispatch({
      type: constants.UPDATE_ITEM_FIELD,
      payload: {
        '@id': resource['@id'],
        accessor,
        value,
      },
    })
  }

  const classes = useStyles()

  return (
    <>
      {disabled ? (
        <div className={classes.disabled}>{prop(resource, accessor)}</div>
      ) : isInput ? (
        <InputCell
          resource={resource}
          accessor={accessor}
          propertySchema={propertySchema}
          onSuccess={onSuccess}
          width={`calc(100% - 40px)`}
          type='integer'
        />
      ) : (
        <TextCell
          resource={resource}
          accessor={accessor}
          setIsInput={setIsInput}
        />
      )}
    </>
  )
}

EditableCell.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  propertySchema: PropTypes.shape({
    validate: PropTypes.arrayOf(PropTypes.string),
  }),
  tableStateDispatch: PropTypes.func.isRequired,
}
