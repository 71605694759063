import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import randomHash from 'random-hash'
import clsx from 'clsx'
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Editor } from '@tinymce/tinymce-react'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'
import { styles as translationTabStyles } from 'core/components/form/fields/translation/TranslationTab.styles'

// TinyMCE so the global var exists
import 'tinymce/tinymce'
// DOM model
//import 'tinymce/models/dom/model'
// Theme
import 'tinymce/themes/silver'
// Toolbar icons
import 'tinymce/icons/default'
// Editor styles
import 'tinymce/skins/ui/oxide/skin.css'
import 'tinymce/skins/ui/oxide/content.css'

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/autosave'
import 'tinymce/plugins/bbcode'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/code'
import 'tinymce/plugins/codesample'
import 'tinymce/plugins/colorpicker'
import 'tinymce/plugins/contextmenu'
import 'tinymce/plugins/directionality'
import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/fullpage'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/help'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/image'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/importcss'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/legacyoutput'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/media'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/noneditable'
import 'tinymce/plugins/pagebreak'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/quickbars'
import 'tinymce/plugins/save'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/spellchecker'
import 'tinymce/plugins/tabfocus'
import 'tinymce/plugins/table'
import 'tinymce/plugins/template'
import 'tinymce/plugins/textcolor'
import 'tinymce/plugins/textpattern'
import 'tinymce/plugins/toc'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/visualchars'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/paste'

// importing plugin resources
import 'tinymce/plugins/emoticons/js/emojis'

// eslint-disable-next-line no-undef
import 'tinymcelang/pl'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 15,
    width: '100%',
  },
  label: {
    position: 'relative',
    marginBottom: 15,
    transform: 'none',
  },
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}))

export const Tinymce3Type = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  formWidth = '100%',
  translationLang = null,
}) => {
  const [id] = useState(randomHash())
  const theme = useTheme()

  const handleChange = content => {
    setValue(name, content)
    validateField(content)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  const classes = useStyles()

  const defaultSettings = {
    menubar: '', //'file edit view insert',
    force_br_newlines: false,
    force_p_newlines: true,
    forced_root_block: '',
    entities: '160,nbsp', //default is full list
    entity_encoding: 'named',
    language: process.env.REACT_APP_LOCALE,
    directionality: translationLang === 'he' ? 'rtl' : 'ltr',
    paste_as_text: true,
    image_advtab: true,
    nonbreaking_wrap: false,
    plugins: [
      'autolink lists link charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime paste nonbreaking',
    ],
    contextmenu: 'link | copy paste',
    toolbar:
      'insertfile undo redo | ' +
      'bold italic | ' +
      'link unlink nonbreaking | language',
    content_langs: [
      { title: 'Angielski', code: 'en' },
      { title: 'Hiszpański', code: 'es' },
      { title: 'Francuski', code: 'fr' },
      { title: 'Niemiecki', code: 'de' },
      { title: 'Portugalski', code: 'pt' },
      { title: 'Łaciński', code: 'la' },
    ],
    extended_valid_elements:
      'span[id|class|style|title|dir<ltr?rtl|lang|xml::lang|onclick|ondblclick|' +
      'onmousedown|onmouseup|onmouseover|onmousemove|onmouseout|onkeypress|' +
      'onkeydown|onkeyup],script[charset|defer|language|src|type]',
    style_formats: [],
    paste_text_sticky: true,
    paste_text_sticky_default: true,
    browser_spellcheck: true,
    width:
      formWidth -
      (translationLang &&
        translationTabStyles.root?.paddingLeft +
          translationTabStyles.root?.paddingRight),
    height: 300,
    skin: theme.palette.type === 'dark' ? 'oxide-dark' : '',
    content_css: theme.palette.type === 'dark' ? 'oxide-dark' : '',
  }

  return (
    <FormControl
      error={renderError && !!error}
      required={validators && validators.includes('required')}
      disabled={disabled}
      className={classes.root}
    >
      <InputLabel htmlFor={id} className={classes.label}>
        <span style={label.color && { color: label.color }}>
          {translate(label.text || label)}
        </span>
      </InputLabel>
      <Editor
        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
        disabled={disabled}
        id={id}
        init={defaultSettings}
        textareaName={name}
        value={value}
        onEditorChange={handleChange}
      />
      <FormHelperText>
        {translate(renderError && error ? error : hint)}
      </FormHelperText>
      {compare && (
        <div
          className={clsx(
            classes.compare,
            value !== compareValue && classes.compareNeq
          )}
        >
          {compareValue}
        </div>
      )}
    </FormControl>
  )
}

Tinymce3Type.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.string,
  value: PropTypes.string,
  compareValue: PropTypes.string,
  compare: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  formWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  translationLang: PropTypes.string,
}
