import React from 'react'
import { Route } from 'react-router-dom'
import { AccountCircleOutlined } from '@material-ui/icons'
import { Profile } from './Profile'

const routes = () => ({
  index: {
    title: 'T_MODULE_PROFILE',
    type: Route,
    render: props => <Profile {...props} />,
    path: '/profile',
    exact: true,
    icon: AccountCircleOutlined,
  },
})

export default routes
