import { fetchData } from './fetchData'
import { handleAuthError } from './handleAuthError'
import { processError } from './processError'

export const fetchDataHandleAuthError = (
  url,
  method = 'GET',
  request = {},
  handleSuccess = () => {},
  handleError = () => {},
  headers = {}
) =>
  fetchData(url, method, headers, request).then(
    response => handleSuccess(response),
    error => {
      handleAuthError.default(error)

      return handleError(processError(error))
    }
  )
