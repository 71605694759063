export const constants = {
  SET_VALUE: 'FORM_SET_VALUE',
  SET_ERROR: 'FORM_SET_ERROR',
  SET_ADDITIONAL: 'FORM_SET_ADDITIONAL',
  RENDER_ERROR: 'FORM_RENDER_ERROR',
  SUBMIT: 'FORM_SUBMIT',
  PROCESS: 'FORM_PROCESS',
  SUCCESS: 'FORM_SUCCESS',
  FAILURE: 'FORM_FAILURE',
  SET_COMPARE_RESOURCE: 'FORM_SET_COMPARE_RESOURCE',
  REMOVE_COMPARE_RESOURCE: 'FORM_REMOVE_COMPARE_RESOURCE',
}
