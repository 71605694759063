import fileSchema from 'core/_schema/gigapixelfile'

const schema = {
  endpoint: '/api/gigapixels',
  resource: {
    definition: 'Gigapixel-gigapixel.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
        },
      },
      file: {
        type: 'file',
        description: 'T_GENERAL_FILE',
        endpoint: fileSchema.endpoint.single,
        validate: ['maxSize'],
        accept: '.jpg,.png,.jpeg',
        hint: 'wybierz tylko dozwolone typy jpg, jpeg, png',
        ofmEnable: true
      },
      idName:{
        label: 'ID',
        hint: 'unikalny identyfikator',
        validate: ['required'],
      },
      stat:{}
    },
  },
}

export default schema
