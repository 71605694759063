import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
// import { useSelector } from 'react-redux'
import { Paper } from 'core/components/Paper'

import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { operations } from './table/operations' //'core/components/table/_columns/operations'
import { MainCellSave } from './table/cells/MainCellSave'


import { translate } from 'core/_helpers/translate'
// import { publish } from 'core/components/table/_mass'
import schema from '_schema/newsletter'
import routes from './routes'
import { MainCellSendcount } from './table/cells/MainCellSendcount'

export const Collection = ({ wrapWithPaper = true, showNewButton = true }) => {
  // const profile = useSelector(state => state.profile)

  const columns = useMemo(
    () => [
      {
        header: translate('Title'),
        accessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        filterName: 'translations.title',
        filterable: true,
        // Cell: MainCell,
        width: '50%',
      },
      {
        header: translate('Wysłanych emaili'),
        accessor: `sentCount`,
        filterName: 'sentCount',
        filterable: true,
        Cell: MainCellSendcount,
        width: '10%',
      },
      {
        header: translate('Data wysłania'),
        accessor: `sentDate`,
        filterName: 'sentDate',
        filterable: true,
        Cell: MainCellSave,
        width: '10%',
      },

      { ...operations(`translations.${process.env.REACT_APP_LOCALE}.title`, true, true, false, true, false), width: '15%' },
    ],
    []
  )

  const defaultFilters = {
  }

  const customFilters = [
    // { name: 'showInDziejeSie', title: 'baner Dzieje się', value: null, Filter: YesNoFilter },
    // { name: 'showInShopBanner', title: 'baner Sklepu', value: null, Filter: YesNoFilter },
  ]

  const WrapComponent = wrapWithPaper ? Paper : 'div'

  return (
    <WrapComponent>
      <CollectionHeader
        buttonTitle={translate('Dodaj newsletter')}
        buttonPath={routes().new.path}
        hideButton={!showNewButton}
      >
        {translate('Newsletter')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultFilters={defaultFilters}
        defaultSorters={{ createdAt: 'desc' }}
        // selectable={[publish.on(), publish.off()]}
        isRowSelectable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        customFilters={customFilters}
        orderable={false}
        isRowLinkable={true}
      />
    </WrapComponent>
  )
}

Collection.propTypes = {
  wrapWithPaper: PropTypes.bool,
  showNewButton: PropTypes.bool,
}
