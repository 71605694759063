import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Menu, MenuItem, IconButton } from '@material-ui/core'
import { MoreVertOutlined } from '@material-ui/icons'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { RestoreVersion } from 'core/components/resource/RestoreVersion'
import { translate } from 'core/_helpers/translate'
import clsx from 'clsx'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItem: {
    fontSize: 14,
    '& svg': {
      fontSize: 20,
    },
  },
  link: {
    width: '100%',
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  menuTrigger: {
    borderRadius: 4,
    padding: 7,
  },
  menuTriggerOpen: {
    border: '1px solid #d3d4d5',
    borderBottomColor: '#fff',
    backgroundColor: '#fff',
    
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  }
})

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    
    borderTopRightRadius: 0
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

export const operations = onRestoreSuccess => ({
  header: translate('T_GENERAL_OPERATIONS'),
  Cell: ({ resource, viewPath, editPath }) => {
    const classes = useStyles()
    const [menuAnchor, setMenuAnchor] = useState(null)

    const handleMenu = e => {
      setMenuAnchor(e.currentTarget)
    }

    const handleClose = () => {
      setMenuAnchor(null)
    }

    return (
      <div className={classes.root}>
        <IconButton
          aria-label="record menu"
          aria-controls="record-menu"
          aria-haspopup="true"
          onClick={handleMenu}
          className={clsx( classes.menuTrigger,
            menuAnchor && classes.menuTriggerOpen
          )}
        >
          <MoreVertOutlined color="inherit" />
        </IconButton>
        <StyledMenu
          id="record-menu"
          anchorEl={menuAnchor}
          keepMounted
          open={!!menuAnchor}
          onClose={handleClose}
        >
          <MenuItem className={classes.menuItem}>
            <Link
              to={viewPath.replace(
                ':id',
                resource[process.env.REACT_APP_RESOURCE_ID]
              )}
              className={classes.link}
            >
              {translate('T_GENERAL_VIEW')}
            </Link>
          </MenuItem>
          <MenuItem className={classes.menuItem}>
            <RestoreVersion
              version={resource}
              editPath={editPath}
              onSuccess={onRestoreSuccess}
            />
          </MenuItem>
        </StyledMenu>
      </div>
    )
  },
})
