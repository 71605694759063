import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import DateFnsUtils from '@date-io/date-fns'
import plLocale from 'date-fns/locale/pl'
import moment from 'moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
// import { FormHelperText } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'

const LOCALES = {
  pl: plLocale,
}

const FORMAT = 'yyyy-MM-dd'

const DATE_PICKER_ERRORS = [
  translate('T_GENERAL_INVALID_DATE'),
  translate('T_GENERAL_INVALID_MIN_DATE'),
  translate('T_GENERAL_INVALID_MAX)DATE'),
]

const useStyles = makeStyles({
  input: {
    width: 160,
    marginLeft: 4,
    marginRight: 4,
    '& input': {
      paddingTop: 13,
    },
  },
})

export const DateFilter = ({
  name,
  title,
  value,
  disabled,
  handleEnter,
  setFilter,
  classes = {},
}) => {
  const handleChange = value => {
    const date = value ? moment(value).format(FORMAT.toUpperCase()) : null
    setFilter(name, date || null)
  }

  // const handleKeyDown = e => {
  //   switch (e.key) {
  //     case 'Enter':
  //       handleEnter && handleEnter()
  //       break
  //     default:
  //       break
  //   }
  // }

  const defaultClasses = useStyles()

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={LOCALES[process.env.REACT_APP_LOCALE]}
    >
      <div className={clsx(defaultClasses.input, classes.input)}>
        <KeyboardDatePicker
          id={`keyboard-id-${name}`}
          name={name}
          label={translate(title)}
          value={![null, undefined].includes(value) ? new Date(value) : null}
          disabled={disabled}
          disableToolbar
          // variant="inline"
          format={FORMAT}
          // margin="normal"
          minDate={false}
          invalidDateMessage={DATE_PICKER_ERRORS[0]}
          minDateMessage={DATE_PICKER_ERRORS[1]}
          maxDateMessage={DATE_PICKER_ERRORS[2]}
          onChange={handleChange}
          // onError={handleError}
        />
        {/* <FormHelperText error={renderError && !!error} disabled={disabled}>
          {renderError && !!error
            ? !DATE_PICKER_ERRORS.includes(error)
              ? translate(error)
              : null
            : translate(hint)}
        </FormHelperText> */}
      </div>
    </MuiPickersUtilsProvider>
  )
}

DateFilter.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  handleEnter: PropTypes.func,
  setFilter: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    input: PropTypes.string,
  }),
}
