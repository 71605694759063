import React, { useMemo } from 'react'
import { Paper } from 'core/components/Paper'
// import { useSelector } from 'react-redux'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { operations } from 'core/components/table/_columns/operations'
import { translate } from 'core/_helpers/translate'
import { publish } from 'core/components/table/_mass'
import { MainCellSave } from './table/cells/MainCellSave'
import schema from '_schema/formsetSave'
import routes from './routes'

export const FormSaveCollection = ({...rest}) => {
  // const profile = useSelector(state => state.profile)
  const { match } = rest

  const defaultFilters = {
    'parent.uuid': match.params.pid,
  }

  const columns = useMemo(
    () => [
      {
        header: translate('Data zgłoszenia'),
        accessor: `createdAt`,
        // filterName: 'translations.title',
        // filterable: true,
        Cell: MainCellSave,
        width: '73%',
      },

      { ...operations(`translations.${process.env.REACT_APP_LOCALE}.title`, true, true, false, true, false), width: '15%' },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader
        hideButton={true}
        // buttonTitle={translate('Dodaj')}
        // buttonPath={routes().formsaveNew.path.replace(':pid',match.params.pid)}
      >
        {translate('Zgłoszenia formularza')}
        &nbsp;
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultFilters={defaultFilters}
        defaultSorters={{ createdAt: 'desc' }}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().formsaveEdit.path.replace(':pid',match.params.pid)}
        autoWidth={false}
        storeCollectionId={`${schema.endpoint}${match.params.pid}`}
      />
    </Paper>
  )
}
