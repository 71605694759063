import React, { useMemo } from 'react'
import { Paper } from 'core/components/Paper'
import { useSelector } from 'react-redux'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { operations } from './table/operations'
import { translate } from 'core/_helpers/translate'
// import { publish } from 'core/components/table/_mass'
// import { MainCell } from './table/cells/MainCell'
import schema from '_schema/shopForm'
import routes from './routes'

export const Collection = () => {
  const profile = useSelector(state => state.profile)
  const columns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_TITLE'),
        accessor: `title`,//`translations.${process.env.REACT_APP_LOCALE}.title`,
        // filterName: 'translations.title',
        filterable: true,
        // Cell: MainCell,
        width: '73%',
      },

      { ...operations(`title`, true, false, (profile.isModerator ? true : false), true, false), width: '15%' },
    ],
    [profile.isModerator]
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_SHORTCUT_NEW_BUTTON')}
        buttonPath={routes().new.path}
        hideButton={true}
      >
        {translate('Formularze zakupowe')}
        &nbsp;
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={false}
        // selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
