import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Paper } from 'core/components/Paper'

import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
// import { operations } from 'core/components/table/_columns/operations'
import { operations } from './table/columns/operations'
// import queryString from 'query-string'

import { translate } from 'core/_helpers/translate'
import { publish } from 'core/components/table/_mass'
import schema from '_schema/mainMenu'
import routes from './routes'

export const Collection = ({ wrapWithPaper = true, showNewButton = true, ...rest }) => {
  const profile = useSelector(state => state.profile)

  //const { pid } = queryString.parse(rest.location.search)
  const pid = rest.match.params.id

  const columns = useMemo(
    () => [
      {
        header: translate('Title'),
        accessor: `pageTitle`,
        // filterName: 'translations.title',
        // filterable: true,
        // Cell: MainCell,
        width: '73%',
      },

      { ...operations(`pageTitle`, true, true, (profile.isModerator ? true : false), true, true), width: '15%' },
    ],
    [profile.isModerator]
  )

  const defaultFilters = pid ? {
    'exists[parent]': true,
    'pagination': true,
    'parent.uuid':pid,
  } : {
    'exists[parent]': false,
    'pagination': true,
  }

  const customFilters = [
    // {name: 'pageType', title: 'page type', value: 'project'},
  ]

  const WrapComponent = wrapWithPaper ? Paper : 'div'

  const collectionId = 'main_menu'

  return (
    <WrapComponent key={pid}>
      <CollectionHeader
        buttonTitle={translate('Dodaj nową pozycję')}
        buttonPath={pid ? routes().newParent.path.replace(':pid', pid ) : routes().new.path }
        hideButton={!showNewButton}
      >
        {translate('Lista menu')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultFilters={defaultFilters}
        defaultSorters={{ ord: 'desc' }}
        selectable={[publish.on(), publish.off()]}
        isRowSelectable={resource => profile.isOpenform || resource.isStatable}
        editPath={routes().edit.path}
        autoWidth={false}
        customFilters={customFilters}
        orderable={true}
        isRowLinkable={true}
        storeCollectionId={`${collectionId}-${pid}`}
      />
    </WrapComponent>
  )
}

Collection.propTypes = {
  wrapWithPaper: PropTypes.bool,
  showNewButton: PropTypes.bool,
}
