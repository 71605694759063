const schema = {
  endpoint: '/api/shipping_type_points',
  resource: {
    definition: 'ShippingTypePoint-shippingTypePoint.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Nazwa',
          },
          address: {
            type: 'textarea',
          }
        },
      },
      //off
      // map:{
      //   type: 'map',
      //   label: 'Mapa',
      //   validate: ['required'],
      // },
      stat: {},
    },
  },
}

export default schema
