import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Loader } from './Loader'

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
})

export const RestoreLoader = ({ classes = {} }) => {
  const defaultClasses = useStyles()

  return (
    <div className={clsx(defaultClasses.root, classes.root)}>
      <Loader size="5.0rem" disableShrink={true} />
    </div>
  )
}

RestoreLoader.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
}
