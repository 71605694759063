import React, { useMemo } from 'react'
import queryString from 'query-string'
import { BlankForm, NotFound } from 'core/pages'
import { Paper } from 'core/components/Paper'
import { Loader } from 'core/components/Loader'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { EditHeader } from 'core/components/EditHeader'
import schema from 'core/_schema/packageLogotypeItem'
import routes from './routes'
import parentSchema from 'core/_schema/packageLogotype'
import parentRoutes from 'core/pages/PackageLogotypes/routes'

export const New = ({ schema: definitions, ...rest }) => {
  const { pid } = queryString.parse(rest.location.search)

  if (!pid) {
    throw new Error('Missing query argument: pid')
  }

  const [state, setState] = useResourceState()

  const { resource: parent, isFetching, fetchError } = state

  const parentIri = useMemo(() => `${parentSchema.endpoint}/${pid}`, [pid])

  useResourceFetch(
    parentIri,
    parent,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    true
  )

  const defaultData = { parent: parentIri }

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <EditHeader
        resource={parent}
        editPath={parentRoutes().edit.path}
        wrapWithTitle={true}
      />
      <BlankForm
        defaultData={defaultData}
        definitionSchema={definitions[schema.resource.definition]}
        customResourceSchema={schema.resource}
        method="POST"
        url={schema.endpoint}
        storeCollectionId={parent['@id']}
        collectionPath={parentRoutes().show.path.replace(':id', pid)}
        editPath={routes().edit.path}
        fieldsFullWidth={false}
        width={600}
      />
    </Paper>
  )
}
