const schema = {
  endpoint: '/api/cookie_items',
  resourceforadd: {
    definition: 'CookieItem-cookieItem.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Nazwa grupy',
          },
          shortLead: {
            type: 'textarea',
          },
        },
      },
    },
  },
  resource: {
    definition: 'CookieItem-cookieItem.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Nazwa grupy',
          },
          shortLead: {
            type: 'textarea',
          },
        },
      },

      required: {},
      stat: {},
      // idName: {},

      script: {
        type: 'textarea',
      },

      options: {
        type: 'embeddedcollection',
        endpoint: '/api/cookie_item_options',
        definition: 'CookieItemOption-cookieItem.read',
        description: 'Poszczególne cookies',
        additionalProperties: {
          $ref:
            '#/definitions/CookieItemOption-cookieItem.write_cookieItem.create',
        },

        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',

        properties: {
          translations: {
            type: 'translation',
            properties: {
              title: {},
              provider: {},
              shortLead: {
                type: 'textarea',
              },
              date: {},
              kind: {},
            },
          },

          stat: {},
        },
      },
    },
  },
}

export default schema
