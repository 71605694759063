import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'

export const iartcollectionBlock = (type = null, anchor = true) => {
  let b = {
    name: 'iartcollectionBlock',
    label: 'Blok "Powiązane obiekty z kolekcji iart"',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            hint: 'domyślny tytuł "Obiekty z kolekcji", można zmienić',
          },
        },
      },

      elements: {
        type: 'collection',
        endpoint: type?.endpoint ?? '/api/block_elements',
        definition: type?.definition ?? 'BlockElement-blockElement.read',
        description: 'Wybierz obiekty z kolekcji iart',
        additionalProperties: {
          $ref:
            type?.ref ??
            '#/definitions/BlockElement-blockElement.write_blockElement.create',
        },
        titleAccessor: function(resource, handleSuccess) {
          if (resource['@titleAccessor']) return resource['@titleAccessor']
          const setResource = res => {
            resource['@titleAccessor'] =
              res.translations && res.translations[process.env.REACT_APP_LOCALE]
                ? res.translations[process.env.REACT_APP_LOCALE].title ?? '--'
                : '--'
            if (handleSuccess) {
              handleSuccess(resource)
            }
          }
          if (resource.iartCollection) {
            fetchDataHandleAuthError(
              resource.iartCollection,
              'GET',
              null,
              setResource
            )
          } else {
            return '--' //translate('T_GENERAL_MISSING_TITLE').replace('<','‹ ').replace('>',' ›')
          }
        },
        properties: {
          // iartCollection: {
          //   type: 'resource',
          //   endpoint: `/api/iartcollections_form?pagination=false&exists[versionable]=false`,
          //   titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
          //   label: 'Obiekty z kolekcji iart',
          //   storeCollectionId: 'iartcollections_form',
          // },
          iartCollection: {
            type: 'autocomplete',
            label: 'Obiekty z kolekcji iart',
            titleProperty: `translations.${process.env.REACT_APP_LOCALE}.title`,
            searchProperty: 'translations.title',
            endpoint: '/api/iart_collections?pagination=false',
            validate: ['required'],
          },
          stat: {},
        },
      },
      anchor: {},
    },
  }
  if (!anchor) {
    delete b.properties.anchor
  }
  return b
}
