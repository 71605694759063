const schema = {
  endpoint: '/api/discount_products',
  resource: {
    definition: 'DiscountProduct-discountProduct.read',
    properties: {

      title: {
        validate: ['required'],
      },

      floatRate: {
        type: 'number',
        minValue: 0,
        maxValue: 100,
        defaultValue: 0.0,
        // validate: ['required'],
        label: 'Wartość procentowa',
        endAdornment: '%',
      },
      floatPrice: {
        type: 'number',
        minValue: 0,
        label: 'Wartość kwotowa',
        hint: 'jeśli wpisana, zastępuje wartość procentową'
      },
      categories: {
        type: 'multipleResourceNotCategory',
        endpoint: `/api/product_categories_form?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,//'uuid',
        label: 'Product categories',
      },

      products: {
        type: 'multipleResource',
        endpoint: `/api/products_form?pagination=false&exists[versionable]=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,//'uuid',
        label: 'Products',
      },

      startDate:{
        type: 'date',
        label: 'Data rozpoczęcia',
      },

      endProperDate:{
        type: 'date',
        label: 'Data zakończenia (włącznie)',
      },

      stat: {
        // description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
