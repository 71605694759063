import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  MenuItem as MUIMenuItem,
  ListItemIcon,
  Tooltip,
  Collapse,
  List,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ExpandMoreOutlined } from '@material-ui/icons'
import { MenuItem } from './MenuItem'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  list: {
    paddingLeft: 18,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    backgroundColor: theme.elements.subMenu,
  },
  listSidebarClosed: {
    paddingLeft: 0,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  header: {
    color: theme.elements.menu.text,
    '& *': {
      color: theme.elements.menu.text,
    },
    '&:hover': {
      backgroundColor: theme.elements.menu.hover,
    },
    fontSize: '1.0em',
    paddingTop: 12,
    paddingBottom: 12,
  },
  headerListItemIcon: {
    minWidth: 44,
  },
  headerIcon: {
    fontSize: 22,
  },
  listItem: {
    fontSize: '.95em',
  },
  listItemIcon: {
    minWidth: 40,
  },
  icon: {
    fontSize: 20,
  },
}))

export const SubMenu = ({
  title,
  icon: IconComponent,
  items = [],
  isSidebarOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(state => !state)
  }

  const classes = useStyles()

  const header = (
    <MUIMenuItem button onClick={handleToggle} className={classes.header}>
      <ListItemIcon className={classes.headerListItemIcon}>
        {isOpen ? (
          <ExpandMoreOutlined className={classes.headerIcon} />
        ) : (
          <IconComponent className={classes.headerIcon} />
        )}
      </ListItemIcon>
      {translate(title)}
    </MUIMenuItem>
  )

  return (
    <>
      {isSidebarOpen ? (
        header
      ) : (
        <Tooltip title={translate(title)} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          className={clsx(
            classes.list,
            !isSidebarOpen && classes.listSidebarClosed
          )}
        >
          {items.map((item, i) => (
            <div key={`sub-menu-item-${i}`}>
              {isSidebarOpen ? (
                <MenuItem
                  item={item}
                  classes={{
                    listItem: classes.listItem,
                    listItemIcon: classes.listItemIcon,
                    icon: classes.icon,
                  }}
                />
              ) : (
                <Tooltip title={translate(item.title)} placement="right">
                  <div>
                    <MenuItem
                      item={item}
                      classes={{
                        listItem: classes.listItem,
                        listItemIcon: classes.listItemIcon,
                        icon: classes.icon,
                      }}
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          ))}
        </List>
      </Collapse>
    </>
  )
}

SubMenu.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      exact: PropTypes.bool,
      icon: PropTypes.elementType.isRequired,
    })
  ),
  isSidebarOpen: PropTypes.bool.isRequired,
}
