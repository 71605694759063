export const schema = {
  endpoint: '/api/tag_entries',
  extraStoreCollectionId: 'tag_entries_form',
  resource: {
    definition: 'TagEntry-tagEntry.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
        },
      },
      name: {
        label: 'Nazwa dodatkowa, widoczna tylko w PA'
      },
      stat: { description: 'T_GENERAL_PUBLICATION' },
    },
  },
}

export default schema
