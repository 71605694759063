import React from 'react'
import PropTypes from 'prop-types'
import { Table, TableBody, alpha } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { BodySkeleton } from 'core/components/table'

const useStyles = makeStyles(theme => ({
  cell: {
    border: 0,
  },
  skeleton: {
    backgroundColor: alpha(theme.elements.sideBar.text, 0.15),
  },
}))

export const MenuSkeleton = ({ rows }) => {
  const classes = useStyles()

  return (
    <Table size="small">
      <TableBody>
        <BodySkeleton
          rows={rows}
          columns={1}
          animation={false}
          classes={{
            cell: classes.cell,
            skeleton: classes.skeleton,
          }}
        />
      </TableBody>
    </Table>
  )
}

MenuSkeleton.propTypes = {
  rows: PropTypes.number.isRequired,
}
