import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { prop } from 'core/_helpers/prop'
import { makeStyles } from '@material-ui/styles'
// import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles({
  image: {
    display: 'flex',
    alignItems: 'center',
  },
  imgContainer: {
    width: 200,
  },
  img: {
    maxHeight: 100,
    width: '100%',
    height: 'auto',
  },
  title: {},
  icon: {
    float: 'right',
    marginLeft: '0.50em',
  },
})

export const MainCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
}) => {
  const history = useHistory()

  const onClick = () => {
    if (isRowLinkable) {
      editPath &&
        history.push(
          editPath.replace(':id', resource[process.env.REACT_APP_RESOURCE_ID])
        )
    }
  }

  const classes = useStyles()

  return (
    <div onClick={onClick} className={classes.image}>
      <div className={classes.title}>
        { accessor === `translations.${process.env.REACT_APP_LOCALE}.slug` ? <span>{process.env.REACT_APP_FRONT_ENTRYPOINT}/pwa/collection/{prop(resource, 'uuid')}</span>
        : <span>{prop(resource, accessor)}</span> }
      </div>
    </div>
  )
}

MainCell.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    translations: PropTypes.shape({
      [process.env.REACT_APP_LOCALE]: PropTypes.shape({
        author: PropTypes.string,
        description: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  isRowLinkable: PropTypes.bool,
  editPath: PropTypes.string,
}
