import React, { useState, useMemo } from 'react'
import clsx from 'clsx'
import {
  InputLabel,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
} from '@material-ui/core'
import { DialogTitleWithClose } from 'core/components/DialogTitleWithClose'
import { CreateOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { getSchemaByRef } from 'core/_helpers/getSchemaByRef'
import { StaticCollection } from 'core/components/static'
import { translate } from 'core/_helpers/translate'
import { boolean } from 'core/_helpers/boolean'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { Form } from 'core/components/form'
import { Paper } from 'core/components/Paper'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages'
import { applyProperties } from 'core/_helpers/applyProperties'


const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 15,
  },
  label: {
    position: 'relative',
    transform: 'none',
    display: 'inline-flex',
    color: theme.palette.text.secondary,
    marginRight: 5,
    verticalAlign: 'middle',
  },
  labelButton: {
    cursor: 'pointer',
  },
  dialogContent: {
    marginBottom: 15,
  },
}))

export const CollectionPackageEmbededType = ({
  uuid, //block uuid b2bab0f3-a1d3-4317-8376-d05e5c152098
  label,
  value, //api/package_files/9eb34c9c-e660-40cd-8f61-624a5c2eb0af
  properties: customProperties,
  definitionRef = null,
  endpoint, //api/package_files/
  formUrl,
  titleAccessor = null,
  disabled = false,
  classes = {},
  dialogTitle = null,
}) => {
  const schema = useSelector(state => state.schema)
  const definitionSchema = useMemo(
    () => (definitionRef ? getSchemaByRef(schema, definitionRef) : schema),
    [schema, definitionRef]
  )

  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(state => !state)
  }

  const handleSuccess = () => {}
  const [state, setState] = useResourceState()
  const { presource, isFetching, fetchError } = state

  useResourceFetch(
    value,
    presource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    true
  )

  const properties = applyProperties(
    customProperties,
    definitionSchema.properties,
    'missing'
  )

  const defaultClasses = useStyles()

  return (<>
    { value ? (
    <div className={clsx(defaultClasses.root, classes.root)}>
      <InputLabel className={clsx(defaultClasses.label, classes.label)}>
        <span style={label.color && { color: label.color }}>
          {translate(label.text || label)}
        </span>
      </InputLabel>
      <Tooltip
        title={translate(
          uuid ? 'T_GENERAL_EDIT' : 'T_GENERAL_SAVE_FORM_TO_EDIT'
        )}
      >
        <span>
          <IconButton color="primary" onClick={handleToggle} disabled={!uuid}>
            <CreateOutlined
              className={clsx(defaultClasses.labelButton, classes.labelButton)}
            />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog
        onClose={handleToggle}
        open={isOpen}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitleWithClose onClose={handleToggle}>
          {translate(dialogTitle || label || label.text)}
        </DialogTitleWithClose>
        <DialogContent
          className={clsx(defaultClasses.dialogContent, classes.dialogContent)}
        >
          {disabled ? (
            <StaticCollection
              items={value || []}
              properties={properties}
              definitionSchema={definitionSchema}
            />
          ) : (
            isFetching ? (
              <Paper>
                <Loader />
              </Paper>
            ): fetchError ? (
              <NotFound />
            ) : (state.resource === null ? '(ten blok wymaga ponownego zapisu)' :
              <Form
                url={state.resource['@id']}
                method="PUT"
                properties={properties}
                resource={state.resource}
                width={'100%'}
                fetchCompareResource={boolean(
                  process.env.REACT_APP_FORM_FETCH_COMPARE_RESOURCE
                )}
                handleSuccess={handleSuccess}
                showSubmitAndStayButton={false}
                showCancelButton={false}
                disabled={disabled}
                showSubmit={false}
                key={`${
                  state.resource[process.env.REACT_APP_RESOURCE_ID]
                }-${0}`}
              />)
          )}
        </DialogContent>
      </Dialog>
    </div> ) : null }</>
  )
}


