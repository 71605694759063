const schema = {
  endpoint: '/api/shipping_types',
  resource: {
    definition: 'ShippingType-shippingType.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Nazwa',
          },
        },
      },

      code: {
        label: 'Kod',
        hint: '(SKU) zaimportowany',
        length: 40,
      },

      isPersonal:{
        label: 'Odbiór osobisty',
        hint: 'mapa miejsc odbioru bedzie dostępna'
      },
      // cashOnDelivery: {
      //   label: 'Za pobraniem (Płatność przy odbiorze)',
      //   hint: 'gdy w koszyku ebook, opcja jest niedostepna'
      // },
      shippingServiceProvider: {
        type: 'choice',
        choices: {
          'INPOST' : 'Inpost',
        },
      },
      stat: {},
    },
  },
}

export default schema
