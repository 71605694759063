import React from 'react'
import { Route } from 'react-router-dom'
import PlaylistAddOutlinedIcon from '@material-ui/icons/PlaylistAddOutlined'
import { Collection } from './Collection'
import { SchemableComponent } from 'core/components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { addRoutesConfig } from 'core/_helpers/addRoutesConfig'

const basePath = '/sobiesciana_category'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'Lista kategorii',
        type: Route,
        render: props => <Collection {...props} />,
        path: `${basePath}`,
        exact: true,
        icon: PlaylistAddOutlinedIcon,
      },
      indexParent: {
        subtitle: 'Lista kategorii',
        type: Route,
        render: props => <Collection {...props} />,
        path: `${basePath}/parent/:id`,
        exact: true,
        icon: PlaylistAddOutlinedIcon,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        path: `${basePath}/new`,
        exact: true,
      },
      newParent: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        path: `${basePath}/new/:pid`,
        exact: true,
      },

      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={Edit}
            uniqueKey={true}
            {...props}
          />
        ),
        path: `${basePath}/edit/:id`,
        exact: true,
      },
      editParent: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={Edit}
            uniqueKey={true}
            {...props}
          />
        ),
        path: `${basePath}/edit/:id/parent/:pid`,
        exact: true,
      },
    },
    {
      title: 'Kategorie',
      resourceAccess: 'sobiesciana_catgory_schema_id',
      indexPath: basePath,
      isOpenform: true,
    }
  )

export default routes
