export const addDefaultFilters = (filters, columns) => {
  const defaultFilters = Object.assign({}, filters)
  const modifiedColumns = columns.map(column => {
    if (
      column.accessor &&
      ![null, undefined].includes(defaultFilters[column.accessor])
    ) {
      const value = defaultFilters[column.accessor]
      delete defaultFilters[column.accessor]
      return {
        ...column,
        filterable: true,
        value,
      }
    }

    return column
  })

  return [
    ...modifiedColumns,
    ...Object.keys(defaultFilters).map(key => ({
      accessor: key,
      filterable: true,
      value: defaultFilters[key],
    })),
  ]
}
