// to samo co w api/src/Entity/FormsetItem.php
const formsetItemsTypeList = {
  text: 'Pole tekstowe (input)',
  radio: 'Pole z opcjami (radio)',
  checkbox: 'Pole wyboru (checkbox)',
  textarea: 'Obszar tekstowy',
  select: 'Lista wyboru',
  multiple: 'Lista wyboru z możliwością zaznaczenia kilkiu opcji',
  email: 'Pole do wprowadzenia adresu email',
  // 'url'   : 'Pole do wprowadzenia adresu url',
  number: 'Pole liczbowe',
  tel: 'Pole do wprowadzenia numeru telefonu',
  // 'date'   : 'Pole do wprowadzenia daty',
  // 'time'   : 'Pole do wprowadzenia godziny',
  file: 'Pole do dodania załączników',
  consent: 'Pole do zatwierdzenia zgód',
}
// to samo co w api/src/Entity/FormsetItem.php
const formsetItemsAutoList = {
  name: 'pełna nazwa',
  'given-name':
    'imię (w niektórych kulturach zachodnich, znane również jako „pierwsze imię” )',
  'family-name':
    'nazwisko rodowe (w niektórych kulturach zachodnich znane również jako nazwisko)',
  'organization-title':
    'stanowisko (np., „Inżynier oprogramowania”, „Wiceprezes”, „Zastępca dyrektora generalnego”)',
  username: 'nazwa użytkownika',
  organization:
    'nazwa firmy odpowiadająca osobie, adresowi lub danym kontaktowym w innych polach powiązanych z tym polem',
  'street-address': 'adres',
  'address-level2': 'miejscowość',
  'address-level1': 'województwo',
  'country-name': 'nazwa kraju',
  'postal-code': 'kod pocztowy',
  language: 'preferowany język',
  bday: 'urodziny',
  sex: 'tożsamość płciowa (np. kobieta, mężczyzna)',
  url:
    'adres strony głównej lub innej strony internetowej odpowiadająca firmie, osobie, adresowi lub danym kontaktowym w innych polach powiązanych z tym polem',
}

const schema = {
  endpoint: '/api/formset_items',
  resourceforadd: {
    definition: 'FormsetItem-formset.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Tytuł',
          },
        },
      },
      type: {
        type: 'choice',
        choices: formsetItemsTypeList,
        label: 'Typ pola',
        validate: ['required'],
      },
    },
  },
  resource: {
    definition: 'FormsetItem-formset.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Tytuł',
          },
          shortLead: {
            type: 'tinymce3',
          },
          placeholder: {},
          errorMessage: {},
        },
      },
      type: {
        type: 'choice',
        choices: formsetItemsTypeList,
        label: 'Typ pola',
        validate: ['required'],
        disabled: true,
      },
      autofill: {
        type: 'choice',
        choices: formsetItemsAutoList,
        label: 'Typ autouzupełnienia',
      },
      required: {},
      fileSize: {
        hint: 'podaj maksymalny rozmiar w MB',
        validate: ['required'],
      },
      fileExtension: {
        hint: 'podaj po przecinku, np jpg,gif,png',
        validate: ['required'],
      },
      textareaSize: {},
      maxCount: {
        type: 'integer',
        minValue: 0,
        defaultValue: 0,
        hint: 'wpisz 0 lub puste pole aby wyłaczyć',
      },
      stat: {},

      options: {
        type: 'embeddedcollection',
        endpoint: '/api/formset_item_options',
        definition: 'FormsetItemOption-formset.read',
        description: 'Opcje pola',
        additionalProperties: {
          $ref: '#/definitions/FormsetItemOption-formset.write_formset.create',
        },

        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',

        properties: {
          translations: {
            type: 'translation',
            properties: {
              title: {},
              shortLead: {
                type: 'tinymce3',
              },
            },
          },
          isDefault: {},
          required: {},
          stat: {},
        },
      },
    },
  },
}

export default schema
