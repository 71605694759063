import React from 'react'
import { useHistory } from 'react-router-dom'
import { prop } from 'core/_helpers/prop'
import { makeStyles } from '@material-ui/styles'
// import WorkIcon from '@material-ui/icons/Work'
import WorkOutlineIcon from '@material-ui/icons/WorkOutline'
// import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber'
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined'
import { Avatar, Badge } from '@material-ui/core'
import { green } from '@material-ui/core/colors'

const useStyles = makeStyles({
  image: {
    display: 'flex',
    alignItems: 'center',
  },
  imgContainer: {
    width: 200,
  },
  img: {
    maxHeight: 100,
    width: '100%',
    height: 'auto',
  },
  title: {
    marginLeft: '1.0em',
  },
  green: {
    color: '#fff',
    backgroundColor: green[500],
  },
  badge: {
    color: '#fff',
    backgroundColor: '#ccc',
  },
})

export const TypeCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
}) => {
  const history = useHistory()

  const onClick = () => {
    if (isRowLinkable) {
      editPath &&
        history.push(
          editPath.replace(':id', resource[process.env.REACT_APP_RESOURCE_ID])
        )
    }
  }

  const classes = useStyles()
  const products = prop(resource, 'numProducts')
  const tickets = prop(resource, 'numTickets')

  return (
    <div onClick={onClick} className={classes.image}>
      <div className={classes.title}>
        <Badge
          overlap="circular"
          badgeContent={products}
          showZero
          classes={{ badge: products ? classes.green : classes.badge }}
          style={{ marginBottom: 5 }}
        >
          <Avatar className={products ? classes.green : null}>
            <WorkOutlineIcon />
          </Avatar>
        </Badge>
        <Badge
          badgeContent={tickets}
          showZero
          overlap="circular"
          classes={{ badge: tickets ? classes.green : classes.badge }}
        >
          <Avatar className={tickets ? classes.green : null}>
            <ConfirmationNumberOutlinedIcon />
          </Avatar>
        </Badge>
      </div>
    </div>
  )
}
