import { tokenExpiration } from './tokenExpiration'

const INTERVAL_PERIOD = 10 * 1000
const EXP_LIMIT = 2 * 6 * INTERVAL_PERIOD

let interval = null

const start = (token, dispatch, logout, refresh) => {
  if (interval) {
    clearInterval(interval)
  }

  interval = setInterval(() => {
    const exp = token && tokenExpiration(token)
    if (!exp) {
      dispatch(logout())
    } else if (Date.now() > exp - EXP_LIMIT) {
      dispatch(refresh())
    }
  }, INTERVAL_PERIOD)
}

const clear = () => {
  if (!interval) {
    return
  }

  clearInterval(interval)
  interval = null
}

export const tokenRefresh = {
  start,
  clear,
}
