import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { CustomSwitch } from './CustomSwitch'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { profileConstants } from 'core/_constants'
import { notification } from 'core/_helpers/notification'

export const ContextualHintSwitch = ({ profileEndpoint }) => {
  const value = useSelector(state => state.profile?.showContextualHints)
  const dispatch = useDispatch()

  const onChange = e => {
    const showContextualHints = e.target.checked

    fetchDataHandleAuthError(
      profileEndpoint,
      'PATCH',
      { body: JSON.stringify({ showContextualHints }) },
      response => {
        const showContextualHints = response.showContextualHints

        dispatch({
          type: profileConstants.UPDATE,
          payload: { showContextualHints },
        })

        notification(
          'success',
          `T_FORM_CONTEXTUAL_HINTS_${showContextualHints ? 'ON' : 'OFF'}`,
          'T_FORM_SUCCESS'
        )
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  return value === undefined ? null : (
    <CustomSwitch
      name="contextual_hints_switch"
      checked={value}
      onChange={onChange} />
  )
}

ContextualHintSwitch.propTypes = {
  profileEndpoint: PropTypes.string.isRequired,
}
