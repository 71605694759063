export const addOpenformProperties = (
  isOpenform,
  properties,
  resource,
  schema
) => {

  let modifiedProperties = JSON.parse(JSON.stringify(properties))

  if (isOpenform) {
    return {
      ...modifiedProperties,
      idName: {
        type: 'string',
        description: {
          text: 'T_GENERAL_OPENFORM_PAGE_IDNAME',
          color: '#cc0000',
        },
      },
    }
  }

  return modifiedProperties
}
