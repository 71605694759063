

const schema = {
  endpoint: '/api/newsletter_recipients',
  resource: {
    definition: 'NewsletterRecipient-newsletterRecipient.read',
    properties: {

      email: {},
      lang: {},
      consentRodo:{},
      consentMarketing:{},
      stat: {},
      createdAt: {
        label: 'Data i czas dodania',
        type: 'datetime',
        readonly: true,
        disabled:true,
      },
      isTest:{}
    },
  },
}

export default schema
