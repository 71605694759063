export const textColumn = () => ({
  name: 'textColumn',
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_COLUMN',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
          // hint: 'T_GENERAL_BLOCK_TYPE_TEXT_TITLE_HINT'
        },
      },
    },
    elements: {
      type: 'collection',
      endpoint: '/api/block_elements',
      definition: 'BlockElement-blockElement.read',
      description: 'T_GENERAL_BLOCK_TYPE_TEXT_COLUMN_ADD',
      additionalProperties: {
        $ref:
          '#/definitions/BlockElement-blockElement.write_blockElement.create',
      },
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
            description: {
              type: 'tinymce',
            },
            link: {},
          },
        },
        stat: {},
      },
    },
    anchor: {},
  },
})
