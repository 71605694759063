import React, { useState, useCallback, useEffect } from 'react'
// import PropTypes from 'prop-types'
import clsx from 'clsx'
import randomHash from 'random-hash'
import {
  TextField,
  FormControl,
  FormHelperText,
  Popper,
  Chip,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'
// import { prop } from 'core/_helpers/prop'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
  },
  formControllFullWidth: {
    minWidth: '100%',
  },
  autocomplete: {
    minWidth: 260,
  },
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}))

export const MultiChoiceType = ({
  name,
  label,
  hint = null,
  initialValue = null,
  value,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  fullWidth = false,
  choices,
}) => {
  const [id] = useState(randomHash())

  // const [choices, setChoices] = useState(choiceses || [])

  const handleChange = (e, options) => {
    setValue(
      name,
      options.map(option => option.value.toString())
    )
    validateField(options.map(option => option.value.toString()))
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  useEffect(() => {
    if (value === null && !initialValue) {
      setValue(name, [])
    }
  }, [setValue, name, value, initialValue])

  const options = Object.keys(choices).map(k => ({
    value: k.toString(), //resource['@id'],
    title: choices[k].toString(), //prop(resource, titleAccessor),
  }))

  // console.log(value)
  const classes = useStyles()

  return (
    <FormControl
      className={clsx(
        classes.formControl,
        fullWidth && classes.formControllFullWidth
      )}
      error={renderError && !!error}
      disabled={disabled || !Object.keys(choices).length}
    >
      <Autocomplete
        id={id}
        name={name}
        options={options}
        multiple
        getOptionLabel={option => option.title}
        getOptionSelected={(option, value) => {
          return option.value.toString() === value.value.toString()
        }}
        onChange={handleChange}
        value={
          getValues(value)?.map(value => ({
            value: value.toString(),
            title:
              options.find(
                option => option.value.toString() === value.toString()
              )?.title || '',
          })) || []
        }
        renderInput={params => (
          <TextField
            {...params}
            label={
              translate(label.text || label) +
              (validators && validators.includes('required') ? ' *' : '')
            }
            variant="outlined"
          />
        )}
        renderTags={(valuex, getTagProps) =>
          valuex.map((option, index) => (
            <Chip
              variant="outlined"
              label={option.title}
              {...getTagProps({ index })}
            />
          ))
        }
        PopperComponent={props => (
          <Popper
            {...props}
            style={{ width: 'auto' }}
            placement="bottom-start"
          />
        )}
        disabled={disabled || !Object.keys(choices).length}
        classes={{ root: classes.autocomplete }}
      />
      <FormHelperText>
        {translate(renderError && error ? error : hint)}
      </FormHelperText>
      {/* {compare && (
        <div
          className={clsx(
            classes.compare,
            getValues(value).toString() !==
              getValues(compareValue).toString() && classes.compareNeq
          )}
        >
          {compareValue &&
            getValues(compareValue)
              .map(value => {
                const resource = Object.keys(choices).find(
                  key => choices[key] === value
                )

                if (!resource) {
                  return null
                }

                return resource //prop(resource, titleAccessor)
              })
              .join(', ')}
        </div>
      )} */}
    </FormControl>
  )
}

const getValues = values => {
  if (!values || values === null) {
    return []
  }

  if (typeof values === 'object' && !Array.isArray(values)) {
    return Object.keys(values)
  } else return values
}

MultiChoiceType.propTypes = {}
