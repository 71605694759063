
const schema = {
  endpoint: '/api/iart_collections',
  resource: {
    definition: 'IartCollection-iartCollection.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            disabled:true
          },

          description: {
            type: 'textarea', //'tinymce',
            disabled:true
          },

          dateTitle: {
            disabled:true
          },

          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {
            type: 'slug',
            routes: {
              pl: '/kolekcja/:slug',
              en: '/en/collection/:slug',
              // he: '/he/:slug'
            },
          },
          preview: {
            label: 'Podgląd',
            description:'',
            type: 'preview',
            routes: {
              pl: '/kolekcja/:slug',
              en: '/en/collection/:slug',
            },
          },
        },
      },

      numberInventory:{disabled:true},
      dimensions:{disabled:true},
      importId:{disabled:true},

    },
  },
  subresources: {
  },
}

export default schema
