import React, { useMemo } from 'react'
import { Paper } from 'core/components/Paper'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { operations } from 'core/components/table/_columns/operations'
import { translate } from 'core/_helpers/translate'
import schema from '_schema/sobiescianaAttribute'
import routes from '../routes'

export const Collection = ({ ...rest }) => {

  const pid = rest.match.params.pid

  const columns = useMemo(
    () => [

      {
        header: `${translate('T_GENERAL_TITLE')}`,
        accessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        filterName: 'translations.title',
        filterable: true,
        sortable: false,
        width: '77%',
      },
      {
        ...operations(
          `translations.${process.env.REACT_APP_LOCALE}.title`,
          true,
          true,
          true,
          true
        ),
        width: '13%',
      },
    ],
    []
  )


  const defaultFilters = {
    'exists[parent]': true,
    'pagination': true,
    'parent.uuid':pid,
  }

  const collectionId = schema.endpoint

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('Dodaj atrybut')}
        buttonPath={routes().attribute_new.path.replace(':pid',pid)}
      >
        {translate('Atrybuty')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        defaultFilters={defaultFilters}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'asc' }}
        orderable={true}
        isRowLinkable={true}
        editPath={routes().attribute_edit.path.replace(':pid',pid)}
        autoWidth={false}
        storeCollectionId={`${collectionId}-${pid}`}
      />
    </Paper>
  )
}
