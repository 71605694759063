import React, { useCallback } from 'react'
import { Form } from 'core/pages'
import { SidebarNotStatable } from 'core/components/resource/sidebar'
import schema from '_schema/place'
import routes from './routes'
import { store } from 'core/_store'
import { collectionConstants } from 'core/_constants'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const handleSuccess = useCallback((resource, defaultSucces) => {
    if (schema.extraStoreCollectionId) {
      store.dispatch({
        type: collectionConstants.RESET_CONFIG,
        payload: { id: schema.extraStoreCollectionId },
      })
    }
    defaultSucces(resource)
  }, [])

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor={`translations.${process.env.REACT_APP_LOCALE}.title`}
      SidebarComponent={SidebarNotStatable}
      width='100%'
      handleSuccess={handleSuccess}
      handleSuccessAndStay={handleSuccess}
    />
  )
}
