// import { Sidebar } from 'core/components/resource/sidebar'
import React from 'react'
import { useStyles } from 'core/components/resource/sidebar/Sidebar.styles'
import {
  Button,
  FormControlLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { notification } from 'core/_helpers/notification'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'

export const SidebarWithSendTestEmail = ({
  resource,
  versionable = false,
  titleAccessor = 'title',
  collectionPath,
  disabled = false,
}) => {
  const defaultClasses = useStyles()

  const [btnDisable, setDisable] = React.useState(false)
  const [email, setEmail] = React.useState(null)
  const [hasemail, setHasEmail] = React.useState(true)
  const [gender, setGender] = React.useState('both')
  const [lang, setLang] = React.useState('pl')

  const handleChangeEmail = event => {
    setEmail(event.target.value)
    if (event.target.value) {
      setHasEmail(true)
    }
  }

  const handleChangeGender = event => {
    setGender(event.target.value)
  }

  const handleChangelang = event => {
    setLang(event.target.value)
  }

  const statusChangeSucces = res => {
    setDisable(false)

    notification('success', 'Email wysłany.', 'T_FORM_SUCCESS')
  }
  const statusChangeError = res => {
    setDisable(false)

    notification('error', 'Email nie wysłany.', 'T_FORM_ERROR')
  }

  const handleClick = event => {
    if (!email) {
      setHasEmail(false)
      return
    }
    setHasEmail(true)
    setDisable(true)

    //fetch ..........
    fetchDataHandleAuthError(
      '/api/order_email_test/' + resource.uuid,
      'POST',
      { body: JSON.stringify({ email: email, gender: gender, lang }) },
      statusChangeSucces,
      statusChangeError
    )
  }

  return (
    <List className={defaultClasses.list}>
      <ListItem className={defaultClasses.item} style={{ flexWrap: 'wrap', alignItems:'top' }}>
        <TextField
          id="standard-basic"
          label="Email"
          required
          value={email}
          onChange={handleChangeEmail}
          style={{ width: '100%' }}
          error={!hasemail}
        />
        <div style={{ width: '100%', marginBottom: 20 }}></div>
        <RadioGroup
          aria-label="gender"
          name="gender"
          value={gender}
          onChange={handleChangeGender}
          style={{marginRight:40}}
        >
          <FormControlLabel
            value="ticket"
            control={<Radio />}
            label="tylko bilet"
          />
          <FormControlLabel
            value="product"
            control={<Radio />}
            label="tylko produkt"
          />
          <FormControlLabel
            value="both"
            control={<Radio />}
            label="bilet i produkt"
          />
        </RadioGroup>

        <RadioGroup
          aria-label="languasasd"
          name="languasasd"
          value={lang}
          onChange={handleChangelang}
        >
          <FormControlLabel value="pl" control={<Radio />} label="polski" />
          <FormControlLabel value="en" control={<Radio />} label="angielski" />
        </RadioGroup>
        <div style={{ width: '100%', marginBottom: 20 }}></div>
        <Button
          color="primary"
          size={'small'}
          disabled={btnDisable}
          variant="contained"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          Wyslij email testowy
        </Button>
      </ListItem>
    </List>

    // <Sidebar
    //   resource={resource}
    //   statable={false}
    //   deletable={false}
    //   versionable={versionable}
    //   titleAccessor={titleAccessor}
    //   collectionPath={collectionPath}
    //   disabled={disabled}
    // />
  )
}

SidebarWithSendTestEmail.propTypes = {}
