
const schema = {
  endpoint: '/api/main_menus',
  resource: {
    definition: 'MainMenu-mainMenu.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Title',
            hint: 'jeśli wpisany, będzie użyty zamiast nazwy strony'
          },
          // link: {
          //   hint: 'wpisz link lub wybierz stronę poniżej'
          // },
        },
      },

      page: {
        type: 'resource',
        endpoint: `/api/pages_form?pagination=false&pageType=page&exists[versionable]=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,//'uuid',
        label: 'T_BLOCK_ELEMENT_LABEL_PAGE',
        storeCollectionId: 'pages_form_type_page',
      },

      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
