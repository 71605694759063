import React, { useMemo } from 'react'
import { Paper } from 'core/components/Paper'
import { Title } from 'core/components/Title'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { standard } from 'core/components/table/_columns/standard'
import { DateTimeCell } from 'core/components/table/cells/DateTimeCell'
import { ModuleCell } from './table/cells/ModuleCell'
import { MethodCell } from './table/cells/MethodCell'
import { translate } from 'core/_helpers/translate'
import schema from 'core/_schema/registryEntry'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      { ...standard('username'), width: '17%' },
      {
        accessor: 'requestUri',
        header: translate('T_GENERAL_MODULE'),
        filterable: false,
        sortable: false,
        width: '20%',
        Cell: ModuleCell,
      },
      {
        accessor: 'requestMethod',
        header: translate('T_GENERAL_OPERATION'),
        filterable: false,
        sortable: false,
        width: '11%',
        Cell: MethodCell,
      },
      {
        accessor: 'resourceUuid',
        header: translate('T_GENERAL_RECORD'),
        filterable: true,
        sortable: false,
        width: '21%',
      },
      { ...standard('ip'), width: '12%' },
      {
        ...standard('createdAt'),
        Cell: DateTimeCell,
        header: translate('T_GENERAL_OPERATION_DATE'),
        width: '12%',
      },
    ],
    []
  )

  return (
    <Paper>
      <Title>{translate(routes().index.title)}</Title>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        autoWidth={false}
        defaultSorters={{ createdAt: 'desc' }}
      />
    </Paper>
  )
}
