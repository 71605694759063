const schema = {
  endpoint: '/api/payment_types',
  resource: {
    definition: 'PaymentType-paymentType.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Nazwa',
          },
          description: {
            type: 'textarea',
            label: 'Opis w podsumowaniu zakupu'
          }
        },
      },

      shippings: {
        type: 'multipleResource',
        endpoint: `/api/shipping_types?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,//'uuid',
        label: 'Shipping types',
      },

      isTransfer:{
        label: 'Przelew',
        hint: 'przelew tradycyjny na konto bankowe sklepu'
      },

      isPayu:{
        label: 'PayU',
        hint: 'metoda bedzie kierować do PayU w celu sfinalizowania transakcji'
      },

      stat: {},
    },
  },
}

export default schema
