export const addRoutesConfig = (routes, config) =>
  Object.assign(
    {},
    ...Object.keys(routes).map(key => ({
      [key]: {
        ...routes[key],
        ...config,
      },
    }))
  )
