import { constants } from 'core/components/table/_state'

export const handleSorter = (column, order, orderable, dispatch) =>
  dispatch({
    type: constants.HANDLE_COLUMN_SORTERS,
    payload: {
      sorters: Object.assign(
        orderable
          ? {
              ord: {
                order: null,
              },
            }
          : {},
        { [column]: { order } }
      ),
    },
  })
