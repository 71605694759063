import React from 'react'
import { Form } from 'core/pages'
import schema from '_schema/educationCategory'
import routes from './routes'

export const New = ({ schema: definitions }) => {
  const defaultData = { type: 4 }

  return (
    <Form
      defaultData={defaultData}
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      method="POST"
      url={schema.endpoint}
      collectionPath={routes().index.path}
      editPath={routes().edit.path}
      storeCollectionId={schema.endpoint}
      width={600}
    />
  )
}
