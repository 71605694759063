import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Paper } from 'core/components/Paper'
import { Loader } from 'core/components/Loader'
import { Title } from 'core/components/Title'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { DateTimeCell } from '../table/cells/DateTimeCell'
import { NotFound } from 'core/pages'
import { prop } from 'core/_helpers/prop'
import { translate } from 'core/_helpers/translate'
import { commonConstants } from 'core/_constants'
import { operations } from './_columns/operations'

const useStyles = makeStyles({
  title: {
    marginBottom: 25,
  },
})

export const VersionCollection = ({
  endpoint,
  versionableUuid,
  customResourceSchema,
  currentPath,
  versionableCollectionTitle,
  versionableCollectionPath,
  versionableEditPath,
  versionViewPath,
  onRestoreSuccess = null,
  titleAccessor = 'title',
  wrapWithPaper = true,
}) => {
  const columns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_TIME'),
        Cell: DateTimeCell,
        accessor: 'createdAt',
        width: '40%',
      },
      {
        accessor: 'versionAuthor',
        header: translate('T_GENERAL_ADMIN'),
        width: '45%',
      },
      { ...operations(onRestoreSuccess), width: '15%' },
    ],
    [onRestoreSuccess]
  )

  const defaultFilters = useMemo(
    () => ({
      [`versionable.${process.env.REACT_APP_RESOURCE_ID}`]: versionableUuid,
    }),
    [versionableUuid]
  )

  const [state, setState] = useResourceState()

  const { resource: versionable, isFetching, fetchError } = state

  useResourceFetch(
    `${endpoint}/${versionableUuid}`,
    versionable,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    true
  )

  const dispatch = useDispatch()

  const isNotVersionable = useMemo(() => versionable?.versionable, [
    versionable,
  ])

  useEffect(() => {
    if (isNotVersionable) {
      dispatch({ type: commonConstants.RESET_CURRENT_RESOURCES })
    }
  }, [isNotVersionable, dispatch])

  useEffect(() => {
    if (!versionable || isNotVersionable) {
      return
    }

    dispatch({
      type: commonConstants.ADD_CUSTOM_BREADCRUMBS,
      payload: {
        path: currentPath,
        breadcrumbs: [
          {
            title: versionableCollectionTitle,
            path: versionableCollectionPath,
          },
          {
            title: `${versionableCollectionTitle} (${prop(
              versionable,
              titleAccessor
            )})`,
            path: versionableEditPath.replace(
              ':id',
              versionable[process.env.REACT_APP_RESOURCE_ID]
            ),
          },
          {
            title: translate('T_GENERAL_RECORD_VERSION_COLLECTION'),
            path: currentPath,
          },
          {
            title: 'T_GENERAL_RECORD_LIST',
          },
        ],
      },
    })
  }, [
    versionable,
    isNotVersionable,
    currentPath,
    versionableCollectionTitle,
    versionableCollectionPath,
    versionableEditPath,
    titleAccessor,
    dispatch,
  ])

  const classes = useStyles()

  const WrapComponent = wrapWithPaper ? Paper : 'div'

  return isFetching ? (
    <WrapComponent>
      <Loader />
    </WrapComponent>
  ) : fetchError ? (
    <NotFound />
  ) : isNotVersionable ? (
    <Redirect to={versionableCollectionPath} />
  ) : (
    <WrapComponent>
      <Title classes={{ root: classes.title }}>
        {translate('T_GENERAL_RECORD_VERSION_COLLECTION')}
      </Title>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${endpoint}.get`}
        endpoint={endpoint}
        storeCollectionId={currentPath}
        columns={columns}
        customResourceSchema={customResourceSchema}
        defaultFilters={defaultFilters}
        defaultSorters={{ createdAt: 'desc' }}
        orderable={false}
        resetable={false}
        isRowLinkable={false}
        editPath={versionableEditPath}
        viewPath={versionViewPath}
        autoWidth={false}
      />
    </WrapComponent>
  )
}

VersionCollection.propTypes = {
  endpoint: PropTypes.string.isRequired,
  versionableUuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  customResourceSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  currentPath: PropTypes.string.isRequired,
  versionableCollectionTitle: PropTypes.string.isRequired,
  versionableCollectionPath: PropTypes.string.isRequired,
  versionableEditPath: PropTypes.string.isRequired,
  versionViewPath: PropTypes.string.isRequired,
  onRestoreSuccess: PropTypes.func,
  titleAccessor: PropTypes.string,
  wrapWithPaper: PropTypes.bool,
}
