import React from 'react'
import { TableRow } from '@material-ui/core'
import { sortableElement } from 'react-sortable-hoc'

export const ManuallyOrderableTableRow = sortableElement(
  ({ className, index, children }) => (
    <TableRow className={className} index={index}>
      {children}
    </TableRow>
  )
)
