import { translate } from 'core/_helpers/translate'

export const prepareColumn = (column, properties) => {
  let additionalSettings = {}

  if (!column.header && column.accessor) {
    const accessor = column.accessor.split('.')[0]
    const description = properties[accessor]?.description

    additionalSettings = {
      header: translate(description.text || description),
    }
  }

  return {
    ...column,
    ...additionalSettings,
  }
}
