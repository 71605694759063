const schema = {
  endpoint: '/api/package_faqs',
  resource: {
    definition: 'PackageFaq-packageFaq.read',
    properties: {
      title: {
        validate: ['required'],
      },
    },
  },
  subresources: {
    item: {
      endpoint: '/api/package_faq_items',
      definition: 'PackageFaqItem-packageFaqItem.read',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
            description: {
              type: 'tinymce2'
            },
          },
        },
      },
    },
  },
}

export default schema
