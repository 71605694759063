export const applyProperties = (
  baseProperties,
  additionalProperties,
  method = 'apply'
) => {
  if (!additionalProperties) {
    return baseProperties
  }

  return Object.keys(baseProperties).reduce(
    method === 'apply'
      ? (obj, name) =>
          Object.assign(obj, {
            [name]: { ...baseProperties[name], ...additionalProperties[name] },
          })
      : (obj, name) =>
          Object.assign(obj, {
            [name]: {
              ...baseProperties[name],
              ...Object.assign(
                {},
                ...Object.keys(additionalProperties[name] || {})
                  .map(
                    prop =>
                      !baseProperties[name][prop] && {
                        [prop]: additionalProperties[name][prop],
                      }
                  )
                  .filter(prop => prop)
              ),
            },
          }),
    {}
  )
}
