import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
// import { TextField } from '@material-ui/core'
// import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
// import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
// import Select from '@material-ui/core/Select'
import { Autocomplete } from '@material-ui/lab'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { TextField } from '@material-ui/core'
import { prop } from 'core/_helpers/prop'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles({
  input: {
    textAlign: 'left',
    width: 160,
    marginLeft: 4,
    marginRight: 4,
    '& input': {
      paddingTop: 13,
    },
  },
})

export const ResourceFilter = ({
  name,
  title,
  value,
  disabled,
  handleEnter,
  setFilter,
  classes = {},
  extraparams
}) => {

  const endpoint = extraparams.endpoint
  const titleAccessor = extraparams.accessor
  const [choices, setChoices] = useState([])

  const handleChange = (e, option) => {
    const value = option ? option.value : null
    setFilter(name, value || null)
  }

  useEffect(() => {
    if (!endpoint) {
      return
    }

    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      endpoint,
      'GET',
      { signal },
      response => {
        setChoices(response['hydra:member'])
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [endpoint, setChoices])


  const defaultClasses = useStyles()

  const options = choices.map(resource => ({
    value: resource.uuid,
    title: translate(prop(resource, titleAccessor)),
  }))

  return (
    <div>
      <FormControl className={clsx(defaultClasses.input, classes.input)}>
        {/* <InputLabel id={`demo-controlled-open-select-label-${name}`}>{title}</InputLabel> */}

        <Autocomplete
          id={`combo-box-demo-${name}`}
          getOptionLabel={option => option.title}
          getOptionSelected={(option, value) => {
            return option.value.toString() === value.value.toString()
          }}
          onChange={handleChange}
          options={options}
          value={
            value
              ? {
                  value: value,
                  title:
                    options.find(option => option.value === value)?.title || '',
                }
              : null
          }
          disabled={disabled}
          renderInput={params => (
            <TextField {...params} label={title} />
          )}
        />

        <FormHelperText>{title}</FormHelperText>
      </FormControl>
    </div>
  )
}

ResourceFilter.propTypes = {}
