import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'core/_helpers/prop'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  div: {
    cursor: 'pointer',
  },
})

export const TextCell = ({ resource, accessor, setIsInput }) => {
  const onClick = () => {
    setIsInput(true)
  }

  const classes = useStyles()

  return (
    <div onClick={onClick} className={classes.div}>
      {prop(resource, accessor)}
    </div>
  )
}

TextCell.propTypes = {
  resource: PropTypes.object.isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  setIsInput: PropTypes.func.isRequired,
}
