import React, { useMemo } from 'react'
import { HomeBox } from 'pages/Home/HomeBox'
import { standard } from 'core/components/table/_columns/standard'
import { operations } from 'core/components/table/_columns/operations'
import { translate } from 'core/_helpers/translate'
import schema from 'core/_schema/admin'
import routes from 'core/pages/Admins/routes'

export const Admins = () => {
  const columns = useMemo(
    () => [
      {
        ...standard('fullName'),
        width: '45%',
      },
      {
        ...standard('email'),
        width: '45%',
      },
      {
        ...operations(null, false, false, false, true),
        header: translate('T_GENERAL_DATE'),
        width: '10%',
      },
    ],
    []
  )

  return (
    <HomeBox
      columns={columns}
      route={routes().index}
      endpoint={schema.endpoint}
      isRowLinkable={true}
      editPath={routes().edit.path}
    />
  )
}
