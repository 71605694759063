import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    color: theme.elements.tab.body.color,
    backgroundColor: theme.elements.tab.body.background,
    marginBottom: 35,
    borderRadius: 4,
    overflow: 'hidden'
  },
  bar: {
    display: 'flex',
    flexDirection: 'row',
    color: theme.elements.tab.header.color,
    backgroundColor: theme.elements.tab.header.background,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.disabled}`,
    '& .MuiTabs-root': {
      minHeight: 40
    },
    '& .MuiTab-root': {
      textTransform: 'lowercase',
      borderRight: `1px solid ${theme.palette.disabled}`,

      color: theme.palette.primary.main,
      opacity: 1,
      minHeight: 40
    },
    '& .Mui-selected': {
      backgroundColor: theme.elements.tab.body.background,
      color: theme.elements.tab.body.color,
    }
  },
  indicator: {
    height: 0
  },
  invalid: {
    backgroundColor: theme.palette.error.main,
  },
  contextualHint: {
    display: 'flex',
    flexGrow: 2,
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: 15,
  },
}))
