import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { TextField } from '@material-ui/core'
import { notification } from 'core/_helpers/notification'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'
import { prop } from 'core/_helpers/prop'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import buildUrl from 'build-url'

const useStyles = makeStyles({
  header: {
    fontSize: 14,
    marginBottom: 10,
  },
  chosen: {
    color: 'gray',
    marginBottom: 10,
  },
  choices: {
    marginTop: 10,
    '&>*:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
})

export const AutocompleteType = ({
  name,
  label,
  titleProperty = 'title',
  searchProperty = 'title',
  hint = null,
  endpoint,
  initialValue,
  value,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  parentProperty = null,
  parent = null,
}) => {
  const [text, setText] = useState('')
  const [choices, setChoices] = useState([])
  const [fullValue, setFullValue] = useState(null)

  const handleTextChange = e => {
    const value = e.target.value
    setText(value)
  }

  const handleTextKeyUp = e => {
    const value = e.target.value

    if (value.length < 3) {
      setChoices([])

      return
    }

    const controller = new AbortController()
    const { signal } = controller

    const endpointSplit = endpoint.split('?')
    const url = buildUrl(endpointSplit[0], {
      queryParams: {
        [searchProperty]: text,
        ...(parent ? { [parentProperty]: parent } : {}),
      },
    })

    fetchDataHandleAuthError(
      `${url}${endpointSplit[1] ? '&' + endpointSplit[1] : ''}`,
      'GET',
      { signal },
      response => {
        setChoices(response['hydra:member'])
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const handleChoose = e => {
    const uuid = e.target.getAttribute('uuid')
    const value = choices.find(choice => choice.uuid === uuid)

    setValue(name, value['@id'])
    setFullValue(value)
    validateField(value)

    setText('')
    setChoices([])
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  const setResource = res => {
    setFullValue(res)
  }

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  useEffect(() => {
    if (fullValue === null && value !== null) {
      fetchDataHandleAuthError(value, 'GET', null, setResource)
    }
  }, [fullValue, value])

  const classes = useStyles()

  return (
    <>
      <div className={classes.header}>
        {translate(label?.text || label) +
          (validators && validators.includes('required') ? ' *' : '')}
      </div>
      <div className={classes.chosen}>
        WYBRANY: {prop(fullValue, titleProperty)}
      </div>
      <TextField
        value={text}
        onChange={handleTextChange}
        onKeyUp={handleTextKeyUp}
        disabled={disabled}
        error={renderError && !!error}
        helperText={translate(renderError && error ? error : hint)}
        InputLabelProps={
          label?.color && {
            style: {
              color: label.color,
            },
          }
        }
        placeholder="Wpisz, aby zobaczyć podpowiedzi i wybrać"
        variant="standard"
        style={{
          width: 400,
        }}
      />
      <div className={classes.choices}>
        {choices.map(choice => (
          <div uuid={choice.uuid} onClick={handleChoose} key={choice.uuid}>
            {prop(choice, titleProperty)}
          </div>
        ))}
      </div>
    </>
  )
}

AutocompleteType.propTypes = {}
