import React, { useMemo } from 'react'
import { NotFound } from 'core/pages'
import { Paper } from 'core/components/Paper'
import { Loader } from 'core/components/Loader'
import { EditHeader } from 'core/components/EditHeader'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { ResourceMediaAddMultipleDialog } from 'core/components/ResourceMediaAddMultipleDialog'
import { operations } from 'core/components/table/_columns/operations'
import { applyProperties } from 'core/_helpers/applyProperties'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { translate } from 'core/_helpers/translate'
import { publish } from 'core/components/table/_mass'
import { MainCell } from './table/cells/MainCell'
import schema from 'core/_schema/packageLogotype'
import routes from './routes'
import itemSchema from 'core/_schema/packageLogotypeItem'
import itemRoutes from 'core/pages/PackageLogotypeItems/routes'
import { prop } from 'core/_helpers/prop'

export const Show = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const parentIri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    parentIri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false
  )

  const getTitle = resource => {
    const title = prop(
      resource,
      `translations.${process.env.REACT_APP_LOCALE}.title`
    )
    return title === null ? translate('T_GENERAL_MISSING_TITLE') : title
  }

  const itemColumns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_TITLE'),
        accessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        filterName: 'translations.title',
        filterable: true,
        Cell: MainCell,
        width: '73%',
      },
      {
        ...operations(getTitle),
        width: '15%',
      },
    ],
    []
  )

  const itemDefaultFilters = {
    [`parent.${process.env.REACT_APP_RESOURCE_ID}`]: match.params.id,
  }

  const itemMassProperties = useMemo(
    () =>
      applyProperties(
        {
          translations: itemSchema.resource.properties.translations,
          stat: itemSchema.resource.properties.stat,
        },
        definitions[itemSchema.resource.definition].properties,
        'missing'
      ),
    [definitions]
  )

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_PACKAGE_GALLERY_ITEMS_NEW_BUTTON')}
        buttonPath={`${itemRoutes().new.path}?pid=${match.params.id}`}
        additionalButtons={
          <ResourceMediaAddMultipleDialog
            title={translate(
              'T_MODULE_PACKAGE_GALLERY_ITEMS_NEW_MULTIPLE_BUTTON'
            )}
            parentIri={parentIri}
            fileEndpoint={itemSchema.resource.properties.photo.endpointMultiple}
            itemEndpoint={itemSchema.endpoint}
            itemProperties={itemMassProperties}
          />
        }
      >
        <EditHeader resource={resource} editPath={routes().edit.path} />
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${itemSchema.endpoint}.get`}
        endpoint={itemSchema.endpoint}
        storeCollectionId={parentIri}
        columns={itemColumns}
        customResourceSchema={itemSchema.resource}
        defaultFilters={itemDefaultFilters}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={itemRoutes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
