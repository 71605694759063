import React, { useState, useCallback, useEffect } from 'react'
import clsx from 'clsx'
import randomHash from 'random-hash'
import {
  FormControl,
  TextField,
  FormHelperText,
  Popper,
  Chip,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'
import { prop } from 'core/_helpers/prop'
import { store } from 'core/_store'
import { collectionConstants } from 'core/_constants'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
    marginTop: 10,
  },
  formControllFullWidth: {
    minWidth: '100%',
  },
  autocomplete: {
    minWidth: 260,
  },
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}))

export const ResourceAttrType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  endpoint = null,
  resources = null,
  titleAccessor,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  fullWidth = false,
  storeCollectionId = null,
  // uuid = null,
  name2 = null,
  label2 = null,
  titleAccessor2,
  endpoint2,
  getValue,
}) => {

  let p = getValue('parent').split('/')
  endpoint = `${endpoint}&parent=${p[p.length-1]}`

  const [id] = useState(randomHash())
  const [id2] = useState(randomHash())

  const [options, setOptions] = useState([])
  const [choices, setChoices] = useState(resources || [])

  const [value2, setValue2] = useState(getValue(name2))
  const [options2, setOptions2] = useState([])
  const [choices2, setChoices2] = useState([])

  const handleChange = (e, option) => {
    setValue(name, option ? option.value : null)
    validateField(option ? option.value : null)
    setValue2([])
    setValue(name2, null)
    getAjaxValuesWhenChange(option ? option.value : null)
  }
  const handleChange2 = (e, options) => {
    setValue(name2, options.map(option => option.value))
    setValue2(options.map(option => option.value))
    validateField(options.map(option => option.value))
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  function Tcompare(a, b) {
    if (a.translations?.pl.title && b.translations?.pl.title) {
      return new Intl.Collator().compare(
        a.translations.pl.title,
        b.translations.pl.title
      )
    } else if (a.title && b.title) {
      return new Intl.Collator().compare(
        a.title,
        b.title
      )
    }
    return 0
    // if (a.translations.pl.title < b.translations.pl.title) { return -1 } if (a.translations.pl.title > b.translations.pl.title) { return 1  } return 0
  }

  useEffect(
    () => {
      if (!endpoint || resources) {
        return
      }

      const tt =
        parseInt(Math.random() * 5000, 10) * (storeCollectionId ? 1 : 0)

      setTimeout(function() {
        // store.dispatch({ type: collectionConstants.RESET_CONFIG, payload: {id : storeCollectionId} })
        if (
          storeCollectionId &&
          store.getState().collection[storeCollectionId]?.items
        ) {
          const s = store.getState().collection[storeCollectionId].items
          setChoices(s)
        } else {
          const controller = new AbortController()
          const { signal } = controller

          fetchDataHandleAuthError(
            endpoint,
            'GET',
            { signal },
            response => {
              let xsc = response['hydra:member']
              if (xsc.length) {
                xsc = xsc.sort(Tcompare)
              }
              setChoices(xsc)
              getAjaxValuesWhenChange(value)

              const data = {
                id: storeCollectionId,
                config: { items: xsc },
              }

              store.dispatch({
                type: collectionConstants.SET_CONFIG,
                payload: data,
              })
            },
            error => {
              if (error.response.title === 'AbortError') {
                return
              }

              notification('error', error.response.detail, error.response.title)
            }
          )
          return () => controller.abort()
        }
      }, tt)
    },
    // eslint-disable-next-line
    [resources]
  )

  useEffect(() => {
    const opt = choices?.map(resource => ({
      value: resource['@id'],
      title: prop(resource, titleAccessor, translate('T_GENERAL_MISSING_TITLE')),
    }))
    setOptions(opt)
  }, [choices, titleAccessor])

  useEffect(() => {
    const opt2 = choices2?.map(resource => ({
      value: resource['@id'],
      title: prop(resource, titleAccessor2, translate('T_GENERAL_MISSING_TITLE')),
    }))
    setOptions2(opt2)
  }, [choices2, titleAccessor2])

  const classes = useStyles()

  const value2Change =
    getValues(value2)?.map(value => ({
      value: value,
      title: options2.find(option => option.value === value)?.title || translate('T_GENERAL_MISSING_TITLE'),
    })) || []

  const getAjaxValuesWhenChange = valueSel => {
    if(!valueSel) return;

    const controller = new AbortController()
    const { signal } = controller

    endpoint2 = endpoint2.split('&parent=')[0]
    const splitValue = valueSel.split('/')
    endpoint2 = `${endpoint2}&parent=${splitValue[splitValue.length - 1]}`

    fetchDataHandleAuthError(
      endpoint2,
      'GET',
      { signal },
      response => {
        setChoices2(response['hydra:member'])
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }
        notification('error', error.response.detail, error.response.title)
      }
    )
    return () => controller.abort()
  }

  return (
    <>
      <FormControl
        className={clsx(
          classes.formControl,
          fullWidth && classes.formControllFullWidth
        )}
        error={renderError && !!error}
      >
        <Autocomplete
          id={id}
          name={name}
          options={options}
          getOptionLabel={option => option.title}
          getOptionSelected={(option, value) => {
            return option.value === value.value
          }}
          onChange={handleChange}
          value={
            getValu(value)
              ? {
                  value: getValu(value),
                  title:
                    options.find(option => option.value === getValu(value))
                      ?.title || '',
                }
              : null
          }
          renderInput={params => (
            <TextField
              {...params}
              label={
                translate(label.text || label) +
                (validators && validators.includes('required') ? ' *' : '')
              }
              variant="outlined"
            />
          )}
          PopperComponent={props => (
            <Popper
              {...props}
              style={{ width: 'auto' }}
              placement="bottom-start"
            />
          )}
          disabled={disabled || !choices.length}
          classes={{ root: classes.autocomplete }}
        />
        <FormHelperText>
          {translate(renderError && error ? error : hint)}
        </FormHelperText>
        {compare && (
          <div
            className={clsx(
              classes.compare,
              getValu(value) !== getValu(compareValue) && classes.compareNeq
            )}
          >
            {compareValue &&
              options.find(option => option.value === getValu(compareValue))
                ?.title}
          </div>
        )}
      </FormControl>

      <FormControl
        className={clsx(
          classes.formControl,
          fullWidth && classes.formControllFullWidth
        )}
        error={renderError && !!error}
        disabled={disabled || !choices2.length}
      >
        <Autocomplete
          id={id2}
          name={name2}
          options={options2}
          multiple
          getOptionLabel={option => option.title}
          getOptionSelected={(option, value) => {
            return option.value === value.value
          }}
          onChange={handleChange2}
          value={value2Change}
          renderInput={params => (
            <TextField
              {...params}
              label={
                translate(label2.text || label2) +
                (validators && validators.includes('required') ? ' *' : '')
              }
              variant="outlined"
            />
          )}
          renderTags={(value, getTagProps) =>
            value?.map((option, index) => (
              <Chip
                variant="outlined"
                label={option.title}
                {...getTagProps({ index })}
              />
            ))
          }
          PopperComponent={props => (
            <Popper
              {...props}
              style={{ width: 'auto' }}
              placement="bottom-start"
            />
          )}
          disabled={disabled || !choices2.length}
          classes={{ root: classes.autocomplete }}
        />
        <FormHelperText>
          {translate(renderError && error ? error : hint)}
        </FormHelperText>
        {compare && (
          <div
            className={clsx(
              classes.compare,
              getValues(value).toString() !==
                getValues(compareValue).toString() && classes.compareNeq
            )}
          >
            {compareValue &&
              getValues(compareValue)
                .map(value => {
                  const resource = choices2.find(
                    resource => resource['@id'] === value
                  )

                  if (!resource) {
                    return null
                  }

                  return prop(resource, titleAccessor2)
                })
                .join(', ')}
          </div>
        )}
      </FormControl>
    </>
  )
}

const getValu = value => {
  if (!value) {
    return null
  }

  if (typeof value === 'string') {
    return value
  }

  return value['@id']
}

const getValues = values => {
  if (!values) {
    return []
  }

  return values.map(value => {
    if (typeof value === 'string') {
      return value
    }

    return value['@id']
  })
}
