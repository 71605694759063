import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { commonConstants } from 'core/_constants'
import { constants } from 'pages/Crews/table/_state'

export const performManualOrderChange = (
  items,
  oldIndex,
  newIndex,
  tableDispatch,
  storeDispatch
) => {
  const direction = oldIndex < newIndex ? 'down' : 'up'

  const previousNeighbour =
    direction === 'down'
      ? items[newIndex]
      : newIndex > 0
      ? items[newIndex - 1]
      : null

  const nextNeighbour =
    direction === 'down'
      ? newIndex + 1 < items.length
        ? items[newIndex + 1]
        : null
      : items[newIndex]

  let body = {}

  const oldParent = items[oldIndex].parent || null
  const previousNeighbourParent = previousNeighbour?.parent || null
  const nextNeighbourParent = nextNeighbour?.parent || null

  if (!previousNeighbour) {
    body = {
      newParent: null,
      newOrd: null === oldParent ? items[newIndex].ord : items[0].ord + 1,
    }
  } else if (!nextNeighbour) {
    body = {
      newParent: null === oldParent ? null : previousNeighbourParent,
      newOrd: null === oldParent ? items[newIndex].ord : 0,
    }
  } else if (previousNeighbour.parent === nextNeighbour.parent) {
    body = {
      newParent: previousNeighbour.parent || null,
      newOrd:
        previousNeighbourParent === oldParent
          ? items[newIndex].ord
          : previousNeighbour.ord,
    }
  } else {
    const newParent =
      nextNeighbourParent === previousNeighbour['@id']
        ? nextNeighbourParent
        : previousNeighbourParent

    body = {
      newParent,
      newOrd:
        newParent === oldParent
          ? items[newIndex].ord
          : nextNeighbourParent === previousNeighbour['@id']
          ? nextNeighbour.ord + 1
          : 0,
    }
  }

  tableDispatch({ type: constants.MANUAL_ORDER_CHANGE_START })

  fetchDataHandleAuthError(
    items[oldIndex]['@id'],
    'PATCH',
    {
      body: JSON.stringify(body),
    },
    () => {
      tableDispatch({ type: constants.MANUAL_ORDER_CHANGE_SUCCESS })
      notification('success', 'T_FORM_RECORD_UPDATED', 'T_FORM_SUCCESS')

      storeDispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })
    },
    error => {
      tableDispatch({ type: constants.MANUAL_ORDER_CHANGE_FAILURE })
      notification(
        'error',
        error.response.violations.length
          ? error.response.violations[0].message
          : error.response.detail,
        error.response.title
      )
    }
  )
}
