import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { VersionCollection as OriginalVersionCollection } from 'core/components/version'
import { translate } from 'core/_helpers/translate'
import { commonConstants } from 'core/_constants'
import schema from 'core/_schema/bip'
import routes from './routes'

export const VersionCollection = ({ schema: definitions, ...rest }) => {
  const { match, location } = rest

  const dispatch = useDispatch()

  const onRestoreSuccess = useCallback(
    () => dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD }),
    [dispatch]
  )

  return (
    <OriginalVersionCollection
      endpoint={schema.endpoint}
      versionableUuid={match.params.id}
      customResourceSchema={schema.resource}
      currentPath={location.pathname}
      versionableCollectionTitle={translate('BIP')}
      versionableCollectionPath={routes().index.path}
      versionableEditPath={routes().edit.path}
      versionViewPath={routes().version_view.path}
      onRestoreSuccess={onRestoreSuccess}
      titleAccessor={`translations.${process.env.REACT_APP_LOCALE}.title`}
    />
  )
}
