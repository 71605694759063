import { constants } from './constants'
import { reducer as originalReducer } from 'core/components/table/_state'
import { processNestedToFlatList } from 'core/pages/Pages/_helpers/processNestedToFlatList'

export const reducer = (state, action) => {
  let items, toggled

  switch (action.type) {
    case constants.SET_SINGLE_SELECT:
      return {
        ...state,
        data: {
          ...state.data,
          selected: state.data.selected.map((item, i) => ({
            value: i === action.payload.row ? !item.value : item.value,
            iri: item.iri,
          })),
        },
      }
    case constants.SET_MASS_SELECT:
      return {
        ...state,
        data: {
          ...state.data,
          selected: state.data.selected.map((item, i) => ({
            value: (typeof action.payload.isRowSelectable === 'boolean'
            ? action.payload.isRowSelectable
            : action.payload.isRowSelectable(state.data.items[i]))
              ? !state.data.selectAll
              : item.value,
            iri: item.iri,
          })),
          selectAll: !state.data.selectAll,
        },
      }
    case constants.FETCH_SUCCESS:
      items = processNestedToFlatList(
        action.payload.data.items,
        state.data.toggled
      )

      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
          items,
          rawItems: action.payload.data.items,
          selected: items.map(item => ({ value: false, iri: item['@id'] })),
          selectAll: false,
          totalItems: items.length,
        },
        init: false,
        isFetching: false,
      }
    case constants.MANUAL_ORDER_CHANGE_SUCCESS:
      return {
        ...state,
        config: {
          ...state.config,
        },
      }
    case constants.TOGGLE:
      toggled = {
        ...state.data.toggled,
        [action.payload]: !state.data.toggled[action.payload],
      }

      items = processNestedToFlatList(state.data.rawItems, toggled)

      return {
        ...state,
        data: {
          ...state.data,
          items: items,
          selected: items.map(page => ({
            value:
              state.data.selected.find(item => item.iri === page['@id'])
                ?.value || false,
            iri: page['@id'],
          })),
          selectAll: false,
          toggled,
        },
      }
    case constants.TOGGLE_ALL:
      toggled = Object.assign(
        {},
        ...state.data.rawItems.map(item => ({
          [item['@id']]: !state.data.toggledAll,
        }))
      )

      items = processNestedToFlatList(state.data.rawItems, toggled)

      return {
        ...state,
        data: {
          ...state.data,
          items: items,
          selected: items.map(page => ({
            value:
              state.data.selected.find(item => item.iri === page['@id'])
                ?.value || false,
            iri: page['@id'],
          })),
          selectAll: false,
          toggled,
          toggledAll: !state.data.toggledAll,
        },
      }
    default:
      return originalReducer(state, action)
  }
}
