import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {
    marginBottom: 32,
  },
})

export const Copyright = props => {
  const classes = useStyles()

  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      className={classes.root}
      {...props}
    >
      {'© ' + new Date().getFullYear() + ' Openform'}
    </Typography>
  )
}
