const schema = {
  endpoint: '/api/product_filters',
  resource: {
    definition: 'ProductFilter-productFilter.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Tytuł',
          },
        },
      },

      stat: {},
    },
  },
}

export default schema
