import React from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Form } from 'core/pages'
import { Sidebar } from './sidebar'
import schema from 'core/_schema/admin'
import routes from './routes'
import profileRoutes from 'core/pages/Profile/routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const profile = useSelector(state => state.profile)
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return [profile[process.env.REACT_APP_RESOURCE_ID], 'profile'].includes(
    match.params.id
  ) ? (
    <Redirect to={profileRoutes().index.path} />
  ) : (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      modifyProperties={properties => ({
        ...properties,
        password: {
          ...properties.password,
          validate: properties.password.validate?.filter(
            validator => validator !== 'required'
          ),
        },
      })}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor="fullName"
      SidebarComponent={Sidebar}
    />
  )
}
