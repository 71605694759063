import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { IconButton, Tooltip } from '@material-ui/core'
import { GetAppOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { DialogLoader } from 'core/components/DialogLoader'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles({
  labelButton: {
    cursor: 'pointer',
  },
})

export const YoutubeThumbnail = ({
  name,
  code,
  endpoint,
  setValue,
  setUpdatedAt,
  formUrl,
  formMethod,
  disabled = false,
  classes = {},
}) => {
  const [isFetching, setIsFetching] = useState(false)

  const TooltipComponent = disabled ? 'span' : Tooltip

  const handleDownload = () => {
    setIsFetching(true)

    fetchDataHandleAuthError(
      endpoint,
      'POST',
      { body: JSON.stringify({ code }) },
      response => {
        const resource = response

        if (!['PUT', 'PATCH'].includes(formMethod)) {
          uploadSuccess(resource)

          return
        }

        fetchDataHandleAuthError(
          formUrl,
          'PUT',
          { body: JSON.stringify({ [name]: resource }) },
          response => {
            uploadSuccess(resource)

            if (!response.updatedAt) {
              return
            }

            setUpdatedAt(response.updatedAt)
          },
          error => {
            uploadFailure(error)
          }
        )
      },
      error => {
        uploadFailure(error)
      }
    )
  }

  const uploadSuccess = resource => {
    setValue(resource)
    setIsFetching(false)
  }

  const uploadFailure = error => {
    if (error.response.title === 'AbortError') {
      return
    }

    setIsFetching(false)
    notification('error', error.response.detail, error.response.title)
  }

  const defaultClasses = useStyles()

  return (
    <>
      <DialogLoader isOpen={isFetching} />
      <TooltipComponent
        title={translate('T_GENERAL_DOWNLOAD_YOUTUBE_THUMBNAIL')}
      >
        <IconButton
          color="primary"
          onClick={handleDownload}
          disabled={disabled || isFetching}
        >
          <GetAppOutlined
            className={clsx(defaultClasses.labelButton, classes.labelButton)}
          />
        </IconButton>
      </TooltipComponent>
    </>
  )
}

YoutubeThumbnail.propTypes = {
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  setUpdatedAt: PropTypes.func.isRequired,
  formUrl: PropTypes.string.isRequired,
  formMethod: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    labelButton: PropTypes.string,
  }),
}
