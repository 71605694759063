import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Paper as OriginalPaper } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'transparent',
  },
  containerWithPadding: {
    padding: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    backgroundColor: theme.elements.content,
  },
  paperWithHeader: {
    padding: 0,
  },
  header: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.elements.header.color,
    backgroundColor: theme.elements.header.background,
    fontWeight: 700,
    fontSize: '16px',
  },
  content: {
    padding: theme.spacing(2),
  },
}))

export const Paper = ({
  children,
  header = null,
  withPadding = true,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(
        classes.container,
        withPadding && classes.containerWithPadding
      )}
      {...rest}
    >
      <OriginalPaper
        className={clsx(classes.paper, header && classes.paperWithHeader)}
      >
        {header ? (
          <>
            <div className={classes.header}>{header}</div>
            <div className={classes.content}>{children}</div>
          </>
        ) : (
          children
        )}
      </OriginalPaper>
    </div>
  )
}

Paper.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string,
  withPadding: PropTypes.bool,
}
