import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'

export const CustomButton = ({
  children = null,
  title,
  path,
  color = 'primary',
  size = 'medium',
  ...rest
}) => (
  <Button
    component={Link}
    to={path}
    variant="contained"
    color={color}
    size={size}
    {...rest}
  >
    {children || title}
  </Button>
)

CustomButton.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  path: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
}
