import { getFilters } from './getFilters'
import { addDefaultFilters } from './addDefaultFilters'

export const prepareFilters = (
  defaultFilters,
  preparedColumns,
  schemaParameters
) =>
  getFilters(
    addDefaultFilters(defaultFilters, preparedColumns),
    schemaParameters
  )
