const schema = {
  endpoint: '/api/users',
  resource: {
    definition: 'User-user.read',
    properties: {
      email: {},

      // floatRate: {
      //   type: 'number',
      //   minValue: 0,
      //   defaultValue: 0.0,
      //   label: 'Rabat specjalny',
      //   endAdornment: '%',
      // },


      stat: {
        hint: 'czy może się logować, konto potwierdzone z linka aktywacyjnego'
      },

      deleted: {
        label: 'Usunięte',
        hint: 'użytkownik usunął konto w profilu'
      },

    },
  },
}

export default schema
