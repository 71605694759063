import React, { useMemo } from 'react'
import { Paper } from 'core/components/Paper'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { operations } from 'core/components/table/_columns/operations'
import { translate } from 'core/_helpers/translate'
import schema from '_schema/gigapixel'
import routes from './routes'
import { CollectionHeader as Aditional } from './table/CollectionHeader'
import { MainCell } from './table/cells/MainCell'
import { useSelector } from 'react-redux'

export const Collection = () => {
  const profile = useSelector(state => state.profile)
  const columns = useMemo(
    () => [
      {
        header: `${translate('T_GENERAL_TITLE')}`,
        accessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        filterName: 'translations.title',
        filterable: true,
        sortable: false,
        width: '57%',
      },

      {
        header: `ID`,
        accessor: `idName`,
        filterName: 'idName',
        filterable: true,
        sortable: false,
        width: '27%',
      },

      {
        header: `Utworzony`,
        accessor: `generate`,
        filterName: 'generate',
        filterable: true,
        sortable: false,
        width: '27%',
        Cell: MainCell,
      },

      {
        ...operations(
          `translations.${process.env.REACT_APP_LOCALE}.title`,
          true,
          true,
          true,
          true,
          false
        ),
        width: '13%',
      },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('Dodaj plik')}
        buttonPath={routes().new.path}
        additionalButtons={
          profile.isOpenform ? (
            <Aditional buttonTitle={translate('[0F] Twórz')} />
          ) : (
            null
          )
        }
      >
        {translate('Zdjęcia')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
