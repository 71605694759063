//uwaga, wszystkie bloki, głównie dla page, wykorzystywane tez przez rózne encje, zmiana wplynie na wszystkie encje
import {
  anchorBlock,
  text,
  textQuote,
  textWithVideo,
  textWithAudio,
  textWithImage,
  textColumn,
  textVisit,
  textVisit2,
  packageFile,
  packageGallery,
  textElements,
  textLinks,
  packageFaq,
  packageLogotype,
  textNews,
  textQuoteSection,
  textWithBackground,
  textPerson,
  textCrew,
  caffeBlock,
  clubBlock,
  pageBlock,
  happenBlock,
  educationBlock,
  happeninspBlock,
  productBlock,
  pricelistBlock,
  // konkursBlock,
  formsetBlock,
  residenceBlock,
  sketchfab,
  imageGigapixel,
  iartcollectionBlock,
  pasazBlock,
  sobiescianaBlock,
  exhibitionBlock,
} from 'core/_schema/_blocks'

const schema = (photoEndpoint, thumbEndpoint, fileEndpoint) => ({
  types: [
    anchorBlock(),
    text(),
    textQuote(),
    textWithVideo(photoEndpoint, thumbEndpoint),
    textWithImage(photoEndpoint, thumbEndpoint),
    textWithBackground(photoEndpoint, thumbEndpoint),
    textWithAudio(fileEndpoint, photoEndpoint, thumbEndpoint),
    packageFile(),
    packageGallery(),
    textElements(),
    textLinks(),
    packageFaq(),
    packageLogotype(),
    imageGigapixel(photoEndpoint, thumbEndpoint),
    textColumn(),
    textPerson(),
    textCrew(),
    textQuoteSection(photoEndpoint, thumbEndpoint),
    pricelistBlock(photoEndpoint, thumbEndpoint),
    textVisit(),
    textVisit2(),
    caffeBlock(),
    clubBlock(),
    textNews(),

    pageBlock(),  //blok powiazane artykuly
    happenBlock(), //blok proponowane wydarzenia
    educationBlock(), //blok proponowana oferta edukacyjna
    productBlock(), //blok polecane produkty
    exhibitionBlock(), //blok powiazane wystawy
    sobiescianaBlock(), //blok polecane z sobiesciany
    pasazBlock(), // blok powiązane z Pasażu Wiedzy
    iartcollectionBlock(), //blok Powiązane obiekty z kolekcji iart

    happeninspBlock(),
    // konkursBlock(),
    residenceBlock(),
    sketchfab(),
    formsetBlock(),
  ],
})

export default schema
