import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
// import { translate } from 'core/_helpers/translate'

import { notification } from 'core/_helpers/notification'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { TableLoader } from 'core/components/table'

import ReplayIcon from '@material-ui/icons/Replay'
import { IconButton, Tooltip } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  loader: {
    width: '50px',
    position: 'relative',
    display: 'inline-block',
    height: '40px',
    verticalAlign: 'middle',
  },
}))

export const OrderStatusResend = ({
  uuid,
  name,
  label,
  // hint = null,
  // initialValue = null,
  value,
  setValue,
  colon = ':',
  btnSize = 'medium',
}) => {
  const classes = useStyles()
  const [btnDisable, setDisable] = React.useState(false)

  const statusChangeSucces = res => {
    setDisable(false)

    notification(
      'success',
      'Status zmieniony. Email do kupującego wysłany.',
      'T_FORM_SUCCESS'
    )
  }
  const statusChangeError = res => {
    setDisable(false)

    notification('error', 'Status nie zmieniony.', 'T_FORM_ERROR')
  }

  const handleClick = () => {
    setDisable(true)
    //fetch ..........
    fetchDataHandleAuthError(
      '/api/order_parts/' + uuid,
      'PATCH',
      { body: JSON.stringify({ newOrderStat: value }) },
      statusChangeSucces,
      statusChangeError
    )
  }

  return (
    <div style={{ width: 35 }}>
      {/* <Button
        color="primary"
        size={btnSize}
        disabled={btnDisable}
        variant="contained"
        onClick={handleClick}
      >
        Wyśli ponownie email
      </Button> */}

      <Tooltip title="Wyśli ponownie email">
        <span>
          <IconButton
            color="primary"
            size={btnSize}
            disabled={btnDisable}
            variant="contained"
            onClick={handleClick}
          >
            <ReplayIcon />
          </IconButton>
        </span>
      </Tooltip>

      <div className={clsx(classes.loader)}>
        <TableLoader show={btnDisable} align="center" />
      </div>
    </div>
  )
}
