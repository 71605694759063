export const textWithAudio = (
  fileEndpoint,
  photoEndpoint,
  thumbEndpoint,
  anchor = true
) => {
  let b = {
    name: 'textWithAudio',
    label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_AUDIO',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          text: {
            type: 'tinymce',
          },
          text2: {
            type: 'textarea',
            label: 'Transkrypcja',
          },
          author: {},
          alt: {},
        },
      },
      signlanguagebutton: {
        hint: 'link',
      },
      photo: {
        type: 'image',
        description: 'Maska (użyty kadr tylko pionowy)',
        endpoint: photoEndpoint,
        validate: ['maxSize'],
        thumbs: thumbEndpoint && {
          endpoint: thumbEndpoint,
        },
        ofmEnable: true,
      },
      file: {
        type: 'file',
        description: 'T_GENERAL_AUDIO',
        endpoint: fileEndpoint,
        accept: 'audio/*',
        validate: ['maxSize'],
        hint: 'wybierz tylko dozwolone typy audio',
        ofmEnable: true,
      },
      anchor: {},
    },
  }

  if (!anchor) {
    delete b.properties.anchor
  }

  return b
}
