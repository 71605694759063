import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'

export const sobiescianaBlock = (type = null, anchor = true) => {
  let b = {
    name: 'sobiescianaBlock',
    label: 'Blok "Powiązane materiały z Sobiesciany"',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            hint: 'domyślny tytuł "Powiązane materiały", można zmienić',
          },
        },
      },

      elements: {
        type: 'collection',
        endpoint: type?.endpoint ?? '/api/block_elements',
        definition: type?.definition ?? 'BlockElement-blockElement.read',
        description: 'Wybierz materiały z Sobiesciany',
        additionalProperties: {
          $ref:
            type?.ref ??
            '#/definitions/BlockElement-blockElement.write_blockElement.create',
        },
        titleAccessor: function(resource, handleSuccess) {
          if (resource['@titleAccessor']) return resource['@titleAccessor']
          const setResource = res => {
            resource['@titleAccessor'] =
              res.translations && res.translations[process.env.REACT_APP_LOCALE]
                ? res.translations[process.env.REACT_APP_LOCALE].title ?? '--'
                : '--'
            if (handleSuccess) {
              handleSuccess(resource)
            }
          }
          if (resource.sobiesciana) {
            fetchDataHandleAuthError(
              resource.sobiesciana,
              'GET',
              null,
              setResource
            )
          } else {
            return '--' //translate('T_GENERAL_MISSING_TITLE').replace('<','‹ ').replace('>',' ›')
          }
        },
        properties: {
          // sobiesciana: {
          //   type: 'resource',
          //   endpoint: `/api/sobiescianas_form?pagination=false&exists[versionable]=false`,
          //   titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
          //   label: 'Materiały z Sobiesciany',
          //   storeCollectionId: 'sobiescianas_form',
          // },
          sobiesciana: {
            type: 'autocomplete',
            label: 'Materiały z Sobiesciany',
            titleProperty: `translations.${process.env.REACT_APP_LOCALE}.title`,
            searchProperty: 'translations.title',
            endpoint: '/api/sobiescianas?pagination=false',
            validate: ['required'],
          },
          stat: {},
        },
      },
      anchor: {},
    },
  }
  if (!anchor) {
    delete b.properties.anchor
  }
  return b
}
