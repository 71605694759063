import React from 'react'
import { Route } from 'react-router-dom'
import { SchemableComponent } from 'core/components/SchemableComponent'
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined'
import { New } from './New'
import { Edit } from './Edit'
import { Collection } from './Collection'
import { PointCollection } from './PointCollection'
import { PointNew } from './PointNew'
import { PointEdit } from './PointEdit'
import { addRoutesConfig } from 'core/_helpers/addRoutesConfig'
import schema from '_schema/shippingType'

const basePath = '/shipping_types'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: LocalShippingOutlinedIcon,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        path: `${basePath}/new`,
        exact: true,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: `${basePath}/:id`,
        exact: true,
      },

      pointsIndex: {
        subtitle: 'T_GENERAL_RECORD_LIST_POINTS',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={PointCollection}
            {...props}
          />
        ),
        path: `${basePath}/:pid/points`,
        exact: true,
      },
      pointNew: {
        subtitle: 'T_GENERAL_RECORD_NEW_POINT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={PointNew}
            {...props}
          />
        ),
        path: `${basePath}/:pid/point/new`,
        exact: true,
      },
      pointEdit: {
        subtitle: 'T_GENERAL_RECORD_EDIT_POINT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={PointEdit}
            {...props}
          />
        ),
        path: `${basePath}/:pid/point/:id`,
        exact: true,
      },
    },
    {
      title: 'Shipping types',
      resourceAccess: schema.endpoint,
      indexPath: basePath,
    }
  )

export default routes
