import { put } from 'redux-saga/effects'
import { AuthError } from './authError'
import { authActions } from 'core/_actions'
import { store } from 'core/_store'

const def = error => {
  if (error instanceof AuthError === false) {
    return
  }

  store.dispatch(authActions.logout())
}

function* saga(error) {
  if (error instanceof AuthError === false) {
    return
  }

  yield put(authActions.logout())
}

export const handleAuthError = {
  default: def,
  saga,
}
