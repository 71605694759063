//uwaga, te bloki są wspólne z innymi encjami
import {
  anchorBlock,
  text,
  textWithVideo,
  packageFile,
  packageLogotype
} from 'core/_schema/_blocks'

// te bloki są tylko w news
import {
  textWithImage,
} from 'pages/News/_blocks'

const schema = (photoEndpoint, thumbEndpoint, fileEndpoint) => ({
  types: [
    anchorBlock(),
    text(),
    textWithVideo(photoEndpoint, thumbEndpoint, false),
    textWithImage(photoEndpoint, null),
    packageFile(),
    packageLogotype(),
  ],
})

export default schema
