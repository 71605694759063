import React, { useMemo } from 'react'
// import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { getSchemaByRef } from 'core/_helpers/getSchemaByRef'
// import { EmbeddedCollection } from 'core/components/embedded'
import { StaticCollection } from 'core/components/static'
import { translate } from 'core/_helpers/translate'

import { useEmbeddedCollectionFetch } from 'core/components/embedded/_helpers/useEmbeddedCollectionFetch'
import { reducer } from 'core/components/embedded/_state'
import { BodySkeleton, TableLoader } from 'core/components/table'


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 30,
    marginTop: 30,
  },
}))

export const TablecollectionType = ({
  uuid,
  // label,
  value,
  properties,
  definitionRef = null,
  endpoint,
  // formUrl,
  // titleAccessor = null,
  disabled = false,
  classes = {},
  // statable = false,
  // addTitle = null,
  // expandTitle = null,
  // panelTitle = null,
  // types = null,
}) => {
  const schema = useSelector(state => state.schema)
  const definitionSchema = useMemo(
    () => (definitionRef ? getSchemaByRef(schema, definitionRef) : schema),
    [schema, definitionRef]
  )

  const defaultClasses = useStyles()

  const [state ] = useEmbeddedCollectionFetch(reducer, endpoint, uuid)

  return (
    <div className={clsx(defaultClasses.root, classes.root)}>
      {disabled ? (
        <StaticCollection
          items={value || []}
          properties={properties}
          definitionSchema={definitionSchema}
        />
      ) : uuid && (
        // <EmbeddedCollection
        //   endpoint={endpoint}
        //   pid={uuid}
        //   parentIri={formUrl}
        //   properties={properties}
        //   definitionSchema={definitionSchema}
        //   alignButtonEvenly={true}
        //   titleAccessor={titleAccessor}
        //   disabled={!uuid || disabled}
        //   headerTitle={translate(label.text || label)}
        //   statable={statable}
        //   addTitle={addTitle}
        //   expandTitle={expandTitle}
        //   panelTitle={panelTitle}
        //   types={types}
        // />

        <TableContainer component={Paper}>
          <TableLoader show={!state.init && state.isFetching} align="center" />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Tytuł</TableCell>
              {/* <TableCell align="right">Wariant</TableCell> */}
              <TableCell align="right">Zniżka</TableCell>
              <TableCell align="right">Cena</TableCell>
              <TableCell align="right">Ilość</TableCell>
              <TableCell align="right">Łącznie</TableCell>
              <TableCell align="right">Ebook</TableCell>
              <TableCell align="right">Bilet</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {

            state.init ? (
              <BodySkeleton rows={1} columns={1} />
            ) : state.items.length > 0 ? (

            state.items.map((item, i) =>
            { return (
              <TableRow key={item.uuid}>
                <TableCell component="th" scope="row">{item.title}</TableCell>
                {/* <TableCell align="right">{item.titleVariant}</TableCell> */}
                <TableCell align="right">{item.priceRate/100}%</TableCell>
                <TableCell align="right">{item.discountPrice/100}</TableCell>
                <TableCell align="right">{item.quantity}</TableCell>
                <TableCell align="right">{item.quantity * item.discountPrice/100}</TableCell>
                <TableCell align="right">{item.isDigital ? 'tak' : 'nie'}</TableCell>
                <TableCell align="right">{item.isTicket ? 'tak' : 'nie'}</TableCell>
              </TableRow>
            )}
            )

            ):(


              <TableRow>
                <TableCell
                  colSpan={2}
                  className={clsx(
                    defaultClasses.emptyResults,
                    classes.emptyResults
                  )}
                >
                  {translate('T_GENERAL_ITEMS_EMPTY')}
                </TableCell>
              </TableRow>


            )

            }
          </TableBody>
        </Table>
      </TableContainer>

      )}
    </div>
  )
}
