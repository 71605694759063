import { useReducer, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { constants } from 'core/pages/Messenger/_state'
import moment from 'moment'
import buildUrl from 'build-url'

const initState = ({ perPage = null }) => ({
  items: [],
  config: {
    currentPointer: moment().add(5, 'minutes'),
    perPage: perPage || parseInt(process.env.REACT_APP_COLLECTION_PER_PAGE),
  },
  init: true,
  isFetching: true,
})

export const useMessageCollectionFetch = (
  reducer,
  endpoint,
  recipient,
  perPage = null
) => {
  const [state, dispatch] = useReducer(reducer, { perPage }, initState)

  useEffect(() => {
    dispatch({ type: constants.FETCH_START })

    const url = buildUrl(endpoint, {
      queryParams: {
        [`recipient.${process.env.REACT_APP_RESOURCE_ID}`]: recipient,
        createdAt: `${state.config.currentPointer.format(
          'YYYY-MM-DDTHH:mm:ss'
        )},${state.config.perPage}`,
      },
    })

    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      url,
      'GET',
      { signal },
      response => {
        dispatch({
          type: constants.FETCH_SUCCESS,
          payload: {
            items: response['hydra:member'].reverse(),
            moreItems:
              response['hydra:totalItems'] !== response['hydra:member'].length,
          },
        })
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        dispatch({ type: constants.FETCH_FAILURE })
        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [endpoint, recipient, state.config])

  const reload = useSelector(state => state.common.reload)

  useEffect(() => {
    dispatch({ type: constants.RESET_CURRENT_POINTER })
  }, [dispatch, reload])

  return [state, dispatch]
}
