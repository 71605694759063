import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.elements.title,
  },
}))

export const Title = ({
  children,
  component = 'h2',
  variant = 'h3',
  classes = {},
  ...rest
}) => {
  const defaultClasses = useStyles()

  return (
    <Typography
      className={clsx(defaultClasses.root, classes.root)}
      component={component}
      variant={variant}
      gutterBottom
      {...rest}
    >
      {children}
    </Typography>
  )
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
  component: PropTypes.string,
  variant: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
}
