const schema = {
  endpoint: '/api/crews',
  resource: {
    definition: 'Crew-crew.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
        },
      },
    },
  },
  subresources: {
    item: {
      endpoint: '/api/crew_items',
      definition: 'CrewItem-crewItem.read',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
            job: {
              label: 'T_CREW_JOB',
            },
            extraInfo: {
              label: 'T_CREW_EXTRAINFO',
              hint: 'T_CREW_EXTRAINFO_HINT',
            },
            description: { type: 'textarea' },
          },
        },
        email: {},
        phone: {},
        phone2: {
          label:'Drugi telefon',
        },
      },
    },
  },
}

export default schema
