const schema = {
  endpoint: '/api/whitelists',
  resource: {
    definition: 'Whitelist-whitelist.read',
    properties: {
      ip: {
        type: 'string',
      },

      stat: {},
    },
  },
}

export default schema
