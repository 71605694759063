import React from 'react'
import { useHistory } from 'react-router-dom'
import { prop } from 'core/_helpers/prop'
import { makeStyles } from '@material-ui/styles'
// import { translate } from 'core/_helpers/translate'
import moment from 'moment'

const useStyles = makeStyles({
  image: {
    display: 'flex',
    alignItems: 'center',
  },
  imgContainer: {
    width: 200,
  },
  img: {
    maxHeight: 100,
    width: '100%',
    height: 'auto',
  },
  title: {
    marginLeft: '1.0em',
  },
})

export const DateTimeCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
}) => {
  const history = useHistory()

  const onClick = () => {
    if (isRowLinkable) {
      editPath &&
        history.push(
          editPath.replace(':id', resource[process.env.REACT_APP_RESOURCE_ID])
        )
    }
  }

  const classes = useStyles()

  return (
    <div onClick={onClick} className={classes.image}>
      <div className={classes.title}>
        {moment(prop(resource, accessor)).format('DD.MM.YYYY HH:mm')}
      </div>
    </div>
  )
}
