import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
// import { BlockCollection } from 'core/components/block'
import { Title } from 'core/components/Title'
import { Form } from 'core/pages'
import { Sidebar } from './sidebar/Sidebar'
import { translate } from 'core/_helpers/translate'
// import { usePropertyResourcesFetch } from 'core/_helpers/usePropertyResourcesFetch'
// import { addBlockPropertyResources } from 'core/_helpers/addBlockPropertyResources'
import { addOpenformProperties } from './_helpers/addOpenformProperties'
import { modifyPropertiesByPage } from './_helpers/modifyPropertiesByPage'
// import { commonConstants } from 'core/_constants'
import schema from '_schema/crew'
import routes from './routes'
// import packageFileSchema from 'core/_schema/packageFile'
// import packageGallerySchema from 'core/_schema/packageGallery'
// import packageFaqSchema from 'core/_schema/packageFaq'
import { EmbeddedCollection } from 'core/components/embedded'

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
  },
  form: {
    marginTop: 0,
  },
})

export const Edit = ({ schema: definitions, ...rest }) => {
  // const dispatch = useDispatch()

  const profile = useSelector(state => state.profile)
  const { match } = rest

  const iri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  const iri2 = `${schema.endpoint}/${match.params.id}`

  const isNotEditable = useCallback(
    resource =>
      resource.versionable || (!profile.isOpenform && !resource.isEditable),
    [profile.isOpenform]
  )

  const history = useHistory()
  const handleSuccess = useCallback(() => {
    // dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })
    history.push(routes().index.path)
  }, [history]//, dispatch]
  )

  const handleSuccessAndStay = //useCallback(
    () => {
    // dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })
  }//, [dispatch])

  // const packageResourcesMapping = useMemo(
  //   () => ({
  //     packageFile: packageFileSchema.endpoint+'?pagination=false&embeded=false',
  //     packageGallery: packageGallerySchema.endpoint+'?pagination=false&embeded=false',
  //     packageFaq: packageFaqSchema.endpoint+'?pagination=false'
  //   }),
  //   []
  // )

  // const [packageResources] = usePropertyResourcesFetch(packageResourcesMapping)

  // const blockTypes = useMemo(
  //   () =>
  //     addBlockPropertyResources(
  //       schema.subresources.block.types,
  //       packageResources
  //     ),
  //   [packageResources]
  // )

  const classes = useStyles()

  return (
    <Form
      iri={iri}
      isNotEditable={isNotEditable}
      collectionPath={routes().index.path}
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      modifyProperties={(properties, resource, schema) => {
        let modifiedProperties = modifyPropertiesByPage(
          properties,
          resource,
          schema
        )
        return addOpenformProperties(
          profile.isOpenform,
          modifiedProperties,
          resource,
          schema
        )
      }}
      method="PUT"
      url={iri}
      storeCollectionId={schema.endpoint}
      handleSuccess={handleSuccess}
      handleSuccessAndStay={handleSuccessAndStay}
      sidebar={true}
      sidebarTitleAccessor={`translations.${process.env.REACT_APP_LOCALE}.title`}
      SidebarComponent={Sidebar}
      fieldsFullWidth={false}
      header={
        <Container maxWidth="xl" className={classes.header}>
          <Title>{translate('T_GENERAL_EDIT')}</Title>
        </Container>
      }
      width='100%'
      key={iri}
      classes={{ root: classes.form }}
    >
      <EmbeddedCollection
        endpoint={schema.subresources.item.endpoint}
        pid={match.params.id}
        parentIri={iri2}
        properties={schema.subresources.item.properties}
        definitionSchema={definitions[schema.subresources.item.definition]}
        headerTitle={translate('T_MODULE_CREW_ITEMS_HEADER_TITLE')}
        expandTitle={translate('T_MODULE_CREW_ITEMS_EXPAND_TITLE')}
        panelTitle={translate('T_GENERAL_CREW_ITEMS')}
        statable={true}
      />
    </Form>
  )
}
