import formsetSchema from '_schema/formset'
const schema = {
  endpoint: '/api/shop_forms',
  resource: {
    definition: 'ShopForm-shopForm.read',
    properties: {

      title: {
        hint: 'nazwa tylko widoczna w PA'
      },

      formset: {
        type: 'resource',
        endpoint: `${formsetSchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`
      },

      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
