import React from 'react'
import { Route } from 'react-router-dom'
// import { Settings } from '@material-ui/icons'
import SettingsApplicationsOutlinedIcon from '@material-ui/icons/SettingsApplicationsOutlined'
import { SchemableComponent } from 'core/components/SchemableComponent'
import { Edit } from './Edit'
import { addRoutesConfig } from 'core/_helpers/addRoutesConfig'
import schema from '_schema/config'

const basePath = '/config_bip'

const routes = () =>
  addRoutesConfig(
    {
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: basePath,
        exact: true,
        icon: SettingsApplicationsOutlinedIcon,
      },
    },
    {
      title: 'BIP',
      resourceAccess: schema.endpoint,
    }
  )

export default routes
