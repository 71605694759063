import photoSchema from 'core/_schema/photo'

const schemaItem = {
  type: 'collection',
  endpoint: '/api/page_section_images',
  definition: 'PageSectionImage-pageSectionImage.read',
  description: 'T_PAGE_SECTION_IMAGES',
  additionalProperties: {
    '$ref':'#/definitions/PageSectionImage-pageSectionImage.write_pageSectionImage.create',
  },
  titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        alt: {
          description: 'T_GENERAL_ALT',
        },
      },
    },
    photo: {
      type: 'image',
      description: 'T_GENERAL_MEDIA_PHOTO',
      endpoint: photoSchema.endpoint.single,
      endpointMultiple: photoSchema.endpoint.multiple,
      thumbs: {
        endpoint: '/api/page_section_image_thumbs',
      },
      validate: ['maxSize'],
      ofmEnable: true
    },
    stat: {
      description: 'T_GENERAL_PUBLICATION',
    },
  },
}

const schema = {
  endpoint: '/api/page_sections?idName[]=home_section_a&idName[]=home_section_b&idName[]=home_section_c&idName[]=home_section_d&idName[]=home_section_e&idName[]=home_section_f&idName[]=home_section_g&idName[]=home_section_h',
  resource: {
    definition: 'PageSection-pageSection.read',
    sectionA: {
      id: 'home_section_a',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              type: 'textarea',
            },
            text: {
              type: 'textarea'
            },
            btnTitle: {}
          },
        },
        items: schemaItem,
        stat: {}
      }
    },

    sectionB: {
      id: 'home_section_b',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            text: {
              type: 'textarea'
            }
          },
        },
        stat: {}
      }
    },

    sectionC: {
      id: 'home_section_c',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              type: 'textarea',
            },
            text: {
              type: 'textarea'
            },
            btnTitle: {}
          },
        },
        items: schemaItem,
        stat: {}
      }
    },

    sectionD: {
      id: 'home_section_d',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              type: 'textarea',
            },
            text: {
              type: 'textarea'
            },
            btnTitle: {}
          },
        },
        items: schemaItem,
        stat: {}
      }
    },

    sectionE: {
      id: 'home_section_e',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              type: 'textarea',
            },
            text: {
              type: 'textarea'
            },
            btnTitle: {}
          },
        },
        stat: {}
      }
    },

    sectionF: {
      id: 'home_section_f',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              type: 'textarea',
            },
            text: {
              type: 'textarea'
            },
            btnTitle: {}
          },
        },
        items: schemaItem,
        stat: {}
      }
    },

    sectionG: {
      id: 'home_section_g',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              type: 'textarea',
            },
            text: {
              type: 'textarea'
            },
            btnTitle: {}
          },
        },
        items: schemaItem,
        stat: {}
      }
    },

    sectionH: {
      id: 'home_section_h',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            text: {
              type: 'textarea'
            },
            btnTitle: {}
          },
        },
        items: schemaItem,
        stat: {}
      }
    },

  },
}

export default schema
