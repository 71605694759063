import React from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'core/pages'
import schema from '_schema/residenceItem'
import schema2 from '_schema/residence'
import routes from './routes'
import { modifyPropertiesByPage } from './_helpers/modifyPropertiesByPage'
import { addOpenformProperties } from './_helpers/addOpenformProperties'

export const ResidenceitemNew = ({ schema: definitions, ...rest }) => {
  const { match } = rest
  const iri = `${schema2.endpoint}/${match.params.pid}`
  const profile = useSelector(state => state.profile)

  return (
    <>
      {match.params.pid && (
        <Form
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={schema.resource}
          method="POST"
          url={schema.endpoint}
          collectionPath={routes().residenceitemsIndex.path.replace(
            ':pid',
            match.params.pid
          )}
          editPath={routes().residenceitemEdit.path.replace(':pid', match.params.pid)}
          storeCollectionId={schema.endpoint}
          defaultData={{ parent: iri }}
          width={'75%'}
          modifyProperties={(properties, resource, schema) => {
            let modifiedProperties = modifyPropertiesByPage(
              properties,
              resource,
              schema
            )
            return addOpenformProperties(
              profile.isOpenform,
              modifiedProperties,
              resource,
              schema
            )
          }}
        />
      )}
    </>
  )
}
