import React from 'react'
import PropTypes from 'prop-types'
import { FileType } from './file/FileType'

export const ImageType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  error = false,
  renderError = false,
  endpoint,
  disabled = false,
  validators,
  setValue,
  setError,
  formUrl,
  formMethod,
  uuid,
  thumbs = false,
  accept = null,
  ytCode = null,
  endpointYoutube = null,
  minDimensions = null,
  nodeRef = null,
  classes = {},
  ofmEnable = false, //file manager enable
}) => (
  <FileType
    name={name}
    type="image"
    label={label}
    hint={hint}
    initialValue={initialValue}
    value={value}
    error={error}
    renderError={renderError}
    endpoint={endpoint}
    disabled={disabled}
    validators={validators}
    setValue={setValue}
    setError={setError}
    formUrl={formUrl}
    formMethod={formMethod}
    uuid={uuid}
    thumbs={thumbs}
    accept={accept}
    ytCode={ytCode}
    endpointYoutube={endpointYoutube}
    minDimensions={minDimensions}
    nodeRef={nodeRef}
    classes={classes}
    ofmEnable={ofmEnable}
  />
)

ImageType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }),
  value: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  endpoint: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  formUrl: PropTypes.string.isRequired,
  formMethod: PropTypes.string.isRequired,
  uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  thumbs: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      endpoint: PropTypes.string.isRequired,
    }),
  ]),
  accept: PropTypes.string,
  ytCode: PropTypes.string,
  endpointYoutube: PropTypes.string,
  minDimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  nodeRef: PropTypes.object,
  classes: PropTypes.shape({
    labelContainer: PropTypes.string,
    label: PropTypes.string,
    labelButton: PropTypes.string,
    deleteButton: PropTypes.string,
    imagePreview: PropTypes.string,
  }),
}
