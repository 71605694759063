
export const pricelistBlock = (photoEndpoint, thumbEndpoint, packagePricelistSchema) => ({
  name: 'pricelistBlock',
  label: 'Blok z cennikiem',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        subtitle: {},
      },
    },
    buttonOne: {
      label: 'Pokazuj "Kup bilet"',
    },
    buttonTwo: {
      label: 'Pokazuj "Przejdź do biletów"'
    },
    photo: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      validate: ['maxSize'],
      thumbs: thumbEndpoint && {
        endpoint: thumbEndpoint,
      },
    },
    packagePricelist: {
      type: 'resource',
      endpoint: `${packagePricelistSchema.endpoint}?pagination=false&embeded=false`,
      titleAccessor: 'title',
      description: 'Pakiet cennika',
    },
    elements: {
      type: 'collection',
      endpoint: '/api/exhibition_block_elements',
      definition: 'ExhibitionBlockElement-exhibitionBlockElement.read',
      description: 'lub załącz elementy cennika',
      additionalProperties: {
        '$ref':'#/definitions/ExhibitionBlockElement-exhibitionBlockElement.write_exhibitionBlockElement.create',
      },
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
            priceString: {
              label: 'Cena/opis'
            },
          },
        },
        isHeader:{
          label: 'Wyswietl jako nagłówek',
        },
        stat: {}
      },
    },

  },
})
