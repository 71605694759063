import React, { useMemo, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { Form, NotFound } from 'core/pages'
import { Paper } from 'core/components/Paper'
import { Title } from 'core/components/Title'
import { Loader } from 'core/components/Loader'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { addOpenformProperties } from './_helpers/addOpenformProperties'
import { collectionActions } from 'core/_actions'
import { commonConstants } from 'core/_constants'
import { translate } from 'core/_helpers/translate'
import schema from '_schema/pasaz'
import routes from './routes'

export const New = ({ schema: definitions, ...rest }) => {
  const profile = useSelector(state => state.profile)

  const [state, setState] = useResourceState()

  const { resource: parent, isFetching, fetchError } = state

  const defaultData = { }

  useResourceFetch(
    null,//`${schema.endpoint}?idName=page_news`,
    parent,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    true
  )

  const isNotEditable = useMemo(
    () =>
      !parent
        ? false
        : parent.versionable ||
          (!profile.isOpenform &&
            (!parent.isSubPageable ||
              parent.nestingLevel >=
                process.env.REACT_APP_PAGE_MAX_NESTING_LEVEL)),
    [parent, profile.isOpenform]
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (isNotEditable) {
      dispatch({ type: commonConstants.RESET_CURRENT_RESOURCES })
    }
  }, [isNotEditable, dispatch])

  const history = useHistory()

  const handleSuccess = useCallback(() => {
    // dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })
    dispatch(collectionActions.resetConfig(schema.endpoint))

    history.push(routes().index.path)
  }, [history, dispatch])

  const handleSuccessAndStay = useCallback(
    resource => {
      // dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })

      history.push(
        routes().edit.path.replace(
          ':id',
          resource[process.env.REACT_APP_RESOURCE_ID]
        )
      )
    },
    [history]//, dispatch]
  )

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : isNotEditable ? (
    <Redirect to={routes().index.path} />
  ) : (
    <Form
      defaultData={defaultData}
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      modifyProperties={properties =>
        addOpenformProperties(profile.isOpenform, properties)
      }
      method="POST"
      url={schema.endpoint}
      storeCollectionId={schema.endpoint}
      collectionPath={routes().index.path}
      editPath={routes().edit.path}
      handleSuccess={handleSuccess}
      handleSuccessAndStay={handleSuccessAndStay}
      header={
        <Title>
          {translate('T_GENERAL_NEW')}
        </Title>
      }
      fieldsFullWidth={false}
      width={800}
    />
  )
}
