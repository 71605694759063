export const iksorisTicketField = {
  type: 'resourceIksoris',
  name1: 'iksorisEventId',
  endpoint1: `/api/iksoris/get/event/0/0`,
  titleAccessor1: `title`,
  label1: 'Wydarzenie',

  name2: 'iksorisTopicId',
  label2: 'Temat',
  titleAccessor2: `title`,
  endpoint2: `/api/iksoris/get/topic/0/0`,

  name3: 'iksorisTermId',
  label3: 'Termin',
  titleAccessor3: `title`,
  endpoint3: `/api/iksoris/get/term/0/0`,

  name4: 'iksorisTopicIdGroup',
  label4: 'Temat',
  titleAccessor4: `title`,
  endpoint4: `/api/iksoris/get/topicgroup/0/0`,

  name5: 'iksorisTermIdGroup',
  label5: 'Termin',
  titleAccessor5: `title`,
  endpoint5: `/api/iksoris/get/termgroup/0/0`,
}
