export const addBlockPropertyResources = (blocks, resources) =>
  blocks.map(block => ({
    ...block,
    properties: Object.assign(
      {},
      ...Object.keys(block.properties).map(name => ({
        [name]: {
          ...block.properties[name],
          resources: Object.keys(resources).includes(name)
            ? [...resources[name]]
            : [],
        },
      }))
    ),
  }))
