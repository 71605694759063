import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { TableRow, TableCell } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  skeleton: {
    zIndex: 1,
  },
})

export const BodySkeleton = ({
  rows,
  columns,
  animation = 'wave',
  classes = {},
}) => {
  const defaultClasses = useStyles()

  return [...Array(rows)].map((row, i) => (
    <TableRow key={`skeleton-${i}`} className={clsx(classes.row)}>
      {[...Array(columns)].map((cell, j) => (
        <TableCell key={`cell-${j}`} className={clsx(classes.cell)}>
          <Skeleton
            animation={animation}
            className={clsx(defaultClasses.skeleton, classes.skeleton)}
          />
        </TableCell>
      ))}
    </TableRow>
  ))
}

BodySkeleton.propTypes = {
  rows: PropTypes.number.isRequired,
  columns: PropTypes.number.isRequired,
  animation: PropTypes.oneOf(['pulse', 'wave', false]),
  classes: PropTypes.shape({
    row: PropTypes.string,
    cell: PropTypes.string,
    skeleton: PropTypes.string,
  }),
}
