export const modifyPropertiesByPage = (properties, resource, schema) => {

  switch (resource.idName) {
    // Modify by page here
    case 'xxx_page':
      let modifiedPropertiesContact = properties
      delete modifiedPropertiesContact.translations.properties.subtitle
      return modifiedPropertiesContact
    default:
      return properties
  }
}
