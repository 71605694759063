import adminSchema from './admin'

const schema = {
  endpoint: '/api/admins/profile',
  profile: {
    definition: 'Admin-admin.read',
    properties: {
      fullName: adminSchema.resource.properties.fullName,
    },
    get: '/api/admins/profile',
    set: '/api/admins/profile',
  },
  changePassword: {
    properties: {
      currentPassword: {
        type: 'password',
        description: 'T_FORM_FIELD_PASSWORD',
        validate: ['required'],
      },
      password: adminSchema.resource.properties.password,
    },
    set: '/api/admins/profile',
  },
}

export default schema
