import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { translate } from 'core/_helpers/translate'
import { constants } from 'core/components/table/_state'

const performexportselected = (action, title) => ({
  action,
  title: translate(title),
  execute: (tableState, tableStateDispatch) => {
    let itemsUuidToSend = []

    const items = tableState.data.selected.map((isSelected, index) => {
      const visSelected =
        typeof isSelected === 'object' ? isSelected.value : isSelected

      if (!visSelected) {
        return tableState.data.items[index]
      }

      itemsUuidToSend.push(tableState.data.items[index].uuid)

      return tableState.data.items[index]
    })

    const request = {
      url: `${tableState.collection}/exportselected`,
      method: 'POST',
      body: {
        items: itemsUuidToSend,
      },
    }

    setTimeout(() => {
      // Promise.all([
      // requests.map(request =>
      fetchDataHandleAuthError(
        request.url,
        request.method,
        {
          body: JSON.stringify(request.body),
        },
        response => {
          var blob = new Blob([JSON.stringify(response)])
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `export.json`)

          // Append to html link element page
          document.body.appendChild(link)
          // Start download
          link.click()
          // Clean up and remove the link
          link.parentNode.removeChild(link)
        }
      ).then(
        () => {
          notification(
            'success',
            'T_TRANSLATOR_MASS_SELECT_EXPORT_DONE',
            'T_FORM_SUCCESS'
          )
          tableStateDispatch({
            type: constants.MASS_SELECT_ACTION_SUCCESS,
            payload: { action, items },
          })
        },
        error => {
          notification('error', error.response.detail, error.response.title)
          tableStateDispatch({
            type: constants.MASS_SELECT_ACTION_FAILURE,
            payload: { action },
          })
        }
      )
      // )
      // ])
    }, 500)
  },
})

const performdeleteselected = (action, title) => ({
  action,
  title: translate(title),
  execute: (tableState, tableStateDispatch) => {
    let itemsUuidToSend = []
    // eslint-disable-next-line
    const items = tableState.data.selected.map((isSelected, index) => {
      const visSelected =
        typeof isSelected === 'object' ? isSelected.value : isSelected

      if (!visSelected) {
        return tableState.data.items[index]
      }

      itemsUuidToSend.push(tableState.data.items[index].uuid)

      return tableState.data.items[index]
    })

    const request = {
      url: `${tableState.collection}/deleteselected`,
      method: 'POST',
      body: {
        items: itemsUuidToSend,
      },
    }

    setTimeout(() => {
      fetchDataHandleAuthError(
        request.url,
        request.method,
        {
          body: JSON.stringify(request.body),
        },
        // response => {}
      ).then(
        () => {
          notification(
            'success',
            'T_TRANSLATOR_MASS_SELECT_DELETE_DONE',
            'T_FORM_SUCCESS'
          )
          setTimeout(function() {
            tableStateDispatch({
              type: constants.RELOAD,
            })
          }, 1000)
        },
        error => {
          notification('error', error.response.detail, error.response.title)
          tableStateDispatch({
            type: constants.MASS_SELECT_ACTION_FAILURE,
            payload: { action },
          })
        }
      )
    }, 500)
  },
})

export const exportselected = {
  go: (action = 'exportselected', title = 'T_TRANSLATOR_MASS_SELECT_EXPORT') =>
    performexportselected(action, title),
  delete: (
    action = 'deleteselected',
    title = 'T_TRANSLATOR_MASS_SELECT_DELETE'
  ) => performdeleteselected(action, title),
}
