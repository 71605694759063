import React, { useMemo } from 'react'
import { Paper } from 'core/components/Paper'
// import { useSelector } from 'react-redux'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { operations } from 'core/components/table/_columns/operations'
import { translate } from 'core/_helpers/translate'
import { publish } from 'core/components/table/_mass'
// import { MainCell } from './table/cells/MainCell'
import schema from '_schema/shippingAreaCost'
import routes from './routes'

export const CostsCollection = ({...rest}) => {
  // const profile = useSelector(state => state.profile)
  const { match } = rest

  const defaultFilters = {
    'parent.uuid': match.params.pid,
  }

  const columns = useMemo(
    () => [
      {
        header: translate('Shipping type'),
        accessor: `shippingTitle`,
        filterName: 'shippingTitle',
        // filterable: true,
        // Cell: MainCell,
        width: '30%',
      },
      {
        header: translate('Weight from'),
        accessor: `weightFrom`,
        filterName: 'weightFrom',
        // filterable: true,
        // Cell: MainCell,
        width: '30%',
      },
      {
        header: translate('Weight to'),
        accessor: `weightTo`,
        filterName: 'weightTo',
        // filterable: true,
        // Cell: MainCell,
        width: '30%',
      },
      {
        header: translate('Price'),
        accessor: `floatPrice`,
        filterName: 'floatPrice',
        // filterable: true,
        // Cell: MainCell,
        width: '33%',
      },

      { ...operations(function(){ return 'koszt dostawy' }, true, true, true, true, false), width: '15%' },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_SHIPPING_AREACOST_NEW_BUTTON')}
        buttonPath={routes().costNew.path.replace(':pid',match.params.pid)}
      >
        {translate('Shipping area costs')}
        &nbsp;
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultFilters={defaultFilters}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().costEdit.path.replace(':pid',match.params.pid)}
        autoWidth={false}
        storeCollectionId={`${schema.endpoint}${match.params.pid}`}
      />
    </Paper>
  )
}
