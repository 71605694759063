// eslint-disable-next-line no-undef
tinymce.addI18n('pl', {
  '#': '#',
  Accessibility: 'Dost\u0119pno\u015b\u0107',
  Accordion: '',
  'Accordion body...': '',
  'Accordion summary...': '',
  Action: 'Czynno\u015b\u0107',
  Activity: 'Aktywno\u015b\u0107',
  Address: 'Adres',
  Advanced: 'Zaawansowane',
  Align: 'Wyr\xf3wnaj',
  'Align center': 'Wyr\xf3wnaj do \u015brodka',
  'Align left': 'Wyr\xf3wnaj do lewej',
  'Align right': 'Wyr\xf3wnaj do prawej',
  Alignment: 'Wyr\xf3wnanie',
  'Alignment {0}': '',
  All: 'Wszystkie',
  'Alternative description': 'Alternatywny opis',
  'Alternative source': 'Alternatywne \u017ar\xf3d\u0142o',
  'Alternative source URL': 'Alternatywny URL \u017ar\xf3d\u0142a',
  Anchor: 'Kotwica',
  'Anchor...': 'Kotwica...',
  Anchors: 'Zakotwiczenia',
  'Animals and Nature': 'Zwierz\u0119ta i natura',
  Arrows: 'Strza\u0142ki',
  B: 'B',
  'Background color': 'Kolor t\u0142a',
  'Background color {0}': '',
  Black: 'Czarny',
  Block: 'Zablokuj',
  'Block {0}': '',
  Blockquote: 'Blok cytatu',
  Blocks: 'Bloki',
  Blue: 'Niebieski',
  'Blue component': 'Niebieski',
  Body: 'Tre\u015b\u0107',
  Bold: 'Pogrubienie',
  Border: 'Obramowanie',
  'Border color': 'Kolor obramowania',
  'Border style': 'Styl ramki',
  'Border width': 'Grubo\u015b\u0107 ramki',
  Bottom: 'D\xf3\u0142',
  'Browse files': '',
  'Browse for an image': 'Przegl\u0105daj za zdj\u0119ciem',
  'Browse links': '',
  'Bullet list': 'Lista wypunktowana',
  Cancel: 'Anuluj',
  Caption: 'Tytu\u0142',
  Cell: 'Kom\xf3rka',
  'Cell padding': 'Dope\u0142nienie kom\xf3rki',
  'Cell properties': 'W\u0142a\u015bciwo\u015bci kom\xf3rki',
  'Cell spacing': 'Odst\u0119py kom\xf3rek',
  'Cell styles': 'Style kom\xf3rek',
  'Cell type': 'Typ kom\xf3rki',
  Center: '\u015arodek',
  Characters: 'Znaki',
  'Characters (no spaces)': 'Znaki (bez spacji)',
  Circle: 'Ko\u0142o',
  Class: 'Klasa',
  'Clear formatting': 'Wyczy\u015b\u0107 formatowanie',
  Close: 'Zamknij',
  Code: 'Kod',
  'Code sample...': 'Przyk\u0142ad kodu...',
  'Code view': 'Widok kodu',
  'Color Picker': 'Selektor kolor\xf3w',
  'Color swatch': 'Pr\xf3bka koloru',
  Cols: 'Kol.',
  Column: 'Kolumna',
  'Column clipboard actions': 'Akcje schowka dla kolumny',
  'Column group': 'Grupa kolumn',
  'Column header': 'Nag\u0142\xf3wek kolumny',
  'Constrain proportions': 'Utrzymuj proporcje',
  Copy: 'Kopiuj',
  'Copy column': 'Kopiuj kolumn\u0119',
  'Copy row': 'Kopiuj wiersz',
  'Could not find the specified string.':
    'Nie znaleziono okre\u015blonego tekstu.',
  'Could not load emojis': 'B\u0142\u0105d wczytywania emotikon\xf3w',
  Count: 'Liczba',
  Currency: 'Waluta',
  'Current window': 'Bie\u017c\u0105ce okno',
  'Custom color': 'Kolor niestandardowy',
  'Custom...': 'Niestandardowy...',
  Cut: 'Wytnij',
  'Cut column': 'Wytnij kolumn\u0119',
  'Cut row': 'Wytnij wiersz',
  'Dark Blue': 'Ciemnoniebieski',
  'Dark Gray': 'Ciemnoszary',
  'Dark Green': 'Ciemnozielony',
  'Dark Orange': 'Ciemnopomara\u0144czowy',
  'Dark Purple': 'Ciemnopurpurowy',
  'Dark Red': 'Ciemnoczerwony',
  'Dark Turquoise': 'Ciemnoturkusowy',
  'Dark Yellow': 'Ciemno\u017c\xf3\u0142ty',
  Dashed: 'Kreskowane',
  'Date/time': 'Data/godzina',
  'Decrease indent': 'Zmniejsz wci\u0119cie',
  Default: 'Domy\u015blnie',
  'Delete accordion': '',
  'Delete column': 'Usu\u0144 kolumn\u0119',
  'Delete row': 'Usu\u0144 wiersz',
  'Delete table': 'Usu\u0144 tabel\u0119',
  Dimensions: 'Wymiary',
  Disc: 'Dysk',
  Div: 'Div',
  Document: 'Dokument',
  Dotted: 'Kropkowane',
  Double: 'Podw\xf3jne',
  'Drop an image here': 'Upu\u015b\u0107 obraz tutaj',
  'Dropped file type is not supported':
    'Dodany typ pliku nie jest obs\u0142ugiwany',
  Edit: 'Edytuj',
  Embed: 'Osad\u017a',
  Emojis: 'Emotikony',
  'Emojis...': 'Emotikony...',
  Error: 'B\u0142\u0105d',
  'Error: Form submit field collision.':
    'B\u0142\u0105d: kolizja pola przesy\u0142ania formularza.',
  'Error: No form element found.':
    'B\u0142\u0105d: nie znaleziono elementu formularza.',
  'Extended Latin': 'Rozszerzony \u0142aci\u0144ski',
  'Failed to initialize plugin: {0}':
    'Nie mo\u017cna zainicjowa\u0107 dodatku: {0}',
  'Failed to load plugin url: {0}':
    'Nie uda\u0142o si\u0119 za\u0142adowa\u0107 adresu url dodatku: {0}',
  'Failed to load plugin: {0} from url {1}':
    'Nie uda\u0142o si\u0119 za\u0142adowa\u0107 dodatku: {0} spod adresu url {1}',
  'Failed to upload image: {0}':
    'Nie uda\u0142o si\u0119 przes\u0142a\u0107 obrazu: {0}',
  File: 'Plik',
  Find: 'Znajd\u017a',
  'Find (if searchreplace plugin activated)':
    'Znajd\u017a (je\u015bli w\u0142\u0105czono dodatek wyszukuj\u0105cy)',
  'Find and Replace': 'Znajd\u017a i Zamie\u0144',
  'Find and replace...': 'Znajd\u017a i zamie\u0144...',
  'Find in selection': 'Znajd\u017a w zaznaczeniu',
  'Find whole words only': 'Znajd\u017a tylko ca\u0142e wyrazy',
  Flags: 'Flagi',
  'Focus to contextual toolbar':
    'Aktywuj pasek narz\u0119dzi menu kontekstowego',
  'Focus to element path': 'Aktywuj \u015bcie\u017ck\u0119 elementu',
  'Focus to menubar': 'Aktywuj pasek menu',
  'Focus to toolbar': 'Aktywuj pasek narz\u0119dzi',
  Font: 'Czcionka',
  'Font size {0}': '',
  'Font sizes': 'Rozmiary czcionek',
  'Font {0}': '',
  Fonts: 'Fonty',
  'Food and Drink': 'Jedzenie i picie',
  Footer: 'Stopka',
  Format: 'Format',
  'Format {0}': '',
  Formats: 'Formaty',
  Fullscreen: 'Pe\u0142ny ekran',
  G: 'G',
  General: 'Og\xf3lne',
  Gray: 'Szary',
  Green: 'Zielony',
  'Green component': 'Zielony',
  Groove: 'Rowkowane',
  'Handy Shortcuts': 'Przydatne skr\xf3ty',
  Header: 'Nag\u0142\xf3wek',
  'Header cell': 'Kom\xf3rka nag\u0142\xf3wka',
  'Heading 1': 'Nag\u0142\xf3wek 1',
  'Heading 2': 'Nag\u0142\xf3wek 2',
  'Heading 3': 'Nag\u0142\xf3wek 3',
  'Heading 4': 'Nag\u0142\xf3wek 4',
  'Heading 5': 'Nag\u0142\xf3wek 5',
  'Heading 6': 'Nag\u0142\xf3wek 6',
  Headings: 'Nag\u0142\xf3wki',
  Height: 'Wysoko\u015b\u0107',
  Help: 'Pomoc',
  'Hex color code': 'Szesnastkowy kod koloru',
  Hidden: 'Ukryte',
  'Horizontal align': 'Wyr\xf3wnanie w poziomie',
  'Horizontal line': 'Pozioma linia',
  'Horizontal space': 'Odst\u0119p w poziomie',
  ID: 'ID',
  'ID should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.':
    'ID powinien zaczyna\u0107 si\u0119 liter\u0105 i sk\u0142ada\u0107 si\u0119 tylko z liter, cyfr, my\u015blnik\xf3w, kropek, przecink\xf3w oraz znak\xf3w podkre\u015blenia.',
  'Image is decorative': 'Obraz jest dekoracyjny',
  'Image list': 'Lista obraz\xf3w',
  'Image title': 'Tytu\u0142 obrazu',
  'Image...': 'Obraz...',
  'ImageProxy HTTP error: Could not find Image Proxy':
    'ImageProxy HTTP b\u0142\u0105d: Nie znaleziono Image Proxy',
  'ImageProxy HTTP error: Incorrect Image Proxy URL':
    'ImageProxy HTTP b\u0142\u0105d: Nieprawid\u0142owy adres URL Image Proxy',
  'ImageProxy HTTP error: Rejected request':
    'ImageProxy HTTP b\u0142\u0105d: Odrzucono \u017c\u0105danie',
  'ImageProxy HTTP error: Unknown ImageProxy error':
    'ImageProxy HTTP b\u0142\u0105d: Nieznany b\u0142\u0105d ImageProxy',
  'Increase indent': 'Zwi\u0119ksz wci\u0119cie',
  Inline: 'W tek\u015bcie',
  Insert: 'Wstaw',
  'Insert Template': 'Wstaw szablon',
  'Insert accordion': '',
  'Insert column after': 'Wstaw kolumn\u0119 po',
  'Insert column before': 'Wstaw kolumn\u0119 przed',
  'Insert date/time': 'Wstaw dat\u0119/godzin\u0119',
  'Insert image': 'Wstaw obraz',
  'Insert link (if link plugin activated)':
    'Wstaw \u0142\u0105cze (je\u015bli w\u0142\u0105czono dodatek linkuj\u0105cy)',
  'Insert row after': 'Wstaw wiersz po',
  'Insert row before': 'Wstaw wiersz przed',
  'Insert table': 'Wstaw tabel\u0119',
  'Insert template...': 'Wstaw szablon...',
  'Insert video': 'Wstaw wideo',
  'Insert/Edit code sample': 'Dodaj/Edytuj przyk\u0142adowy kod',
  'Insert/edit image': 'Wstaw/edytuj obraz',
  'Insert/edit link': 'Wstaw/edytuj \u0142\u0105cze',
  'Insert/edit media': 'Wstaw/edytuj multimedia',
  'Insert/edit video': 'Wstaw/edytuj wideo',
  Inset: 'Wstawione',
  'Invalid hex color code: {0}':
    'Nieprawid\u0142owy kod szesnastkowy koloru: {0}',
  'Invalid input': 'Nieprawid\u0142owa warto\u015b\u0107',
  Italic: 'Kursywa',
  Justify: 'Wyjustuj',
  'Keyboard Navigation': 'Nawigacja za pomoc\u0105 klawiatury',
  Language: 'J\u0119zyk',
  'Learn more...': 'Dowiedz si\u0119 wi\u0119cej...',
  Left: 'Lewo',
  'Left to right': 'Od lewej do prawej',
  'Light Blue': 'Jasnoniebieski',
  'Light Gray': 'Jasnoszary',
  'Light Green': 'Jasnozielony',
  'Light Purple': 'Jasnopurpurowy',
  'Light Red': 'Jasnoczerwony',
  'Light Yellow': 'Jasno\u017c\xf3\u0142ty',
  'Line height': 'Wysoko\u015b\u0107 Linii',
  'Link list': 'Lista \u0142\u0105czy',
  'Link...': '\u0141\u0105cze...',
  'List Properties': 'Ustawienia Listy',
  'List properties...': 'Ustawienia listy...',
  'Loading emojis...': 'Wczytywanie emotikon\xf3w...',
  'Loading...': 'Wczytywanie...',
  'Lower Alpha': 'Ma\u0142e alfanumeryczne',
  'Lower Greek': 'Ma\u0142e greckie',
  'Lower Roman': 'Ma\u0142e rzymskie',
  'Match case': 'Uwzgl\u0119dniaj wielko\u015b\u0107 liter',
  Mathematical: 'Matematyczne',
  'Media poster (Image URL)': 'Plakat (URL obrazu)',
  'Media...': 'Multimedia...',
  'Medium Blue': '\u015arednioniebieski',
  'Medium Gray': '\u015arednioszary',
  'Medium Purple': '\u015aredniopurpurowy',
  'Merge cells': 'Scal kom\xf3rki',
  Middle: '\u015arodek',
  'Midnight Blue': 'Nocny b\u0142\u0119kit',
  'More...': 'Wi\u0119cej...',
  Name: 'Nazwa',
  'Navy Blue': 'Ciemnoniebieski',
  'New document': 'Nowy dokument',
  'New window': 'Nowe okno',
  Next: 'Nast\u0119pny',
  No: 'Nie',
  'No alignment': 'Bez wyr\xf3wnania',
  'No color': 'Bez koloru',
  'Nonbreaking space': 'Nie\u0142amliwa spacja',
  None: 'Brak',
  'Numbered list': 'Lista numerowana',
  OR: 'LUB',
  Objects: 'Obiekty',
  Ok: 'Ok',
  'Open help dialog': 'Otw\xf3rz okno dialogowe pomocy',
  'Open link': 'Otw\xf3rz \u0142\u0105cze',
  'Open link in...': 'Otw\xf3rz \u0142\u0105cze w...',
  'Open popup menu for split buttons':
    'Otw\xf3rz menu podr\u0119czne dla przycisk\xf3w',
  Orange: 'Pomara\u0144czowy',
  Outset: 'Zewn\u0119trzne',
  'Page break': 'Podzia\u0142 strony',
  Paragraph: 'Akapit',
  Paste: 'Wklej',
  'Paste as text': 'Wklej jako tekst',
  'Paste column after': 'Wklej kolumn\u0119 po',
  'Paste column before': 'Wklej kolumn\u0119 przed',
  'Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.':
    'Wklejanie jest w trybie tekstowym. Dop\xf3ki nie wy\u0142\u0105czysz tej opcji, zawarto\u015b\u0107 b\u0119dzie wklejana jako zwyk\u0142y tekst.',
  'Paste or type a link': 'Wklej lub wpisz \u0142\u0105cze',
  'Paste row after': 'Wklej wiersz po',
  'Paste row before': 'Wklej wiersz przed',
  'Paste your embed code below:': 'Wklej poni\u017cej kod do osadzenia:',
  People: 'Ludzie',
  Plugins: 'Dodatki',
  'Plugins installed ({0}):': 'Zainstalowane dodatki ({0}):',
  'Powered by {0}': 'Stworzone dzi\u0119ki {0}',
  Pre: 'Pre',
  Preferences: 'Ustawienia',
  Preformatted: 'Wst\u0119pne formatowanie',
  'Premium plugins:': 'Dodatki Premium:',
  'Press the Up and Down arrow keys to resize the editor.': '',
  'Press the arrow keys to resize the editor.': '',
  'Press {0} for help': '',
  Preview: 'Podgl\u0105d',
  Previous: 'Poprzedni',
  Print: 'Drukuj',
  'Print...': 'Drukuj...',
  Purple: 'Purpurowy',
  Quotations: 'Cudzys\u0142owy',
  R: 'R',
  'Range 0 to 255': 'Od 0 do 255',
  Red: 'Czerwony',
  'Red component': 'Czerwony',
  Redo: 'Powt\xf3rz',
  Remove: 'Usu\u0144',
  'Remove color': 'Usu\u0144 kolor',
  'Remove link': 'Usu\u0144 \u0142\u0105cze',
  Replace: 'Zast\u0105p',
  'Replace all': 'Zast\u0105p wszystkie',
  'Replace with': 'Zast\u0105p ci\u0105giem:',
  Resize: 'Zmie\u0144 rozmiar',
  'Restore last draft': 'Przywr\xf3\u0107 ostatni szkic',
  'Reveal or hide additional toolbar items': '',
  'Rich Text Area': 'Przestrze\u0144 tekstu sformatowanego',
  'Rich Text Area. Press ALT-0 for help.':
    'Obszar tekstu sformatowanego. Naci\u015bnij ALT-0, aby uzyska\u0107 pomoc.',
  'Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help':
    'Obszar Edycji. ALT-F9: menu. ALT-F10: pasek narz\u0119dzi. ALT-0: pomoc',
  Ridge: 'Grzbietowe',
  Right: 'Prawo',
  'Right to left': 'Od prawej do lewej',
  Row: 'Wiersz',
  'Row clipboard actions': 'Akcje schowka dla wiersza',
  'Row group': 'Grupa wierszy',
  'Row header': 'Nag\u0142\xf3wek wiersza',
  'Row properties': 'W\u0142a\u015bciwo\u015bci wiersza',
  'Row type': 'Typ wiersza',
  Rows: 'Wier.',
  Save: 'Zapisz',
  'Save (if save plugin activated)':
    'Zapisz (je\u015bli w\u0142\u0105czono dodatek zapisuj\u0105cy)',
  Scope: 'Zasi\u0119g',
  Search: 'Wyszukaj',
  'Select all': 'Zaznacz wszystko',
  'Select...': 'Wybierz...',
  Selection: 'Zaznaczenie',
  Shortcut: 'Skr\xf3t',
  'Show blocks': 'Poka\u017c bloki',
  'Show caption': 'Poka\u017c podpis',
  'Show invisible characters': 'Poka\u017c niewidoczne znaki',
  Size: 'Rozmiar',
  Solid: 'Pe\u0142ne',
  Source: '\u0179r\xf3d\u0142o',
  'Source code': 'Kod \u017ar\xf3d\u0142owy',
  'Special Character': 'Znak Specjalny',
  'Special character...': 'Znak specjalny...',
  'Split cell': 'Podziel kom\xf3rk\u0119',
  Square: 'Kwadrat',
  'Start list at number': 'Rozpocznij numeracj\u0119 od',
  Strikethrough: 'Przekre\u015blenie',
  Style: 'Styl',
  Subscript: 'Indeks dolny',
  Superscript: 'Indeks g\xf3rny',
  'Switch to or from fullscreen mode':
    'W\u0142\u0105cz lub wy\u0142\u0105cz tryb pe\u0142noekranowy',
  Symbols: 'Symbole',
  'System Font': 'Font systemowy',
  Table: 'Tabela',
  'Table caption': 'Tytu\u0142 tabeli',
  'Table properties': 'W\u0142a\u015bciwo\u015bci tabeli',
  'Table styles': 'Style tabel',
  Template: 'Szablon',
  Templates: 'Szablony',
  Text: 'Tekst',
  'Text color': 'Kolor tekstu',
  'Text color {0}': '',
  'Text to display': 'Tekst do wy\u015bwietlenia',
  'The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?':
    'Wprowadzony URL wygl\u0105da na adres e-mail. Czy chcesz doda\u0107 wymagany prefiks mailto: ?',
  'The URL you entered seems to be an external link. Do you want to add the required http:// prefix?':
    'Wprowadzony URL wygl\u0105da na \u0142\u0105cze zewn\u0119trzne. Czy chcesz doda\u0107 http:// jako prefiks?',
  'The URL you entered seems to be an external link. Do you want to add the required https:// prefix?':
    'Wprowadzony URL wygl\u0105da na \u0142\u0105cze zewn\u0119trzne. Czy chcesz doda\u0107 wymagany prefiks https://?',
  Title: 'Tytu\u0142',
  'To open the popup, press Shift+Enter':
    'Aby otworzy\u0107 okienko, naci\u015bnij Shift+Enter',
  'Toggle accordion': '',
  Tools: 'Narz\u0119dzia',
  Top: 'G\xf3ra',
  'Travel and Places': 'Podr\xf3\u017ce i miejsca',
  Turquoise: 'Turkusowy',
  Underline: 'Podkre\u015blenie',
  Undo: 'Cofnij',
  Upload: 'Prze\u015blij',
  'Uploading image': 'Przesy\u0142anie obrazu',
  'Upper Alpha': 'Wielkie alfanumeryczne',
  'Upper Roman': 'Wielkie rzymskie',
  Url: 'URL',
  'User Defined': 'W\u0142asny',
  Valid: 'Prawid\u0142owe',
  Version: 'Wersja',
  'Vertical align': 'Wyr\xf3wnanie w pionie',
  'Vertical space': 'Odst\u0119p w pionie',
  View: 'Widok',
  'Visual aids': 'Pomoce wizualne',
  Warn: 'Ostrze\u017cenie',
  White: 'Bia\u0142y',
  Width: 'Szeroko\u015b\u0107',
  'Word count': 'Liczba s\u0142\xf3w',
  Words: 'S\u0142owa',
  'Words: {0}': 'S\u0142\xf3w: {0}',
  Yellow: '\u017b\xf3\u0142ty',
  Yes: 'Tak',
  'You are using {0}': 'U\u017cywasz {0}',
  'You have unsaved changes are you sure you want to navigate away?':
    'Masz niezapisane zmiany. Czy na pewno chcesz opu\u015bci\u0107 stron\u0119?',
  "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
    'Twoja przegl\u0105darka nie obs\u0142uguje bezpo\u015bredniego dost\u0119pu do schowka. U\u017cyj zamiast tego kombinacji klawiszy Ctrl+X/C/V.',
  alignment: 'wyr\xf3wnanie',
  'austral sign': 'znak australa',
  'cedi sign': 'znak cedi',
  'colon sign': 'znak colon',
  'cruzeiro sign': 'znak cruzeiro',
  'currency sign': 'znak waluty',
  'dollar sign': 'znak dolara',
  'dong sign': 'znak donga',
  'drachma sign': 'znak drachmy',
  'euro-currency sign': 'znak euro',
  example: 'przyk\u0142ad',
  formatting: 'formatowanie',
  'french franc sign': 'znak franka francuskiego',
  'german penny symbol': 'znak feniga',
  'guarani sign': 'znak guarani',
  history: 'historia',
  'hryvnia sign': 'znak hrywny',
  indentation: 'wci\u0119cie',
  'indian rupee sign': 'znak rupii indyjskiej',
  'kip sign': 'znak kipa',
  'lira sign': 'znak liry',
  'livre tournois sign': 'znak livre tournois',
  'manat sign': 'znak manata',
  'mill sign': 'znak mill',
  'naira sign': 'znak nairy',
  'new sheqel sign': 'znak nowego szekla',
  'nordic mark sign': 'znak nordic mark',
  'peseta sign': 'znak pesety',
  'peso sign': 'znak peso',
  'ruble sign': 'znak rubla',
  'rupee sign': 'znak rupii',
  'spesmilo sign': 'znak spesmilo',
  styles: 'style',
  'tenge sign': 'znak tenge',
  'tugrik sign': 'znak tugrika',
  'turkish lira sign': 'znak liry tureckiej',
  'won sign': 'znak wona',
  'yen character': 'znak jena',
  'yen/yuan character variant one': 'jen/juan, wariant pierwszy',
  'yuan character': 'znak juana',
  'yuan character, in hong kong and taiwan':
    'znak juana w Hongkongu i na Tajwanie',
  '{0} characters': '{0} znak\xf3w',
  '{0} columns, {1} rows': '',
  '{0} words': '{0} s\u0142.',
})
