import photoSchema from 'core/_schema/photo'
const schema = {
  endpoint: '/api/residence_items',
  resource: {
    definition: 'ResidenceItem-residenceItem.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Tytuł',
          },
          description: {
            type: 'textarea',
          },
          info: {},
          link: {},
          alt: {},
        },
      },
      photo: {
        type: 'media[image]',
        description: 'T_GENERAL_MEDIA_PHOTO',
        endpoint: photoSchema.endpoint.single,
        endpointYoutube: photoSchema.endpoint.youtube,
        thumbs: {
          endpoint: '/api/residence_item_thumbs',
        },
        validate: ['maxSize'],
        ytProperty: 'youtube',
        ofmEnable: true
      },
      group: {
        type: 'resource',
        endpoint: `/api/residence_groups?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
        label: 'Grupa pokoi',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
