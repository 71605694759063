export const getFilters = (columns, parameters = []) =>
  Object.assign(
    {},
    ...columns
      .filter(
        column => column.filterable && (column.filterName || column.accessor)
      )
      .map(column => {
        const param = parameters.find(
          param =>
            param.name === column.filterName || param.name === column.accessor
        )
        return param
          ? {
              [column.filterName || column.accessor]: {
                title: column.filterTitle || column.header,
                name: param.name,
                in: param.in,
                type: column.Filter || param.type,
                value: column.value === undefined ? null : column.value,
              },
            }
          : null
      })
      .filter(param => param)
  )
