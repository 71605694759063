import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { List, ListItem } from '@material-ui/core'
import { Status } from 'core/components/resource/Status'
import { Delete } from 'core/components/resource/Delete'
import { CreateVersion } from 'core/components/resource/CreateVersion'
import { commonConstants } from 'core/_constants'
import { translate } from 'core/_helpers/translate'
import routes from 'core/pages/Pages/routes'
import { useStyles } from 'core/components/resource/sidebar/Sidebar.styles'
import red from '@material-ui/core/colors/red'

export const Sidebar = ({
  resource,
  titleAccessor = `translations.${process.env.REACT_APP_LOCALE}.title`,
  statAccessor = 'stat',
  collectionPath,
  disabled = false,
  handleSyncUpdatedAt
}) => {
  const defaultClasses = useStyles()

  const history = useHistory()
  const dispatch = useDispatch()

  const onDeleteSuccess = () => {
    dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })

    history.push(collectionPath)
  }

  const profile = useSelector(state => state.profile)

  const maxNesting = process.env.REACT_APP_PAGE_MAX_NESTING_LEVEL

  let isSubPageable =
    profile.isOpenform ||
    (resource.isSubPageable && resource.nestingLevel < maxNesting)

    if(resource.idName && resource.idName === 'page_news'){
      isSubPageable = false
    }

    let isStatable = () => {
      if(profile.isOpenform) { return true }
      if(resource.isStatable && profile.isModerator ) { return true }
      return false
    }

  return (
    <List className={defaultClasses.list}>
      {isSubPageable && (
        <ListItem className={defaultClasses.item}>
          <Link
            to={`${routes().new.path}?pid=${
              resource[process.env.REACT_APP_RESOURCE_ID]
            }`}
            className={defaultClasses.link}
          >
            {translate('T_MODULE_PAGES_ADD_SUBPAGE')}
            <span style={{color: red[800]}}>{ (profile.isOpenform && (!resource.isSubPageable || !(resource.nestingLevel < maxNesting)  )) ? ' [OF]' : '' }</span>
          </Link>
        </ListItem>
      )}
      <ListItem className={defaultClasses.item}>
        <Status
          resource={resource}
          accessor={statAccessor}
          disabled={disabled || !isStatable() }
          isSwitch={false}
          handleSyncUpdatedAt={handleSyncUpdatedAt}
        />
      </ListItem>
      <ListItem className={defaultClasses.item}>
        <Delete
          resource={resource}
          accessor={titleAccessor}
          disabled={disabled || (!profile.isOpenform && !resource.isDeletable)}
          onSuccess={onDeleteSuccess}
        />
      </ListItem>
      <ListItem className={defaultClasses.item}>
        <CreateVersion resource={resource} disabled={disabled} />
      </ListItem>
      <ListItem className={defaultClasses.item}>
        <Link
          to={routes().version_collection.path.replace(
            ':id',
            resource[process.env.REACT_APP_RESOURCE_ID]
          )}
          className={defaultClasses.link}
        >
          {translate('T_GENERAL_RECORD_VERSION_COLLECTION')}
        </Link>
      </ListItem>
    </List>
  )
}

Sidebar.propTypes = {
  resource: PropTypes.shape({
    [process.env.REACT_APP_RESOURCE_ID]: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    '@id': PropTypes.string.isRequired,
    nestingLevel: PropTypes.number.isRequired,
    isSubPageable: PropTypes.bool,
    isStatable: PropTypes.bool,
    isDeletable: PropTypes.bool,
  }).isRequired,
  titleAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.func,
  ]),
  statAccessor: PropTypes.string,
  collectionPath: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  handleSyncUpdatedAt: PropTypes.func.isRequired,
}
