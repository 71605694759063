import React, { useCallback } from 'react'
// import { useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { Form, NotFound } from 'core/pages'
import { Paper } from 'core/components/Paper'
import { Title } from 'core/components/Title'
import { Loader } from 'core/components/Loader'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { translate } from 'core/_helpers/translate'
import schematmp from '_schema/tagStruct'
import routes from './routes'
import { store } from 'core/_store'
import { collectionConstants } from 'core/_constants'

export const New = ({ schema: definitions, ...rest }) => {
  const schema = {
    ...schematmp,
    resource: {
      ...schematmp.resource,
      properties: Object.assign({}, schematmp.resource.properties),
    },
  }

  delete schema.resource.properties.parent

  // const profile = useSelector(state => state.profile)

  // const { pid } = queryString.parse(rest.location.search)
  const pid = rest.match.params.pid
  const defaultData = pid ? { parent: [`${schema.endpoint}/${pid}`] } : {}

  const [state, setState] = useResourceState()

  const { resource: parent, isFetching, fetchError } = state

  useResourceFetch(
    pid && `${schema.endpoint}/${pid}`,
    parent,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    true
  )

  const isNotEditable = false

  const history = useHistory()

  const handleSuccess = useCallback(() => {
    history.push(
      pid ? routes().indexParent.path.replace(':id', pid) : routes().index.path
    )
    if (schema.extraStoreCollectionId) {
      store.dispatch({
        type: collectionConstants.RESET_CONFIG,
        payload: { id: schema.extraStoreCollectionId },
      })
      store.dispatch({
        type: collectionConstants.RESET_CONFIG,
        payload: { id: 'tag_structs_form_parent_false' },
      })
      store.dispatch({
        type: collectionConstants.RESET_CONFIG,
        payload: { id: 'tag_structs_form_parent_true' },
      })
    }
  }, [history, pid, schema.extraStoreCollectionId])

  const handleSuccessAndStay = useCallback(
    resource => {
      history.push(
        pid
          ? routes().indexParent.path.replace(':id', pid)
          : routes().index.path
      )
      history.push(
        routes().edit.path.replace(
          ':id',
          resource[process.env.REACT_APP_RESOURCE_ID]
        )
      )
      if (schema.extraStoreCollectionId) {
        store.dispatch({
          type: collectionConstants.RESET_CONFIG,
          payload: { id: schema.extraStoreCollectionId },
        })
      }
    },
    [history, pid, schema.extraStoreCollectionId]
  )
  const handleCancel = () => {
    history.goBack()
  }

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : pid && fetchError ? (
    <NotFound />
  ) : isNotEditable ? (
    <Redirect to={routes().index.path} />
  ) : (
    <Form
      defaultData={defaultData}
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      // modifyProperties={properties =>
      //   addOpenformProperties(profile.isOpenform, properties)
      // }
      method="POST"
      url={schema.endpoint}
      storeCollectionId={schema.endpoint}
      collectionPath={routes().index.path}
      editPath={routes().edit.path}
      handleSuccess={handleSuccess}
      handleSuccessAndStay={handleSuccessAndStay}
      handleCancel={handleCancel}
      header={
        <Title>{translate(pid ? 'Dodaj nowy tag' : 'T_GENERAL_NEW')}</Title>
      }
      fieldsFullWidth={false}
      width={800}
    />
  )
}
