import React from 'react'
import { Form } from 'core/pages'
import schema from '_schema/shortcut'
import routes from './routes'
import { addProperties } from './_helpers/addProperties'
import { useSelector } from 'react-redux'

export const New = ({ schema: definitions }) => {
  const profile = useSelector(state => state.profile)
  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      method="POST"
      url={schema.endpoint}
      collectionPath={routes().index.path}
      editPath={routes().edit.path}
      storeCollectionId={schema.endpoint}
      width={600}
      modifyProperties={properties => addProperties(profile, properties)}
    />
  )
}
