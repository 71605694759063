import { put, call } from 'redux-saga/effects'
import { profileConstants } from 'core/_constants'
import { profileService } from 'core/_services'
import { handleAuthError } from 'core/_helpers/handleAuthError'
import { prop } from 'core/_helpers/prop'
import schema from 'core/_schema/profile'

const accessProperties = [
  'resourceAccess',
  'isSuperAdmin',
  'isOpenform',
  'isModerator',
  'wasPasswordChangedByOtherAdmin',
  'showContextualHints',
  `group.${process.env.REACT_APP_RESOURCE_ID}`,
]

function* fetch() {
  yield put({ type: profileConstants.REQUEST })

  const { response, error } = yield call(profileService.get)

  if (response) {
    yield put({
      type: profileConstants.SUCCESS,
      payload: {
        ...[
          process.env.REACT_APP_RESOURCE_ID,
          ...accessProperties,
          ...Object.keys(schema.profile.properties),
        ].reduce(
          (obj, key) =>
            Object.assign(obj, { [key.split('.')[0]]: prop(response, key) }),
          {}
        ),
      },
    })

    return
  }

  yield call(handleAuthError.saga, error)

  return
}

const update = values => ({
  type: profileConstants.UPDATE,
  payload: {
    ...[...accessProperties, ...Object.keys(schema.profile.properties)].reduce(
      (obj, key) =>
        Object.assign(obj, { [key.split('.')[0]]: prop(values, key) }),
      {}
    ),
  },
})

export const profileActions = {
  fetch,
  update,
}
