import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { isActive } from 'core/pages/Messenger/_helpers/isActive'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.success.main,
    marginLeft: 5,
  },
}))

export const ActiveDot = ({ recipient }) => {
  const classes = useStyles()

  return isActive(recipient) ? (
    <span className={classes.root}>&bull;</span>
  ) : null
}

ActiveDot.propTypes = {
  recipient: PropTypes.shape({
    lastActivityAt: PropTypes.string,
  }).isRequired,
}
