import React, { useCallback } from 'react'
import { Form } from 'core/pages'
import schema from '_schema/place'
import routes from './routes'
import { store } from 'core/_store'
import { collectionConstants } from 'core/_constants'

export const New = ({ schema: definitions }) => {
  const handleSuccess = useCallback((resource, defaultSucces) => {
    if (schema.extraStoreCollectionId) {
      store.dispatch({
        type: collectionConstants.RESET_CONFIG,
        payload: { id: schema.extraStoreCollectionId },
      })
    }
    defaultSucces(resource)
  }, [])
  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      method="POST"
      url={schema.endpoint}
      collectionPath={routes().index.path}
      editPath={routes().edit.path}
      storeCollectionId={schema.endpoint}
      width={'75%'}
      handleSuccess={handleSuccess}
      handleSuccessAndStay={handleSuccess}
    />
  )
}
