// import { schema as schematagstr } from '_schema/tagStruct'

const schema = {
  endpoint: '/api/sobiesciana_filter_sets',
  resource: {
    definition: 'SobiescianaFilterSet-sobiescianaFilterSet.read',
    properties: {
      name: {
        label: 'Nazwa',
        hint: 'nazwa widoczna tylko w PA',
        validate: ['required']
      },
      stat: {},

      filters: {
        type: 'embeddedcollection',
        endpoint: '/api/sobiesciana_filters',
        definition: 'SobiescianaFilter-sobiescianaFilter.read',
        description: 'Filtry',
        statable: true,
        additionalProperties: {
          $ref:
            '#/definitions/SobiescianaFilter-sobiescianaFilter.write_sobiescianaFilter.create',
        },

        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,

        properties: {
          translations: {
            type: 'translation',
            properties: {
              title: {
                label: 'Nazwa',
              },
            },
          },

          inheritFrom: {
            type: 'resource',
            endpoint: `/api/sobiesciana_filters_form?pagination=false`,
            titleAccessor: `title`,
            label: 'Użyj filtra rodzica',
            hint: 'jesli rodzic ma taki sam filtr wybierz go. wszystkie pola zostaną odziedziczone z wybranego',
            storeCollectionId: 'sobiesciana_filters_form',
          },

          type:{
            type: 'choice',
            label: 'Typ filtra',
            choices: {
              1 : 'Pole wyszukiwania po tytule',
              2 : 'Pole na tagi',
              3 : 'Pole wyszukiwania po "Numer Innwentarza"',
              4 : 'Pole wyszukiwania po "Wymiary"',
              5 : 'Pole na Oś Czasu'
            },
            validate: ['required']
          },

          tag: {
            type: 'resource',
            endpoint: `/api/tag_structs_form?pagination=false&exists[parent]=false`,
            titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,//'uuid',
            label: 'Grupa tagów',
            hint: 'tylko jeśl typ pola na tagi',
            storeCollectionId: 'tag_structs_form_parent_false',
          },

          group:{
            type: 'choice',
            choices: {
              1 : 'Dane podstawowe',
              2 : 'Miejsce przechowywania',
              3 : 'Czas powstania',
              4 : 'Dane dookreślające',
            },
            validate: ['required']
          },
          showTimeline: {
            hint: 'tylko jeśli pole na oś czasu'
          },
          importName: {disabled: true, label: 'Importowana nazwa' },
          // stat: {},//because statable==true
        },
      },
    },
  },
}

export default schema
