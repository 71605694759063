import 'date-fns'
import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import randomHash from 'random-hash'
import DateFnsUtils from '@date-io/date-fns'
import plLocale from 'date-fns/locale/pl'
import moment from 'moment'
import clsx from 'clsx'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { FormHelperText } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'

const LOCALES = {
  pl: plLocale,
}

const FORMAT = 'yyyy-MM-dd'

const DATE_PICKER_ERRORS = [
  translate('T_GENERAL_INVALID_DATE'),
  translate('T_GENERAL_INVALID_MIN_DATE'),
  translate('T_GENERAL_INVALID_MAX)DATE'),
]

const useStyles = makeStyles(theme => ({
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}))

export const DateType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
}) => {
  const [id] = useState(randomHash())

  const handleChange = value => {
    const date = value ? moment(value).format(FORMAT.toUpperCase()) : null
    setValue(name, date)
    validateField(date)
  }

  const handleError = message => {
    if (message && message !== error) {
      setError(name, message)
    }
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  const classes = useStyles()

  return (
    <>
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={LOCALES[process.env.REACT_APP_LOCALE]}
      >
        <>
          <KeyboardDatePicker
            id={id}
            name={name}
            label={
              translate(label.text || label) +
              (validators && validators.includes('required') ? ' *' : '')
            }
            value={![null, undefined].includes(value) ? new Date(value) : null}
            disabled={disabled}
            disableToolbar
            variant="inline"
            format={FORMAT}
            margin="normal"
            minDate={false}
            invalidDateMessage={DATE_PICKER_ERRORS[0]}
            minDateMessage={DATE_PICKER_ERRORS[1]}
            maxDateMessage={DATE_PICKER_ERRORS[2]}
            onChange={handleChange}
            onError={handleError}
          />
          <FormHelperText error={renderError && !!error} disabled={disabled}>
            {renderError && !!error
              ? !DATE_PICKER_ERRORS.includes(error)
                ? translate(error)
                : null
              : translate(hint)}
          </FormHelperText>
        </>
      </MuiPickersUtilsProvider>
      {compare && (
        <div
          className={clsx(
            classes.compare,
            value !== compareValue && classes.compareNeq
          )}
        >
          {compareValue && moment(compareValue).format(FORMAT.toUpperCase())}
        </div>
      )}
    </>
  )
}

DateType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.string,
  value: PropTypes.string,
  compareValue: PropTypes.string,
  compare: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
}
