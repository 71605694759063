export const getSorters = (columns, parameters = []) =>
  Object.assign(
    {},
    ...columns
      .filter(
        column => column.sortable && (column.sorterName || column.accessor)
      )
      .map(column => {
        const param = parameters.find(
          param =>
            param.name === `order[${column.sorterName}]` ||
            param.name === `order[${column.accessor}]`
        )
        return param
          ? {
              [column.sorterName || column.accessor]: {
                name: param.name,
                in: param.in,
                order: column.order || null,
              },
            }
          : null
      })
      .filter(param => param)
  )
