import _block from 'pages/EducationHome/_block'
import photoSchema from 'core/_schema/photo'
import fileSchema from 'core/_schema/file'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { schema as schematag } from '_schema/tagEntry'
import schemaplace from '_schema/place'
import { iksorisTicketField } from './_iksoris'

const schema = {
  endpoint: '/api/happens',
  endpointResourceAccess: '/api/education_homes',
  resource: {
    definition: 'Happen-happen.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          subtitle: {},
          shortLead: {
            type: 'textarea',
          },
          text: {
            type: 'tinymce',
          },

          termin: {},
          hours: {},
          conductor: {},
          ticketPriceString: {},
          material: { type: 'tinymce' },
          remember: { type: 'tinymce' },

          photoAlt: {},
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {
            type: 'slug',
            routes: {
              pl: '/wydarzenie/:slug',
              en: '/en/event/:slug',
            },
          },
          preview: {
            label: 'Podgląd',
            description: '',
            type: 'preview',
            routes: {
              pl: '/wydarzenie/:slug',
              en: '/en/event/:slug',
            },
          },
        },
      },

      dataRange: {
        type: 'dateRange',
      },

      tags: {
        type: 'embeddedcollection',
        endpoint: '/api/happen_tags',
        definition: 'HappenTag-happenTag.read',
        description: 'T_HAPPEN_TAGS',
        additionalProperties: {
          $ref: '#/definitions/HappenTag-happenTag.write_happenTag.create',
        },

        titleAccessor: function(resource, handleSuccess) {
          if (resource['@titleAccessor']) return resource['@titleAccessor']
          const setResource = res => {
            resource['@titleAccessor'] =
              res.translations && res.translations[process.env.REACT_APP_LOCALE]
                ? res.translations[process.env.REACT_APP_LOCALE].title ?? '--'
                : '--'
            if (handleSuccess) {
              handleSuccess(resource)
            }
          }
          if (resource.tag) {
            fetchDataHandleAuthError(resource.tag, 'GET', null, setResource)
          } else {
            return '--'
          }
        },

        properties: {
          tag: {
            type: 'resourcetag',
            endpoint: `/api/tag_entries?pagination=false`,
            titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.titleWithName`, //'uuid',
            storeCollectionId: schematag.extraStoreCollectionId,
            label: 'T_TAG_ENTRY',
          },
          stat: {},
        },
      },

      // type: {
      //   type: 'media[type]',
      // },
      // youtube: {
      //   type: 'media[youtube]',
      //   description: 'T_GENERAL_YOUTUBE_CODE',
      // },
      photo: {
        type: 'media[image]',
        description: 'T_GENERAL_MEDIA_PHOTO',
        endpoint: photoSchema.endpoint.single,
        endpointYoutube: photoSchema.endpoint.youtube,
        thumbs: {
          endpoint: '/api/happen_thumbs',
        },
        validate: ['maxSize'],
        ytProperty: 'youtube',
        ofmEnable: true,
      },

      // iksorisEventId:{
      //   defaultValue: 0,
      //   hint: 'jeśli wpisany, bedzie otwierac zakup indywidualny biletu'
      // },
      // iksorisTopicId:{
      //   defaultValue: 0,
      //   hint: 'jeśli wpisany, bedzie otwierac zakup grupowy biletu'
      // },
      // iksorisTermId:{
      //   defaultValue: 0,
      //   hint: 'jesli wpisany, bedzie odrazu wybany w kroku 2 zakupu biletu'
      // },
      iksoris: iksorisTicketField,

      languageArray: {
        type: 'multichoice',
        choices: {
          en: 'En',
          pl: 'Pl',
          ua: 'Ua',
          pjm: 'PJM',
          inne: 'Inne',
        },
      },

      accessArray: {
        type: 'multichoice',
        choices: {
          audiodesc: 'Audiodeskrypcja',
          moveaccess: 'Dostępność ruchowa',
          knowledge: 'Dostępność intelektualna',
          easytext: 'Łatwy tekst',
          aac: 'AAC',
          induction: 'Pętla indukcyjna',
          bigtext: 'Powiększona czcionka',
          tyflo: 'Tyflografiki',
          inPrice: 'Oferta w cenie',
          forFree: 'Bezplatne',
        },
      },

      ageFrom: {
        type: 'integer',
        minValue: 0,
      },
      ageTo: {
        type: 'integer',
        minValue: 0,
      },

      place: {
        type: 'resource',
        endpoint: `/api/places?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
        label: 'Place',
        storeCollectionId: schemaplace.extraStoreCollectionId,
      },
      meetingPlace: {
        type: 'resource',
        endpoint: `/api/places?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
        label: 'Place of meet',
        storeCollectionId: schemaplace.extraStoreCollectionId,
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/happen_blocks?pagination=false',
      definition: 'HappenBlock-happenBlock.read',
      types: _block(
        photoSchema.endpoint.single,
        '/api/happen_block_thumbs',
        fileSchema.endpoint.single,
        {
          endpoint: '/api/happen_block_elements',
          definition: 'HappenBlockElement-happenBlockElement.read',
          ref:'#/definitions/HappenBlockElement-happenBlockElement.write_happenBlockElement.create',
        }
      ).types,
    },
  },
}

export default schema
