export const addOpenformProperties = (
  isOpenform,
  properties,
  resource,
  schema
) => {
  const modifiedProperties = properties

  if (isOpenform) {
    return {
      ...properties,
      idName: {
        type: 'string',
        description: {
          text: 'T_GENERAL_OPENFORM_PAGE_IDNAME',
          color: '#cc0000',
        },
      },
    }
  }

  if (resource && !resource.isStatable) {
    delete modifiedProperties.stat
  }

  return modifiedProperties
}
