import React from 'react'
import { ResourceMediaEdit } from 'core/pages/ResourceMediaEdit'
import { useSelector } from 'react-redux'
import schema from '_schema/personItem'
import routes from './routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest
  const profile = useSelector(state => state.profile)

  return (
    <ResourceMediaEdit
      uuid={match.params.id}
      definitions={definitions}
      modifyProperties={(properties, resource, schema) => {
        const modifiedProperties = properties
        if (resource && !profile.isModerator) {
          delete modifiedProperties.stat
        }
        return modifiedProperties
      }}
      schema={schema}
      editPath={routes().edit.path}
      parentEditPath={routes().edit.path}
      parentShowPath={routes().index.path}
      formWidth={750}
      key={match.params.id}
    />
  )
}
