import React from 'react'
// import { useHistory } from 'react-router-dom'
// import { prop } from 'core/_helpers/prop'
import { makeStyles } from '@material-ui/styles'
// import { translate } from 'core/_helpers/translate'
// import moment from 'moment'

const useStyles = makeStyles({
  title: {
  },
})

export const MainCellSendcount = ({
  resource,
  // accessor,
  isRowLinkable = false,
  editPath = null,
}) => {

  const classes = useStyles()

  return (
      <div className={classes.title}>
        { resource.recipientsCount > 0 ? (<>
        { resource.sentCount } z { resource.recipientsCount } </>) : '--' }
      </div>
  )
}


