import photoSchema from 'core/_schema/photo'
// import vatSchema from '_schema/vat'
// import fileSchema from 'core/_schema/file'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { schema as schematag } from '_schema/tagEntry'

const schema = {
  endpoint: '/api/products',
  resourceforadd: {
    //przy dodawaniu nowego tylko okrojone dane
    definition: 'Product-product.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            type: 'textarea',
          },
        },
      },
    },
  },
  resource: {
    //edycja full, wszystkie pola
    definition: 'Product-product.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            type: 'textarea',
          },
          text: {
            type: 'tinymce',
          },
          photoAlt: {},
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {
            type: 'slug',
            routes: {
              pl: '/produkt/:slug',
              en: '/en/product/:slug',
            },
          },
          preview: {
            label: 'Podgląd',
            description:'',
            type: 'preview',
            routes: {
              pl: '/produkt/:slug',
              en: '/en/product/:slug',
            },
          },
        },
      },


      code: {
        label: 'Kod produktu',
        hint: '(SKU) zaimportowany ze starego sklepu',
        length: 40,
      },

      // floatPriceNetto: {
      //   type: 'number',
      //   minValue: 0,
      //   label: 'Cena netto'
      // },
      floatPrice: {
        type: 'number',
        minValue: 0,
        label: 'Cena brutto'
      },
      floatPriceLower: { // wyliczana automatycznie
        type: 'number',
        minValue: 0,
        disabled: true,
        label: 'Najniższa cena z 30dni'
      },
      quantity: {
        type: 'integer',
        minValue: 0,
      },
      weight: {
        type: 'integer',
        minValue: 0,
        endAdornment: 'g',
      },

      stat: {},
      showInShopBanner: {},
      // isNew: {},
      // isPromo: {},
      // isRecommended: {},
      // isFuture: {},


      tags: {
        type: 'collection',
        endpoint: '/api/product_tags',
        definition: 'ProductTag-productTag.read',
        description: 'Tagi produktu',
        additionalProperties: {
          '$ref':'#/definitions/ProductTag-productTag.write_productTag.create',
        },

        titleAccessor: function(resource, handleSuccess) {
          if(resource["@titleAccessor"]) return resource["@titleAccessor"]
          const setResource = (res) => {
            resource["@titleAccessor"] = res.translations && res.translations[process.env.REACT_APP_LOCALE] ? (res.translations[process.env.REACT_APP_LOCALE].title ?? '--') : '--'
            if(handleSuccess) {
              handleSuccess(resource)
            }
          }
          if(resource.tag) {
            fetchDataHandleAuthError(resource.tag,'GET',null,setResource)
          } else {
            return '--'
          }
        },
        properties: {
          tag: {
            type: 'resourcetag',
            endpoint: `/api/tag_entries?pagination=false`,
            titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.titleWithName`,//'uuid',
            storeCollectionId: schematag.extraStoreCollectionId,
            label: 'T_TAG_ENTRY'
          },
          stat: {}
        },
      },

      categories: {
        type: 'multipleResourceNotCategory',
        endpoint: `/api/product_categories_form?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
        label: 'Product categories',
      },

      filters: {
        type: 'multipleResource',
        endpoint: `/api/product_filters?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,//'uuid',
        label: 'Filtry'
      },

      // vats: {
      //   type: 'collection',
      //   endpoint: '/api/product_vats',
      //   definition: 'ProductVat-vat.read',
      //   description: 'T_BOOK_INDIVIDUAL_VAT',
      //   additionalProperties: {
      //     '$ref':'#/definitions/ProductVat-vat.write_vat.create',
      //   },
      //   titleAccessor: function(res) {
      //     return `${res.floatRate}% ${vatSchema.resource.properties.type.choices[res.type]}, ${vatSchema.resource.properties.whoType.choices[res.whoType]}, ${vatSchema.resource.properties.fromArea.choices[res.fromArea]}`
      //   },
      //   types: vatSchema.resource.properties.type.choices,

      //   properties: vatSchema.resource.properties,
      // },

      photo: {
        type: 'media[image]',
        description: 'Zdjęcie główne',
        endpoint: photoSchema.endpoint.single,
        endpointYoutube: photoSchema.endpoint.youtube,
        thumbs: {
          endpoint: '/api/product_thumbs',
        },
        validate: ['maxSize'],
        ytProperty: 'youtube',
        ofmEnable: true
      },


      images: {
        type: 'embeddedcollection',
        endpoint: '/api/product_images',
        definition: 'ProductImage-productImage.read',
        description: 'Dodatkowe zdjęcia produktu',
        additionalProperties: {
          $ref: '#/definitions/ProductImage-productImage.write_productImage.create',
        },

        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',

        properties: {
          translations: {
            type: 'translation',
            properties: {
              title: {},
              alt: {
                description: 'T_GENERAL_ALT',
              },
            },
          },
          photo: {
            type: 'image',
            description: 'T_GENERAL_MEDIA_PHOTO',
            endpoint: photoSchema.endpoint.single,
            endpointMultiple: photoSchema.endpoint.multiple,
            thumbs: {
              endpoint: '/api/product_image_thumbs',
            },
            validate: ['maxSize'],
            ofmEnable: true
          },
          stat: {
            description: 'T_GENERAL_PUBLICATION',
          },
        },
      },


      isDigital:{},

      // ebookPdf: {
      //   type: 'file',
      //   description: 'Plik pdf',
      //   accept: 'application/pdf',
      //   endpoint: '/api/file_ebooks',
      //   validate: ['maxSize'],
      // },
      // ebookMobi: {
      //   type: 'file',
      //   description: 'Plik mobi',
      //   accept: 'application/x-mobipocket-ebook,.mobi',
      //   endpoint: '/api/file_ebooks',
      //   validate: ['maxSize'],
      // },
      // ebookEpub: {
      //   type: 'file',
      //   description: 'Plik epub',
      //   accept: 'application/epub+zip,.epub',
      //   endpoint: '/api/file_ebooks',
      //   validate: ['maxSize'],
      // },


      ebooks: {
        type: 'collection',
        endpoint: '/api/product_file_items',
        definition: 'ProductFileItem-productFileItem.read',
        description: 'Ebooki',
        additionalProperties: {
          $ref: '#/definitions/ProductFileItem-productFileItem.write_productFileItem.create',
        },

        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,

        properties: {
          translations: {
            type: 'translation',
            properties: {
              title: {
                type: 'string',
                label: 'Tytuł pliku',
              },
            }
          },
          file: {
            type: 'file',
            description: 'Plik (pdf,mobi,epub)',
            accept: 'application/pdf,application/epub+zip,.epub,application/x-mobipocket-ebook,.mobi',
            endpoint: '/api/file_ebooks',
            validate: ['maxSize'],
            hint: 'wybierz tylko dozwolone typy pdf, mobi, epub',
            ofmEnable: true
          },
          lang: {
            type: 'choice',
            choices: {
              pl: 'polski',
              en: 'angielski',
            },
            placeholder: 'wszystkie',
            hint: 'domyślnie wszystkie jezyki',
            label: 'Wersja językowa',
          },
          stat: {},
        },
      },


    },
  },
}

export default schema
