import React from 'react'
import schema from '_schema/pwaMenu'
import routes from './routes'
import { Form } from 'core/pages'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`
  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={false}
      width={750}
      // sidebarTitleAccessor="pageTitle"
      // SidebarComponent={Sidebar}
    />

  )
}
