import React, { useState, useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { BlankForm } from 'core/pages'
import { Paper } from 'core/components/Paper'
import { Loader } from 'core/components/Loader'
import { Title } from 'core/components/Title'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { NotFound } from 'core/pages'
import { translate } from 'core/_helpers/translate'
import schema from '_schema/homeSection'

const LANGS = process.env.REACT_APP_RESOURCE_LANGS.split(',').map(lang =>
  lang.trim()
)

const useStyles = makeStyles({
  section: {
    width: 700,
    marginBottom: 50,
    paddingBottom: 30,
    borderBottom: '1px solid #ddd',
    '&:last-child': {
      marginBottom: 0,
      paddingBottom: 0,
      borderBottom: 'none',
    },
  },
  section_title: {
    marginBottom: 20,
  },
})

export const Edit = ({ schema: definitions }) => {
  const [state, setState] = useState({
    isFetching: true,
    fetchError: false,
    resource: null,
  })

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      schema.endpoint,
      'GET',
      { signal },
      response => {
        let sortedResponse = {}
        response['hydra:member'].forEach(item => {
          if (!!item.idName) {
            sortedResponse[item.idName] = item
          }
        })

        setState({
          isFetching: false,
          resource: response['hydra:member'].length ? sortedResponse : {},
        })
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
        setState({
          isFetching: false,
          fetchError: true,
        })
      }
    )

    return () => controller.abort()
  }, [])

  const handleSuccess = properties => resource => {
    setState(state => ({
      ...state,
      resource: {
        ...state.resource,
        ...Object.assign(
          {},
          ...Object.keys(properties).map(prop =>
            prop === 'translations' ? {} : { [prop]: resource[prop] }
          )
        ),
        translations: Object.assign(
          {},
          ...LANGS.map(lang => ({
            [lang]: {
              ...state.resource.translations?.[lang],
              ...Object.assign(
                {},
                ...Object.keys(
                  properties.translations.properties
                ).map(prop => ({ [prop]: resource.translations[lang][prop] }))
              ),
            },
          }))
        ),
      },
    }))
  }

  // const additionalResourcesMapping = useMemo(
  //   () => ({
  //     // recipe: `${recipeSchema.endpoint}?order[ord]=desc`,
  //     // exhibit: `${exhibitSchema.endpoint}?order[ord]=desc`,
  //   }),
  //   []
  // )

  // const [additionalResources] = usePropertyResourcesFetch(
  //   additionalResourcesMapping
  // )

  const resourceSchema = useMemo(
    () => ({
      ...schema.resource,
    }),
    []
  )

  const { isFetching, fetchError, resource } = state

  const classes = useStyles()

  const secs = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      {secs.map(sec => {
        return (
          <div className={classes.section}>
            <Title className={classes.section_title}>
              {translate(`T_MODULE_HOME_CONFIG_SECTION_${sec}`)}
            </Title>
            <BlankForm
              resource={resource[resourceSchema[`section${sec}`].id]}
              definitionSchema={definitions[schema.resource.definition]}
              customResourceSchema={resourceSchema[`section${sec}`]}
              defaultData={
                resource[resourceSchema[`section${sec}`].id] &&
                resource[resourceSchema[`section${sec}`].id]['@id']
                  ? {}
                  : { idName: resourceSchema[`section${sec}`].id }
              }
              method={
                resource[resourceSchema[`section${sec}`].id] &&
                resource[resourceSchema[`section${sec}`].id]['@id']
                  ? 'PUT'
                  : 'POST'
              }
              url={
                (resource[resourceSchema[`section${sec}`].id] &&
                  resource[resourceSchema[`section${sec}`].id]['@id']) ||
                `${schema.endpoint}?idname=${
                  resourceSchema[`section${sec}`].id
                }`
              }
              handleSuccess={handleSuccess(
                schema.resource[`section${sec}`].properties
              )}
              modifyProperties={(properties, resource, schema) => {
                if (properties.items && properties.items.properties.photo) {
                  let modifiedProperties = JSON.parse(
                    JSON.stringify(properties)
                  )
                  // if (profile.isOpenform) {
                  //   const desc =
                  //     modifiedProperties.items.properties.photo.description
                  //   modifiedProperties.items.properties.photo.description = {
                  //     text: `[OF] ${desc}`,
                  //     color: '#cc0000',
                  //   }
                  // } else {
                  //   delete modifiedProperties.items.properties.photo
                  // }
                  return modifiedProperties
                }
                return properties
              }}
              showSubmitAndStayButton={false}
              showCancelButton={false}
              fieldsFullWidth={false}
              buttonsFixed={false}
              width={700}
              key={`home-config-section-${sec}`}
              // onlyPassedProperties={true}
            />
          </div>
        )
      })}
    </Paper>
  )
}
