import {
  textWithImage,
} from './_blocks'

const schema = (photoEndpoint, thumbEndpoint, fileEndpoint) => ({
  types: [
    textWithImage(photoEndpoint, thumbEndpoint),
  ],
})

export default schema
