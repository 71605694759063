import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunkMiddleware from 'redux-thunk'
import reducers from '_reducers'
import sagas from '_sagas'

const sagaMiddleware = createSagaMiddleware()

export const configureStore = () => {
  const store = createStore(
    reducers,
    applyMiddleware(sagaMiddleware, thunkMiddleware)
  )

  sagas.map(saga => sagaMiddleware.run(saga))

  return store
}
