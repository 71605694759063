import React, { createContext, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider as EmotionThemeProvider } from '@material-ui/styles'
import { theme } from 'theme'

const ThemeConfigContext = createContext({})

export const useThemeConfig = () => useContext(ThemeConfigContext)

export const ThemeProvider = ({ children }) => {
  const [themeConfig, setThemeConfig] = useState({
    isDark: false,
    hasMounted: false,
  })

  useEffect(() => {
    setThemeConfig({
      isDark: localStorage.getItem('darkTheme') === 'true',
      hasMounted: true,
    })
  }, [])

  const handleToggle = () => {
    localStorage.setItem('darkTheme', JSON.stringify(!themeConfig.isDark))
    setThemeConfig(state => ({ ...state, isDark: !state.isDark }))
  }

  if (!themeConfig.hasMounted) {
    return <div />
  }

  const computedTheme = themeConfig.isDark ? theme('dark') : theme('light')

  return (
    <EmotionThemeProvider theme={computedTheme}>
      <ThemeConfigContext.Provider
        value={{
          dark: themeConfig.isDark,
          toggle: handleToggle,
        }}
      >
        {children}
      </ThemeConfigContext.Provider>
    </EmotionThemeProvider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
