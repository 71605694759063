import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Paper } from 'core/components/Paper'

import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { operations } from 'core/components/table/_columns/operations'

import { translate } from 'core/_helpers/translate'
import { publish } from 'core/components/table/_mass'
import schema from '_schema/product'
import schemaCategory from '_schema/productCategory'
import routes from './routes'

import { editable } from 'core/components/table/_columns/editable'
import { EditableCell } from './table/cells/EditableCell'
import { YesNoFilter } from 'core/components/table/filters/YesNoFilter'
import { PriceLtGtFilter } from 'core/components/table/filters/PriceLtGtFilter'
import { ResourceFilter } from 'core/components/table/filters/ResourceFilter'

export const Collection = ({ wrapWithPaper = true, showNewButton = true }) => {
  const profile = useSelector(state => state.profile)

  const columns = useMemo(
    () => [
      {
        header: translate('Title'),
        accessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        filterable: true,
        filterName: 'translations.title',
        sortable: true,
        sorterName: 'translations.title',
        width: '50%',
      },
      {
        header: translate('Cena brutto'),
        accessor: `floatPrice`,
        sortable: true,
        sorterName: 'price',
        width: '11%',
      },
      {
        ...editable('quantity'),
        header: 'Ilość',
        Cell: EditableCell,
        filterable: true,
        filterName: 'quantity',
        sortable: true,
        sorterName: 'quantity',
        width: '11%',
      },
      {
        header: translate('Waga (g)'),
        accessor: `weight`,
        width: '10%',
      },

      {
        ...operations(
          `translations.${process.env.REACT_APP_LOCALE}.title`,
          true,
          true,
          true,
          true,
          true
        ),
        width: '15%',
      },
    ],
    []
  )

  const defaultFilters = {
    'exists[versionable]': false,
  }

  const customFilters = [
    {
      name: 'price[gte]',
      title: 'Cena od',
      value: '',
      extraparams: { base: 100 },
      Filter: PriceLtGtFilter,
    },
    {
      name: 'price[lte]',
      title: 'Cena do',
      value: '',
      extraparams: { base: 100 },
      Filter: PriceLtGtFilter,
    },
    {
      name: 'quantity[gte]',
      title: 'Ilość od',
      value: '',
      extraparams: { base: 1 },
      Filter: PriceLtGtFilter,
    },
    {
      name: 'quantity[lte]',
      title: 'Ilość do',
      value: '',
      extraparams: { base: 1 },
      Filter: PriceLtGtFilter,
    },
    {
      name: 'showInShopBanner',
      title: 'baner Sklepu',
      value: null,
      Filter: YesNoFilter,
    },
    {
      name: 'stat',
      title: 'Aktywny',
      value: null,
      Filter: YesNoFilter,
    },
    {
      name: 'categories.uuid',
      title: 'Kategoria',
      value: null,
      extraparams: { endpoint : `${schemaCategory.endpointForm}?pagination=false`, accessor: `translations.${process.env.REACT_APP_LOCALE}.title` },
      Filter: ResourceFilter,
    },
  ]

  const WrapComponent = wrapWithPaper ? Paper : 'div'

  return (
    <WrapComponent>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_PRODUCTS_NEW_BUTTON')}
        buttonPath={routes().new.path}
        hideButton={!showNewButton}
      >
        {translate('Products')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultFilters={defaultFilters}
        defaultSorters={{ ord: 'desc' }}
        selectable={[publish.on(), publish.off()]}
        isRowSelectable={resource => profile.isOpenform || resource.isStatable}
        editPath={routes().edit.path}
        autoWidth={false}
        customFilters={customFilters}
        orderable={true}
        sortable={true}
        isRowLinkable={true}
      />
    </WrapComponent>
  )
}

Collection.propTypes = {
  wrapWithPaper: PropTypes.bool,
  showNewButton: PropTypes.bool,
}
