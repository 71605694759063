import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'

export const happenBlock = (type = null, anchor = true) => {
  let b = {
    name: 'happenBlock',
    label: 'Blok "Proponowane wydarzenia"',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            hint: 'domyślny tytuł "Proponowane wydarzenia", można zmienić',
          },
        },
      },
      automatic: {
        label: 'Automatycznie dobieraj wydarzenia',
      },
      elements: {
        type: 'collection',
        endpoint: type?.endpoint ?? '/api/block_elements',
        definition: type?.definition ?? 'BlockElement-blockElement.read',
        description: 'lub wybierz wydarzenia ręcznie',
        additionalProperties: {
          $ref:
            type?.ref ??
            '#/definitions/BlockElement-blockElement.write_blockElement.create',
        },
        titleAccessor: function(resource, handleSuccess) {
          if (resource['@titleAccessor']) return resource['@titleAccessor']
          const setResource = res => {
            resource['@titleAccessor'] =
              res.translations && res.translations[process.env.REACT_APP_LOCALE]
                ? res.translations[process.env.REACT_APP_LOCALE].title ?? '--'
                : '--'
            if (handleSuccess) {
              handleSuccess(resource)
            }
          }
          if (resource.happen) {
            fetchDataHandleAuthError(resource.happen, 'GET', null, setResource)
          } else {
            return '--' //translate('T_GENERAL_MISSING_TITLE').replace('<','‹ ').replace('>',' ›')
          }
        },
        properties: {
          happen: {
            type: 'resource',
            endpoint: `/api/happens_form?pagination=false&happenType=happen&exists[parent]=false&exists[versionable]=false`,
            titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
            label: 'Wydarzenia',
            storeCollectionId: 'happens_form_type_happen',
          },
          stat: {},
        },
      },
      anchor: {},
    },
  }

  if (!anchor) {
    delete b.properties.anchor
  }
  return b
}
