import { getSorters } from './getSorters'
import { addDefaultSorters } from './addDefaultSorters'

export const prepareSorters = (
  orderable,
  defaultSorters,
  preparedColumns,
  schemaParameters
) =>
  getSorters(
    addDefaultSorters(
      orderable
        ? { ...defaultSorters, ord: defaultSorters.ord || null }
        : defaultSorters,
      preparedColumns
    ),
    schemaParameters
  )
