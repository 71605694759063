export const modifyBlockByPage = (blockTypes, resource) => {

  switch (resource.idName) {
    // Modify by page here
    case 'page_contact':
      let modifiedPropertiesContact = blockTypes.filter(elem => elem.name === 'text' || elem.name === 'textCrew')
      return modifiedPropertiesContact
    case 'page_crew':
      return []
    default:
      return blockTypes
  }
}
