import { useReducer, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { collectionActions } from 'core/_actions/collection.actions'
import { notification } from 'core/_helpers/notification'
import { constants } from 'pages/Crews/table/_state'
import buildUrl from 'build-url'

const initState = ({
  initialConfig,
  endpoint,
  filters,
  sorters,
  storeCollectionId,
}) => {
  const defaultConfig = {
    page: 1,
    perPage: parseInt(process.env.REACT_APP_COLLECTION_PER_PAGE),
    paginable: false,
    sorters,
    filters,
  }

  return {
    collection: endpoint,
    storeCollectionId: storeCollectionId || endpoint,
    data: {
      items: [],
      rawItems: [],
      selected: [],
      selectAll: false,
      totalItems: 0,
      toggled: {},
      toggledAll: false,
      ord: sorters.ord.order,
    },
    config: initialConfig || defaultConfig,
    defaultConfig: defaultConfig,
    init: true,
    isFetching: false,
  }
}

export const useCollectionFetch = (
  reducer,
  endpoint,
  filters = [],
  sorters = [],
  storeCollectionId = null
) => {
  const initialConfig = useSelector(
    state => state.collection[storeCollectionId || endpoint]
  )
  const [state, stateDispatch] = useReducer(
    reducer,
    { initialConfig, endpoint, filters, sorters, storeCollectionId },
    initState
  )

  const reduxDispatch = useDispatch()

  const reload = useSelector(state => state.common.reload)

  useEffect(() => {
    stateDispatch({ type: constants.FETCH_START })

    const body = {
      ...getParametersIn(state.config.sorters, 'body', 'order'),
      ...getParametersIn(state.config.filters, 'body', 'value'),
    }

    const query = {
      ...getParametersIn(state.config.sorters, 'query', 'order'),
      ...getParametersIn(state.config.filters, 'query', 'value'),
    }

    const url = buildUrl(state.collection, {
      queryParams: query,
    })

    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      url,
      'GET',
      { ...body, signal },
      response => {
        const data = {
          items: response['hydra:member'],
          ord: state.config.sorters?.ord?.order || null,
        }

        stateDispatch({ type: constants.FETCH_SUCCESS, payload: { data } })
        reduxDispatch(
          collectionActions.setConfig(state.storeCollectionId, state.config)
        )
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        stateDispatch({ type: constants.FETCH_FAILURE })
        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [
    reduxDispatch,
    state.storeCollectionId,
    state.config,
    reload,
    state.collection,
  ])

  return [state, stateDispatch]
}

const getParametersIn = (parameters, type, prop) => ({
  ...Object.assign(
    {},
    ...Object.keys(parameters)
      .filter(
        key => parameters[key][prop] !== null && parameters[key].in === type
      )
      .map(key => ({
        [parameters[key].name]: parameters[key][prop],
      }))
  ),
})
