import React from 'react'
import PropTypes from 'prop-types'
import { DialogTitle, Typography, IconButton } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: 16,
    paddingRight: 75,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  },
}))

export const DialogTitleWithClose = ({ children, onClose, ...rest }) => {
  const classes = useStyles()

  return (
    <DialogTitle disableTypography className={classes.root} {...rest}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseOutlined />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

DialogTitleWithClose.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
}
