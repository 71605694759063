import { store } from 'react-notifications-component'
import { translate } from './translate'

export const notification = (type, message, title = null) => {
  store.addNotification({
    type: type === 'error' ? 'danger' : type,
    title: translate(
      title ? title.toString() : type.charAt(0).toUpperCase(0) + type.slice(1)
    ),
    message: translate(message),
    insert: 'bottom',
    container: 'bottom-center',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
      pauseOnHover: true,
    },
  })
}
