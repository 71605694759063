import React, { useMemo } from 'react'
import { Paper } from 'core/components/Paper'
import { Title } from 'core/components/Title'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { standard } from 'core/components/table/_columns/standard'
import { DateTimeCell } from 'core/components/table/cells/DateTimeCell'
import { ResultCell } from './table/cells/ResultCell'
import { translate } from 'core/_helpers/translate'
import schema from 'core/_schema/loginEntry'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        ...standard('username'),
        width: '25%',
      },
      {
        ...standard('createdAt'),
        Cell: DateTimeCell,
        width: '25%',
        header: translate('T_LOGIN_DATE'),
      },
      {
        ...standard('ip'),
        width: '20%',
      },
      {
        ...standard('result'),
        width: '23%',
        Cell: ResultCell,
      },
    ],
    []
  )

  return (
    <Paper>
      <Title>{translate(routes().index.title)}</Title>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        autoWidth={false}
        defaultSorters={{ createdAt: 'desc' }}
      />
    </Paper>
  )
}
