const schema = {
  endpoint: '/api/shipping_area_costs',
  resource: {
    definition: 'ShippingAreaCost-shippingAreaCost.read',
    properties: {

      shipping:{
        type: 'resource',
        endpoint: `/api/shipping_types?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,//'uuid',
        label: 'Shipping type',
        validate: ['required'],
      },

      weightFrom: {
        type: 'integer',
        minValue: 0,
        endAdornment: 'g',
        defaultValue: 0,
      },

      weightTo: {
        type: 'integer',
        minValue: 0,
        endAdornment: 'g',
        defaultValue: 0,
      },

      floatPrice: {
        type: 'number',
        minValue: 0,
        defaultValue: 0.0,
      },

      stat: {
        // description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
