import React from 'react'
import PropTypes from 'prop-types'
import { ImageType } from 'core/components/form/fields/ImageType'

export const MediaImageType = ({ additional, label, ytProperty, ...rest }) => (
  <ImageType
    label={additional.media === 'youtube' ? 'T_GENERAL_VIDEO_MASK' : label}
    ytCode={additional[ytProperty]}
    {...rest}
  />
)

MediaImageType.propTypes = {
  additional: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  ytProperty: PropTypes.string.isRequired,
}
