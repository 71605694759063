const schema = {
  endpoint: '/api/shipping_countries',
  resource: {
    definition: 'ShippingCountry-shippingCountry.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Nazwa kraju',
          },
        },
      },
      area: {
        type: 'resource',
        endpoint: `/api/shipping_areas?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,//'uuid',
        label: 'Shipping area',
      },
      isInUE: {
        label: 'Kraj w Uni Europejskiej',
      },
      isPoland: {
        label: 'Polska',
      },
      countryCode: {
        label: 'Kod kraju (ISO, dwie litery)',
        validate: ['required'],
      },
      stat: {
        // description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
