import React from 'react'
import { ResourceMediaEdit } from 'core/pages/ResourceMediaEdit'
import schema from 'core/_schema/packageGalleryItem'
import routes from './routes'
import parentRoutes from 'core/pages/PackageGalleries/routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  return (
    <ResourceMediaEdit
      uuid={match.params.id}
      definitions={definitions}
      schema={schema}
      editPath={routes().edit.path}
      parentEditPath={parentRoutes().edit.path}
      parentShowPath={parentRoutes().show.path}
      formWidth={750}
      key={match.params.id}
    />
  )
}
