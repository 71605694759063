
export const textElements = () => ({
  name: 'textElements',
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_ELEMENTS',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
      },
    },
    elements: {
      type: 'collection',
      endpoint: '/api/exhibition_block_elements',
      definition: 'ExhibitionBlockElement-exhibitionBlockElement.read',
      additionalProperties: {
        '$ref':'#/definitions/ExhibitionBlockElement-exhibitionBlockElement.write_exhibitionBlockElement.create',
      },
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
          },
        },
        page: {
          type: 'resource',
          endpoint: `/api/pages_form?pagination=false&pageType[]=page&pageType[]=project&exists[versionable]=false`,
          titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,//'uuid',
          label: 'T_BLOCK_ELEMENT_LABEL_PAGE',
          storeCollectionId: 'pages_form_type_page_project',
        },
        stat: {}
      },
    },
  },
})
