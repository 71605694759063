import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
// import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'
import { Remove, Add, KeyboardTab } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { prop } from 'core/_helpers/prop'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { translate } from 'core/_helpers/translate'
import { constants } from 'pages/ProductCategories/table/_state'
// import { commonConstants } from 'core/_constants'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    marginRight: 6,
    border: '1px solid',
    borderRadius: '50%',
    color: '#979797',
    marginLeft: -2,
    position: 'relative',
    fontSize: 24,
    backgroundColor: theme.elements.content,
    zIndex: 1,
  },
  icon: {
    cursor: 'pointer',
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    position: 'relative',
    paddingLeft: 6,
    '& span': {
      color: theme.palette.primary.main,
    },
    '&::before': {
      content: '""',
      height: 55,
      borderLeft: '1px solid',
      borderBottom: '1px solid',
      color: '#979797',
      borderBottomColor: 'inherit',
      borderLeftColor: 'inherit',
      position: 'absolute',
      top: -17,
      left: -19,
      minWidth: 30,
    },
    '&::after': {
      content: '""',
      height: 1,
      borderTop: '1px solid',
      borderTopColor: 'inherit',
      position: 'absolute',
      top: 10,
      left: -18,
      width: 12,
    },
  },
  subpage: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 2,
    cursor: 'pointer',
  },
}))

export const TitleCell = ({
  resource,
  previousResource,
  accessor,
  editPath,
  tableState,
  tableStateDispatch,
}) => {
  const handleToggle = useCallback(() => {
    tableStateDispatch({
      type: constants.TOGGLE,
      payload: resource['@id'],
    })
  }, [tableStateDispatch, resource])

  // const storeDispatch = useDispatch()

  const onSubpageClick = () => {
    tableStateDispatch({ type: constants.MANUAL_ORDER_CHANGE_START })

    fetchDataHandleAuthError(
      resource['@id'],
      'PATCH',
      {
        body: JSON.stringify({
          newParent: previousResource['@id'],
        }),
      },
      () => {
        tableStateDispatch({ type: constants.MANUAL_ORDER_CHANGE_SUCCESS })
        notification('success', 'T_FORM_RECORD_UPDATED', 'T_FORM_SUCCESS')

        // storeDispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })
      },
      error => {
        tableStateDispatch({ type: constants.MANUAL_ORDER_CHANGE_FAILURE })
        notification(
          'error',
          error.response.violations.length
            ? error.response.violations[0].message
            : error.response.detail,
          error.response.title
        )
      }
    )
  }

  const classes = useStyles()

  return (
    <div
      className={classes.root}
      style={{ marginLeft: indent(resource, tableState.data.items) }}
    >
      {resource.children?.length ? (
        <span className={classes.iconContainer} onClick={handleToggle}>
          {!tableState.data.toggled[resource['@id']] ? (
            <Remove className={classes.icon} />
          ) : (
            <Add className={classes.icon} />
          )}
        </span>
      ) : null}
      <Link
        to={editPath.replace(
          ':id',
          resource[process.env.REACT_APP_RESOURCE_ID]
        )}
        className={classes.link}
        style={{
          borderLeftColor: resource.children?.length
            ? '#979797'
            : !resource.parent
            ? 'transparent'
            : '#979797',
          borderBottomColor: resource.children?.length
            ? !tableState.data.toggled[resource['@id']]
              ? '#979797'
              : 'transparent'
            : 'transparent',
          borderTopColor: resource.children?.length
            ? '#979797'
            : !resource.parent
            ? 'transparent'
            : '#979797',
        }}
      >
        <span>
          {!resource.parent ? (
            <strong>{prop(resource, accessor)}</strong>
          ) : (
            prop(resource, accessor)
          )}
        </span>
      </Link>
      {process.env.REACT_APP_PRODUCTCATEGORY_MAX_NESTING_LEVEL > 1 && previousResource && previousResource.parent === resource.parent && (
        <div className={classes.subpage} onClick={onSubpageClick}>
          <Tooltip
            title={translate('T_MODULE_PAGES_SUBPAGE_UNDER_PREVIOUS_PAGE')}
          >
            <KeyboardTab />
          </Tooltip>
        </div>
      )}
    </div>
  )
}

const indent = (resource, items, isRoot = true) =>
  (isRoot ? (resource.children?.length ? 0 : 30) : 30) +
  (resource.parent
    ? indent(
        items.find(item => item['@id'] === resource.parent),
        items,
        false
      )
    : 0)

const pageShape = {
  [process.env.REACT_APP_RESOURCE_ID]: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  '@id': PropTypes.string.isRequired,
}

TitleCell.propTypes = {
  resource: PropTypes.shape({
    ...pageShape,
    parent: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape(pageShape)),
  }).isRequired,
  previousResource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    parent: PropTypes.string,
  }),
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  editPath: PropTypes.string.isRequired,
  tableState: PropTypes.object.isRequired,
  tableStateDispatch: PropTypes.func.isRequired,
}
