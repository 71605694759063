import _media from './_media'
import photoSchema from './photo'

const schema = {
  endpoint: '/api/package_logotypes',
  resource: {
    definition: 'PackageLogotype-packageLogotype.read',
    properties: {
      title: {
        validate: ['required'],
      },
    },
  },
  subresources: {
    item: {
      endpoint: '/api/package_logotype_items',
      definition: 'PackageLogotypeItem-packageLogotypeItem.read',
      types: _media(photoSchema.endpoint.single, '/package_logotype_item_thumbs')
        .types,
    },
  },
}

export default schema
