import React, { useCallback } from 'react'
// import { useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { Form, NotFound } from 'core/pages'
import { Paper } from 'core/components/Paper'
import { Title } from 'core/components/Title'
import { Loader } from 'core/components/Loader'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
// import { addOpenformProperties } from './_helpers/addOpenformProperties'
// import { collectionActions } from 'core/_actions'
// import queryString from 'query-string'
// import { commonConstants } from 'core/_constants'
import { translate } from 'core/_helpers/translate'
import schema from '_schema/mainMenu'
import routes from './routes'

export const New = ({ schema: definitions, ...rest }) => {
  // const profile = useSelector(state => state.profile)

  // const { pid } = queryString.parse(rest.location.search)
  const pid = rest.match.params.pid
  const defaultData = pid
    ? { parent: `${schema.endpoint}/${pid}` }
    : {  }

  const [state, setState] = useResourceState()

  const { resource: parent, isFetching, fetchError } = state

  useResourceFetch(
    pid && `${schema.endpoint}/${pid}`,
    parent,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    true
  )

  const isNotEditable = false
  // useMemo(
  //   () =>
  //     !parent
  //       ? false
  //       : parent.versionable ||
  //         (!profile.isOpenform &&
  //           (!parent.isSubPageable ||
  //             parent.nestingLevel >=
  //               3 )),
  //   [parent, profile.isOpenform]
  // )

  // const dispatch = useDispatch()

  // useEffect(() => {
  //   if (isNotEditable) {
  //     dispatch({ type: commonConstants.RESET_CURRENT_RESOURCES })
  //   }
  // }, [isNotEditable, dispatch])

  const history = useHistory()

  const handleSuccess = useCallback(() => {
    history.push(
      pid ? routes().indexParent.path.replace(':id', pid) : routes().index.path
    )
  }, [history, pid])

  const handleSuccessAndStay = useCallback(
    resource => {
      history.push(
        pid
          ? routes().indexParent.path.replace(':id', pid)
          : routes().index.path
      )
      history.push(
        routes().edit.path.replace(
          ':id',
          resource[process.env.REACT_APP_RESOURCE_ID]
        )
      )
    },
    [history, pid]
  )
  const handleCancel = () => {
    history.goBack()
  }

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : pid && fetchError ? (
    <NotFound />
  ) : isNotEditable ? (
    <Redirect to={routes().index.path} />
  ) : (
    <Form
      defaultData={defaultData}
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      // modifyProperties={properties =>
      //   addOpenformProperties(profile.isOpenform, properties)
      // }
      method="POST"
      url={schema.endpoint}
      storeCollectionId={schema.endpoint}
      collectionPath={routes().index.path}
      editPath={routes().edit.path}
      handleSuccess={handleSuccess}
      handleSuccessAndStay={handleSuccessAndStay}
      handleCancel={handleCancel}
      header={
        <Title>
          {translate(pid ? 'T_MODULE_PAGES_ADD_SUBPAGE' : 'T_GENERAL_NEW')}
        </Title>
      }
      fieldsFullWidth={false}
      width={800}
    />
  )
}
