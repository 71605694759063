//uwaga, te bloki są wspólne z innymi encjami
import { packageFile } from 'core/_schema/_blocks'

// te bloki są tylko w pasaz
import {
  anchorBlock,
  textWithImage,
  packageLogotype,
  textWithVideo,
  text,
} from 'pages/Pasazs/_blocks'

const schema = (photoEndpoint, thumbEndpoint, fileEndpoint) => ({
  types: [
    anchorBlock(),
    text(),
    textWithVideo(photoEndpoint, thumbEndpoint, false),
    textWithImage(photoEndpoint, null),
    packageFile(),
    packageLogotype(),
  ],
})

export default schema
