export const schema = {
  endpoint: '/api/tag_pasaz_entries',
  extraStoreCollectionId: 'tag_pasaz_entry_form',
  resource: {
    definition: 'TagPasazEntry-tagStruct.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
        },
      },

      // parent: {
      //   type: 'multipleResource',
      //   endpoint: `/api/tag_structs_form?pagination=false`,//&exists[parent]=false`,
      //   titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,//'uuid',
      //   storeCollectionId: 'tag_pasaz_entry_form',
      //   label: 'Przypisany do tagów'
      // },

      stat: { description: 'T_GENERAL_PUBLICATION' },
    },
  },
}

export default schema
