const schema = {
  endpoint: '/api/vats',
  resource: {
    definition: 'Vat-vat.read',
    properties: {

      floatRate: {
        type: 'number',
        endAdornment: '%',
        hint: 'np 2,5,20.7 itp.',
        validate: ['required'],
      },

      type: {
        type: 'choice',
        validate: ['required'],
        choices: {
          1 : 'dla książki',
          2 : 'dla mediów elektronicznych (e-book)',
          3 : 'dla czasopism'
        },
      },

      whoType: {
        type: 'choice',
        validate: ['required'],
        choices: {
          1 : 'osoba fizyczna',
          2 : 'dla firm',
        },
      },

      fromArea: {
        type: 'choice',
        validate: ['required'],
        choices: {
          'pl' : 'z Polski',
          'ue' : 'z Uni Europejskiej',
          'na' : 'z poza Uni Europejskiej'
        },
      },

    },
  },
}

export default schema
