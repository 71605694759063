import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '@material-ui/core'
import { constants } from './_state'

export const MassSelectCheckbox = ({
  tableState,
  tableStateDispatch,
  isRowSelectable = true,
}) => {
  const handleMassSelectCheckbox = useCallback(() => {
    tableStateDispatch({
      type: constants.SET_MASS_SELECT,
      payload: { isRowSelectable },
    })
  }, [isRowSelectable, tableStateDispatch])

  return (
    <Checkbox
      checked={!!tableState.data.selectAll}
      onChange={handleMassSelectCheckbox}
      disabled={tableState.isInit || tableState.isFetching}
      name="mass"
      color="secondary"
    />
  )
}

MassSelectCheckbox.propTypes = {
  tableState: PropTypes.shape({
    data: PropTypes.shape({
      selectAll: PropTypes.bool,
    }).isRequired,
    isInit: PropTypes.bool,
    isFetching: PropTypes.bool,
  }).isRequired,
  tableStateDispatch: PropTypes.func.isRequired,
  isRowSelectable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
}
