import { constants } from './constants'
import moment from 'moment'

export const reducer = (state, action) => {
  switch (action.type) {
    case constants.FETCH_START:
      return {
        ...state,
        isFetching: true,
      }
    case constants.FETCH_SUCCESS:
      return {
        ...state,
        items: action.payload.items.concat(state.items),
        moreItems: action.payload.moreItems,
        init: false,
        isFetching: false,
      }
    case constants.FETCH_FAILURE:
      return {
        ...state,
        init: false,
        isFetching: false,
      }
    case constants.RESET_CURRENT_POINTER:
      return {
        ...state,
        items: [],
        init: true,
        config: {
          ...state.config,
          currentPointer: moment().add(5, 'minutes'),
        },
      }
    case constants.MOVE_CURRENT_POINTER:
      return {
        ...state,
        config: {
          ...state.config,
          currentPointer: moment(state.items[0].createdAt),
        },
      }
    case constants.ADD_ITEM:
      return {
        ...state,
        items: state.items.concat([action.payload.value]),
        isFetching: false,
      }
    case constants.UPDATE_ITEM:
      return {
        ...state,
        items: state.items.map(item =>
          item['@id'] === action.payload.value['@id']
            ? action.payload.value
            : item
        ),
      }
    default:
      return {
        ...state,
        ...action.payload,
      }
  }
}
