export const addDefaultSorters = (sorters, columns) => {
  const defaultSorters = Object.assign({}, sorters)
  const modifiedColumns = columns.map(column => {
    if (column.accessor && defaultSorters[column.accessor]) {
      const order = defaultSorters[column.accessor]
      delete defaultSorters[column.accessor]
      return {
        ...column,
        sortable: true,
        order,
      }
    }

    return column
  })

  return [
    ...modifiedColumns,
    ...Object.keys(defaultSorters).map(key => ({
      accessor: key,
      sortable: true,
      order: defaultSorters[key],
    })),
  ]
}
