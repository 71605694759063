
const schema = {
  endpoint: '/api/formsets',
  resource: {
    definition: 'Formset-formset.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Tytuł formularza',
          },
        },
      },

      email: {},
      publishingDateFrom: {
        type: 'datetime'
      },
      publishingDateTo: {
        type: 'datetime'
      },


      stat: {},
    },
  },
}

export default schema
