export const recalculateCrop = (original, crop, direction = 'frame') => {
  if (original.clientWidth === original.naturalWidth) {
    return crop
  }

  const factor =
    direction === 'frame'
      ? original.clientWidth / original.naturalWidth
      : original.naturalWidth / original.clientWidth

  return {
    ...crop,
    x: Math.round(factor * (crop.x > 0 ? crop.x : 0)),
    y: Math.round(factor * (crop.y > 0 ? crop.y : 0)),
    width: Math.round(factor * crop.width),
    height: Math.round(factor * crop.height),
  }
}
