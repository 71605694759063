import { schema as schemafiles } from '_schema/sobiescianaFile'

export const schema = {
  endpoint: '/api/sobiescianas',
  resource: {
    definition: 'Sobiesciana-sobiesciana.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          description: {
            type: 'tinymce',
          },
          size: {},
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {
            type: 'slug',
            routes: {
              pl: '/sobiesciana/:slug',
              en: '/en/sobiesciana/:slug',
            },
          },
          preview: {
            label: 'Podgląd',
            description:'',
            type: 'preview',
            routes: {
              pl: '/sobiesciana/:slug',
              en: '/en/sobiesciana/:slug',
            },
          },
        },
      },


      category: {
        type: 'resourceCategory',
        endpoint: `/api/sobiesciana_categories_form?pagination=false&level=2`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,//'uuid',
        label: 'Kategoria',
        name2: 'subcategory',
        label2: 'Pod kategoria',
        titleAccessor2: `translations.${process.env.REACT_APP_LOCALE}.title`,//'uuid',
        endpoint2: `/api/sobiesciana_categories_form?pagination=false`,
        validate: ['required'],
      },

      inventoryNumber: {},

      publishDate:{
        type: 'string',
        disabled:true,
      },
      publishDateTo:{
        type: 'string',
        disabled:true,
      },

      files: {
        type: 'collection',
        endpoint: '/api/sobiesciana_files',
        definition: 'SobiescianaFile-sobiescianaFile.read',
        description: 'Zdjęcia',
        additionalProperties: {
          $ref:
            '#/definitions/SobiescianaFile-sobiescianaFile.write_sobiescianaFile.create',
        },
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
        properties: schemafiles.resource.properties,
      },
    },
  },
  subresources: {},
}

export default schema
