import React, { useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { FormControl, InputLabel, Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { translate } from 'core/_helpers/translate'
import resources from '_schema/_resources'

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: 5,
    marginBottom: 10,
  },
  control: {
    display: 'block',
  },
  label: {
    position: 'relative',
    transform: 'none',
    display: 'inline-flex',
    color: theme.palette.text.secondary,
  },
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}))

export const ResourceAccessType = ({
  name,
  label,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  disabled = false,
  setValue,
  classes = {},
}) => {
  const handleChange = e => {
    const resourceName = e.target.name
    const isChecked = e.target.checked

    setValue(
      name,
      isChecked
        ? (value || []).concat([resourceName])
        : (value || []).filter(name => name !== resourceName)
    )
  }

  const resourceAccess = resources()
  const defaultClasses = useStyles()

  useEffect(() => {
    if (!initialValue) {
      setValue(name, [])
    }
  }, [setValue, name, initialValue])

  return (
    <>
      <div
        className={clsx(
          defaultClasses.label,
          defaultClasses.title,
          classes.title
        )}
      >
        {translate(label.text || label)}
      </div>
      {Object.keys(resourceAccess).map(key => (
        <FormControl
          disabled={disabled}
          key={`resource-access-${key}`}
          className={defaultClasses.control}
        >
          <Checkbox
            name={key}
            id={`resource-access-${key}`}
            onChange={handleChange}
            checked={value ? value.includes(key) : false}
          />
          <InputLabel
            htmlFor={`resource-access-${key}`}
            className={clsx(defaultClasses.label, classes.label)}
          >
            {translate(resourceAccess[key])}
          </InputLabel>
        </FormControl>
      ))}
      {compare && (
        <div
          className={clsx(
            defaultClasses.compare,
            value.toString() !== compareValue.toString() &&
              defaultClasses.compareNeq
          )}
        >
          {compareValue &&
            Object.keys(resourceAccess)
              .filter(key => compareValue.includes(key))
              .map(key => translate(resourceAccess[key]))
              .join(', ')}
        </div>
      )}
    </>
  )
}

ResourceAccessType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  initialValue: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.arrayOf(PropTypes.string),
  compareValue: PropTypes.arrayOf(PropTypes.string),
  compare: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    title: PropTypes.string,
    label: PropTypes.string,
  }),
}
