import React, { useCallback, useState } from 'react'
import clsx from 'clsx'
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core'
import { ExpandMoreOutlined, ExpandLessOutlined } from '@material-ui/icons'
import {
  ManuallyOrderableTableBody,
  ManuallyOrderableTableRow,
  ManualOrderDragHandle,
} from 'core/components/table'
import { Form } from 'core/components/form'
// import { ContextualHint } from 'core/components/ContextualHint'
import { BodySkeleton, TableLoader } from 'core/components/table'
import { Status } from 'core/components/resource/Status'
import { Delete } from 'core/components/resource/Delete'
// import { Add } from './Add'
import { useEmbeddedCollectionFetch } from './_helpers/useEmbeddedCollectionFetch'
import { performOrderChange } from './_helpers/performOrderChange'
import { applyProperties } from 'core/_helpers/applyProperties'
// import { prop } from 'core/_helpers/prop'
import { useEmbeddedCollectionStyles, embeddedCollectionPropTypes } from '.'
import { translate } from 'core/_helpers/translate'
import { constants, reducer } from './_state'
import { boolean } from 'core/_helpers/boolean'
import { useSelector } from 'react-redux'

export const EmbeddedPageSection = ({
  endpoint,
  pid,
  parentIri,
  properties: customProperties,
  definitionSchema,
  headerTitle = null,
  expandTitle = null,
  panelTitle = null,
  titleAccessor = null,
  statable = false,
  statAccessor = 'stat',
  contextualHint = null,
  disabled = false,
  classes = {},
}) => {
  const [state, dispatch] = useEmbeddedCollectionFetch(reducer, endpoint, pid)

  const defaultClasses = useEmbeddedCollectionStyles()

  const profile = useSelector(state => state.profile)

  const handleDelete = resource => {
    dispatch({
      type: constants.REMOVE_ITEM,
      payload: {
        value: resource,
      },
    })
  }

  const handleOrderChange = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex) {
        return
      }

      performOrderChange(state.items, oldIndex, newIndex, dispatch)
    },
    [state.items, dispatch]
  )

  const [expanded, setExpanded] = useState({
    switch: false,
    items: {},
  })

  const handleExpand = () => {
    if (state.isFetching) {
      return
    }

    setExpanded(state => ({
      switch: !state.switch,
      items: Object.assign(
        {},
        ...Object.keys(state).map(key => ({ [key]: !state.switch }))
      ),
    }))
  }

  const handleSingleExpand = iri => () => {
    setExpanded(state => ({
      ...state,
      items: {
        ...state.items,
        [iri]:
          state.items[iri] !== undefined ? !state.items[iri] : !state.switch,
      },
    }))
  }

  // doklejamy idname jesli admin
  const properties = applyProperties(
    profile.isOpenform
      ? {
          ...customProperties,
          idName: {
            type: 'string',
            description: {
              text: 'T_GENERAL_OPENFORM_PAGE_IDNAME',
              color: '#cc0000',
            },
          },
        }
      : customProperties,
    definitionSchema.properties,
    'missing'
  )

  const propertiesByIdname = resource => {
    // let modifiedProperties = Object.assign(
    //   {},
    //   {
    //     ...properties,
    //     translations: {
    //       ...properties.translations,
    //       properties: Object.assign({}, properties.translations.properties),
    //     },
    //   }
    // )

    let modifiedProperties = JSON.parse(JSON.stringify(properties))

    if (profile.isOpenform) {
      const desc = modifiedProperties.items.properties.photo.description
      modifiedProperties.items.properties.photo.description = {
        text: `[OF] ${desc}`,
        color: '#cc0000',
      }
    } else {
      delete modifiedProperties.items.properties.photo
    }

    switch (resource.idName) {
      case 'discovery_architecture':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.title
        delete modifiedProperties.translations.properties.subtitle
        return modifiedProperties

      case 'visit_family_kinder1':
      case 'visit_family_kinder2':
      case 'visit_family_kinder3':
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'visit_group_contact':
      case 'visit_solo_contact':
      case 'visit_family_contact':
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'visit_planresidence':
        delete modifiedProperties.translations.properties.btnTitle
      // eslint-disable-next-line
      case 'visit_accessibility':
      case 'visit_ukraine':
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.items
        return modifiedProperties

      case 'edu_family':
      case 'edu_guest':
      case 'edu_groups':
        delete modifiedProperties.translations.properties.subtitle
        return modifiedProperties

      case 'visit_cafe':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.subtitle
        return modifiedProperties

      case 'become_relaxphoto':
        delete modifiedProperties.translations
        return modifiedProperties

      case 'shop_tickets':
        delete modifiedProperties.translations.properties.btnTitle
      // eslint-disable-next-line
      case 'shop_products':
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.items
        return modifiedProperties

      case 'shopticket_solo':
      case 'shopticket_group':
      case 'edu_mergeoffers':
        delete modifiedProperties.items
        return modifiedProperties

      case 'shop_bookstore':
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'shop_ticket_question':
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'prk_introduction_image':
        delete modifiedProperties.translations
        return modifiedProperties

      case 'prk_museum_culture_nature':
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.text
        return modifiedProperties

      case 'prk_rich_history':
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'prk_palace_today':
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.translations.properties.text
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'prk_timeline_header':
        delete modifiedProperties.items
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'prk_timeline_block1_1':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.text
        return modifiedProperties

      case 'prk_timeline_block1_2':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.text
        delete modifiedProperties.items
        return modifiedProperties

      case 'prk_timeline_block1_3':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.subtitle
        return modifiedProperties

      case 'prk_timeline_block1_4':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.items
        return modifiedProperties

      case 'prk_timeline_block1_5':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.items
        return modifiedProperties

      case 'prk_timeline_block2_1':
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'prk_timeline_block2_2':
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.translations.properties.title
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.items
        return modifiedProperties

      case 'prk_timeline_block2_3':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.translations.properties.text
        return modifiedProperties

      case 'prk_timeline_block2_4':
        delete modifiedProperties.translations.properties.title
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'prk_timeline_block2_5':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.items
        return modifiedProperties

      case 'prk_timeline_block2_6':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.translations.properties.text
        return modifiedProperties

      case 'prk_timeline_block2_7':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.subtitle
        return modifiedProperties

      case 'prk_timeline_block2_8':
        delete modifiedProperties.translations.properties.title
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'prk_timeline_block2_9':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.translations.properties.text
        return modifiedProperties

      case 'prk_timeline_block2_10':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.translations.properties.text
        return modifiedProperties

      case 'prk_timeline_block3_1':
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'prk_timeline_block3_2':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.text
        return modifiedProperties

      case 'prk_timeline_block3_3':
        delete modifiedProperties.translations.properties.title
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.items
        return modifiedProperties

      case 'prk_timeline_block3_4':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.text
        delete modifiedProperties.translations.properties.subtitle
        return modifiedProperties

      case 'prk_timeline_block3_5':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.text
        delete modifiedProperties.translations.properties.subtitle
        return modifiedProperties

      case 'prk_timeline_block3_6':
        delete modifiedProperties.translations.properties.title
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'prk_timeline_block4_1':
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'prk_timeline_block4_2':
        delete modifiedProperties.translations.properties.title
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.items
        return modifiedProperties

      case 'prk_timeline_block4_3':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.text
        delete modifiedProperties.items
        return modifiedProperties

      case 'prk_timeline_block4_4':
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'prk_timeline_block4_5':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.translations.properties.text
        return modifiedProperties

      case 'prk_timeline_block5_1':
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'prk_timeline_block5_2':
        delete modifiedProperties.translations.properties.title
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.items
        return modifiedProperties

      case 'prk_timeline_block5_3':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.text
        delete modifiedProperties.items
        return modifiedProperties

      case 'prk_timeline_block5_4':
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'prk_timeline_block5_5':
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'prk_timeline_block5_6':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.translations.properties.text
        return modifiedProperties

      case 'prk_timeline_block6_1':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.translations.properties.text
        return modifiedProperties

      case 'prk_timeline_block6_2':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.items
        return modifiedProperties

      case 'prk_timeline_block6_3':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.text
        delete modifiedProperties.items
        return modifiedProperties

      case 'prk_timeline_block6_4':
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'prk_timeline_block6_5':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.text
        delete modifiedProperties.items
        return modifiedProperties

      case 'prk_timeline_block6_6':
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'prk_timeline_block6_7':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.text
        delete modifiedProperties.items
        return modifiedProperties

      case 'prk_timeline_block6_8':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.translations.properties.title
        delete modifiedProperties.translations.properties.text
        delete modifiedProperties.items
        return modifiedProperties

      case 'prk_timeline_block6_9':
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'prk_timeline_block6_10':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.text
        delete modifiedProperties.translations.properties.subtitle
        return modifiedProperties

      case 'prk_timeline_block6_11':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.text
        delete modifiedProperties.translations.properties.subtitle
        return modifiedProperties

      case 'prk_timeline_block7_1':
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'prk_timeline_block7_2':
        delete modifiedProperties.translations.properties.title
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.items
        return modifiedProperties

      case 'prk_timeline_block7_3':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.translations.properties.text
        return modifiedProperties

      case 'prk_timeline_block7_4':
        delete modifiedProperties.translations.properties.title
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'prk_timeline_block7_5':
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'prk_timeline_block8':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.items
        return modifiedProperties

      case 'prk_branch_national_museum':
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'prk_branch_national_museum_2':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.items
        return modifiedProperties

      case 'prk_branch_national_museum_3':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.subtitle
        return modifiedProperties

      case 'prk_branch_national_museum_4':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.subtitle
        return modifiedProperties

      case 'prk_museum_palace_king_jan':
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'prk_museum_palace_king_jan_2':
        delete modifiedProperties.translations.properties.btnTitle
        return modifiedProperties

      case 'prk_museum_palace_king_jan_3':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.subtitle
        return modifiedProperties

      case 'prk_museum_palace_king_jan_4':
        delete modifiedProperties.translations.properties.btnTitle
        delete modifiedProperties.translations.properties.subtitle
        delete modifiedProperties.translations.properties.text
        delete modifiedProperties.items
        return modifiedProperties

      default:
        //==all
        delete modifiedProperties.translations.properties.subtitle
        return modifiedProperties
    }
  }

  return (
    <>
      <div className={clsx(defaultClasses.header, classes.header)}>
        <div>{headerTitle}</div>
        <div
          className={clsx(defaultClasses.massExpand, classes.massExpand)}
          onClick={handleExpand}
        >
          {translate(`T_GENERAL_EXPAND_${expanded.switch ? 'ON' : 'OFF'}`)}
          {expandTitle ? ` ${expandTitle}:` : ':'}{' '}
          {expanded.switch ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
        </div>
      </div>
      <TableContainer
        className={clsx(
          defaultClasses.collectionContainer,
          classes.collectionContainer
        )}
      >
        <TableLoader show={!state.init && state.isFetching} align="center" />
        <Table size="small">
          <ManuallyOrderableTableBody
            onSortEnd={handleOrderChange}
            helperClass={clsx(defaultClasses.draggedRow, classes.draggedRow)}
            useDragHandle={profile.isOpenform ? true: false}
          >
            {state.init ? (
              <BodySkeleton rows={1} columns={1} />
            ) : state.items.length > 0 ? (
              state.items.map((item, i) => {
                const handleSuccess = resource => {
                  dispatch({
                    type: constants.UPDATE_ITEM,
                    payload: {
                      value: resource,
                    },
                  })
                }

                const handleSyncUpdatedAt = resource => {
                  dispatch({
                    type: constants.SYNC_UPDATED_AT,
                    payload: {
                      iri: resource['@id'],
                      updatedAt: resource.updatedAt,
                    },
                  })
                }
                const title = item.translations?.[
                  process.env.REACT_APP_LOCALE
                ]?.title?.replace(/(<([^>]+)>)/gi, '')
                  ? item.translations?.[
                      process.env.REACT_APP_LOCALE
                    ]?.title?.replace(/(<([^>]+)>)/gi, '')
                  : translate(item.idName)

                const Anyrow = profile.isOpenform ? ManuallyOrderableTableRow : TableRow
                return (
                  <Anyrow
                    index={i}
                    key={`${item[process.env.REACT_APP_RESOURCE_ID]}`}
                  >
                    <TableCell
                      key="item"
                      className={clsx(defaultClasses.cell, classes.cell)}
                      style={{ width: '100%' }}
                    >
                      <ExpansionPanel
                        expanded={
                          expanded.items[item['@id']] !== undefined
                            ? expanded.items[item['@id']]
                            : expanded.switch
                        }
                      >
                        <ExpansionPanelSummary
                          classes={{
                            content: clsx(
                              defaultClasses.expansionPanelSummary,
                              classes.expansionPanelSummary
                            ),
                          }}
                        >
                          { profile.isOpenform ? (<div style={{color:'#e00', display:'flex'}} >[<ManualOrderDragHandle/>]</div>) : null }
                          <Typography
                            onClick={handleSingleExpand(item['@id'])}
                            className={clsx(
                              defaultClasses.itemTitle,
                              classes.itemTitle
                            )}
                          >
                            <span>{panelTitle ? `${panelTitle}: ` : ''}</span>
                            {title}
                          </Typography>
                          {statable && (
                            <div
                              className={clsx(
                                defaultClasses.itemIcon,
                                classes.itemIcon
                              )}
                            >
                              <Status
                                resource={item}
                                accessor={statAccessor}
                                disabled={state.isFetching}
                                handleSyncUpdatedAt={handleSyncUpdatedAt}
                              />
                            </div>
                          )}
                          <div
                            className={clsx(
                              defaultClasses.itemIcon,
                              classes.itemIcon
                            )}
                          >
                            {profile.isOpenform ? (
                              <div style={{ color: '#cc0000' }}>
                                <Delete
                                  resource={item}
                                  accessor={title}
                                  disabled={state.isFetching}
                                  isIcon={true}
                                  onSuccess={handleDelete}
                                />
                              </div>
                            ) : null}
                          </div>
                          <div
                            onClick={handleSingleExpand(item['@id'])}
                            className={clsx(
                              defaultClasses.expandIcon,
                              classes.expandIcon
                            )}
                          >
                            {(expanded.items[item['@id']] !== undefined ? (
                              expanded.items[item['@id']]
                            ) : (
                              expanded.switch
                            )) ? (
                              <ExpandLessOutlined />
                            ) : (
                              <ExpandMoreOutlined />
                            )}
                          </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          classes={{
                            root: clsx(
                              defaultClasses.itemContainer,
                              classes.itemContainer
                            ),
                          }}
                        >
                          <Form
                            url={item['@id']}
                            method="PUT"
                            properties={propertiesByIdname(item)}
                            resource={item}
                            width={800}
                            fetchCompareResource={boolean(
                              process.env.REACT_APP_FORM_FETCH_COMPARE_RESOURCE
                            )}
                            handleSuccess={handleSuccess}
                            showSubmitAndStayButton={false}
                            showCancelButton={false}
                            disabled={disabled}
                            key={`${
                              item[process.env.REACT_APP_RESOURCE_ID]
                            }-${i}`}
                          />
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </TableCell>
                  </Anyrow>
                )
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={2}
                  className={clsx(
                    defaultClasses.emptyResults,
                    classes.emptyResults
                  )}
                >
                  {translate('T_GENERAL_ITEMS_EMPTY')}
                </TableCell>
              </TableRow>
            )}
          </ManuallyOrderableTableBody>
        </Table>
      </TableContainer>
      {/* <div className={clsx(defaultClasses.addContainer, classes.addContainer)}>
        <Add
          endpoint={endpoint}
          parent={parentIri}
          dispatch={dispatch}
          disabled={disabled || state.isFetching}
        />
        {contextualHint && (
          <div
            className={clsx(
              defaultClasses.contextualHint,
              classes.contextualHint
            )}
          >
            <ContextualHint name={contextualHint} />
          </div>
        )}
      </div> */}
    </>
  )
}

EmbeddedPageSection.propTypes = embeddedCollectionPropTypes
