import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { translate } from 'core/_helpers/translate'
import { notification } from 'core/_helpers/notification'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { constants } from 'core/components/table/_state'

import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import { DialogTitleWithClose } from 'core/components/DialogTitleWithClose'
import { Loader } from 'core/components/Loader'

const useStyles = makeStyles(theme => ({
  loader: {
    width: '50px',
    position: 'relative',
    display: 'inline-block',
    height: '40px',
    verticalAlign: 'middle',
  },
  text: {
    cursor: 'pointer',
    width: '100%',
  },
  disabled: {
    color: theme.palette.disabled,
  },
  confirmText: {
    marginTop: 12,
  },
}))

export const OrderBack = ({
  uuid,
  name,
  label,
  // hint = null,
  // initialValue = null,
  value,
  setValue,
  colon = ':',
  btnSize = 'medium',
  disabled = false,
  tableStateDispatch,
}) => {
  const classes = useStyles()
  // const [btnDisable, setDisable] = React.useState(disabled)
  const [state, setState] = React.useState({
    isConfirmOpen: false,
    isFetching: false,
  })

  const handleConfirmToggle = () => {
    setState(state => ({
      ...state,
      isConfirmOpen: !state.isConfirmOpen,
    }))
  }

  // const statusChangeSucces = res => {
  //   setDisable(false)

  //   notification(
  //     'success',
  //     'Status zmieniony. Email do kupującego wysłany.',
  //     'T_FORM_SUCCESS'
  //   )
  // }
  // const statusChangeError = res => {
  //   setDisable(false)

  //   notification('error', 'Status nie zmieniony.', 'T_FORM_ERROR')
  // }

  // const handleClick = () => {
  //   setDisable(true)
  //   //fetch ..........
  //   fetchDataHandleAuthError(
  //     '/api/order_parts/' + uuid,
  //     'PATCH',
  //     { body: JSON.stringify({ newOrderStat: value }) },
  //     statusChangeSucces,
  //     statusChangeError
  //   )
  // }

  const handleDelete = () => {
    setState(state => ({
      ...state,
      isFetching: true,
    }))

    fetchDataHandleAuthError(
      '/api/orderparts_return', // + uuid,
      'POST',
      { body: JSON.stringify({ uuid: uuid }) },
      () => {
        setState({
          isConfirmOpen: false,
          isFetching: false,
        })
        notification(
          'success',
          'Stany magazynowe zwrócone poprawnie',
          'T_FORM_SUCCESS'
        )
        tableStateDispatch({ type: constants.RELOAD })
      },
      error => {
        setState({
          isConfirmOpen: false,
          isFetching: false,
        })

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  return (
    <div style={{ width: 35 }}>
      <Tooltip
        title={
          disabled ? 'Zwrócono stany magazynowe' : 'Zwróć stany magazynowe'
        }
      >
        <span>
          <IconButton
            color="primary"
            size={btnSize}
            disabled={disabled}
            variant="contained"
            onClick={handleConfirmToggle}
          >
            <AddShoppingCartIcon />
          </IconButton>
        </span>
      </Tooltip>

      <Dialog open={state.isConfirmOpen} fullWidth={true} maxWidth="sm">
        <DialogTitleWithClose onClose={handleConfirmToggle}>
          Zwrot stanów magazynowych
        </DialogTitleWithClose>
        <DialogContent dividers>
          {state.isFetching && <Loader align="center" marginBottom={15} />}
          <DialogContentText className={classes.confirmText}>
            Czy napewno chcesz zwrócić stany magazynowe dla wszystkich produktów
            z zamówienia?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirmToggle}
            disabled={state.isFetching}
            autoFocus
            variant="outlined"
          >
            {translate('T_GENERAL_CANCEL')}
          </Button>
          <Button
            onClick={handleDelete}
            disabled={state.isFetching}
            variant="contained"
            color="primary"
          >
            {translate('T_GENERAL_CONFIRM')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
