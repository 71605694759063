import adminSchema from 'core/_schema/admin'
import adminGroupSchema from 'core/_schema/adminGroup'
import loginEntry from 'core/_schema/loginEntry'
import registryEntry from 'core/_schema/registryEntry'
import page from 'core/_schema/page'
import translatorEntry from 'core/_schema/translatorEntry'
import packageFile from 'core/_schema/packageFile'
import packageGallery from 'core/_schema/packageGallery'
import configSchema from './config'
import configPwaSchema from './pwaConfig'
import menuPwa from '_schema/pwaMenu'
import packageLogotype from 'core/_schema/packageLogotype'
import packageFaq from 'core/_schema/packageFaq'
import newsShema from '_schema/news'
import tagEntry from '_schema/tagEntry'
import personItem from '_schema/personItem'
import crew from '_schema/crew'
import shortcut from '_schema/shortcut'
import residence from '_schema/residence'
import residenceGroup from '_schema/residenceGroup'
import happen from '_schema/happen'
import exhibs from '_schema/exhibition'
import educationHome from '_schema/educationHome'
import educationMuseum from '_schema/educationMuseum'
import project from 'core/_schema/project'
import projectCategory from '_schema/educationCategory'
import sobiesciana from '_schema/sobiesciana'
import sobiescianaTag from '_schema/tagStruct'
import pasaz from '_schema/pasaz'
import pasazTopic from '_schema/pasazTopic'
import pasazTag from '_schema/tagPasazEntry'
import iartCollection from '_schema/iartCollection'
import bip from 'core/_schema/bip'
import product from '_schema/product'
import user from '_schema/user'
import paymentType from '_schema/paymentType'
import shippingType from '_schema/shippingType'
import shippingArea from '_schema/shippingArea'
import shippingCountry from '_schema/shippingCountry'
import costs from '_schema/shippingAreaCost'
import order from '_schema/order'
import newsletter from '_schema/newsletter'
import cookies from '_schema/cookieItem'
import whitelist from '_schema/whitelist'
import gigapixel from '_schema/gigapixel'
import formset from '_schema/formset'
import educationCategory from '_schema/educationCategory'
import places from '_schema/place'
import productCategory from '_schema/productCategory'
import prductFilter from '_schema/productFilter'
import productDiscount from '_schema/discountProduct'
import productCode from '_schema/discountCode'
import shippingPoints from '_schema/shippingTypePoint'
import shopForm from '_schema/shopForm'
import cookieIp from '_schema/cookieIp'
import vat from '_schema/vat'
import orderEmail from '_schema/orderEmail'
// import homeBannerItem from './homeBannerItem'

const resources = () => ({
  [page.endpoint]: 'Pages',
  [shortcut.endpoint] : 'Sekcja "Na skróty"',
  [packageFile.endpoint]: 'File packages',
  [packageGallery.endpoint]: 'Gallery packages',
  [packageLogotype.endpoint]: 'Logotype packages',
  [packageFaq.endpoint]: 'Faq packages',
  [formset.endpoint]: 'Formularze',
  [gigapixel.endpoint]: 'Gigapixel',
  [residence.endpoint]: 'Rezydencja',
  [residenceGroup.endpoint]: 'Rezydencja grupy',
  [newsShema.endpointResourceAccess]: 'News',

  [happen.endpoint]: 'Wydarzenia',
  [educationCategory.endpointHappenCategory]: 'Kategorie wydarzeń',
  [educationCategory.endpointVisitCategory]: 'Kategorie "Odwiedź nas"',
  [places.endpoint]: 'Miejsca',



  [exhibs.endpoint]: 'Wystawy',

  [educationCategory.endpoint]: 'Kategorie "w muzeum"',
  [educationCategory.endpointHomeCategory]: 'Kategorie "w domu"',
  [educationMuseum.endpointResourceAccess]: 'Edukacja wydarzenia',
  [educationHome.endpointResourceAccess]: 'Edukacja artykuły',

  [project.endpointProjects]: 'Projekty',
  [projectCategory.endpointProjectCategory]: 'Projekty kategorie',

  [sobiesciana.endpoint]: 'Sobiesciana',
  [sobiescianaTag.endpoint]: 'Tagi Sobiesciana',

  [pasaz.endpoint]: 'Pasaż wiedzy',
  [pasazTopic.endpoint]: 'Pasaż wiedzy tematy',
  [pasazTag.endpoint]: 'Pasaż wiedzy tagi',

  [iartCollection.endpoint]: 'iArt',
  [tagEntry.endpoint]: 'Tag entries',
  [personItem.endpoint]: 'Person items',
  [crew.endpoint]: 'Crews',

  [bip.endpoint]: 'BIP',
  [bip.endpointBipAccess]: 'BIP ustawienia',

  [product.endpoint]: 'Produkty',
  [productCategory.endpoint]: 'Produkty - kategorie',
  [prductFilter.endpoint]: 'Produkty - filtry',
  [productDiscount.endpoint]:'Produkty - promocje',
  [productCode.endpoint]: 'Produkty - kody rabatowe',

  [order.endpoint]: 'Sklep - zamówienia',
  [user.endpoint]: 'Sklep - użytkownicy',
  [paymentType.endpoint]: 'Sklep - typy płatności',
  [shippingType.endpoint]: 'Sklep - typy dostaw',
  [shippingPoints.endpoint]:'Sklep - punkty dostaw',
  [shippingArea.endpoint]: 'Sklep - obszary',
  [costs.endpoint]: 'Sklep - koszty dostaw',
  [shippingCountry.endpoint]: 'Sklep - kraje',
  [shopForm.endpoint] : 'Sklep - formularze',
  [vat.endpoint]: 'Sklep - vat',
  [orderEmail.endpoint] : 'Sklep - Emaile',

  [newsletter.endpoint]: 'Newsletter',

  [configPwaSchema.endpoint]: 'Aplikacja konfiguracja',
  [menuPwa.endpoint]: 'Aplikacja menu główne',

  [adminSchema.endpoint]: 'Administrators',
  [adminGroupSchema.endpoint]: 'Administrator Groups',

  [loginEntry.endpoint]: 'Login entries',
  [registryEntry.endpoint]: 'Registry entries',
  [translatorEntry.endpoint]: 'Translator entries',
  [configSchema.endpoint]: 'Config',


  [cookies.endpoint]: 'Cookies',
  [cookieIp.endpoint]: 'Cookies IP',
  [whitelist.endpoint]: 'Dostęp IP'
  // [homeBannerItem.endpoint]: 'Home banner items',
})

export default resources
