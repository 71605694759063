const schema = {
  endpoint: '/api/residence_groups',
  resource: {
    definition: 'ResidenceGroup-residenceGroup.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Tytuł',
          },
        },
      },

      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
