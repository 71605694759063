import React, { useMemo } from 'react'
import { Paper } from 'core/components/Paper'
import { NotFound } from 'core/pages'
import { Loader } from 'core/components/Loader'
import { BlankForm } from 'core/pages'
import { useSelector } from 'react-redux'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { operations } from './_helpers/_operations'
import { translate } from 'core/_helpers/translate'
import { publish } from 'core/components/table/_mass'
import schema from '_schema/cookieItem'
import routes from './routes'
import schema2 from '_schema/config'

export const Collection = ({ schema: definitions }) => {
  const profile = useSelector(state => state.profile)
  const columns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_TITLE'),
        accessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        filterName: 'translations.title',
        filterable: true,
        width: '73%',
      },

      {
        ...operations(
          `translations.${process.env.REACT_APP_LOCALE}.title`,
          true,
          true,
          profile.isModerator ? true : false,
          true,
          false
        ),
        width: '15%',
      },
    ],
    [profile.isModerator]
  )

  const [state, setState] = React.useState({
    isFetching: true,
    fetchError: false,
    resource: null,
  })

  React.useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      schema2.endpoint,
      'GET',
      { signal },
      response => {
        setState({
          isFetching: false,
          resource: response['hydra:member'].length
            ? response['hydra:member'][0]
            : {},
        })
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
        setState({
          isFetching: false,
          fetchError: true,
        })
      }
    )

    return () => controller.abort()
  }, [])

  const handleSuccess = resource => {
    setState(state => ({
      ...state,
      resource,
    }))
  }
  const { isFetching, fetchError, resource } = state

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('Dodaj grupę')}
        buttonPath={routes().new.path}
      >
        {translate('Cookies')}

        <div style={{ fontSize: 'initial', marginTop:'2em' }}>
          {isFetching ? (
            <Loader />
          ) : fetchError ? (
            <NotFound />
          ) : (
            <BlankForm
              resource={resource}
              definitionSchema={definitions[schema2.resource.definition]}
              customResourceSchema={schema2.resourceforcookies}
              method={resource['@id'] ? 'PUT' : 'POST'}
              url={resource['@id'] || schema2.endpoint}
              handleSuccess={handleSuccess}
              showSubmitAndStayButton={false}
              showCancelButton={false}
              key={resource['@id'] || 'init'}
              fieldsFullWidth={true}
              buttonsFixed={false}
              width={700}
            />
          )}
        </div>
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
