import { fetchData } from 'core/_helpers/fetchData'

const SCHEMA_PATH = process.env.REACT_APP_API_SCHEMA_PATH

const get = () =>
  fetchData(`${SCHEMA_PATH}`, 'GET', {
    accept: 'application/json',
  }).then(
    response => ({ response }),
    error => ({ error })
  )

export const schemaService = {
  get,
}
