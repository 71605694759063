export const modifyPropertiesByPage = (properties, resource, schema) => {
  let modifiedProperties = Object.assign({}, properties)
  modifiedProperties.translations.properties = Object.assign(
    {},
    properties.translations.properties
  )
  let idname = resource.idName ?? resource.parentIdname
  switch (idname) {
    case 'page_xxx':
      modifiedProperties.audiodescription = {
        description: 'Audiodeskrypcja',
        hint: 'link',
        maxLength: 511,
        type: 'string',
      }
      return modifiedProperties
    case 'bip_work':
      delete modifiedProperties.expandMenu
      return modifiedProperties

    default:
      // delete modifiedProperties.translations.properties.subtitle
      return modifiedProperties
  }
}
