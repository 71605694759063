import React, { useCallback } from 'react'
import { Form } from 'core/pages'
import { useHistory } from 'react-router-dom'
import { SidebarNotStatable } from 'core/components/resource/sidebar'
import schema from '_schema/tagStruct'
import routes from './routes'
import { store } from 'core/_store'
import { collectionConstants } from 'core/_constants'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`
  const uuid = match.params.pid ?? null

  const history = useHistory()
  const handleSuccess = useCallback(
    resource => {
      history.push(
        uuid
          ? routes().indexParent.path.replace(':id', uuid)
          : routes().index.path
      )
      if (schema.extraStoreCollectionId) {
        store.dispatch({
          type: collectionConstants.RESET_CONFIG,
          payload: { id: schema.extraStoreCollectionId },
        })
        store.dispatch({
          type: collectionConstants.RESET_CONFIG,
          payload: { id: 'tag_structs_form_parent_false' },
        })
        store.dispatch({
          type: collectionConstants.RESET_CONFIG,
          payload: { id: 'tag_structs_form_parent_true' },
        })
      }
    },
    [history, uuid]
  )
  const handleCancel = () => {
    history.goBack()
  }

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor={`translations.${process.env.REACT_APP_LOCALE}.title`}
      SidebarComponent={SidebarNotStatable}
      handleSuccess={handleSuccess}
      handleCancel={handleCancel}
      width={600}
      key={iri}
    />
  )
}
