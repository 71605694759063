import _block from 'pages/Exhibitions/_block'
import photoSchema from 'core/_schema/photo'
import fileSchema from 'core/_schema/file'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { schema as schematag } from '_schema/tagEntry'
import { iksorisTicketField } from './_iksoris'
import schemaplace from '_schema/place'

const schema = {
  endpoint: '/api/exhibitions',
  resource: {
    definition: 'Exhibition-exhibition.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          shortLead: {
            type: 'textarea',
          },
          text: {
            type: 'tinymce',
          },
          textDate: {
            label: 'Termin wystawy',
          },
          textTime: {},
          ticketPriceString: {},
          curators: {
            hint:
              'można wpisać po przecinku osoby, znak ; przenosi do nowej linii',
          },
          extraInfo: {
            type: 'tinymce',
          },
          photoAlt: {},
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {
            type: 'slug',
            routes: {
              pl: '/wystawa/:slug',
              en: '/en/exhibition/:slug',
            },
          },
          preview: {
            label: 'Podgląd',
            description: '',
            type: 'preview',
            routes: {
              pl: '/wystawa/:slug',
              en: '/en/exhibition/:slug',
            },
          },
        },
      },

      tags: {
        type: 'embeddedcollection',
        endpoint: '/api/exhibition_tags',
        definition: 'ExhibitionTag-exhibitionTag.read',
        description: 'Tagi wystaw',
        additionalProperties: {
          $ref:
            '#/definitions/ExhibitionTag-exhibitionTag.write_exhibitionTag.create',
        },

        titleAccessor: function(resource, handleSuccess) {
          if (resource['@titleAccessor']) return resource['@titleAccessor']
          const setResource = res => {
            resource['@titleAccessor'] =
              res.translations && res.translations[process.env.REACT_APP_LOCALE]
                ? res.translations[process.env.REACT_APP_LOCALE].title ?? '--'
                : '--'
            if (handleSuccess) {
              handleSuccess(resource)
            }
          }
          if (resource.tag) {
            fetchDataHandleAuthError(resource.tag, 'GET', null, setResource)
          } else {
            return '--'
          }
        },

        properties: {
          tag: {
            type: 'resourcetag',
            endpoint: `/api/tag_entries?pagination=false`,
            titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.titleWithName`, //'uuid',
            storeCollectionId: schematag.extraStoreCollectionId,
            label: 'T_TAG_ENTRY',
          },
          stat: {},
        },
      },

      // type: {
      //   type: 'media[type]',
      // },
      // youtube: {
      //   type: 'media[youtube]',
      //   description: 'T_GENERAL_YOUTUBE_CODE',
      // },
      photo: {
        type: 'media[image]',
        description: 'T_GENERAL_MEDIA_PHOTO',
        endpoint: photoSchema.endpoint.single,
        endpointYoutube: photoSchema.endpoint.youtube,
        thumbs: {
          endpoint: '/api/exhibition_thumbs',
        },
        validate: ['maxSize'],
        ytProperty: 'youtube',
        ofmEnable: true,
      },

      // iksorisEventId:{
      //   defaultValue: 0,
      //   hint: 'jeśli wpisany, bedzie otwierac zakup indywidualny biletu'
      // },
      // iksorisTopicId:{
      //   defaultValue: 0,
      //   hint: 'jeśli wpisany, bedzie otwierac zakup grupowy biletu'
      // },
      // iksorisTermId:{
      //   defaultValue: 0,
      //   hint: 'jesli wpisany, bedzie odrazu wybany w kroku 2 zakupu biletu'
      // },
      iksoris: iksorisTicketField,

      stateType: {
        type: 'choice',
        choices: {
          1: 'Aktualna',
          2: 'Planowana',
          3: 'Archiwum',
        },
        validate: ['required'],
      },
      exhibitionType: {
        type: 'choice',
        choices: {
          1: 'Stała',
          2: 'Czasowa',
          3: 'On-line',
        },
        validate: ['required'],
      },
      place: {
        type: 'resource',
        endpoint: `/api/places?pagination=false`,
        titleAccessor: `titlePa`, // `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
        label: 'Place',
        storeCollectionId: schemaplace.extraStoreCollectionId,
      },
      audioguide: {},
      educator: {},
      individual: {},

      iconsPage: {
        type: 'resource',
        endpoint: `/api/pages_form?pagination=false&pageType=page&exists[versionable]=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
        storeCollectionId: 'pages_form_type_page',
      },
      visitAsistance: {},
      asistancePage: {
        type: 'resource',
        endpoint: `/api/pages_form?pagination=false&pageType=page&exists[versionable]=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
        storeCollectionId: 'pages_form_type_page',
      },

      sesons: {
        type: 'collection',
        endpoint: '/api/sezons',
        definition: 'Sezon-sezon.read',
        description: 'Godziny otwarcia',
        additionalProperties: {
          $ref: '#/definitions/Sezon-sezon.write_sezon.create',
        },

        titleAccessor: function(resource, handleSuccess) {
          return 'Sezon/czas obowiązywania'
        },

        properties: {
          dateFrom: { type: 'date', validate: ['required'] },
          dateTo: { type: 'date', validate: ['required'] },
          pn: { hint: 'zostaw puste, jesli nieczynne' },
          wt: { hint: 'zostaw puste, jesli nieczynne' },
          sr: { hint: 'zostaw puste, jesli nieczynne' },
          cz: { hint: 'zostaw puste, jesli nieczynne' },
          pt: { hint: 'zostaw puste, jesli nieczynne' },
          so: { hint: 'zostaw puste, jesli nieczynne' },
          nd: { hint: 'zostaw puste, jesli nieczynne' },
          stat: {},
        },
      },
      closes: {
        type: 'collection',
        endpoint: '/api/closeds',
        definition: 'Closed-closed.read',
        description: 'Święta/zamknięcia',
        additionalProperties: {
          $ref: '#/definitions/Closed-closed.write_closed.create',
        },

        titleAccessor: function(resource, handleSuccess) {
          const title =
            resource.translations &&
            resource.translations[process.env.REACT_APP_LOCALE]
              ? resource.translations[process.env.REACT_APP_LOCALE].title
              : null
          return `${
            resource.dateFrom ? resource.dateFrom.split('T')[0] : '--'
          } ${title ? title : ''}`
        },

        properties: {
          translations: {
            type: 'translation',
            properties: {
              title: {},
            },
          },
          dateFrom: {
            type: 'date',
            validate: ['required'],
            hint: 'rok jest nieistotny',
          },
          stat: {},
        },
      },

      highlighted: {},
      isBig: {},
    },
  },
  subresources: {
    block: {
      endpoint: '/api/exhibition_blocks?pagination=false',
      definition: 'ExhibitionBlock-exhibitionBlock.read',
      types: _block(
        photoSchema.endpoint.single,
        '/api/exhibition_block_thumbs',
        fileSchema.endpoint.single,
        false,
        {
          endpoint: '/api/exhibition_block_elements',
          definition: 'ExhibitionBlockElement-exhibitionBlockElement.read',
          ref: '#/definitions/ExhibitionBlockElement-exhibitionBlockElement.write_exhibitionBlockElement.create',
        }
      ).types,
    },
  },
}

export default schema
