import React from 'react'
import { VersionCollection as OriginalVersionCollection } from 'core/components/version'
import { translate } from 'core/_helpers/translate'
import schema from '_schema/pasaz'
import routes from './routes'

export const VersionCollection = ({ schema: definitions, ...rest }) => {
  const { match, location } = rest

  const onRestoreSuccess = () => {}

  return (
    <OriginalVersionCollection
      endpoint={schema.endpoint}
      versionableUuid={match.params.id}
      customResourceSchema={schema.resource}
      currentPath={location.pathname}
      versionableCollectionTitle={translate('Pasazs')}
      versionableCollectionPath={routes().index.path}
      versionableEditPath={routes().edit.path}
      versionViewPath={routes().version_view.path}
      onRestoreSuccess={onRestoreSuccess}
      titleAccessor={`translations.${process.env.REACT_APP_LOCALE}.title`}
    />
  )
}
