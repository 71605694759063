import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Loader } from 'core/components/Loader'
import SyncIcon from '@material-ui/icons/Sync'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import { notification } from 'core/_helpers/notification'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.5em',
  },
  buttons: {
    '&> *': {
      marginRight: 10,
    },
    '&> *:last-child': {
      marginRight: 0,
    },
  },
  buttonTitle: {
    marginLeft: '.7em',
  },
})

export const CollectionHeader = ({
  children,
  buttonTitle,
  hideButton = false,
  additionalButtons = null,
  classes = {},
}) => {
  const defaultClasses = useStyles()

  const [btnDisable, setDisable] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  const statusChangeSucces = res => {
    setDisable(false)
    notification('success', 'Tworzenie przebiegło pomyślnie.', 'T_SYNC_SUCCESS')
  }
  const statusChangeError = res => {
    setDisable(false)
    notification(
      'error',
      `Tworzenie nie powiodło się.\r\n${res.response.detail ?? ''}`,
      'T_SYNC_ERROR'
    )
  }

  const onClicked = () => {
    setDisable(true)
    setOpen(false)
    //fetch ...........
    fetchDataHandleAuthError(
      '/api/gigapixel_sync',
      'POST',
      { body: JSON.stringify({ date: null }) }, //'2023-05-01'
      statusChangeSucces,
      statusChangeError
    )
  }

  const handleConfirmToggle = () => {
    setOpen(!open)
  }

  return (
    <>
      <Button
        onClick={handleConfirmToggle}
        disabled={btnDisable}
        variant="contained"
        color="primary"
        size="medium"
      >
        {btnDisable ? <Loader /> : <SyncIcon />}
        <span className={clsx(defaultClasses.buttonTitle, classes.buttonTitle)}>
          {btnDisable ? 'Przetwarzanie...' : buttonTitle}
        </span>
      </Button>

      <Dialog open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle onClose={handleConfirmToggle}>
          {translate('Tworzenie plików gigapixelowych')}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText className={classes.confirmText}>
            Nie zalecane gdy duża ilość plików w kolejce, lepiej jak to CRON
            zrobi. Libvips jest nie stabilny w php-fpm. Operacja może potrwać
            dłuższą chwilę, kontynuować ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmToggle} variant="outlined">
            {translate('T_GENERAL_CANCEL')}
          </Button>
          <Button onClick={onClicked} variant="contained" color="primary">
            {translate('Rozpocznij')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

CollectionHeader.propTypes = {
  children: PropTypes.node.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  hideButton: PropTypes.bool,
  additionalButtons: PropTypes.node,
  classes: PropTypes.shape({
    root: PropTypes.string,
    buttons: PropTypes.string,
    buttonTitle: PropTypes.string,
  }),
}
