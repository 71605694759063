import React, { useMemo } from 'react'
import { Paper } from 'core/components/Paper'
import { useSelector } from 'react-redux'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { operations } from 'core/components/table/_columns/operations'
import { translate } from 'core/_helpers/translate'
import { publish } from 'core/components/table/_mass'
// import { MainCell } from './table/cells/MainCell'
import schema from '_schema/residenceItem'
import routes from './routes'

export const ResidenceitemCollection = ({...rest}) => {
  const profile = useSelector(state => state.profile)
  const { match } = rest

  const defaultFilters = {
    'parent.uuid': match.params.pid,
  }

  const columns = useMemo(
    () => [
      {
        header: translate('Name'),
        accessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        filterName: 'translations.title',
        filterable: true,
        // Cell: MainCell,
        width: '73%',
      },
      {
        header: profile.isOpenform ? translate('Idname') : 'Info',
        accessor: profile.isOpenform ? `idName` : `translations.${process.env.REACT_APP_LOCALE}.info`,
        // filterName: 'translations.title',
        // filterable: true,
        // Cell: MainCell,
        width: '13%',
      },
      { ...operations(`translations.${process.env.REACT_APP_LOCALE}.title`, true, true, true, true, false), width: '15%' },
    ],
    [profile.isOpenform]
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('Dodaj pomieszczenie')}
        buttonPath={routes().residenceitemNew.path.replace(':pid',match.params.pid)}
      >
        {translate('Pomieszczenia')}
        &nbsp;
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultFilters={defaultFilters}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().residenceitemEdit.path.replace(':pid',match.params.pid)}
        autoWidth={false}
        storeCollectionId={`${schema.endpoint}${match.params.pid}`}
      />
    </Paper>
  )
}
