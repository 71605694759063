import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import SearchIcon from '@material-ui/icons/Search'
import { translate } from 'core/_helpers/translate'
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { Collection } from 'pages/SearchAll/Collection'
import { DialogTitleWithClose } from 'core/components/DialogTitleWithClose'
import { constants } from 'core/components/table/_state/constants'

const useStyles = makeStyles({
  imgContainer: {
    width: 200,
  },
  img: {
    maxHeight: 100,
    width: '100%',
    height: 'auto',
  },
  listTitle: {
    marginTop: 7,
  },
})

export const SearchAll = () => {
  let dispatch

  const giveMeDispatch = takendispatch => {
    dispatch = takendispatch
  }

  const [open, setOpen] = useState(false)

  const onClickedCloseDialog = () => {
    setOpen(!open)
  }
  const onClickOpenDialog = () => {
    setOpen(true)
  }

  const [word, setWord] = useState('')
  const [filters, setFilters] = useState({ 'translations.title': '' })

  const handleChangeWord = event => {
    setWord(event.target.value)
  }

  const onSearchDialog = () => {
    const filters = {
      'translations.title': {
        name: 'translations.title',
        title: 'tytul',
        type: 'string',
        value: word,
        in: 'query',
      },
    }
    // dispatch({ type: constants.SET_FILTERS, payload: { filters } })
    dispatch({ type: constants.HANDLE_COLUMN_FILTERS, payload: { filters } })
    setFilters({ 'translations.title': word })
  }

  const classes = useStyles()

  return (
    <div>
      <Tooltip title={translate('Szukaj w panelu')}>
        <IconButton edge="start" color="primary" onClick={onClickOpenDialog}>
          <SearchIcon />
        </IconButton>
      </Tooltip>

      <Dialog open={open} fullWidth={true} maxWidth="md">
        <DialogTitleWithClose onClose={onClickedCloseDialog}>
          <Grid container direction="column">
            {translate('Szukaj w panelu')}
            <Grid item>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Wpisz szukane słowo"
                type="text"
                style={{ width: '70%' }}
                value={word}
                onChange={handleChangeWord}
              />
            </Grid>
            <Grid item>
              <Button
                onClick={onSearchDialog}
                variant="contained"
                color="primary"
                size="small"
              >
                {translate('Szukaj')}
              </Button>
            </Grid>
          </Grid>
        </DialogTitleWithClose>

        <DialogContent dividers>
          <DialogContentText className={classes.confirmText}>
            <Collection
              defaultFilters={filters}
              giveMeDispatch={giveMeDispatch}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
}

SearchAll.propTypes = {}
