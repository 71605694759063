import {
  text,
  textQuote,
  textWithVideo,
  textWithAudio,
  textWithImage,
  // textColumn, --- nie ma tu
  // textVisit, --- nie ma tu
  textVisit2,
  packageFile,
  packageGallery,
  // textLinks, --- nie ma tu
  packageFaq,
  textQuoteSection,
  textWithBackground,
  sketchfab,
  imageGigapixel,
  educationBlock,
  happenBlock,
  productBlock,
  iartcollectionBlock,
  pasazBlock,
  sobiescianaBlock,
  exhibitionBlock,
} from 'core/_schema/_blocks'

import {
  textElements,
  textNews,
  textPerson,
  textCrew,
  packageLogotype,
  pricelistBlock,
} from 'pages/Exhibitions/_blocks'

import packagePricelistSchema from 'core/_schema/packagePricelist'

const schema = (photoEndpoint, thumbEndpoint, fileEndpoint, anchor = false, typeBlockElem = null) => ({
  types: [
    text(anchor),
    textQuote(anchor),
    textWithVideo(photoEndpoint, thumbEndpoint, true, anchor),
    textWithImage(photoEndpoint, thumbEndpoint, anchor),
    textWithBackground(photoEndpoint, thumbEndpoint, anchor),
    textWithAudio(fileEndpoint, photoEndpoint, thumbEndpoint, anchor),
    packageFile(anchor),
    packageGallery(anchor),
    textElements(),
    packageFaq(anchor),
    packageLogotype(),
    imageGigapixel(photoEndpoint, thumbEndpoint, anchor),
    textPerson(),
    textCrew(),
    textQuoteSection(photoEndpoint, thumbEndpoint, anchor),
    pricelistBlock(photoEndpoint, thumbEndpoint, packagePricelistSchema),
    textVisit2(anchor),
    textNews(),
    sketchfab(anchor),

    happenBlock(typeBlockElem, anchor), //blok proponowane wydarzenia
    educationBlock(typeBlockElem, anchor), //blok proponowana oferta edukacyjna
    productBlock(typeBlockElem, anchor), //blok polecane produkty
    exhibitionBlock(typeBlockElem, anchor), //blok powiazane wystawy
    sobiescianaBlock(typeBlockElem, anchor), //blok polecane z sobiesciany
    pasazBlock(typeBlockElem, anchor), // blok powiązane z Pasażu Wiedzy
    iartcollectionBlock(typeBlockElem, anchor), //blok Powiązane obiekty z kolekcji iart

  ],
})

export default schema
