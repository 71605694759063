import { useEffect } from 'react'
import { tokenRefresh } from './tokenRefresh'

export const useTokenRefresh = (token, dispatch, logout, refresh) => {
  useEffect(() => {
    if (!token) {
      tokenRefresh.clear()
      return
    }

    tokenRefresh.start(token, dispatch, logout, refresh)

    return () => tokenRefresh.clear()
  }, [token, dispatch, logout, refresh])
}
