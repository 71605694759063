import React from 'react'
import { Route } from 'react-router-dom'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import { Collection } from './Collection'
import { SchemableComponent } from 'core/components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { VersionCollection } from './VersionCollection'
import { VersionView } from './VersionView'
import { addRoutesConfig } from 'core/_helpers/addRoutesConfig'
import schema from '_schema/educationHome'

const basePath = '/happens_education_home'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: DescriptionOutlinedIcon,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        path: `${basePath}/new`,
        exact: true,
      },
      version_collection: {
        subtitle: 'T_GENERAL_RECORD_VERSION_COLLECTION',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={VersionCollection}
            {...props}
          />
        ),
        path: `${basePath}/:id/versions`,
        exact: true,
      },
      version_view: {
        subtitle: 'T_GENERAL_RECORD_VERSION_VIEW',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={VersionView}
            {...props}
          />
        ),
        path: `${basePath}/:id/version`,
        exact: true,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={Edit}
            uniqueKey={true}
            {...props}
          />
        ),
        path: `${basePath}/:id`,
        exact: true,
      },
    },
    {
      title: 'Artykuły',
      resourceAccess: schema.endpointResourceAccess,
      indexPath: basePath,
      // parent: `${basePath}/:id`,
    }
  )

export default routes
