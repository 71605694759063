import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { TextField } from '@material-ui/core'

const useStyles = makeStyles({
  input: {
    width: 160,
    marginLeft: 4,
    marginRight: 4,
    '& input': {
      paddingTop: 13,
    },
  },
})

export const PriceLtGtFilter = ({
  name,
  title,
  value,
  disabled,
  handleEnter,
  setFilter,
  classes = {},
  extraparams,
}) => {
  const base = extraparams.base ?? 1

  const [thisValue, setThisValue] = React.useState(
    value ? (value / base).toFixed(2) : ''
  )

  const handleChange = e => {
    setThisValue(e.target.value)
    let nvalue = parseFloat(e.target.value.replace(',', '.'))
    if (!isNaN(value)) {
      nvalue = nvalue * base
    }

    setFilter(name, !isNaN(nvalue) ? nvalue : '')
  }

  const handleKeyDown = e => {
    switch (e.key) {
      case 'Enter':
        handleEnter && handleEnter()
        break
      default:
        break
    }
  }

  const defaultClasses = useStyles()

  return (
    <TextField
      name={`filter-text-${name}`}
      value={thisValue}
      helperText={title}
      className={clsx(defaultClasses.input, classes.input)}
      disabled={disabled}
      onChange={(e) => handleChange(e)}
      onKeyDown={handleKeyDown}
      // size="small"
      // variant="filled"
      autoFocus
    />
  )
}

PriceLtGtFilter.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  handleEnter: PropTypes.func,
  setFilter: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    input: PropTypes.string,
  }),
}
