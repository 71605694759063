import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import HelpIcon from '@material-ui/icons/Help'

const useStyles = makeStyles(theme => ({
  switch: {
    backgroundColor: 'transparent',
    display: 'flex',
    flex: '0 0 auto',
    height: '28px',
    overflow: 'hidden',
    position: 'relative',
    width: '56px',
  },
  label: {
    cursor: 'pointer',
    width: '100%',
  },
  input: {
    opacity: 0,
    '&.switch__input:checked ~ .switch__text': {
      background: theme.palette.success.main,
    },
    '&.switch__input:checked ~ .switch__text:before': {
      opacity: 0,
      transform: 'translateX(200%)',
    },
    '&.switch__input:checked ~ .switch__text:after': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '&.switch__input:checked ~ .switch__handle': {
      transform: 'translateX(28px)',
    },
  },

  text: {
    backgroundColor: '#EEF0F4',
    border: '1px solid #A4ACB4',
    borderRadius: '50px',
    display: 'block',
    flex: '0 0 auto',
    height: '28px',
    position: 'absolute',
    top: '0',
    width: '56px',
    '&.switch__text:before': {
      content: 'attr(data-off)',
      right: '8px',
      transform: 'translateX(0)',
      color: '#A4ACB4',
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '25px',
      position: 'absolute',
      transition: 'all 0.2s ease-in-out',
      transitionProperty: 'transform',
    },
    '&.switch__text:after': {
      color: 'white',
      content: 'attr(data-on)',
      left: '9px',
      opacity: 0,
      transform: 'translateX(-200%)',
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '25px',
      position: 'absolute',
      transition: 'all 0.2s ease-in-out',
      transitionProperty: 'transform',
    },
  },

  handle: {
    backgroundColor: 'white',
    borderRadius: '18px',
    display: 'block',
    height: '24px',
    margin: '2px',
    position: 'absolute',
    top: '0',
    transition: 'all 0.2s ease-in-out',
    transitionProperty: 'transform',
    width: '24px',
    color: theme.palette.primary.main,
  },
}))

export const CustomSwitch = ({ name, checked, onChange }) => {
  const classes = useStyles()

  return (
    <div aria-label={name} className={classes.switch}>
      <label className={clsx(classes.label, 'switch__label')} htmlFor={name}>
        <input
          role="switch"
          type="checkbox"
          checked={checked}
          className={clsx(classes.input, 'switch__input')}
          id={name}
          onChange={onChange}
        />
        <span
          className={clsx(classes.text, 'switch__text')}
          data-on="on"
          data-off="off"
        ></span>
        <span className={clsx(classes.handle, 'switch__handle')}>
          <HelpIcon />
        </span>
      </label>
    </div>
  )
}

CustomSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}
