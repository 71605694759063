import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Switch } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'left',
    fontSize: '0.75rem',
  },
  container: {
    display: 'inline-block',
    color: theme.palette.text.secondary,

    margin: 0,
    marginTop: 3,
    marginLeft: 0,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    textAlign: 'left',
  },
}))

export const ProjectParentFilter = ({
  name,
  title,
  value,
  disabled,
  setFilter,
  handleFilter,
  extraparams,
  classes = {},
}) => {
  const pid = extraparams.pid
  const blockOrderable = extraparams.blockOrderable

  const handleSwitchChange = e => {
    const valuef = e.target.checked
    handleFilter(name, valuef)

    if (valuef) {
      handleFilter('exists[parent]', null)
      handleFilter('parent.uuid', null)
      blockOrderable(true)
    } else {
      handleFilter('exists[parent]', pid ? true : false)
      handleFilter('parent.uuid', pid ? pid : null)
      blockOrderable(false)
    }
  }

  const defaultClasses = useStyles()

  return (
    <div className={clsx(defaultClasses.root, classes.root)}>
      <div className={clsx(defaultClasses.container, classes.container)}>
        <Switch
          name={`filter-switch-${name}`}
          checked={value === null ? false : value}
          disabled={disabled}
          onChange={handleSwitchChange}
          color="primary"
        />
      </div>
      <span>{title}</span>
    </div>
  )
}

ProjectParentFilter.propTypes = {
  name: PropTypes.string.isRequired,
}
