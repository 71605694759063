import React, { useCallback, useMemo } from 'react'
// import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
// import { BlockCollection } from 'core/components/block'
import { Title } from 'core/components/Title'
import { Form } from 'core/pages'
// import { Sidebar } from './sidebar/Sidebar'
import { translate } from 'core/_helpers/translate'
// import { usePropertyResourcesFetch } from 'core/_helpers/usePropertyResourcesFetch'
// import { addBlockPropertyResources } from 'core/_helpers/addBlockPropertyResources'
// import { addOpenformProperties } from './_helpers/addOpenformProperties'
// import { modifyPropertiesByPage } from './_helpers/modifyPropertiesByPage'
// import { commonConstants } from 'core/_constants'
import schema from '_schema/mainMenu'
import routes from './routes'
// import packageFileSchema from 'core/_schema/packageFile'
// import packageGallerySchema from 'core/_schema/packageGallery'
// import packageFaqSchema from 'core/_schema/packageFaq'
// import { modifyBlockByPage } from './_helpers/modifyBlockByPage'

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
  },
  form: {
    marginTop: 0,
  },
})

export const Edit = ({ schema: definitions, ...rest }) => {
  // const dispatch = useDispatch()

  // const profile = useSelector(state => state.profile)
  const { match } = rest

  const iri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  // const [reses, setReses] = useState(null)

  // const isNotEditable = useCallback(
  //   resource => {
  //     setReses(resource)
  //     return (
  //       resource.versionable || (!profile.isOpenform && !resource.isEditable)
  //     )
  //   },
  //   [profile.isOpenform]
  // )

  const history = useHistory()
  const handleSuccess = useCallback(
    resource => {
      history.push(
        resource.parent
          ? routes().indexParent.path.replace(
              ':id',
              resource.parent[process.env.REACT_APP_RESOURCE_ID]
            )
          : routes().index.path
      )
    },
    [history]
  )
  const handleCancel = () => {
    history.goBack()
  }

  // const handleSuccessAndStay = useCallback(() => {
  //   dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })
  // }, [dispatch])

  // const packageResourcesMapping = useMemo(
  //   () => ({
  //     packageFile: packageFileSchema.endpoint+'?pagination=false&embeded=false',
  //     packageGallery: packageGallerySchema.endpoint+'?pagination=false&embeded=false',
  //     packageFaq: packageFaqSchema.endpoint+'?pagination=false',
  //   }),
  //   []
  // )

  // const [packageResources] = usePropertyResourcesFetch(packageResourcesMapping)

  // const blockTypes = useMemo(() => {
  //   return addBlockPropertyResources(
  //     reses
  //       ? modifyBlockByPage(schema.subresources.block.types, reses)
  //       : schema.subresources.block.types,
  //     packageResources
  //   )
  // }, [packageResources, reses])

  const classes = useStyles()

  return (
    <Form
      iri={iri}
      // isNotEditable={isNotEditable}
      collectionPath={routes().index.path}
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      // modifyProperties={(properties, resource, schema) => {
      //   let modifiedProperties = modifyPropertiesByPage(
      //     properties,
      //     resource,
      //     schema
      //   )
      //   return addOpenformProperties(
      //     profile.isOpenform,
      //     modifiedProperties,
      //     resource,
      //     schema
      //   )
      // }}
      method="PUT"
      url={iri}
      storeCollectionId={schema.endpoint}
      handleSuccess={handleSuccess}
      handleCancel={handleCancel}
      // handleSuccessAndStay={handleSuccessAndStay}
      sidebar={true}
      sidebarTitleAccessor={`translations.${process.env.REACT_APP_LOCALE}.title`}
      // SidebarComponent={Sidebar}
      fieldsFullWidth={false}
      header={
        <Container maxWidth="xl" className={classes.header}>
          <Title>{translate('T_GENERAL_EDIT')}</Title>
        </Container>
      }
      width="100%"
      key={iri}
      classes={{ root: classes.form }}
    >
      {/* <BlockCollection
        endpoint={schema.subresources.block.endpoint}
        pid={match.params.id}
        parentIri={iri}
        definitionSchema={definitions[schema.subresources.block.definition]}
        types={blockTypes}
        statable={true}
        key={iri}
      /> */}
    </Form>
  )
}
