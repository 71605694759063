import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { schema as schematag } from '_schema/tagEntry'

const schema = {
  endpoint: '/api/education_categories', //museum
  endpointProjectCategory: '/api/project_categories',
  endpointHappenCategory: '/api/happen_categories',
  endpointHomeCategory: '/api/education_home_categories',
  endpointVisitCategory: '/api/visit_categories',
  resource: {
    definition: 'EducationCategory-educationCategory.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Tytuł',
          },
        },
      },

      tags: {
        type: 'embeddedcollection',
        endpoint: '/api/education_category_tags',
        definition: 'EducationCategoryTag-educationCategoryTag.read',
        description: 'Tagi w kategorii',
        additionalProperties: {
          $ref:
            '#/definitions/EducationCategoryTag-educationCategoryTag.write_educationCategoryTag.create',
        },

        titleAccessor: function(resource, handleSuccess) {
          if (resource['@titleAccessor']) return resource['@titleAccessor']
          const setResource = res => {
            resource['@titleAccessor'] =
              res.translations && res.translations[process.env.REACT_APP_LOCALE]
                ? (res.translations[process.env.REACT_APP_LOCALE].title ?? '--')
                : '--'
            if (handleSuccess) {
              handleSuccess(resource)
            }
          }
          if (resource.tag) {
            fetchDataHandleAuthError(resource.tag, 'GET', null, setResource)
          } else {
            return '--'//translate('T_GENERAL_MISSING_TITLE').replace('<','‹ ').replace('>',' ›')
          }
        },

        properties: {
          tag: {
            type: 'resourcetag',
            endpoint: `/api/tag_entries?pagination=false`,
            titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.titleWithName`, //'uuid',
            storeCollectionId: schematag.extraStoreCollectionId,
            label: 'T_TAG_ENTRY',
          },
          stat: {},
        },
      },

      stat: {},
    },
  },
}

export default schema
