const schema = {
  endpoint: '/api/pwa_configs',
  resource: {
    definition: 'PwaConfig-config.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          titleWelcome: {},
          textWelcome: {
            type: 'textarea',
          },
          textScaninfo: {
            type: 'textarea',
          },


          titleContact: {},
          textContact: {
            type: 'textarea',
          },
          phoneContact:{
            hint: 'np 22 544 27 00, 678-234-123, +48 660-233-100 itp..'
          },

          titlePark: {},
          openPark: {},
          hourPark: {},

          titlePalac: {},
          openPalac: {},
          hourPalac: {},

          // off
          // metaTitle: {},
          // metaDescription: {
          //   type: 'textarea',
          // },
          // metaKeywords: {
          //   type: 'textarea',
          // },
        },
      },

      // off
      // additionalHeadCode: {
      //   type: 'textarea',
      // },
      // additionalBodyCode: {
      //   type: 'textarea',
      // },
    },
  },
}

export default schema
