import { useState, useCallback } from 'react'

export const usePageDropzoneRef = () => {
  const [refs, setRefs] = useState({ previous: null, next: null })

  const onRef = useCallback(
    (type, element) => {
      setRefs(state => ({
        ...state,
        [type]: element,
      }))
    },
    [setRefs]
  )

  const onPreviousRef = useCallback(element => onRef('previous', element), [
    onRef,
  ])

  const onNextRef = useCallback(element => onRef('next', element), [onRef])

  return {
    previous: refs.previous,
    next: refs.next,
    onPrevious: onPreviousRef,
    onNext: onNextRef,
  }
}
