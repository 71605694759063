import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import { Archive } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { DialogLoader } from 'core/components/DialogLoader'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  withIcon: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: 'inherit',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  withoutIcon: {
    width: '100%',
    textDecoration: 'none',
    cursor: 'pointer',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  disabled: {
    color: theme.palette.disabled,
  },
}))

export const CreateVersion = ({
  resource,
  disabled = false,
  onSuccess = null,
  isIcon = false,
}) => {
  const [isFetching, setIsFetching] = useState(false)

  const classes = useStyles()
  const handleClick = () => {
    if (disabled) {
      return
    }

    setIsFetching(true)

    fetchDataHandleAuthError(
      resource['@id'],
      'PATCH',
      {
        body: JSON.stringify({
          createVersion: true,
        }),
      },
      () => {
        setIsFetching(false)

        notification(
          'success',
          'T_FORM_RECORD_VERSION_CREATED',
          'T_FORM_SUCCESS'
        )
        onSuccess && onSuccess()
      },
      error => {
        setIsFetching(false)

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  return (
    <>
      {disabled ? (
        isIcon ? (
          <Archive className={classes.disabled} disabled />
        ) : (
          <span className={classes.disabled}>
            {translate('T_GENERAL_VERSION_CREATE')}
          </span>
        )
      ) : (
        <div
          onClick={handleClick}
          className={isIcon ? classes.withIcon : classes.withoutIcon}
        >
          {isIcon ? (
            <Tooltip title={translate('T_GENERAL_VERSION_CREATE')}>
              <Archive />
            </Tooltip>
          ) : (
            translate('T_GENERAL_VERSION_CREATE')
          )}
        </div>
      )}
      <DialogLoader
        isOpen={isFetching}
        title={translate('T_GENERAL_VERSION_CREATING')}
      />
    </>
  )
}

CreateVersion.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  isIcon: PropTypes.bool,
}
