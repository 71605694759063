import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { Paper } from 'core/components/Paper'
import { Schemable } from 'core/components/Schemable'
import { ProfileForm } from './ProfileForm'
import { ChangePasswordForm } from './ChangePasswordForm'

export const Profile = () => {
  const profile = useSelector(state => state.profile)

  return (
    <Paper>
      <Schemable>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <ProfileForm resource={profile} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <ChangePasswordForm />
          </Grid>
        </Grid>
      </Schemable>
    </Paper>
  )
}
