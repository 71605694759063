import React, { useMemo } from 'react'
import { HomeBox } from 'pages/Home/HomeBox'
import { standard } from 'core/components/table/_columns/standard'
import { operations } from 'core/components/table/_columns/operations'
import { ModuleCell } from 'core/pages/RegistryEntries/table/cells/ModuleCell'
import { MethodCell } from 'core/pages/RegistryEntries/table/cells/MethodCell'
import { translate } from 'core/_helpers/translate'
import schema from 'core/_schema/registryEntry'
import routes from 'core/pages/RegistryEntries/routes'

export const RegistryEntries = () => {
  const columns = useMemo(
    () => [
      {
        ...standard('username'),
        width: '33%',
      },
      {
        accessor: 'requestUri',
        header: translate('T_GENERAL_MODULE'),
        width: '37%',
        Cell: ModuleCell,
      },
      {
        accessor: 'requestMethod',
        header: translate('T_GENERAL_OPERATION'),
        width: '20%',
        Cell: MethodCell,
      },
      {
        ...operations(null, false, false, false, true),
        header: translate('T_GENERAL_DATE'),
        width: '10%',
      },
    ],
    []
  )

  return (
    <HomeBox
      columns={columns}
      route={routes().index}
      endpoint={schema.endpoint}
    />
  )
}
