import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { Loader } from 'core/components/Loader'

const useStyles = makeStyles({
  title: {
    fontSize: '1.15em',
  },
  content: {
    marginBottom: 15,
  },
})

export const DialogLoader = ({ title = null, isOpen = false }) => {
  const classes = useStyles()

  return (
    <Dialog maxWidth="md" open={isOpen}>
      {title && (
        <DialogTitle>
          <div className={classes.title}>{title}</div>
        </DialogTitle>
      )}
      <DialogContent className={classes.content}>
        <Loader align="center" />
      </DialogContent>
    </Dialog>
  )
}

DialogLoader.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
}
