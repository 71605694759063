import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Paper } from 'core/components/Paper'

import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { operations }  from './table/columns/operations' //from 'core/components/table/_columns/operations'

import { translate } from 'core/_helpers/translate'
import { publish } from 'core/components/table/_mass'
import schema from '_schema/sobiesciana'
import routes from './routes'

import { exportselected } from 'core/pages/TranslatorEntries/table/exportselected'

export const Collection = ({ wrapWithPaper = true, showNewButton = true }) => {
  const profile = useSelector(state => state.profile)

  const columns = useMemo(
    () => [
      {
        header: translate('Title'),
        accessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        filterName: 'translations.title',
        filterable: true,
        // Cell: MainCell,
        width: '73%',
      },

      { ...operations(`translations.${process.env.REACT_APP_LOCALE}.title`, true, true, (profile.isModerator ? true : false), true, true), width: '15%' },
    ],
    [profile.isModerator]
  )

  const defaultFilters = {
    'exists[versionable]': false,
  }

  const customFilters = [
    // {name: 'pageType', title: 'page type', value: 'news'}
  ]

  const WrapComponent = wrapWithPaper ? Paper : 'div'

  return (
    <WrapComponent>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_SOBIESCIANA_NEW_BUTTON')}
        buttonPath={routes().new.path}
        hideButton={!showNewButton}
      >
        {translate('Sobiescianas')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultFilters={defaultFilters}
        defaultSorters={{ ord: 'desc' }}
        selectable={[publish.on(), publish.off(), exportselected.delete()]}
        isRowSelectable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        customFilters={customFilters}
        orderable={true}
        isRowLinkable={true}
      />
    </WrapComponent>
  )
}

Collection.propTypes = {
  wrapWithPaper: PropTypes.bool,
  showNewButton: PropTypes.bool,
}
