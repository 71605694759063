import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { createPortal } from 'react-dom'
import { Button as OriginalButton } from '@material-ui/core'
import { CancelOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { translate } from 'core/_helpers/translate'

export const useStyles = makeStyles(theme => ({
  submit: {
    marginTop: '1.5em',
  },
  cancel: {
    background: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.error.main,
    },
    '&:active': {
      background: theme.palette.error.main,
    },
  },
}))

export const CancelButton = ({
  title = null,
  handleCancel,
  disabled = false,
  nodeRef = null,
  classes = {},
}) => {
  const button = (
    <Button
      title={title}
      handleCancel={handleCancel}
      disabled={disabled}
      classes={classes}
    />
  )

  return nodeRef ? createPortal(button, nodeRef) : button
}

const Button = ({
  title = null,
  handleCancel,
  disabled = false,
  classes = {},
}) => {
  const defaultClasses = useStyles()

  return (
    <div className={clsx(defaultClasses.submit, classes.submit)}>
      <OriginalButton
        type="submit"
        variant="contained"
        color="primary"
        size="small"
        startIcon={<CancelOutlined />}
        disabled={disabled}
        onClick={handleCancel}
        className={defaultClasses.cancel}
      >
        {title || translate('T_FORM_CANCEL')}
      </OriginalButton>
    </div>
  )
}

CancelButton.propTypes = {
  title: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  nodeRef: PropTypes.object,
  classes: PropTypes.shape({
    submit: PropTypes.string,
  }),
}
