import React, { useMemo } from 'react'
import { Paper } from 'core/components/Paper'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { editable } from 'core/components/table/_columns/editable'
import { status } from 'core/components/table/_columns/status'
import { operations } from './table/columns/operations'
import { EditableCell } from './table/cells/EditableCell'
import { StatusCell } from './table/cells/StatusCell'
import { translate } from 'core/_helpers/translate'
import schema from 'core/_schema/adminGroup'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      { ...editable('title'), width: '53%', Cell: EditableCell },
      {
        header: translate('T_GENERAL_SUPER_ADMIN'),
        ...status('isSuperAdmin'),
        width: '25%',
        Cell: StatusCell,
      },
      { ...operations('title'), width: '15%' },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_ADMIN_GROUPS_NEW_BUTTON')}
        buttonPath={routes().new.path}
      >
        {translate('T_MODULE_ADMIN_GROUPS')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
