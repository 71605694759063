import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import schema from '_schema/vat'


const useStyles = makeStyles({
  image: {
    display: 'flex',
    alignItems: 'center',
  },
  imgContainer: {
    width: 200,
  },
  img: {
    maxHeight: 100,
    width: '100%',
    height: 'auto'
  },
  title: {
    marginLeft: '1.0em',
  },
})

export const MainCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
}) => {
  const history = useHistory()

  const onClick = () => {
    if (isRowLinkable) {
      editPath &&
        history.push(
          editPath.replace(':id', resource[process.env.REACT_APP_RESOURCE_ID])
        )
    }
  }

  // const author = resource.translations[process.env.REACT_APP_LOCALE]?.author

  const classes = useStyles()

  return (
    <div onClick={onClick} className={classes.image}>

      <div className={classes.title}>
        { schema.resource.properties[accessor].choices[resource[accessor]] }
      </div>
    </div>
  )
}


