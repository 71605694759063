import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Loader } from './Loader'

export const Schemable = ({ children }) => {
  const schema = useSelector(state => state.schema)

  return schema ? children : <Loader align="center" marginTop={15} />
}

Schemable.propTypes = {
  children: PropTypes.element.isRequired,
}
