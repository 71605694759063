const schema = {
  endpoint: '/api/product_categories',
  endpointForm: '/api/product_categories_form',
  resource: {
    definition: 'ProductCategory-productCategory.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
        },
      },
      filters: {
        type: 'multipleResource',
        endpoint: `/api/product_filters?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,//'uuid',
        label: 'Filtry'
      },
    },
  },
}

export default schema
