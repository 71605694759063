import { makeStyles, alpha } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    fontFamily: theme.typography.fontFamily,
  },
  card: {
    width: 300,
    marginTop: '6.5em',
  },
  logo: {
    borderRadius: '4px 4px 0 0',
    height: 88,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#7856ff'
  },
  avatar: {
    marginTop: '1em',
    marginBottom: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  lock: {
    backgroundColor: theme.palette.disabled,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    padding: '.5em',
  },
  form_inner: {
    padding: '0 .65em 0 .65em',
  },
  input: {
    marginTop: '.4em',
  },
  remember_me: {
    marginTop: '.7em',
    color: theme.elements.remember_me,
  },
  remember_me_checkbox: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    '&:active': {
      backgroundColor: 'transparent !important',
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 0, 0),
    color: theme.elements.login.submit.color,
    backgroundColor: theme.elements.login.submit.background,
    '&:hover': {
      backgroundColor: alpha(theme.elements.login.submit.background, 0.85),
    },
  },
  '@keyframes spin': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  spinner: {
    height: 24,
    animationName: '$spin',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  lower_link: {
    marginTop: 15,
    textDecoration: 'none',
    color: theme.elements.login.text,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  reset_message: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    padding: '3.0em 0 4.75em 0',
    color: theme.palette.success.main,
  },
  new_password_error: {
    marginBottom: '1.5em',
    textAlign: 'center',
    color: theme.palette.error.main,
  },
}))
