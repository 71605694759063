import React from 'react'
import { VersionView as OriginalVersionView } from 'core/components/version'
// import { usePropertyResourcesFetch } from 'core/_helpers/usePropertyResourcesFetch'
// import { addBlockPropertyResources } from 'core/_helpers/addBlockPropertyResources'
import { translate } from 'core/_helpers/translate'
import schema from '_schema/product'
import routes from './routes'
// import packageFileSchema from 'core/_schema/packageFile'
// import packageGallerySchema from 'core/_schema/packageGallery'
// import packageFaqSchema from 'core/_schema/packageFaq'

export const VersionView = ({ schema: definitions, ...rest }) => {
  const { match, location } = rest

  // const packageResourcesMapping = useMemo(
  //   () => ({
  //     // packageFile: packageFileSchema.endpoint+'?pagination=false&embeded=false',
  //     // packageGallery: packageGallerySchema.endpoint+'?pagination=false&embeded=false',
  //     // packageFaq: packageFaqSchema.endpoint+'?pagination=false',
  //   }),
  //   []
  // )

  // const [packageResources] = usePropertyResourcesFetch(packageResourcesMapping)

  // const blockTypes = useMemo(
  //   () =>
  //     addBlockPropertyResources(
  //       schema.subresources.block.types,
  //       packageResources
  //     ),
  //   [packageResources]
  // )

  return (
    <OriginalVersionView
      endpoint={schema.endpoint}
      uuid={match.params.id}
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      currentPath={location.pathname}
      versionableCollectionTitle={translate('Products')}
      versionableCollectionPath={routes().index.path}
      versionableEditPath={routes().edit.path}
      versionCollectionPath={routes().version_collection.path}
      titleAccessor={`translations.${process.env.REACT_APP_LOCALE}.title`}
      blockable={false}
      // blockEndpoint={schema.subresources.block.endpoint}
      // blockDefinitionSchema={definitions[schema.subresources.block.definition]}
      // blockTypes={blockTypes}
    />
  )
}
