import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'

export const textCrew = () => ({
  name: 'textCrew',
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_CREW',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
      },
    },
    elements: {
      type: 'collection',
      endpoint: '/api/bip_block_elements',
      definition: 'BipBlockElement-bipBlockElement.read',
      description: 'T_GENERAL_BLOCK_TYPE_TEXT_CREW_ADD',
      additionalProperties: {
        $ref:
          '#/definitions/BipBlockElement-bipBlockElement.write_bipBlockElement.create',
      },
      titleAccessor: function(resource, handleSuccess) {
        if (
          resource.translations &&
          resource.translations[process.env.REACT_APP_LOCALE].titlePa
        )
          return resource.translations[process.env.REACT_APP_LOCALE].titlePa
        if (resource['@titleAccessor']) return resource['@titleAccessor']

        const setResource = res => {
          resource['@titleAccessor'] =
            res.translations && res.translations[process.env.REACT_APP_LOCALE]
              ? (res.translations[process.env.REACT_APP_LOCALE].titlePa ?? '--')
              : '--'
          if (handleSuccess) {
            handleSuccess(resource)
          }
        }
        if (resource.crew) {
          fetchDataHandleAuthError(resource.crew, 'GET', null, setResource)
        } else {
          return '--' //translate('T_GENERAL_MISSING_TITLE').replace('<','‹ ').replace('>',' ›')
        }
      },
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              hint: 'T_GENERAL_BLOCK_TYPE_TEXT_CREW_TITLE_HINT',
            },
          },
        },
        crew: {
          type: 'resource',
          endpoint: `/api/crew_items?pagination=false`,
          titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.titlePa`, //'uuid',
          label: 'T_BLOCK_ELEMENT_LABEL_CREW',
        },
        stat: {},
      },
    },
    galleryType: {
      type: 'choice',
      label: 'Typ bloku',
      choices: {
        tile: 'T_GENERAL_BLOCK_GALLERY_TYPE_TILE',
        slider: 'T_GENERAL_BLOCK_GALLERY_TYPE_SLIDER',
      },
      validate: ['required'],
    },
  },
})
