
const schema = {
  endpoint: '/api/shortcuts',
  resource: {
    definition: 'Shortcut-shortcut.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Title',
            hint: 'jeśli wpisany, będzie użyty zamiast nazwy strony lub gdy link jest wpisany'
          },
          link: {
            hint: 'wpisz link lub wybierz stronę poniżej'
          },
        },
      },

      page: {
        type: 'resource',
        endpoint: `/api/pages_form?pagination=false&pageType=page&exists[versionable]=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,//'uuid',
        label: 'T_BLOCK_ELEMENT_LABEL_PAGE',
        hint: 'jeśli wybrana, link nie bedzie brany pod uwagę',
        storeCollectionId: 'pages_form_type_page',
      },

      blank: {
        description: 'T_SHORTCUT_BLANK',
      },

      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
