export const modifyPropertiesByPage = (properties, resource, schema) => {
  const modifiedProperties = properties

  switch (resource.idName) {
    // Modify by page here
    case 'test':
      return modifiedProperties
    default:
      return properties
  }
}
