import React from 'react'
import { Form } from 'core/pages'
import schema from '_schema/shippingAreaCost'
import schema2 from '_schema/shippingArea'
import routes from './routes'

export const CostNew = ({ schema: definitions, ...rest }) => {
  const { match } = rest
  const iri = `${schema2.endpoint}/${match.params.pid}`

  return (
    <>
      {match.params.pid && (
        <Form
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={schema.resource}
          method="POST"
          url={schema.endpoint}
          collectionPath={routes().costsIndex.path.replace(
            ':pid',
            match.params.pid
          )}
          editPath={routes().costEdit.path.replace(':pid', match.params.pid)}
          storeCollectionId={schema.endpoint}
          defaultData={{ parent: iri }}
          width={600}
        />
      )}
    </>
  )
}
