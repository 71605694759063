import React from 'react'
import { Route } from 'react-router-dom'
import { SchemableComponent } from 'core/components/SchemableComponent'
import MapOutlinedIcon from '@material-ui/icons/MapOutlined'
import { New } from './New'
import { Edit } from './Edit'
import { CostsCollection } from './CostsCollection'
import { CostNew } from './CostNew'
import { CostEdit } from './CostEdit'
import { Collection } from './Collection'
import { addRoutesConfig } from 'core/_helpers/addRoutesConfig'
import schema from '_schema/shippingArea'

const basePath = '/shipping_areas'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: MapOutlinedIcon,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        path: `${basePath}/new`,
        exact: true,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: `${basePath}/:id`,
        exact: true,
      },

      costsIndex: {
        subtitle: 'T_GENERAL_RECORD_LIST_COSTS',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={CostsCollection}
            {...props}
          />
        ),
        path: `${basePath}/:pid/costs`,
        exact: true,
      },
      costNew: {
        subtitle: 'T_GENERAL_RECORD_NEW_COST',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={CostNew}
            {...props}
          />
        ),
        path: `${basePath}/:pid/cost/new`,
        exact: true,
      },
      costEdit: {
        subtitle: 'T_GENERAL_RECORD_EDIT_COST',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={CostEdit}
            {...props}
          />
        ),
        path: `${basePath}/:pid/cost/:id`,
        exact: true,
      },
    },
    {
      title: 'Shipping areas',
      resourceAccess: schema.endpoint,
      indexPath: basePath,
    }
  )

export default routes
