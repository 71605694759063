import React from 'react'
import { Form } from 'core/pages'
import { SidebarNotStatable } from 'core/components/resource/sidebar'
import schema from '_schema/order'
import routes from './routes'
import { Alert } from '@material-ui/lab'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <>
      <Alert severity="warning">
        Uwaga! - wszystkie poniższe informacje można edytować, jednak należy zachować uwagę:
        <br/>- zmieniając np metodę płatnośći na przelew skasowaniu ulegają informacje dotyczące PayU (np. numer, link)
        <br/>- zmieniając cenę trzba przeliczyć ręcznie, gdy cena = 0, "Zapłacone z payu" jest wyłączone, "Przelew na konto sklepu" jest wyłączone.
        <br/>- pole "Adres dostawy" nie jest zmieniane automatycznie gdy edytuje się dane zamówienia lub edycja pola "Paczkomat" lub "Odbiór osobisty".
      </Alert>
      <Form
        definitionSchema={definitions[schema.resource.definition]}
        customResourceSchema={schema.resource}
        iri={iri}
        method="PUT"
        url={iri}
        collectionPath={routes().index.path}
        storeCollectionId={schema.endpoint}
        sidebar={true}
        sidebarTitleAccessor={`translations.${process.env.REACT_APP_LOCALE}.title`}
        SidebarComponent={SidebarNotStatable}
        width="100%"
      />
    </>
  )
}
