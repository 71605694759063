import packageFaqSchema from 'core/_schema/packageFaq'

export const packageFaq = (anchor = true) => {
  let b = {
    name: 'packageFaq',
    label: 'T_GENERAL_BLOCK_TYPE_PACKAGE_FAQ',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
        },
      },
      packageFaq: {
        type: 'resource',
        endpoint: `${packageFaqSchema.endpoint}?pagination=false`,
        titleAccessor: 'title',
      },
      anchor: {},
    },
  }

  if (!anchor) {
    delete b.properties.anchor
  }
  return b
}
