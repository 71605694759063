import React from 'react'
import { Form } from 'core/components/form'
import schema from 'core/_schema/profile'

export const ChangePasswordForm = () => (
  <Form
    title="T_FORM_CHANGE_PASSWORD_TITLE"
    url={schema.changePassword.set}
    method="PATCH"
    properties={schema.changePassword.properties}
    showSubmitAndStayButton={false}
    showCancelButton={false}
  />
)
