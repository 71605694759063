import React from 'react'
import { prop } from 'core/_helpers/prop'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  title: {},
})

export const TitleCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.title}>
      {prop(resource, accessor) && (
        <span>
          {prop(resource, accessor).substring(0, 128)}
          {prop(resource, accessor).length > 128 ? '...' : ''}
        </span>
      )}
    </div>
  )
}
