export const schema = {
  endpoint: '/api/tag_structs',
  extraStoreCollectionId: 'tag_structs_form',
  resource: {
    definition: 'TagStruct-tagStruct.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
        },
      },
      parent: {
        type: 'multipleResource',
        endpoint: `/api/tag_structs_form?pagination=false`,//&exists[parent]=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,//'uuid',
        storeCollectionId: 'tag_structs_form',
        label: 'Przypisany do tagów'
      },

      dateFrom: {
        type: 'date',
      },
      dateTo: {
        type: 'date',
      },

      map:{
        type: 'map',
        label: 'Mapa',
      },

      stat: { description: 'T_GENERAL_PUBLICATION' },
    },
  },
}

export default schema
