import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import UploadIcon from '@material-ui/icons/Backup'
import { Grid, IconButton, alpha } from '@material-ui/core'
import { notification } from 'core/_helpers/notification'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import { humanFileSize } from './_humanFileSize'
import ErrorIcon from '@material-ui/icons/HighlightOff'
import SuccessIcon from '@material-ui/icons/CheckCircleOutlined'
import { authHeader } from 'core/_helpers/authHeader'

const useStyles = makeStyles(theme => ({
  progressBar: {
    display: 'block',
    padding: 2,
    height: 14,
    width: '100%',
    border: '1px solid #333',
    borderRadius: 3,
  },
  progressBarFill: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    width: '50%',
    borderRadius: 3,
  },
  dropState: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    color: 'white',
    '&.success': {
      backgroundColor: alpha('#5e5', 0.5),
    },
    '&.error': {
      backgroundColor: alpha('#e55', 0.5),
    },
    '& svg': {
      fontSize: '5em',
    },
  },
}))

export const OFMUploadDialog = ({
  notallowedTypes = ['php', 'js', 'sql'],
  accept = '', //'image/jpeg, image/png, image/webp',
  maxTotalSize = 2000 * 1024 * 1024,
  endpoint = `${process.env.REACT_APP_API_ENTRYPOINT}/api/filemanager/files/upload`,
  usethumb = true,
  ofmPath = '/',
  callbackUploadFinish = null,
}) => {
  const drop = React.useRef(null)
  const classes = useStyles()
  const imagesTypes = ['jpeg', 'png', 'webp', 'jpg', 'gif']
  const [files, setFiles] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [uploadProgress, setUploadProgress] = useState([])
  const [imagePreview, setImagePreview] = useState([])
  const [uploadState, setUploadState] = useState([])

  function pushError(message, i = null) {
    setErrorMessage(message)
    notification('error', message, 'Błąd')
    if (i !== null) {
      setUploadState(sf => ({ ...sf, [i]: false }))
    }
  }

  function clearState() {
    setFiles([])
    setErrorMessage('')
    setUploadProgress([])
    setImagePreview([])
    setUploadState([])
  }

  function checkIsAllowedType(selectedFiles) {
    return selectedFiles.some(file => {
      const fileExtension = file.name
        .split('.')
        .pop()
        .toLowerCase()
      return !notallowedTypes.includes(`${fileExtension}`)
    })
  }
  async function handleMultipleFileUploadAjax(
    files,
    setUploadProgress,
    setErrorMessage
  ) {
    try {
      files.forEach((file, i) => {
        const res = /*await*/ uploadFile(endpoint, file, setUploadProgress, i)
        res
          .then(response => {
            if (response.status === 200) {
              setUploadState(sf => ({ ...sf, [i]: true }))
            } else {
              setUploadState(sf => ({ ...sf, [i]: false }))
              var body = JSON.parse(response.body)
              if (body && body.message) {
                notification('error', body.message, 'Błąd')
              } else if (response.body) {
                notification('error', response.body.substring(0, 128), 'Błąd')
              }
            }
            if (callbackUploadFinish) {
              callbackUploadFinish(response.status)
            }
          })
          .catch(e => {
            setUploadState(sf => ({ ...sf, [i]: false }))
          })
      })
    } catch (error) {
      setErrorMessage(error)
    }
  }

  const uploadFile = (url, file, onProgress, i) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.upload.addEventListener('progress', e => {
        onProgress(sf => ({ ...sf, [i]: e.loaded / e.total }))
      })
      xhr.addEventListener('load', () =>
        resolve({ status: xhr.status, body: xhr.responseText })
      )
      xhr.addEventListener('error', () => {
        pushError(`Błąd przesyłaia pliku ${file.name}`, i)
        // reject(new Error('File upload failed'))
      })
      xhr.addEventListener('abort', () => {
        // return reject(new Error('File upload aborted'))
      })

      xhr.open('POST', url, true)
      // xhr.setRequestHeader('Allow-Origin', '*')
      // xhr.setRequestHeader('Cache-Control', 'no-cache')
      // xhr.setRequestHeader('Content-Type','multipart/form-data')
      const a = authHeader()
      xhr.setRequestHeader('Authorization', a.Authorization)
      const formData = new FormData()
      formData.append('path', ofmPath)
      formData.append('usethumb', usethumb)
      formData.append('uploadfile', file)
      xhr.send(formData)
    })
  }

  const validateFile = (file, setErrorMessage) => {
    if (file.size > maxTotalSize) {
      setErrorMessage(
        `Rozmiar pliku ${file.name} przekracza dopuszczalny limit`
      )
    } else {
      const isAllowedFiles = checkIsAllowedType([file])
      if (!isAllowedFiles) {
        setErrorMessage(`Niedozwolone rozszerzenie pliku ${file.name}`)
      } else {
        return true
      }
    }
    return false
  }

  const handleDragOver = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  // user dropuje kilka plikow
  const handleDrop = e => {
    e.preventDefault()
    e.stopPropagation()

    clearState()

    const filesDropped = Array.from(e.dataTransfer.files).filter(file => {
      return validateFile(file, pushError)
    })

    if (filesDropped && filesDropped.length) {
      const previews = filesDropped.map(file => URL.createObjectURL(file))
      setImagePreview(previews)
      setFiles(filesDropped)

      handleMultipleFileUploadAjax(filesDropped, setUploadProgress, pushError)
    }
  }

  useEffect(() => {
    if (drop.current === null) return
    drop.current.addEventListener('dragover', handleDragOver)
    drop.current.addEventListener('drop', handleDrop)

    return () => {
      if (drop.current !== null) {
        drop.current.removeEventListener('dragover', handleDragOver)
        drop.current.removeEventListener('drop', handleDrop)
      }
    }
  })

  // user wybiera z dysku kilka plikow
  const handleFilesOnChange = e => {
    clearState()

    const selectedFiles = Array.from(e.target.files).filter(file => {
      return validateFile(file, pushError)
    })

    const previews = selectedFiles.map(file => URL.createObjectURL(file))
    setImagePreview(previews)
    setFiles(selectedFiles)

    handleMultipleFileUploadAjax(selectedFiles, setUploadProgress, pushError)
  }

  return (
    <React.Fragment>
      <div
        ref={drop}
        style={{ padding: 50, border: '2px #c3c3c3 dashed', borderRadius: 12 }}
      >
        <div>
          <input
            id="icon-button-file"
            type="file"
            multiple
            accept={accept}
            onChange={handleFilesOnChange}
            style={{ display: 'none' }}
          />

          <label htmlFor="icon-button-file">
            <IconButton color="primary" component="span">
              <UploadIcon />
            </IconButton>
          </label>
          <span>Kliknij lub przeciągnij pliki by rozpocząć upload</span>
        </div>

        {errorMessage && (
          <span style={{ color: '#e55', wordWrap: 'break-word' }}>
            {errorMessage}
          </span>
        )}
      </div>

      <Grid container style={{ marginTop: 20 }} spacing={5}>
        {files.map((file, index) => (
          <Grid item key={index} style={{ marginBottom: '10px' }}>
            {/* ikona */}
            <div style={{ position: 'relative', width: 150 }}>
              {imagesTypes.includes(
                file.name
                  .split('.')
                  .pop()
                  .toLowerCase()
              ) ? (
                <img
                  alt=""
                  style={{ maxHeight: 160, width: 150 }}
                  src={imagePreview[index]}
                />
              ) : (
                <div style={{ width: 150, textAlign: 'center' }}>
                  <DescriptionOutlinedIcon
                    style={{ width: 100, height: 'auto' }}
                  />
                </div>
              )}
              <div
                style={{
                  wordBreak: 'break-word',
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                {file.name}
                <br />
                {humanFileSize(file.size)}
              </div>

              {(uploadState[index] ?? null) === true && (
                <div className={`${classes.dropState} success`}>
                  <SuccessIcon />
                </div>
              )}
              {(uploadState[index] ?? null) === false && (
                <div className={`${classes.dropState} error`}>
                  <ErrorIcon />
                </div>
              )}
            </div>
            {/* progress bar */}
            <div className={classes.progressBar}>
              <div
                className={classes.progressBarFill}
                style={{
                  width: `${
                    uploadProgress && uploadProgress[index]
                      ? uploadProgress[index] * 100
                      : 0
                  }%`,
                }}
              ></div>
            </div>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  )
}
