import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { translate } from 'core/_helpers/translate'

import { notification } from 'core/_helpers/notification'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { TableLoader } from 'core/components/table'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles(theme => ({
  loader: {
    width: '50px',
    position: 'relative',
    display: 'inline-block',
    height: '40px',
    verticalAlign: 'middle',
  },
}))

export const options = {
  STATUS_NEW: 1, //             = 1;        //nowa, nie mozna pobrac ebookow/rozdzialow
  STATUS_WAIT_FORPAY: 9, //       = 9;        //oczekuje na platnosc, nie mozna pobrac ebookow/rozdzialow
  STATUS_PAID: 8, //           = 8;        //można pobrać
  STATUS_INPROGRESS: 2, //     = 2;        //mozna pobrac ebooki/rozdzialy
  STATUS_SEND_PREPARE: 7, //   = 7;        //można pobrać
  STATUS_SEND: 3, //              = 3;        //mozna pobrac ebooki/rozdzialy
  STATUS_DONE_INPOINT: 6, //      = 6;        //mozna pobrac ebboki/rozdzialy
  STATUS_DONE: 4, //              = 4;        //mozna pobrac ebboki/rozdzialy
  STATUS_ABORT: 5, //             = 5;        //nie mozna pobrac ebookow/rozdzialow
}

export const OrderStatus = ({
  uuid,
  name,
  label,
  // hint = null,
  // initialValue = null,
  value,
  setValue,
  colon = ':',
  btnSize = 'medium',
}) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedIndex, setSelectedIndex] = React.useState(value)
  const [selectedIndexPrev, setSelectedIndexPrev] = React.useState(value)
  const [btnDisable, setDisable] = React.useState(false)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const statusChangeSucces = res => {
    setValue(name, res.orderStat)
    setSelectedIndex(res.orderStat)
    setDisable(false)

    notification(
      'success',
      'Status zmieniony. Email do kupującego wysłany.',
      'T_FORM_SUCCESS'
    )
  }
  const statusChangeError = res => {
    setSelectedIndex(selectedIndexPrev)
    setDisable(false)

    notification('error', 'Status nie zmieniony.', 'T_FORM_ERROR')
  }

  const handleMenuItemClick = (event, index) => {
    setSelectedIndexPrev(selectedIndex)
    setSelectedIndex(index)
    setAnchorEl(null)
    setDisable(true)
    //fetch ..........
    fetchDataHandleAuthError(
      '/api/order_parts/' + uuid,
      'PATCH',
      { body: JSON.stringify({ newOrderStat: index }) },
      statusChangeSucces,
      statusChangeError
    )
  }

  let options2 = {}
  for (const [key, value] of Object.entries(options)) {
    options2[value] = key
  }

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        {label}
        {colon} {translate(options2[selectedIndex])}
      </div>

      <Button
        color="primary"
        size={btnSize}
        disabled={btnDisable}
        variant="contained"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Zmień status
      </Button>
      <div className={clsx(classes.loader)}>
        <TableLoader show={btnDisable} align="center" />
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.entries(options).map(option => (
          <MenuItem
            key={option}
            // disabled={index === 0}
            selected={option[1] === selectedIndex}
            onClick={event => handleMenuItemClick(event, option[1])}
          >
            {translate(option[0])}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
