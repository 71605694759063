import { prop } from './prop'

export const getSchemaByRef = (schema, ref) => {
  if (!ref) {
    return
  }

  const splitRef = ref.replace('#/', '').split('/')
  const lastRef = splitRef.pop()

  const parentRef = prop(schema, splitRef)

  return parentRef?.[lastRef]
}
