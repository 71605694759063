import photoSchema from 'core/_schema/photo'

const schema = {
  endpoint: '/api/configs',
  endpointBipAccess: '/api/bip_configs',
  resourceforcookies:{
    definition: 'Config-config.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          cookiesBoxTitle:{
            hint:'tytuł boxa'
          },
          cookiesBoxMsg: {
            type: 'textarea',
            hint:'główny tekst w boxie'
          },
        }
      }
    }
  },
  resource: {
    definition: 'Config-config.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          alertMsg: {
            type: 'textarea',
            hint: 'T_MODULE_CONFIG_ALETMESSAGE_HINT',
          },
          alertLink: {
            hint: 'T_MODULE_CONFIG_ALERTLINK_HINT',
          },
          address: {
            label: '[STOPKA] Adres',
            type: 'textarea',
          },
          address2: {
            label: '[STOPKA] Bilety/zamówienia',
            type: 'textarea',
          },
          openHour1: {
            label: 'Pałac wilanowski - dni otwarcia',
          },
          openHour2: {
            label: 'Park i dziedziniec pałacu - dni otwarcia',
          },
          openHourLast1: {
            label: 'Pałac wilanowski - godziny otwarcia',
          },
          openHourLast2: {
            label: 'Park i dziedziniec pałacu - godziny otwarcia',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
        },
      },
      linkFacebook: {},
      linkInstagram: {},
      additionalHeadCode: {
        type: 'textarea',
      },
      additionalBodyCode: {
        type: 'textarea',
      },
      photo: {
        type: 'image',
        description: 'T_MODULE_CONFIG_DEFAULT_FACEBOOK_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        ofmEnable: true
      },
      shopDisabled: { label: 'Wyłącz sklep' },
      shopEmail: { label: 'Email do obsługi zamówień', validate: ['required'] },
      shopPhone: { label: 'Telefon do sklepu' },
      shopTransferAccount: {},
      shopTransferAccountSwift: {},
      ebookLinkTimeout: {},
    },
  },
}

export default schema
