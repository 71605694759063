import React, { useMemo } from 'react'
import { Paper } from 'core/components/Paper'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { operations } from 'core/components/table/_columns/operations'
import { translate } from 'core/_helpers/translate'
import schema from '_schema/cookieIp'
import routes from './routes'
import { DateTimeCell } from 'core/components/table/cells/DateTimeCell'
import { DateFilter } from 'core/components/table/filters/DateFilter'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        header: `IP`,
        accessor: `ip`,
        filterName: 'ip',
        filterable: true,
        sortable: false,
      },

      {
        header: `Data dodania`,
        accessor: `createdAt`,
        width: '12%',
        Cell: DateTimeCell,
        sortable: true,
        sorterName: 'createdAt',
      },

      {
        ...operations(`ip`, true, true, false, true),
        width: '7%',
      },
    ],
    []
  )

  const customFilters = [
    {
      name: 'createdAt[after]',
      title: 'Data od',
      value: null,
      Filter: DateFilter,
    },
    {
      name: 'createdAt[before]',
      title: 'Data do',
      value: null,
      Filter: DateFilter,
    },
  ]

  return (
    <Paper>
      <CollectionHeader
        // buttonTitle={translate('Dodaj IP')}
        // buttonPath={routes().new.path}
        hideButton={true}
      >
        {translate('Lista cookie IP')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ createdAt: 'desc' }}
        orderable={false}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        customFilters={customFilters}
      />
    </Paper>
  )
}
