import React from 'react'
import { Route } from 'react-router-dom'
import { SchemableComponent } from 'core/components/SchemableComponent'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import { New } from './New'
import { Edit } from './Edit'
import { Collection } from './Collection'
import { ResidenceitemCollection } from './ResidenceitemCollection'
import { ResidenceitemNew } from './ResidenceitemNew'
import { ResidenceitemEdit } from './ResidenceitemEdit'
import { addRoutesConfig } from 'core/_helpers/addRoutesConfig'
import schema from '_schema/residence'

const basePath = '/residences'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: LocationCityIcon,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        path: `${basePath}/new`,
        exact: true,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: `${basePath}/:id`,
        exact: true,
      },

      // /*
      residenceitemsIndex: {
        subtitle: 'Lista pomieszczeń',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={ResidenceitemCollection}
            {...props}
          />
        ),
        path: `${basePath}/:pid/residenceitems`,
        exact: true,
      },
      residenceitemNew: {
        subtitle: 'Nowe pole',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={ResidenceitemNew}
            {...props}
          />
        ),
        path: `${basePath}/:pid/residenceitem/new`,
        exact: true,
      },
      residenceitemEdit: {
        subtitle: 'Edycja pola',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={ResidenceitemEdit}
            {...props}
          />
        ),
        path: `${basePath}/:pid/residenceitem/:id`,
        exact: true,
      },

      // */


    },
    {
      title: 'Rezydencja',
      resourceAccess: schema.endpoint,
      indexPath: basePath,
    }
  )

export default routes
