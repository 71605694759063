import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import { authHeader } from 'core/_helpers/authHeader'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { notification } from 'core/_helpers/notification'
import { Loader } from 'core/components/Loader'

export const PreviewType = ({
  name,
  routes,
  label,
  hint = null,
  initialValue,
  value,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  translationLang,
  fullWidth = false,
  lang = null,
  getValue,
}) => {
  const [slug, setSlug] = React.useState(null)

  const [state, setState] = React.useState({
    isFetching: false,
  })

  const handlePreview = url => {
    const redirectUrl = `${process.env.REACT_APP_API_ENTRYPOINT}/preview${url}`

    setState({
      isFetching: true,
    })

    fetch(redirectUrl, {
      method: 'GET',
      headers: {
        // accept: 'application/ld+json',
        ...authHeader(),
      },
      // ...request,
    })
      .then(response => response.json())
      .then(data => {
        // newWindow.document.write(data)//not works on CORS sht
        window.open(
          `${process.env.REACT_APP_API_ENTRYPOINT}/${data.token}`,
          '_blank'
        )
        setState({
          isFetching: false,
        })
      })
      .catch(error => {
        if (error.response)
          notification('error', error.response.detail, error.response.title)
        setState({
          isFetching: false,
        })
      })
  }

  useEffect(() => {
    const t = getValue('translations')
    setSlug(t[translationLang]?.slug)
  }, [value, lang, translationLang, getValue])

  return (
    <>
      {slug && (
        <>
          <Button
            startIcon={state.isFetching ? <Loader /> : <VisibilityIcon />}
            variant="contained"
            color="primary"
            size="small"
            disabled={state.isFetching}
            onClick={() => {
              handlePreview(routes[translationLang].replace(':slug', slug))
            }}
          >
            {label}
          </Button>
        </>
      )}
    </>
  )
}
