export const hasResourceAccess = (resourceAccess, user) => {
  if (!resourceAccess) {
    return true
  }

  if (user.wasPasswordChangedByOtherAdmin) {
    return false
  }

  if (
    user.isOpenform ||
    user.isSuperAdmin ||
    (user.resourceAccess && user.resourceAccess.includes(resourceAccess))
  ) {
    return true
  }

  return false
}
