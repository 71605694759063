const schema = {
  endpoint: '/api/pages_search_for_all',
  resource: {
    definition: 'Page-page.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
        },
      },
    },
  },
}

export default schema
