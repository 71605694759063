import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { Divider, Drawer, Tooltip } from '@material-ui/core'
import { Menu } from 'core/components/menu'
import { Logo } from 'core/components/Logo'
import { MenuSkeleton } from './MenuSkeleton'
import { UserBox } from 'core/components/UserBox'
import { translate } from 'core/_helpers/translate'
import { useStyles } from './SideBar.styles'
import profileRoutes from 'core/pages/Profile/routes'

export const SideBar = ({
  menu,
  isSidebarOpen,
  loading,
  drawerWidth,
  handleChangeDraverWidth,
}) => {
  const classes = useStyles()

  const profileRoute = profileRoutes().index
  const ProfileIcon = profileRoute.icon

  const [isResizing, setIsResizing] = useState(false)
  const [leftDragStart, setLeftDragStart] = useState(drawerWidth)

  const handleMouseMove = useCallback(
    event => {
      if (isResizing) {
        setLeftDragStart(event.clientX)
      }
    },
    [isResizing]
  )

  const handleDragEnd = useCallback(
    e => {
      if (isResizing) {
        handleChangeDraverWidth(e.clientX)
      }
      setIsResizing(false)
    },
    [handleChangeDraverWidth, isResizing]
  )

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleDragEnd)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleDragEnd)
    }
  }, [handleDragEnd, handleMouseMove])

  const handleDragStart = e => {
    setIsResizing(true)
  }

  useEffect(() => {
    setLeftDragStart(drawerWidth)
  }, [drawerWidth])

  return (
    <>
      <div style={{ width: drawerWidth, position: 'relative' }}>
        <Drawer
          variant="permanent"
          classes={{
            docked: classes.docked,
            paper: clsx(
              classes.drawer,
              !isSidebarOpen && classes.drawerSidebarClosed
            ),
          }}
          open={isSidebarOpen}
          style={{
            overflowY: isResizing ? 'visible' : 'auto',
            height: '100vh',
            width: drawerWidth,
          }}
        >
          <Logo className={classes.logo} />
          {isSidebarOpen ? (
            <div className={clsx(classes.userBox)}>
              <UserBox />
            </div>
          ) : (
            <Tooltip title={translate(profileRoute.title)} placement="right">
              <Link
                to={profileRoute.path}
                className={classes.profileLink}
                style={{ paddingTop: '12px', paddingBottom: '12px' }}
              >
                <ProfileIcon />
              </Link>
            </Tooltip>
          )}
          <Divider className={classes.divider} />
          {loading ? (
            <MenuSkeleton rows={4} />
          ) : (
            <Menu items={menu} isSidebarOpen={isSidebarOpen} />
          )}
        </Drawer>
      </div>
      <div
        // onMouseDown={handleMouseDown}
        draggable="false"
        onMouseDown={handleDragStart}
        // onDragEnd={handleDragEnd}
        // onDrag={handleDrag}
        style={{
          cursor: 'col-resize',
          position: 'fixed',
          top: 0,
          // right: 0,
          left: isResizing ? leftDragStart : drawerWidth,
          width: '4px',
          height: '100%',
          zIndex: '100',
          backgroundColor: 'transparent',
          borderLeft: isResizing ? '2px solid blue' : '0',
        }}
      />
    </>
  )
}

const itemShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  icon: PropTypes.elementType.isRequired,
})

SideBar.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.oneOfType([
      itemShape,
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        icon: PropTypes.elementType.isRequired,
        items: PropTypes.arrayOf(itemShape).isRequired,
      }),
    ])
  ),
  isSidebarOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  drawerWidth: PropTypes.number.isRequired,
  handleChangeDraverWidth: PropTypes.func.isRequired,
}
