const schema = {
  endpoint: '/api/sobiesciana_categories',
  resource: {
    definition: 'SobiescianaCategory-sobiescianaCategory.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Tytuł',
          },
        },
      },
      filter: {
        type: 'resource',
        endpoint: `/api/sobiesciana_filter_sets?pagination=false`,
        titleAccessor: 'name',
        label: 'Filtr',
      },
      idName: {},
      iconName:{},
      importName:{ disabled:true },
      stat: {},
    },
  },
}

export default schema
