import React from 'react'
import ReactDOM from 'react-dom'
import Root from 'core/components/root'
import { isLogged } from 'core/_helpers/isLogged'
import { store } from 'core/_store'

isLogged(store.dispatch)

const render = Component =>
  ReactDOM.render(<Component />, document.querySelector('#root'))

render(Root)

if (module.hot) {
  module.hot.accept('./core/components/root', () => {
    const NextRoot = require('./core/components/root').default
    render(NextRoot)
  })
}
