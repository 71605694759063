import { constants } from './constants'

export const reducer = (state, action) => {
  switch (action.type) {
    case constants.FETCH_START:
    case constants.ORDER_CHANGE_START:
      return {
        ...state,
        isFetching: true,
      }
    case constants.FETCH_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        init: false,
        isFetching: false,
      }
    case constants.ORDER_CHANGE_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        isFetching: false,
      }
    case constants.ORDER_CHANGE_FAILURE:
      return {
        ...state,
        isFetching: false,
      }
    case constants.FETCH_FAILURE:
      return {
        ...state,
        init: false,
        isFetching: false,
      }
    case constants.ADD_ITEM:
      return {
        ...state,
        items: state.items.concat([action.payload.value]),
        isFetching: false,
      }
    case constants.REMOVE_ITEM:
      return {
        ...state,
        items: state.items.filter(item => item !== action.payload.value),
      }
    case constants.UPDATE_ITEM:
      return {
        ...state,
        items: state.items.map(item =>
          item['@id'] === action.payload.value['@id']
            ? action.payload.value
            : item
        ),
      }
    case constants.SYNC_UPDATED_AT:
      return {
        ...state,
        items: state.items.map(item =>
          item['@id'] === action.payload.iri
            ? { ...item, updatedAt: action.payload.updatedAt }
            : item
        )
      }
    default:
      return {
        ...state,
        ...action.payload,
      }
  }
}
