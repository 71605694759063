import React from 'react'
import { Alert } from "@material-ui/lab"

export const Info = () => (
<Alert severity="info">
  W powyższym szablonie można używać następujących zmiennych:

  <ul style={{listStyle:'none'}}>


  <li><strong>%order_number%</strong> - numer zamówienia</li>
  <li><strong>%status%</strong> - status zamówienia</li>
  <li><strong>%person_address%</strong> - adres zamawiającego</li>
  <li><strong>%person_invoice%</strong> - dane do faktury</li>
  <li><strong>%start_section_user_notice%</strong> - otwarcie sekcji z uwagami od kupującego</li>
  <li><strong>%user_notice%</strong> - treść uwag od kupującego</li>
  <li><strong>%end_section_user_notice%</strong> - zamknięcie sekcji z uwagami od kupującego</li>
  <li><strong>%start_section_payment_transfer%</strong> - otwarcie sekcji z płatnością przelewem</li>
  <li><strong>%shop_transfer_account%</strong> - numer konta bankowego</li>
  <li><strong>%payment_name%</strong> - tytuł metody płatności</li>
  <li><strong>%end_section_payment_transfer%</strong> - zamknięcie sekcji z płatnością przelewem</li>
  <li><strong>%start_section_payment_payu%</strong> - otwarcie sekcji z płatnością PayU</li>
  <li><strong>%payu_link%</strong> - link do płatnosci PayU</li>
  <li><strong>%end_section_payment_payu%</strong> - zamknięcie sekcji z płatnością PayU</li>
  <li><strong>%start_section_shipping%</strong> - otwarcie sekcji z metodą dostawy</li>
  <li><strong>%shipping_name%</strong> - tytuł metody dostawy </li>
  <li><strong>%shipping_cost%</strong> - łączny koszt dostawy</li>
  <li><strong>%shipping_address%</strong> - adres dostawy</li>
  <li><strong>%end_section_shipping%</strong> - koniec sekcji z metodą dostawy</li>
  <li><strong>%start_section_discountcode%</strong> - start sekcji z użytym kodem</li>
  <li><strong>%discount_code%</strong> - nazwa kodu promocyjnego</li>
  <li><strong>%end_section_discountcode%</strong> - koniec sekcji z użytym kodem</li>
  <li><strong>%products_table%</strong> - tabela z produktami zakupionymi</li>
  <li><strong>%start_section_shop_contact%</strong> - start sekcji z danymi kontaktowymi</li>
  <li><strong>%shop_email%</strong> - email do kontaktu w sklepie</li>
  <li><strong>%shop_phone%</strong> - telefon do sklepu</li>
  <li><strong>%end_section_shop_contact%</strong> - koniec sekcji z danymi kontaktowymi</li>

  <li><strong>%start_section_bilety_contact%</strong> - start sekcji z kontaktem dla biletów</li>
  <li><strong>%end_section_bilety_contact%</strong> - koniec sekcji z kontaktem dla biletów</li>
  <li><strong>%start_section_ksiegarnia_contact%</strong> - start sekcji z kontaktem dla ksiegarni</li>
  <li><strong>%end_section_ksiegarnia_contact%</strong> - koniec sekcji z kontaktem dla ksiegarni</li>

  <li><strong>%start_section_text_bilety%</strong> - start sekcji z tekstem dla biletów</li>
  <li><strong>%end_section_text_bilety%</strong> - koniec sekcji z tekstem dla biletów</li>
  <li><strong>%start_section_text_ksiegarnia%</strong> - start sekcji z tekstem dla ksiegarni</li>
  <li><strong>%end_section_text_ksiegarnia%</strong> - koniec sekcji z tekstem dla ksiegarni</li>
  <li><strong>%start_section_text_bilety_i_ksiegarnia%</strong> - start sekcji z tekstem dla biletów i ksiegarni</li>
  <li><strong>%end_section_text_bilety_i_ksiegarnia%</strong> - koniec sekcji z tekstem dla biletów i ksiegarni</li>

  <li><strong></strong></li>
  <li><strong></strong></li>


  </ul>

  Sekcje nalezy zawsze używać w parach, <b>%start_</b> i <b>%end_ </b> gdyż są to fragmenty maila, które nie zawsze się pojawiają w zamówieniu, np użytkownik móże nie wpisać uwag, albo przy ebooku/bilecie móże nie być opcji dostawy.

</Alert>
)
