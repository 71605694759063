import { validators } from './validators'

export const validate = (validatorNames, value) =>
  (validatorNames && validatorNames.length
    ? validatorNames.map(name =>
        name.includes('(')
          ? validators[name.split('(')[0]](
              value,
              name
                .split('(')
                .pop()
                .split(')')[0]
            )
          : validators[name](value)
      )
    : [{ result: true, message: null }]
  ).reduce((prev, curr) => (prev.result && !curr.result ? curr : prev))
