import React from 'react'
import { Form } from 'core/pages'
import { SidebarNotStatable } from 'core/components/resource/sidebar'
import schema from '_schema/order'
import routes from './routes'

export const Show = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resourceForShow.definition]}
      customResourceSchema={schema.resourceForShow}
      iri={iri}
      method="PUT"
      url={iri}
      title="Podgląd"
      header={() => {}}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={false}
      sidebarTitleAccessor={`translations.${process.env.REACT_APP_LOCALE}.title`}
      SidebarComponent={SidebarNotStatable}
      width="100%"
      showSubmitAndStayButton={false}
      showSubmit={false}
    />
  )
}
