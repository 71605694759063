import { collectionConstants } from 'core/_constants'

const setConfig = (id, config) => ({
  type: collectionConstants.SET_CONFIG,
  payload: { id, config },
})

const resetConfig = id => ({
  type: collectionConstants.RESET_CONFIG,
  payload: { id },
})

export const collectionActions = {
  setConfig,
  resetConfig,
}
