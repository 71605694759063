import React from 'react'
import PropTypes from 'prop-types'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  crop: {
    '& img': {
      maxHeight: '65vh',
    },
  },
})

export const Crop = ({
  src,
  crop,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  setCrop,
  setOriginal = null,
  disabled = false,
  classes = {},
}) => {
  const onImageLoaded = image => {
    if (!setOriginal) {
      return true
    }

    setOriginal({
      clientWidth: image.clientWidth,
      clientHeight: image.clientHeight,
      naturalWidth: image.naturalWidth,
      naturalHeight: image.naturalHeight,
    })

    return false
  }

  const defaultClasses = useStyles()

  return (
    <ReactCrop
      src={src}
      crop={crop}
      minWidth={minWidth}
      minHeight={minHeight}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      keepSelection={true}
      onChange={newCrop => setCrop(newCrop)}
      onImageLoaded={onImageLoaded}
      ruleOfThirds={true}
      disabled={disabled}
      className={clsx(defaultClasses.crop, classes.crop)}
    />
  )
}

Crop.propTypes = {
  src: PropTypes.string.isRequired,
  crop: PropTypes.object.isRequired,
  minWidth: PropTypes.number.isRequired,
  minHeight: PropTypes.number.isRequired,
  maxWidth: PropTypes.number.isRequired,
  maxHeight: PropTypes.number.isRequired,
  setCrop: PropTypes.func.isRequired,
  setOriginal: PropTypes.func,
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    crop: PropTypes.string,
  }),
}
