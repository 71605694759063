import React, { useCallback } from 'react'
import { Form } from 'core/pages'
import { useHistory } from 'react-router-dom'
import { SidebarNotStatable } from 'core/components/resource/sidebar'
import schema from '_schema/sobiescianaCategory'
import routes from './routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`
  const uuid = match.params.pid ?? null

  const history = useHistory()
  const handleSuccess = useCallback(
    resource => {
      history.push(
        uuid
          ? routes().indexParent.path.replace(':id', uuid)
          : routes().index.path
      )
    },
    [history, uuid]
  )
  const handleCancel = () => {
    history.goBack()
  }

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor={`translations.${process.env.REACT_APP_LOCALE}.title`}
      SidebarComponent={SidebarNotStatable}
      handleSuccess={handleSuccess}
      handleCancel={handleCancel}
      width={600}
      key={iri}
    />
  )
}
