import React from 'react'
import { Link } from 'react-router-dom'
import { prop } from 'core/_helpers/prop'
import { makeStyles } from '@material-ui/styles'
import routesPage from 'core/pages/Pages/routes'
import routesProject from 'core/pages/Projects/routes'
import routesBip from 'core/pages/Bip/routes'
import routesHappen from 'pages/Happens/routes'
import routesHappenMuseum from 'pages/EducationMuseum/routes'
import routesHappenHome from 'pages/EducationHome/routes'
import routesNews from 'pages/News/routes'
import routesExhib from 'pages/Exhibitions/routes'
import routesPasaz from 'pages/Pasazs/routes'
import routesSobie from 'pages/Sobiesciana/routes'

const useStyles = makeStyles({
  title: {
    marginLeft: 0,
    textDecoration: 'none',
    color: 'inherit',
  },
})

export const LinkTitleCell = ({
  resource,
  accessor,
  // isRowLinkable = false,
  // editPath = null,
}) => {
  const classes = useStyles()

  const getRouteBytype = () => {
    if (
      typeof resource.editable !== 'undefined' &&
      resource.editable === false
    ) {
      return ''
    }

    switch (resource['@type']) {
      case 'Page':
        // public const PAGE_TYPE_NEWS = 'news';
        // public const PAGE_TYPE_PROJECT = 'project';
        // public const PAGE_TYPE_DEFAULT = 'page';
        if (resource.type === 'page') {
          return routesPage().edit.path.replace(':id', resource.uuid)
        }
        if (resource.type === 'project') {
          return routesProject().edit.path.replace(':id', resource.uuid)
        }
        if (resource.type === 'news') {
          return routesNews().edit.path.replace(':id', resource.uuid)
        }
        break
      case 'Happen':
        // public const HAPPEN_TYPE_EDUCATIONMUSEUM = 'educationMuseum';
        // public const HAPPEN_TYPE_EDUCATIONHOME = 'educationHome';
        // public const HAPPEN_TYPE_DEFAULT = 'happen';
        if (resource.type === 'happen') {
          return routesHappen().edit.path.replace(':id', resource.uuid)
        }
        if (resource.type === 'educationHome') {
          return routesHappenHome().edit.path.replace(':id', resource.uuid)
        }
        if (resource.type === 'educationMuseum') {
          return routesHappenMuseum().edit.path.replace(':id', resource.uuid)
        }
        break
      case 'Exhibition':
        return routesExhib().edit.path.replace(':id', resource.uuid)
      case 'Pasaz':
        return routesPasaz().edit.path.replace(':id', resource.uuid)
      case 'Bip':
        return routesBip().edit.path.replace(':id', resource.uuid)
      case 'Sobiesciana':
        return routesSobie().edit.path.replace(':id', resource.uuid)

      default:
        return ''
    }
    return ''
  }

  return (
    <Link to={getRouteBytype} className={classes.title}>
      {prop(resource, accessor)}
    </Link>
  )
}
