import React from 'react'
import { Route } from 'react-router-dom'
import PlaylistAddOutlinedIcon from '@material-ui/icons/PlaylistAddOutlined'
import { Collection } from './Collection'
import { SchemableComponent } from 'core/components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { addRoutesConfig } from 'core/_helpers/addRoutesConfig'
import schema from '_schema/educationCategory'

const basePath = '/happen_category'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: PlaylistAddOutlinedIcon,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        path: `${basePath}/new`,
        exact: true,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: `${basePath}/:id`,
        exact: true,
      },
    },
    {
      title: 'Education category happen',
      resourceAccess: schema.endpointHappenCategory,
      indexPath: basePath,
    }
  )

export default routes
