
const schema = {
  endpoint: '/api/formset_saves',
  resource: {
    definition: 'FormsetSave-formset.read',
    properties: {


      dataForm:{
        type: 'datetime',
      },

      stat: {},
    },
  },
}

export default schema
