import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles({
  repeatInput: {
    marginTop: 8,
    marginBottom: 12,
  },
})

export const PasswordRepeatType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  fullWidth = false,
  classes = {},
}) => {
  const [repeat, setRepeat] = useState({
    value: null,
    error: false,
  })

  const handleChange = {
    password: e => {
      const passwordValue = getValue(e.target.value)
      setValue(name, passwordValue || null)
      validateField(passwordValue, repeat.value)
    },
    repeat: e => {
      const repeatValue = getValue(e.target.value)
      setRepeat(state => ({ ...state, value: repeatValue || null }))
      validateField(value, repeatValue)
    },
  }

  const validateField = useCallback(
    (passwordValue, repeatValue) => {
      const isPasswordValid = validate(validators, passwordValue)

      setError(name, !isPasswordValid.result && isPasswordValid.message)

      const isRepeatValid = {
        result: passwordValue === repeatValue,
        message:
          passwordValue === repeatValue ||
          'T_VALIDATION_PASSWORDS_DO_NOT_MATCH',
      }

      setRepeat(state => ({
        ...state,
        error: !isRepeatValid.result && isRepeatValid.message,
      }))

      setError(name + 'Repeat', !isRepeatValid.result && isRepeatValid.message)
    },
    [validators, setError, name, setRepeat]
  )

  useEffect(() => {
    validateField(initialValue, null)
  }, [validateField, initialValue])

  const defaultClasses = useStyles()

  return (
    <>
      <TextField
        name={name}
        type="password"
        label={translate(label.text || label) + ' *'}
        value={value || ''}
        disabled={disabled}
        onChange={handleChange.password}
        error={renderError && !!error}
        helperText={translate(renderError && error ? error : hint.origin)}
        fullWidth={fullWidth}
      />
      <div className={clsx(defaultClasses.repeatInput, classes.repeatInput)}>
        <TextField
          name={name + 'Repeat'}
          type="password"
          label={translate((label.text || label) + '_REPEAT') + ' *'}
          value={repeat.value || ''}
          disabled={disabled}
          onChange={handleChange.repeat}
          error={renderError && !!repeat.error}
          helperText={translate(
            renderError && repeat.error ? repeat.error : hint.repeat
          )}
          fullWidth={fullWidth}
        />
      </div>
    </>
  )
}

const getValue = value => (value === '' ? null : value)

PasswordRepeatType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.shape({
    origin: PropTypes.string,
    repeat: PropTypes.string,
  }),
  initialValue: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  classes: PropTypes.shape({
    repeatInput: PropTypes.string,
  }),
}
