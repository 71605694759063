import React from 'react'
import { default as SwitchMode } from '@material-ui/core/Switch'
import { FormGroup, FormControlLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useThemeConfig } from 'core/components/ThemeProvider'
import { Paper } from 'core/components/Paper'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles({
  label: {
    fontSize: '1.0em',
  },
})

export const Settings = () => {
  const themeConfig = useThemeConfig()

  const classes = useStyles()

  return (
    <Paper>
      <FormGroup>
        <FormControlLabel
          control={
            <SwitchMode
              checked={themeConfig.dark}
              onChange={() => themeConfig.toggle()}
              color="primary"
              aria-label="switch mode"
            />
          }
          label={translate(
            'T_SETTINGS_MODE_' + (themeConfig.dark ? 'LIGHT' : 'DARK')
          )}
          classes={{ label: classes.label }}
        />
      </FormGroup>
    </Paper>
  )
}
