import _block from 'pages/EducationMuseum/_block'
import photoSchema from 'core/_schema/photo'
import fileSchema from 'core/_schema/file'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { schema as schematag } from '_schema/tagEntry'
import { iksorisTicketField } from './_iksoris'
import schemaplace from '_schema/place'

const schema = {
  endpoint: '/api/happens',
  endpointResourceAccess: '/api/education_museums',
  resource: {
    definition: 'Happen-happen.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          subtitle: {},
          shortLead: {
            type: 'textarea',
          },
          text: {
            type: 'tinymce',
          },
          termin: {
            hint: 'gdy brak harmonogramu można wypełnić',
          },
          hours: {},
          conductor: {},
          ticketPriceString: {},
          material: { type: 'tinymce' },
          remember: { type: 'tinymce' },
          photoAlt: {},
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {
            type: 'slug',
            routes: {
              pl: '/wydarzenie/:slug',
              en: '/en/event/:slug',
            },
          },
          preview: {
            label: 'Podgląd',
            description:'',
            type: 'preview',
            routes: {
              pl: '/wydarzenie/:slug',
              en: '/en/event/:slug',
            },
          },
        },
      },

      dataRange: {
        type: 'dateRange',
        hint: 'ustaw daty aby dało się wyszukiwać wydarzenie'
      },

      tags: {
        type: 'embeddedcollection',
        endpoint: '/api/happen_tags',
        definition: 'HappenTag-happenTag.read',
        description: 'T_HAPPEN_TAGS',
        additionalProperties: {
          $ref: '#/definitions/HappenTag-happenTag.write_happenTag.create',
        },

        titleAccessor: function(resource, handleSuccess) {
          if (resource['@titleAccessor']) return resource['@titleAccessor']
          const setResource = res => {
            resource['@titleAccessor'] =
              res.translations && res.translations[process.env.REACT_APP_LOCALE]
                ? (res.translations[process.env.REACT_APP_LOCALE].title ?? '--')
                : '--'
            if (handleSuccess) {
              handleSuccess(resource)
            }
          }
          if (resource.tag) {
            fetchDataHandleAuthError(resource.tag, 'GET', null, setResource)
          } else {
            return '--'
          }
        },

        properties: {
          tag: {
            type: 'resourcetag',
            endpoint: `/api/tag_entries?pagination=false`,
            titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.titleWithName`, //'uuid',
            storeCollectionId: schematag.extraStoreCollectionId,
            label: 'T_TAG_ENTRY',
          },
          stat: {},
        },
      },

      // type: {
      //   type: 'media[type]',
      // },
      // youtube: {
      //   type: 'media[youtube]',
      //   description: 'T_GENERAL_YOUTUBE_CODE',
      // },
      photo: {
        type: 'media[image]',
        description: 'T_GENERAL_MEDIA_PHOTO',
        endpoint: photoSchema.endpoint.single,
        endpointYoutube: photoSchema.endpoint.youtube,
        thumbs: {
          endpoint: '/api/happen_thumbs',
        },
        validate: ['maxSize'],
        ytProperty: 'youtube',
        ofmEnable: true
      },

      // iksorisEventId: {
      //   defaultValue: 0,
      //   hint: 'jeśli wpisany, bedzie otwierac zakup indywidualny biletu',
      // },
      // iksorisTopicId: {
      //   defaultValue: 0,
      //   hint: 'jeśli wpisany, bedzie otwierac zakup grupowy biletu',
      // },
      // iksorisTermId: {
      //   defaultValue: 0,
      //   hint: 'jesli wpisany, bedzie odrazu wybany w kroku 2 zakupu biletu',
      // },
      iksoris: iksorisTicketField,

      happenTarget: {
        type: 'choice',
        choices: {
          zwiedzac: 'Zwiedzać pałac, ogrody',
          natura: 'Spędzić czas na łonie natury',
          lekcje: 'Wziąć udział w warsztatach, lekcjach, wykładach',
          dzieci: 'Spędzić czas z dziećmi',
          // wycieczka: 'Zorganizować wycieczkę', //is off https://mantis.openform.pl/view.php?id=24926
        },
        label: 'Co chesz robić ?',
      },

      // isPl: {},
      // isEn: {},
      // isUa: {},
      // isPjm: {},
      // isOther: {},
      // inPrice: {},
      // forFree: {},
      // audiodesc: {},
      // moveaccess: {},
      // knowledge: {},
      // architect: {},
      // easytext: {},
      // aac: {},
      // induction: {},
      // bigtext: {},

      languageArray: {
        type: 'multichoice',
        choices: {
          en: 'En',
          pl: 'Pl',
          ua: 'Ua',
          pjm: 'PJM',
          inne: 'Inne',
        },
      },

      accessArray: {
        type: 'multichoice',
        choices: {
          audiodesc: 'Audiodeskrypcja',
          moveaccess: 'Dostępność ruchowa',
          knowledge: 'Dostępność intelektualna',
          easytext: 'Łatwy tekst',
          aac: 'AAC',
          induction: 'Pętla indukcyjna',
          bigtext: 'Powiększona czcionka',
          tyflo: 'Tyflografiki',
          inPrice: 'Oferta w cenie',
          forFree: 'Bezplatne',
        },
      },

      ageFrom: {
        type: 'integer',
        minValue: 0,
      },
      ageTo: {
        type: 'integer',
        minValue: 0,
      },

      place: {
        type: 'resource',
        endpoint: `/api/places?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
        label: 'Place',
        storeCollectionId: schemaplace.extraStoreCollectionId,
      },
      meetingPlace: {
        type: 'resource',
        endpoint: `/api/places?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
        label: 'Place of meet',
        storeCollectionId: schemaplace.extraStoreCollectionId,
      },

      showInShopBanner: {},
      showInDziejeSie: {},
      showInCalendariumList: {
        hint: 'musi mieć datę lub zakres dat lub powtórzenia'
      },

      harmonograms: {
        type: 'embeddedcollection',
        endpoint: '/api/happens',
        definition: 'Happen-happen.read',
        description: 'Harmonogram',
        statable: true,
        additionalProperties: {
          $ref: '#/definitions/Happen-happen.write_happen.create',
        },

        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',

        properties: {
          translations: {
            type: 'translation',
            properties: {
              title: {},
              text: { type: 'textarea' },
              conductor: {},
              termin: {
                label: 'Data',
                hint: 'gdy brak daty z zakresu dat można wypełnić np, "codziennie"',
              },
              hours: {},
              ticketPriceString: {},
              remember: { type: 'tinymce' },
            },
          },
          dataRange: {
            type: 'dateRange',
            hint: 'ustaw datę/zakresy dat jeśli chcesz wyświetlić informację "+ X terminów"'
          },
          // eventDate: {
          //   type: 'date',
          //   label: 'Data',
          //   validate: ['required'],
          // },
          // publishDate: {
          //   type: 'date',
          //   validate: ['required'],
          // },
          place: {
            type: 'resource',
            endpoint: `/api/places?pagination=false`,
            titleAccessor: `titlePa`,// `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
            label: 'Place',
            storeCollectionId: schemaplace.extraStoreCollectionId,
          },
          meetingPlace: {
            type: 'resource',
            endpoint: `/api/places?pagination=false`,
            titleAccessor: `titlePa`,// `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
            label: 'Place of meet',
            storeCollectionId: schemaplace.extraStoreCollectionId,
          },
          tags: {
            type: 'collection',
            endpoint: '/api/happen_tags',
            definition: 'HappenTag-happenTag.read',
            description: 'Tagi harmonogramu',
            additionalProperties: {
              $ref: '#/definitions/HappenTag-happenTag.write_happenTag.create',
            },

            titleAccessor: function(resource, handleSuccess) {
              if (resource['@titleAccessor']) return resource['@titleAccessor']
              const setResource = res => {
                resource['@titleAccessor'] =
                  res.translations &&
                  res.translations[process.env.REACT_APP_LOCALE]
                    ? (res.translations[process.env.REACT_APP_LOCALE].title ?? '--')
                    : '--'
                if (handleSuccess) {
                  handleSuccess(resource)
                }
              }
              if (resource.tag) {
                fetchDataHandleAuthError(resource.tag, 'GET', null, setResource)
              } else {
                return '--'
              }
            },

            properties: {
              tag: {
                type: 'resourcetag',
                endpoint: `/api/tag_entries?pagination=false`,
                titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.titleWithName`, //'uuid',
                storeCollectionId: schematag.extraStoreCollectionId,
                label: 'T_TAG_ENTRY',
              },
              stat: {},
            },
          },
          // iksorisEventId:{
          //   defaultValue: 0,
          //   hint: 'jeśli wpisany, bedzie otwierac zakup indywidualny biletu'
          // },
          // iksorisTopicId:{
          //   defaultValue: 0,
          //   hint: 'jeśli wpisany, bedzie otwierac zakup grupowy biletu'
          // },
          // iksorisTermId:{
          //   defaultValue: 0,
          //   hint: 'jesli wpisany, bedzie odrazu wybany w kroku 2 zakupu biletu'
          // },
          iksoris: iksorisTicketField,
          // stat: {},
        },
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/happen_blocks?pagination=false',
      definition: 'HappenBlock-happenBlock.read',
      types: _block(
        photoSchema.endpoint.single,
        '/api/happen_block_thumbs',
        fileSchema.endpoint.single,
        {
          endpoint: '/api/happen_block_elements',
          definition: 'HappenBlockElement-happenBlockElement.read',
          ref:'#/definitions/HappenBlockElement-happenBlockElement.write_happenBlockElement.create',
        }
      ).types,
    },
  },
}

export default schema
