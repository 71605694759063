import { authConstants, collectionConstants } from 'core/_constants'

export const collectionReducer = (state = {}, action) => {
  switch (action.type) {
    case collectionConstants.SET_CONFIG:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload.config,
        },
      }
    case collectionConstants.RESET_CONFIG:
      return {
        ...state,
        [action.payload.id]: null,
      }
    case authConstants.REFRESH_FAILURE:
    case authConstants.LOGOUT:
      return {}
    default:
      return state
  }
}
