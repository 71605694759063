import React from 'react'
import { Route } from 'react-router-dom'
import { Chat } from '@material-ui/icons'
import { SchemableComponent } from 'core/components/SchemableComponent'
import { Collection } from './Collection'
import { Show } from './Show'
import { addRoutesConfig } from 'core/_helpers/addRoutesConfig'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: '/messenger',
        exact: true,
        icon: Chat,
      },
      show: {
        subtitle: 'T_MODULE_MESSENGER_THREAD',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Show} {...props} />
        ),
        path: '/messenger/:id/show',
        exact: true,
      },
    },
    {
      title: 'T_MODULE_MESSENGER',
      indexPath: '/messenger',
    }
  )

export default routes
