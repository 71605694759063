import React, { useState } from 'react'
import { Menu, MenuItem, IconButton } from '@material-ui/core'
import { MoreVertOutlined } from '@material-ui/icons'
import { makeStyles, withStyles  } from '@material-ui/core/styles'
import { EditLink } from 'core/components/resource/EditLink'
import { DeleteCell } from 'core/components/table/cells/DeleteCell'
import { StatusCell } from 'core/components/table/cells/StatusCell'
import { CloneCell } from 'core/components/table/cells/CloneCell'
import { Timestamp } from 'core/components/resource/Timestamp'
import { translate } from 'core/_helpers/translate'
import { useSelector } from 'react-redux'
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined'
import { Link } from 'react-router-dom'
import routes from './../routes'

import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItem: {
    fontSize: 14,
    '& svg': {
      fontSize: 20,
    },
  },
  listItem: {
    minWidth: 32,
  },
  menuTrigger: {
    borderRadius: 4,
    padding: 7,
  },
  menuTriggerOpen: {
    border: '1px solid #d3d4d5',
    borderBottomColor: '#fff',
    backgroundColor: '#fff',

    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  icon: {
    color: '#666', //theme.palette.success.main,
    height: 24
  },
}))

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',

    borderTopRightRadius: 0
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

export const operations = (
  accessor = `translations.${process.env.REACT_APP_LOCALE}.title`,
  editable = true,
  deletable = true,
  statable = true,
  timestampable = true,
  cloneable = false
) => ({
  header: translate('T_GENERAL_OPERATIONS'),
  accessor,
  sortable: false,
  filterable: false,
  Cell: ({
    resource,
    accessor,
    tableState,
    tableStateDispatch,
    editPath = null,
  }) => {
    const classes = useStyles()
    const [menuAnchor, setMenuAnchor] = useState(null)
    const profile = useSelector(state => state.profile)

    const handleMenu = e => {
      setMenuAnchor(e.currentTarget)
    }

    const handleClose = () => {
      setMenuAnchor(null)
    }

    return (
      <div className={classes.root}>
        <Link to={routes().costsIndex.path.replace(':pid',resource[process.env.REACT_APP_RESOURCE_ID] ) }
            className={classes.icon}
          >
          <MonetizationOnOutlinedIcon />
        </Link>
        {(editable || cloneable || deletable) && (
          <>
            <IconButton
              aria-label="record menu"
              aria-controls="record-menu"
              aria-haspopup="true"
              onClick={handleMenu}
              className={clsx( classes.menuTrigger,
                menuAnchor && classes.menuTriggerOpen
              )}
            >
              <MoreVertOutlined color="inherit" />
            </IconButton>
            <StyledMenu
              id="record-menu"
              anchorEl={menuAnchor}
              keepMounted
              open={!!menuAnchor}
              onClose={handleClose}
            >
              {editable && (
                <MenuItem className={classes.menuItem}>
                  <EditLink
                    resource={resource}
                    accessor={accessor}
                    editPath={editPath}
                  />
                </MenuItem>
              )}
              {cloneable && (
                <MenuItem className={classes.menuItem}>
                  <CloneCell
                    resource={resource}
                    accessor={accessor}
                    tableStateDispatch={tableStateDispatch}
                  />
                </MenuItem>
              )}
              {deletable && (
                <MenuItem className={classes.menuItem}>
                  <DeleteCell
                    resource={resource}
                    accessor={accessor}
                    tableState={tableState}
                    tableStateDispatch={tableStateDispatch}
                  />
                </MenuItem>
              )}
            </StyledMenu>
          </>
        )}
        {statable && profile.isModerator && (
          <StatusCell
            resource={resource}
            tableStateDispatch={tableStateDispatch}
          />
        )}
        {timestampable && <Timestamp resource={resource} />}
      </div>
    )
  },
})
