export const textVisit = () => ({
  name: 'textVisit',
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_VISIT',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
          hint: 'T_GENERAL_BLOCK_TYPE_TEXT_TITLE_HINT',
        },
      },
    },
    anchor: {},
  },
})
