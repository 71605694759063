import React, { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Paper } from 'core/components/Paper'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { operations } from './table/columns/operations'
import { translate } from 'core/_helpers/translate'
import { publish } from 'core/components/table/_mass'
import schema from 'core/_schema/project'
import routes from './routes'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import HomeIcon from '@material-ui/icons/Home'
import Link from '@material-ui/core/Link'
import { Link as LinkRoute } from 'react-router-dom'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { Loader } from 'core/components/Loader'
import moment from 'moment'
import { ProjectParentFilter } from 'core/components/table/filters/ProjectParentFilter'

export const Collection = ({
  wrapWithPaper = true,
  showNewButton = true,
  ...rest
}) => {
  const profile = useSelector(state => state.profile)

  //const { pid } = queryString.parse(rest.location.search)
  const pid = rest.match.params.id

  const columns = useMemo(
    () => [
      {
        header: translate('Title'),
        accessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        filterName: 'translations.title',
        filterable: true,
        sortable: false,
        sorterName: 'translations.title',
        // Cell: MainCell,
        width: '73%',
      },

      {
        ...operations(
          `translations.${process.env.REACT_APP_LOCALE}.title`,
          true,
          true,
          profile.isModerator ? true : false,
          true,
          true
        ),
        width: '15%',
      },
    ],
    [profile.isModerator]
  )

  const defaultFilters = pid
    ? {
        'exists[versionable]': false,
        'exists[parent]': true,
        pagination: true,
        'parent.uuid': pid,
        pageType: 'project',
      }
    : {
        'exists[versionable]': false,
        'exists[parent]': false,
        pagination: true,
        pageType: 'project',
      }

  const [orderableBlock, setblockOrderable] = useState(false)
  const blockOrderable = bul => setblockOrderable(bul)
  const customFilters = [
    {
      name: 'donothing',
      title: 'szukaj wszędzie',
      value: false,
      extraparams: { pid: pid, blockOrderable: blockOrderable },
      Filter: ProjectParentFilter,
    },
  ]

  const customReset = () => setblockOrderable(false)
  const WrapComponent = wrapWithPaper ? Paper : 'div'
  const collectionId = 'projects'

  const [breadcrumbs, setBreadcrumbs] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  useEffect(
    () => {
      setBreadcrumbs([])

      const controller = new AbortController()
      const { signal } = controller

      const endpoint = `/api/pages/breadcrumbs`

      if (pid) {
        setIsFetching(true)
        fetchDataHandleAuthError(
          `${endpoint}?bread=${pid}&type=project`,
          'GET',
          { signal },
          response => {
            setIsFetching(false)
            if (response['hydra:member']) {
              setBreadcrumbs(response['hydra:member'])
            }
          },
          error => {
            setIsFetching(false)
            if (error.response.title === 'AbortError') {
              return
            }
            notification(
              'error',
              error.response.detail ?? 'Error',
              error.response.title ?? null
            )
          }
        )
      }

      return () => controller.abort()
    },
    // eslint-disable-next-line
    [pid]
  )

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

  return (
    <WrapComponent key={pid}>
      <CollectionHeader
        buttonTitle={`Dodaj nowy ${
          breadcrumbs[breadcrumbs.length - 1]
            ? `do "${truncateString(
                breadcrumbs[breadcrumbs.length - 1].title,
                13
              )}"`
            : ''
        }`}
        buttonPath={
          pid ? routes().newParent.path.replace(':pid', pid) : routes().new.path
        }
        hideButton={!showNewButton}
      >
        {translate('Lista projektów')}
      </CollectionHeader>

      {isFetching ? (
        <Loader />
      ) : (
        <div>
          <Link component={LinkRoute} to={routes().index.path}>
            <HomeIcon color="primary" style={{ verticalAlign: 'middle' }} />
          </Link>
          {breadcrumbs.map(breadcrumb => {
            return (
              <Link
                component={LinkRoute}
                to={routes().indexParent.path.replace(':id', breadcrumb.uuid)}
              >
                <KeyboardArrowRightIcon
                  size="small"
                  fontSize="small"
                  style={{ verticalAlign: 'middle' }}
                />
                <span style={{ verticalAlign: 'middle' }}>
                  {breadcrumb.title}
                </span>
              </Link>
            )
          })}
        </div>
      )}

      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultFilters={defaultFilters}
        defaultSorters={{ ord: 'desc' }}
        selectable={[publish.on(), publish.off()]}
        isRowSelectable={resource => profile.isOpenform || resource.isStatable}
        editPath={routes().edit.path}
        autoWidth={false}
        customFilters={customFilters}
        orderable={orderableBlock ? false : true}
        isRowLinkable={true}
        customReset={customReset}
        storeCollectionId={`${collectionId}-${pid}-${moment().format(
          'DD.MM.YYYY HH:mm:ss'
        )}`}
      />
    </WrapComponent>
  )
}

Collection.propTypes = {
  wrapWithPaper: PropTypes.bool,
  showNewButton: PropTypes.bool,
}
