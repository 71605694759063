import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import randomHash from 'random-hash'
import {
  FormControl,
  TextField,
  FormHelperText,
  Popper,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'
import { prop } from 'core/_helpers/prop'

import Dialog from '@material-ui/core/Dialog'
import { createFilterOptions } from '@material-ui/lab/Autocomplete'

import { Form } from 'core/pages'
import schema from '_schema/tagEntry'
import { useSelector } from 'react-redux'

import { store } from 'core/_store'
import { collectionConstants } from 'core/_constants'

const filter = createFilterOptions()

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
    marginTop: 10,
  },
  formControllFullWidth: {
    minWidth: '100%',
  },
  autocomplete: {
    minWidth: 260,
  },
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}))

export const ResourceTagType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  endpoint = null,
  resources = null,
  titleAccessor,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  fullWidth = false,
  storeCollectionId = null
}) => {
  const mainschema = useSelector(state => state.schema)

  const [id] = useState(randomHash())

  const [options, setOptions] = useState([])
  const [choices, setChoices] = useState(resources || [])
  const [xxTriger, setXXTriger] = useState(null)
  const [schemadynamic, setSchema] = React.useState(schema.resource)

  const handleChange = (e, option) => {
    if (typeof option === 'string') {
      // timeout to avoid instant validation of the dialog's form.
      setTimeout(() => {
        toggleOpen(true)
        setDialogValue({
          title: option,
        })
        schema.resource.properties.translations.properties.title.defaultValue = option
        setSchema(schema.resource)
      })
    } else if (option && option.inputValue) {
      toggleOpen(true)
      setDialogValue({
        title: option.inputValue,
      })
      schema.resource.properties.translations.properties.title.defaultValue =
        option.inputValue
      setSchema(schema.resource)
    } else {
      setValue(name, option ? option.value : null)
      validateField(option ? option.value : null)
    }
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  useEffect(() => {
    if (!endpoint || resources) {
      return
    }

    const tt = 150 + parseInt(Math.random() * 5000, 10) * (storeCollectionId ? 1 : 0)

    setTimeout(function() {
      // store.dispatch({ type: collectionConstants.RESET_CONFIG, payload: {id : storeCollectionId} })
      if (
        storeCollectionId &&
        store.getState().collection[storeCollectionId]?.items
      ) {
        const s = store.getState().collection[storeCollectionId].items
        setChoices(s)
      } else {
        const controller = new AbortController()
        const { signal } = controller

        fetchDataHandleAuthError(
          endpoint,
          'GET',
          { signal },
          response => {
            setChoices(response['hydra:member'])

            const data = {
              id: storeCollectionId,
              config: { items: response['hydra:member'] },
            }

            store.dispatch({
              type: collectionConstants.SET_CONFIG,
              payload: data,
            })
          },
          error => {
            if (error.response.title === 'AbortError') {
              return
            }

            notification('error', error.response.detail, error.response.title)
          }
        )
        return () => controller.abort()
      }
    }, tt)
  }, [endpoint, resources, setChoices, storeCollectionId, xxTriger])


  useEffect(() => {
    const opt = choices?.map(resource => ({
      value: resource['@id'],
      title: prop(resource, titleAccessor, translate('T_GENERAL_MISSING_TITLE')),
    }))
    setOptions(opt)
  }, [choices, titleAccessor])

  const classes = useStyles()

  const [open, toggleOpen] = React.useState(false)

  const handleClose = () => {
    setDialogValue({
      title: '',
    })

    toggleOpen(false)
  }

  const handleCancel = e => {
    e.preventDefault()

    setDialogValue({
      title: '',
    })

    toggleOpen(false)

    return
  }

  const [dialogValue, setDialogValue] = React.useState({
    title: '',
  })

  const handleSubmit = obj => {
    //usuniecie listy w selekcie
    setChoices([])
    //usuniecie kesza
    if(storeCollectionId) {store.dispatch({ type: collectionConstants.RESET_CONFIG, payload: {id : storeCollectionId} })}
    //trigger useEffect, pobiera nową listę
    setXXTriger(dialogValue.title)
    //zamyka dialog okno
    handleClose()
    //ustawia w selekcie aby bylo wybrane to co zapisal w oknie userr
    setValue(name, obj ? obj['@id'] : null)
  }

  return (
    <FormControl
      className={clsx(
        classes.formControl,
        fullWidth && classes.formControllFullWidth
      )}
      error={renderError && !!error}
    >
      <Autocomplete
        id={id}
        name={name}
        options={options}
        getOptionLabel={option => option.title}
        onChange={handleChange}
        value={
          getValue(value)
            ? {
                value: getValue(value),
                title:
                  options.find(option => option.value === getValue(value))
                    ?.title || '',
              }
            : null
        }
        getOptionSelected= {option => option.value}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              title: `Dodaj "${params.inputValue}"`,
            })
          }

          return filtered
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={
              translate(label.text || label) +
              (validators && validators.includes('required') ? ' *' : '')
            }
            variant="outlined"
          />
        )}
        PopperComponent={props => (
          <Popper
            {...props}
            style={{ width: 'auto' }}
            placement="bottom-start"
          />
        )}
        disabled={disabled || !choices.length}
        classes={{ root: classes.autocomplete }}
      />
      <FormHelperText>
        {translate(renderError && error ? error : hint)}
      </FormHelperText>
      {compare && (
        <div
          className={clsx(
            classes.compare,
            getValue(value) !== getValue(compareValue) && classes.compareNeq
          )}
        >
          {compareValue &&
            options.find(option => option.value === getValue(compareValue))
              ?.title}
        </div>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Form
          definitionSchema={mainschema.definitions[schema.resource.definition]}
          customResourceSchema={schemadynamic}
          method="POST"
          url={schema.endpoint}
          collectionPath={null}
          editPath={null}
          storeCollectionId={schema.endpoint}
          wrapWithPaper={false}
          width="100%"
          handleSuccess={handleSubmit}
          handleCancel={handleCancel}
          showCancelButton={true}
          readOnly={false}
          showSubmitAndStayButton={false}
          buttonsFixed={false}
          isCurrentResource={false}
          paddingBottom={20}
        />
      </Dialog>
    </FormControl>
  )
}

const getValue = value => {
  if (!value) {
    return null
  }

  if (typeof value === 'string') {
    return value
  }

  return value['@id']
}

ResourceTagType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
    }),
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
    }),
  ]),
  compareValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
    }),
  ]),
  compare: PropTypes.bool,
  endpoint: PropTypes.string,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
    })
  ),
  titleAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
}
