import 'date-fns'
import React, { useState, useEffect } from 'react'
// import clsx from 'clsx'
import randomHash from 'random-hash'
import {
  TextField,
  Button,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Collapse,
  Paper,
  Radio,
  IconButton,
  FormHelperText,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'
import { Loader } from 'core/components/Loader'
import DeleteIcon from '@material-ui/icons/Delete'
import { translate } from 'core/_helpers/translate'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import plLocale from 'date-fns/locale/pl'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'

const LOCALES = {
  pl: plLocale,
}

const FORMAT = 'yyyy-MM-dd'

const DATE_PICKER_ERRORS = [
  translate('T_GENERAL_INVALID_DATE'),
  translate('T_GENERAL_INVALID_MIN_DATE'),
  translate('T_GENERAL_INVALID_MAX)DATE'),
  'Wpisz datę',
]

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
    marginTop: 10,
  },
  formControllFullWidth: {
    minWidth: '100%',
  },
  autocomplete: {
    minWidth: 260,
  },
  compare: {
    marginTop: 5,
  },
  listFieldBtn: {
    padding: 2,
  },
  textFieldDate: {
    display: 'inline-block',
    width: 'calc(50% - 10px)',
    minWidth: 112,
    marginRight: 10,
    verticalAlign: 'top',
  },
  span08: { fontSize: '0.8em' },
}))

export const DateRangeType = ({
  name,
  value,
  label,
  endpoint = null,
  resources = null,
  error = false,
  renderError = false,
  disabled = false,
  fullWidth = false,
  validators,
  setValue,
  getValue,
  setError,
  titleAccessor,
  hint = null,
}) => {
  const defaultValue = {
    include: [
      // { from: '2021-06-25', to: '2021-06-27', days: null },
      // { from: '2024-01-29', to: '2024-01-31', days: 'wt' },
    ],
    exclude: [],
    repeats: [
      // {from, type, days}
    ],
  }

  const defaultRange = {
    pn: false,
    wt: false,
    sr: false,
    cz: false,
    pt: false,
    so: false,
    nd: false,
    dateTo: null,
    dateFrom: null,
    dateFromError: false,
  }
  const defaultRepeat = {
    type: null,
    numDays: null,
    dateFrom: null,
    dateFromError: false,
    dateTypeError: false,
  }

  const [id1] = useState(randomHash())
  const [id2] = useState(randomHash())
  const [id3] = useState(randomHash())

  const [show, setShow] = useState(false)
  const [isFetching, setFetching] = useState(true)
  const [mainJson, setMainJson] = useState(defaultValue)
  const [range, setRange] = useState(defaultRange)
  const [repeat, setRepeat] = useState(defaultRepeat)

  const classes = useStyles()
  const days = [
    { n: 'pn', val: 'pn' },
    { n: 'wt', val: 'wt' },
    { n: 'śr', val: 'sr' },
    { n: 'cz', val: 'cz' },
    { n: 'pt', val: 'pt' },
    { n: 'so', val: 'so' },
    { n: 'nd', val: 'nd' },
  ]
  const daysRepeat = [
    { n: 'dzień', val: 'day' },
    { n: 'tydzień', val: 'week' },
    { n: 'miesiąc', val: 'month' },
    { n: 'dni', val: 'days' },
  ]

  const handleRangeDateChange = (name, value) => {
    const date = value ? moment(value).format(FORMAT.toUpperCase()) : null
    let error = date === 'Invalid date'
    setRange({
      ...range,
      [name]: date,
      dateFromError: error,
    })
  }
  const handleRangeOutChange = (name, value) => {
    setRange({
      ...range,
      [name]: value.target.checked,
    })
  }
  const handleRepeatDateChange = (name, value) => {
    const date = value ? moment(value).format(FORMAT.toUpperCase()) : null
    let error = date === 'Invalid date'
    setRepeat({
      ...repeat,
      [name]: date,
      dateFromError: error,
    })
  }
  const handleRepeatTypeChange = value => {
    setRepeat({
      ...repeat,
      type: value,
      dateTypeError: false,
    })
  }
  const handleRepeatNumChange = value => {
    setRepeat({
      ...repeat,
      numDays: value,
    })
  }

  const handleClick = () => {
    setShow(!show)
  }

  const handleAddRangeClick = () => {
    if (!range.dateFrom) {
      setRange({
        ...range,
        dateFromError: true,
      })
      return
    }

    let newItem = {
      from: range.dateFrom,
      to: range.dateTo,
      days: days
        .filter(i => {
          return range[i.val] === true
        })
        .map(i => {
          return `${i.val}`
        })
        .join('|'),
    }
    let newInclude = mainJson.include ?? []
    newInclude.push(newItem)
    setMainJson({
      ...mainJson,
      include: newInclude,
    })
    setRange(defaultRange)
    setValue(name, JSON.stringify(mainJson))
  }

  const handleAddRepeatClick = () => {
    if (!repeat.dateFrom) {
      setRepeat({
        ...repeat,
        dateFromError: true,
      })
      return
    }
    if (!repeat.type) {
      setRepeat({
        ...repeat,
        dateTypeError: true,
      })
      return
    }

    let newItem = {
      from: repeat.dateFrom,
      type: repeat.type,
      numDays: repeat.numDays,
    }
    let newRepeats = mainJson.repeats ?? []
    newRepeats.push(newItem)
    setMainJson({
      ...mainJson,
      repeats: newRepeats,
    })
    setRepeat(defaultRepeat)
    setValue(name, JSON.stringify(mainJson))
  }

  const handleDeleteRepeat = key => {
    let arr = mainJson.repeats ?? []
    if (arr.length && arr[key]) {
      arr.splice(key, 1)
      setMainJson({
        ...mainJson,
        repeats: arr,
      })
      setValue(name, JSON.stringify(mainJson))
    }
  }

  const handleDeleteRange = key => {
    let arr = mainJson.include ?? []
    if (arr.length && arr[key]) {
      arr.splice(key, 1)
      setMainJson({
        ...mainJson,
        include: arr,
      })
      setValue(name, JSON.stringify(mainJson))
    }
  }

  useEffect(
    () => {
      if (show) {
        setFetching(true)
        //do something
        setMainJson(value ? JSON.parse(value) : defaultValue)
        setFetching(false)
      }
    },
    // eslint-disable-next-line
    [resources, show]
  )

  return (
    <>
      <Button
        onClick={handleClick}
        variant="outlined"
        color="primary"
        size="small"
      >
        {show ? 'Ukryj wybór daty' : 'Pokaż wybór daty'}
      </Button>
      <br />
      {/* {show ? ( */}
      <Collapse in={show}>
        <Paper
          elevation={4}
          style={{ backgroundColor: '#e9ecf0', padding: 10 }}
        >
          <div>Zakresy</div>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              {isFetching ? (
                <Loader style={{ paddingTop: '10px' }} />
              ) : (
                <form noValidate autoComplete="off">
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={LOCALES[process.env.REACT_APP_LOCALE]}
                  >
                    {/* <TextField
                    id="dateFrom"
                    label="od"
                    type="date"
                    defaultValue="" //2017-05-24
                    onChange={e => handleDateChange('dateFrom', e)}
                    className={classes.textFieldDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    id="dateTo"
                    label="do"
                    type="date"
                    defaultValue=""
                    onChange={e => handleDateChange('dateTo', e)}
                    className={classes.textFieldDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  */}
                    <div className={classes.textFieldDate}>
                      <KeyboardDatePicker
                        id={id1}
                        name="dateFrom"
                        label="od*"
                        value={range.dateFrom}
                        disableToolbar
                        variant="inline"
                        format={FORMAT}
                        margin="normal"
                        minDate={false}
                        autoOk={true}
                        invalidDateMessage={DATE_PICKER_ERRORS[0]}
                        minDateMessage={DATE_PICKER_ERRORS[1]}
                        maxDateMessage={DATE_PICKER_ERRORS[2]}
                        onChange={e => handleRangeDateChange('dateFrom', e)}
                        error={range.dateFromError}
                      />
                      <FormHelperText>
                        <span className={classes.span08}>
                          np. wybór daty 2024-02-12 oznacza początek dnia
                          2024-02-12 od godz.00:00:00
                        </span>
                      </FormHelperText>
                    </div>
                    <div className={classes.textFieldDate}>
                      <KeyboardDatePicker
                        id={id2}
                        name="dateTo"
                        label="do"
                        value={range.dateTo}
                        disableToolbar
                        variant="inline"
                        format={FORMAT}
                        margin="normal"
                        minDate={false}
                        autoOk={true}
                        invalidDateMessage={DATE_PICKER_ERRORS[0]}
                        minDateMessage={DATE_PICKER_ERRORS[1]}
                        maxDateMessage={DATE_PICKER_ERRORS[2]}
                        onChange={e => handleRangeDateChange('dateTo', e)}
                      />
                      <FormHelperText>
                        <span className={classes.span08}>
                          np. wybór daty 2024-02-12 zawiera cały dzień
                          2024-02-12 do godz.23:59:59
                        </span>
                      </FormHelperText>
                    </div>
                  </MuiPickersUtilsProvider>

                  <div>
                    <br />
                    bez:
                  </div>
                  <FormGroup row>
                    {days.map(item => {
                      return (
                        <FormControlLabel
                          key={`${id1}${item.val}_label`}
                          control={
                            <Checkbox
                              key={`${id1}${item.val}_checkbox`}
                              checked={range[item.val]}
                              onChange={e => handleRangeOutChange(item.val, e)}
                              name="dayType"
                              value={item.val}
                            />
                          }
                          label={item.n}
                        />
                      )
                    })}
                  </FormGroup>
                  <Button
                    onClick={e => handleAddRangeClick()}
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<AddCircleOutline />}
                  >
                    dodaj zakres
                  </Button>
                </form>
              )}
            </Grid>

            <Grid item xs={6}>
              {mainJson.include
                ? mainJson.include.map((item, key) => {
                    return (
                      <li key={`${id1}${key}_list`}>
                        {item.from}
                        {item.to ? ` - ${item.to} ` : ' '}
                        {item.days ? item.days : ''}
                        <IconButton
                          aria-label="delete"
                          className={classes.listFieldBtn}
                          onClick={e => handleDeleteRange(key)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </li>
                    )
                  })
                : null}
            </Grid>
          </Grid>

          <hr />

          <div style={{ paddingTop: 10 }}>Powtórzenia</div>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              {isFetching ? (
                <Loader style={{ paddingTop: '10px' }} />
              ) : (
                <>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={LOCALES[process.env.REACT_APP_LOCALE]}
                  >
                    <KeyboardDatePicker
                      id={id3}
                      name="dateFromRepeat"
                      label="od*"
                      value={repeat.dateFrom}
                      className={classes.textFieldDate}
                      disableToolbar
                      variant="inline"
                      format={FORMAT}
                      margin="normal"
                      minDate={false}
                      autoOk={true}
                      invalidDateMessage={DATE_PICKER_ERRORS[0]}
                      minDateMessage={DATE_PICKER_ERRORS[1]}
                      maxDateMessage={DATE_PICKER_ERRORS[2]}
                      onChange={e => handleRepeatDateChange('dateFrom', e)}
                      error={repeat.dateFromError}
                    />
                  </MuiPickersUtilsProvider>
                  <div>
                    <br />
                    co*:
                  </div>

                  <FormGroup row>
                    {daysRepeat.map(item => {
                      if (item.n !== 'dni') {
                        return (
                          <FormControlLabel
                            key={`${id1}${item.val}_label`}
                            control={
                              <Radio
                                key={`${id1}${item.val}_radio`}
                                checked={repeat.type === item.val}
                                onChange={e => handleRepeatTypeChange(item.val)}
                                name="repeatType"
                                value={item.val}
                              />
                            }
                            label={item.n}
                          />
                        )
                      } else {
                        return (
                          <>
                            <FormControlLabel
                              key={`${id1}${item.val}_label`}
                              control={
                                <Radio
                                  key={`${id1}${item.val}_radio`}
                                  checked={repeat.type === item.val}
                                  onChange={e =>
                                    handleRepeatTypeChange(item.val)
                                  }
                                  name="repeatType"
                                  value={item.val}
                                />
                              }
                              label={item.n}
                            />
                            <TextField
                              type="number"
                              min={0}
                              InputProps={{
                                inputProps: { min: 0, max: 1000 },
                              }}
                              name="numDays"
                              onChange={e =>
                                handleRepeatNumChange(e.target.value)
                              }
                              style={{ width: 35 }}
                              value={repeat.numDays ?? 0}
                            />
                          </>
                        )
                      }
                    })}
                  </FormGroup>

                  {repeat.dateTypeError && (
                    <FormHelperText error={true}>
                      Wybierz przedział co ile!
                      <br />
                      <br />
                    </FormHelperText>
                  )}

                  <Button
                    onClick={e => handleAddRepeatClick()}
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<AddCircleOutline />}
                  >
                    dodaj powtórzenie
                  </Button>
                </>
              )}
            </Grid>
            <Grid item xs={6}>
              {mainJson.repeats
                ? mainJson.repeats.map((item, key) => {
                    return (
                      <li key={`${id1}${key}_list_repeat`}>
                        {`${item.from} `}
                        {item.type
                          ? daysRepeat
                              .filter(i => {
                                return i.val === item.type
                              })
                              .map(i => i.n)
                          : ' '}
                        {item.numDays ? `(${item.numDays})` : ''}
                        <IconButton
                          key={`${id1}${key}_list_repeat_btn`}
                          aria-label="delete"
                          className={classes.listFieldBtn}
                          onClick={e => handleDeleteRepeat(key)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </li>
                    )
                  })
                : null}
            </Grid>
          </Grid>
        </Paper>
      </Collapse>
      {/* ) : null} */}
      <FormHelperText>{hint}</FormHelperText>
    </>
  )
}

DateRangeType.propTypes = {}
