import React from 'react'
import { prop } from 'core/_helpers/prop'
// import { translate } from 'core/_helpers/translate'
import { OrderStatus } from 'core/components/form/fields/other/OrderStatus'
import { OrderStatusResend } from 'core/components/form/fields/other/OrderStatusResend'
import { Grid } from '@material-ui/core'
import { OrderBack } from 'core/components/form/fields/other/OrderBack'

export const StatusCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
  tableStateDispatch,
}) => {
  const setNothing = function() {
    return false
  }

  return (
    <Grid container>
      {/* {translate(options2[prop(resource, accessor)])} */}
      <OrderStatus
        uuid={resource.partUuid}
        value={prop(resource, accessor)}
        colon={''}
        setValue={setNothing}
        btnSize="small"
      />
      <Grid container style={{ flexWrap: 'nowrap' }}>
        <OrderStatusResend
          uuid={resource.partUuid}
          value={prop(resource, accessor)}
          colon={''}
          setValue={setNothing}
          btnSize="small"
        />
        <OrderBack
          uuid={resource.partUuid}
          value={prop(resource, accessor)}
          colon={''}
          setValue={setNothing}
          btnSize="small"
          disabled={resource.isReturnedToStorage}
          tableStateDispatch={tableStateDispatch}
        />
      </Grid>
    </Grid>
  )
}
