import React, { useMemo, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { TextField, Popper, Button } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { Paper } from 'core/components/Paper'
import { Title } from 'core/components/Title'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { RecipientCell } from './table/RecipientCell'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { translate } from 'core/_helpers/translate'
import schema from 'core/_schema/messenger'
import routes from './routes'

const useStyles = makeStyles({
  title: {
    marginBottom: 30,
  },
  recipient: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 30,
  },
  autocomplete: {
    display: 'inline-block',
    minWidth: 250,
    margin: '0 15px',
  },
  cell: {
    padding: 0,
  },
})

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        header: translate('T_MODULE_MESSENGER'),
        filterable: false,
        sortable: false,
        width: '100%',
        Cell: RecipientCell,
      },
    ],
    []
  )

  const profile = useSelector(state => state.profile)

  const [state, setState] = useState({
    admins: [],
    recipient: null,
  })

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      `${schema.endpoint.admins}?order[fullName]=asc&pagination=false`,
      'GET',
      { signal },
      response => {
        setState(state => ({
          ...state,
          admins: response['hydra:member'].filter(
            admin =>
              admin[process.env.REACT_APP_RESOURCE_ID] !==
              profile[process.env.REACT_APP_RESOURCE_ID]
          ),
        }))
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [setState, profile])

  const history = useHistory()

  const handleRecipientChange = (e, option) => {
    setState(state => ({
      ...state,
      recipient: option,
    }))
  }

  const handleRecipientChoice = () => {
    if (!state.recipient) {
      return
    }

    history.push(
      routes().show.path.replace(
        ':id',
        state.recipient[process.env.REACT_APP_RESOURCE_ID]
      )
    )
  }

  const classes = useStyles()

  return (
    <Paper>
      <Title classes={{ root: classes.title }}>
        {translate(routes().index.title)}
      </Title>
      <div className={classes.recipient}>
        <div>{translate('T_MODULE_MESSENGER_SEND_TO')}</div>
        <Autocomplete
          options={state.admins}
          getOptionLabel={admin => admin.fullName}
          onChange={handleRecipientChange}
          value={state.recipient}
          renderInput={params => (
            <TextField
              {...params}
              label={translate('T_MODULE_MESSENGER_RECIPIENT')}
              variant="outlined"
              size="small"
            />
          )}
          PopperComponent={props => (
            <Popper
              {...props}
              style={{ width: 'auto' }}
              placement="bottom-start"
            />
          )}
          classes={{ root: classes.autocomplete }}
        />
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleRecipientChoice}
          disabled={!state.recipient}
        >
          {translate('T_MODULE_MESSENGER_GO_FURTHER')}
        </Button>
      </div>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint.threads}.get`}
        endpoint={schema.endpoint.threads}
        columns={columns}
        isRowLinkable={false}
        isRowKeyTimestamped={false}
        editPath={routes().show.path}
        paginable={false}
        resetable={false}
        numerable={false}
        autoWidth={false}
        classes={{ cell: classes.cell }}
      />
    </Paper>
  )
}
