export const modifyBlockByPage = (blockTypes, resource) => {

  // let modifiedProperties = Object.assign({}, blockTypes)

  switch (resource.idName) {
    // Modify by page here
    case 'page_search':
    case 'page_shop':
    case 'page_crew':
      return []
    default:
      return blockTypes
      // modifiedProperties = blockTypes.filter(elem => elem.name === 'text' || elem.name === 'textCrew' || elem.name === 'packageFile')
      // return modifiedProperties
  }
}
