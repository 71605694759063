import React from 'react'
import { makeStyles } from '@material-ui/styles'
// import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles({
  image: {
    display: 'flex',
    alignItems: 'center',
  },
  imgContainer: {
    width: 200,
  },
  img: {
    maxHeight: 100,
    width: '100%',
    height: 'auto',
  },
  title: {},
  icon: {
    float: 'right',
    marginLeft: '0.50em',
  },
})

export const MainCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
}) => {

  const classes = useStyles()

  return (
    <div className={classes.title}>{resource.generate ? 'Tak' : 'Nie'}</div>
  )
}

MainCell.propTypes = {}
