import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Paper } from 'core/components/Paper'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from './table/CollectionTable'
import { translate } from 'core/_helpers/translate'
import { publish } from 'core/components/table/_mass'
import schema from 'core/_schema/page'
import routes from './routes'

export const Collection = ({ wrapWithPaper = true, showNewButton = true }) => {
  const profile = useSelector(state => state.profile)

  const defaultFilters = {
    'exists[versionable]': false,
    'exists[parent]': false,
    'pagination': false,
    'pageType': 'page'
  }

  const customFilters = [
    // {name: 'pageType', title: 'page type', value: 'page'}
  ]

  const WrapComponent = wrapWithPaper ? Paper : 'div'

  let isStatable = (resource) => {
    if(profile.isOpenform) { return true }
    if(resource.isStatable && profile.isModerator ) { return true }
    return false
  }

  return (
    <WrapComponent>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_PAGES_NEW_BUTTON')}
        buttonPath={routes().new.path}
        hideButton={!showNewButton}
      >
        {translate('T_MODULE_PAGES')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        customResourceSchema={schema.resource}
        defaultFilters={defaultFilters}
        defaultSorters={{ ord: 'desc' }}
        selectable={[publish.on(), publish.off()]}
        isRowSelectable={resource => isStatable(resource) }
        editPath={routes().edit.path}
        autoWidth={false}
        customFilters={customFilters}
      />
    </WrapComponent>
  )
}

Collection.propTypes = {
  wrapWithPaper: PropTypes.bool,
  showNewButton: PropTypes.bool,
}
