import { authHeader } from './authHeader'
import { handleResponse as defaultHandleResponse } from './handleResponse'

export const fetchData = (
  url,
  method = 'GET',
  headers = {},
  request = {},
  handleResponse = null,
  absoluteUrl = false
) => {
  const isFormData = headers['Content-Type'] === 'multipart/form-data'
  delete headers['Content-Type']

  return fetch(
    absoluteUrl ? url : `${process.env.REACT_APP_API_ENTRYPOINT}${url}`,
    {
      method,
      headers: {
        accept: 'application/ld+json',
        ...(isFormData
          ? {}
          : {
              'Content-Type':
                method === 'PATCH'
                  ? 'application/merge-patch+json'
                  : 'application/ld+json',
            }),
        ...headers,
        ...authHeader(),
      },
      ...request,
    }
  ).then(handleResponse || defaultHandleResponse)
}
