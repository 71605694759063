export const addOpenformProperties = (
  isOpenform,
  properties,
  resource,
  schema
) => {
  let modifiedProperties = JSON.parse(JSON.stringify(properties))

  if (resource && !resource.finished) {
    delete modifiedProperties.restart
  }

  if (isOpenform) {
    return {
      ...modifiedProperties,
      recipientsCount: {
        type: 'number',
        description: {
          text: '[OF] Odbiorców',
          color: '#cc0000',
        },
      },
      sentCount: {
        type: 'number',
        description: {
          text: '[OF] Wysłanych',
          color: '#cc0000',
        },
      },
      finished: {
        type: 'boolean',
        description: {
          text: '[OF] Zakończone',
          color: '#cc0000',
        },
      },
    }
  }

  if (resource && !resource.isStatable) {
    delete modifiedProperties.stat
  }

  return modifiedProperties
}
