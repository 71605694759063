export const processNestedToFlatList = (nested, toggled) => {
  const flat = []

  addPage(flat, nested, toggled)

  return flat
}

const addPage = (flat, pages, toggled) => {
  for (const page of pages) {
    flat.push(page)

    if (!page.children?.length || toggled[page['@id']]) {
      continue
    }

    addPage(flat, page.children, toggled)
  }
}
