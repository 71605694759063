import React from 'react'
import { Form } from 'core/pages'
import schema from '_schema/orderEmail'
import routes from './routes'
import { Info } from './_helpers/_info'
import { useSelector } from 'react-redux'
import { addOpenformProperties } from './_helpers/addOpenformProperties'

export const New = ({ schema: definitions }) => {

  const profile = useSelector(state => state.profile)

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      method="POST"
      url={schema.endpoint}
      collectionPath={routes().index.path}
      editPath={routes().edit.path}
      storeCollectionId={schema.endpoint}
      width="100%"
      children={ <Info/> }
      modifyProperties={(properties, resource, schema) => {
        return addOpenformProperties(
          profile.isOpenform,
          properties,
          resource,
          schema
        )
      }}
    />
)}
