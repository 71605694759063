import React, { useState, useEffect } from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Paper } from 'core/components/Paper'
import { Title } from 'core/components/Title'
import { Loader } from 'core/components/Loader'
import { ActiveDot, PersistedMessage, NewMessage } from './components'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { translate } from 'core/_helpers/translate'
import { useMessageCollectionFetch } from './_helpers/useMessageCollectionFetch'
import { constants, reducer } from './_state'
import schema from 'core/_schema/messenger'
import adminSchema from 'core/_schema/admin'
import config from 'core/pages/Messenger/_helpers/config'

const useStyles = makeStyles({
  title: {
    marginBottom: 30,
  },
  container: {
    width: 400,
  },
  messages: {
    marginBottom: 20,
  },
})

export const Show = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const [recipient, setRecipient] = useState(null)

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      `${schema.endpoint.admins}/${match.params.id}`,
      'GET',
      { signal },
      response => {
        setRecipient(response)
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [match.params.id, setRecipient])

  const [state, dispatch] = useMessageCollectionFetch(
    reducer,
    schema.endpoint.messages,
    match.params.id,
    config.MESSAGE_PER_PAGE
  )

  const handleMore = () => {
    dispatch({ type: constants.MOVE_CURRENT_POINTER })
  }

  const classes = useStyles()

  return (
    <Paper>
      <Title classes={{ root: classes.title }}>
        {translate('T_MODULE_MESSENGER_THREAD')}{' '}
        {recipient ? (
          <>
            {`${translate('T_MODULE_MESSENGER_WITH')} ${recipient.fullName}`}
            <ActiveDot recipient={recipient} />
          </>
        ) : (
          ''
        )}
      </Title>
      <div className={classes.container}>
        {(state.init || state.isFetching) && (
          <Loader className={classes.loader} />
        )}
        {!state.isFetching && state.moreItems && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleMore}
            disabled={state.isFetching}
          >
            {translate('T_MODULE_MESSENGER_MORE')}
          </Button>
        )}
        <div className={classes.messages}>
          {state.items.map(item => (
            <PersistedMessage
              message={item}
              dispatch={dispatch}
              key={item['@id']}
            />
          ))}
        </div>
        <NewMessage
          endpoint={schema.endpoint.messages}
          recipient={`${adminSchema.endpoint}/${match.params.id}`}
          dispatch={dispatch}
          disabled={state.init || state.isFetching}
        />
      </div>
    </Paper>
  )
}
