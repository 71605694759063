import PropTypes from 'prop-types'

export const blockCollectionPropTypes = {
  endpoint: PropTypes.string.isRequired,
  pid: PropTypes.string.isRequired,
  parentIri: PropTypes.string.isRequired,
  definitionSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  headerTitle: PropTypes.string,
  expandTitle: PropTypes.string,
  titleAccessor: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  statable: PropTypes.string,
  statAccessor: PropTypes.string,
  contextualHint: PropTypes.string,
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    header: PropTypes.string,
    massExpand: PropTypes.string,
    addContainer: PropTypes.string,
    collectionContainer: PropTypes.string,
    cell: PropTypes.string,
    expansionPanelDetails: PropTypes.string,
    emptyResults: PropTypes.string,
    draggedRow: PropTypes.string,
    contextualHint: PropTypes.string,
    expansionPanelSummary: PropTypes.string,
    blockTitle: PropTypes.string,
    blockIcon: PropTypes.string,
    expandIcon: PropTypes.string,
  }),
}
