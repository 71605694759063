import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { translate } from 'core/_helpers/translate'
import schema from './searchAll'
import { LinkTitleCell } from './_cells/LinkTitleCell'
import { TypeBadgeCell } from './_cells/TypeBadgeCell'

export const Collection = ({ defaultFilters, giveMeDispatch }) => {
  const columns = useMemo(
    () => [
      {
        header: translate('Title'),
        accessor: `title`,
        filterName: 'translations.title',
        filterable: false,
        Cell: LinkTitleCell,
        width: '73%',
      },
      {
        header: translate('Typ'),
        accessor: `@type`,
        filterable: false,
        Cell: TypeBadgeCell,
        width: '10%',
      },

      // { ...operations(`translations.${process.env.REACT_APP_LOCALE}.title`, true, true, (profile.isModerator ? true : false), true, true), width: '15%' },
    ],
    []
  )

  // const defaultFilters = {
  //   'exists[versionable]': false,
  //   'exists[parent]': false,
  //   'happenType': 'happen',
  // }

  const customFilters = [
    // { name: 'showInDziejeSie', title: 'baner Dzieje się', value: null, Filter: YesNoFilter },
    // { name: 'showInShopBanner', title: 'baner Sklepu', value: null, Filter: YesNoFilter },
  ]

  return (
    <div>
      <SchemableCollection
        component={CollectionTable}
        resetable={false}
        sortable={false}
        filterable={false}
        numerable={true}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultFilters={defaultFilters}
        isRowSelectable={true}
        editPath={null}
        autoWidth={false}
        customFilters={customFilters}
        orderable={false}
        isRowLinkable={true}
        storeCollectionId={`search_in_panel_p`}
        giveMeDispatch={giveMeDispatch}
      />
    </div>
  )
}

Collection.propTypes = {
  wrapWithPaper: PropTypes.bool,
  showNewButton: PropTypes.bool,
}
