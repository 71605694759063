import React from 'react'
import { Form } from 'core/pages'
import { useSelector } from 'react-redux'
import { SidebarNotStatable } from 'core/components/resource/sidebar'
import { modifyPropertiesByPage } from './_helpers/modifyPropertiesByPage'
import { addOpenformProperties } from './_helpers/addOpenformProperties'
import schema from '_schema/residenceItem'
import routes from './routes'

export const ResidenceitemEdit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`
  const profile = useSelector(state => state.profile)

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().residenceitemsIndex.path.replace(
        ':pid',
        match.params.pid
      )}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor={`translations.${process.env.REACT_APP_LOCALE}.title`}
      SidebarComponent={SidebarNotStatable}
      width="100%"
      modifyProperties={(properties, resource, schema) => {
        let modifiedProperties = modifyPropertiesByPage(
          properties,
          resource,
          schema
        )
        return addOpenformProperties(
          profile.isOpenform,
          modifiedProperties,
          resource,
          schema
        )
      }}
    />
  )
}
