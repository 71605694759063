import React, { useState, useCallback } from 'react'
import { Loader } from 'core/components/Loader'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { translate } from 'core/_helpers/translate'
import { makeStyles } from '@material-ui/styles'

import {
  AppBar,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  // ImageList, jest ok jesli mamy np 2 kolumny itp tu sie nie nada
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Typography,
  alpha,
  Breadcrumbs,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import GetAppIcon from '@material-ui/icons/GetApp'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import SearchIcon from '@material-ui/icons/Search'
import FolderIcon from '@material-ui/icons/FolderOpenOutlined'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import CreateNewFolderOutlinedIcon from '@material-ui/icons/CreateNewFolderOutlined'
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined'
import ReorderOutlinedIcon from '@material-ui/icons/ReorderOutlined'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import PermMediaIcon from '@material-ui/icons/PermMedia'
import UploadIcon from '@material-ui/icons/Backup'
import FolderSheetIcon from '@material-ui/icons/Folder'
import { OFMUploadDialog } from './OFMUploadDialog'
import { humanFileSize } from './_humanFileSize'
import clsx from 'clsx'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    marginTop: 20,
  },
  dialogTitle: {
    fontWeight: 600,
  },
  search: {
    position: 'absolute',
    right: 0,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.15),
    },
    marginLeft: 0,
    marginRight: '10px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  nestedList: {
    paddingLeft: theme.spacing(2),
  },
  imageDetailsHover: {
    display: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: alpha(theme.palette.common.black, 0.55),
    color: 'black',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& button': {
      margin: 10,
    },
  },
  imageThumb: {
    position: 'relative',
    padding: 5,
    border: '1px solid #ddd',
    borderRadius: 10,
    overflow: 'hidden',
    minWidth: 170,
    '&:hover': {
      opacity: 1,
      '& $imageDetailsHover': {
        display: 'flex',
      },
    },
  },
  containerList: {
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  containerListFIles: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  containerListFIlesGrid: {
    flexGrow: 1,
    minHeight: 100,
    gap: 5,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(170px, 1fr))',
    alignContent: 'start',
  },
  containerListFIlesTable: {
    flexGrow: 1,
    minHeight: 100,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  imageThumbImgContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    aspectRatio: '3 / 2',
  },
  imageThumbImg: {
    width: '100%',
    height: 'auto',
    aspectRatio: '3 / 2',
    objectFit: 'contain',
    objectPosition: 'center',
    minWidth: 150,
  },
  paperDialog: {
    maxWidth: '1000px',
  },
  paperDialgoMd: {
    minHeight: '60vh',
  },
  thumbItemTitle: {
    lineBreak: 'anywhere',
  },
  thumbItemSize: {
    fontSize: '0.8em',
  },
  breadArrow: {
    marginLeft: 0,
    marginRight: 0,
  },
}))

export const OFileManager = ({
  ButtonOpenComponent = null, //customowy komponent guzika otwierajacego fm
  openCallback = null,
  closeCallback = null,
  pickupCallback = null, //zwracamy do tej funkcji wybrany filename
  wantPickUp = true, //czy ma byc guzik wybierz aktywny
  disabled = false,
}) => {
  // czy ma się wyświetlić button 3 kropek z dodatkowymi opcjami do pliku?
  const addOptionsButton = false
  const basePath = '/'
  const classes = useStyles()
  const imagesTypes = ['jpeg', 'png', 'webp', 'jpg', 'gif']
  const [open, setOpen] = useState(false)
  const [fullScreen, setFullScreen] = useState(false)
  const [showGrid, setShowGrid] = useState(false)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [isUploadDialogOpen, setisUploadDialogOpen] = useState(false)
  const [ofmPath, setOfmPath] = useState(basePath)
  const [tree, setTree] = useState([])
  const [opentree, setOpenTree] = useState([])
  const [rows, setRows] = useState([])
  const [listFeczing, setListFeczing] = useState(true)
  const [filesFeczing, setFilesFeczing] = useState(true)

  const handleShowGrid = () => {
    setShowGrid(!showGrid)
  }

  const handleFullScreen = () => {
    setFullScreen(!fullScreen)
  }
  const handleClickOpen = () => {
    if (openCallback) {
      openCallback(open, setOpen)
      return
    }
    setOpen(true)
    refreshList()
    filesList(ofmPath)
  }

  const handleClose = () => {
    if (openCallback) {
      closeCallback(open, setOpen)
      return
    }
    setOpen(false)
  }

  const handlePickUp = name => {
    if (!wantPickUp) return
    setOpen(false)
    if (pickupCallback) {
      pickupCallback(name, ofmPath)
    }
  }

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const makeProperPath = path => {
    const p = path
      .split('/')
      .filter(e => e !== null && e !== '')
      .join('/')
    return `/${p ? p + '/' : ''}`
  }
  // const emptyRows = useCallback(() => {
  //   return rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)
  // }, [rows])

  const headCells = [
    { id: 'name', label: 'Nazwa' },
    { id: 'size', label: 'Rozmiar' },
    { id: 'ext', label: 'Typ' },
    { id: 'date', label: 'Data' },
  ]

  // komponenet sortowanie widoku
  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props
    const createSortHandler = property => event => {
      onRequestSort(event, property)
    }

    return (
      <div>
        <TableRow component="div">
          {headCells.map(headCell => (
            <TableCell
              component="div"
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{ paddingTop: 5, paddingBottom: 5 }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </div>
    )
  }

  // kompnent usun plik
  function DeleteButton({ callbackdelete, filename, path, isDir, ...props }) {
    const [state, setState] = useState({ isConfirmOpen: false })

    const handleConfirmToggle = () => {
      setState(state => ({
        ...state,
        isConfirmOpen: !state.isConfirmOpen,
      }))
    }

    const handleDelete = () => {
      if (callbackdelete) {
        callbackdelete(filename, path, isDir)
      }
      setState(state => ({
        ...state,
        isConfirmOpen: !state.isConfirmOpen,
      }))
    }
    return (
      <>
        <Button {...props} onClick={handleConfirmToggle}>
          <DeleteForeverOutlinedIcon />
        </Button>
        <Dialog open={state.isConfirmOpen} fullWidth={true} maxWidth="sm">
          <DialogTitle onClose={handleConfirmToggle}>
            {translate('T_GENERAL_DELETE')}
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              Usunąć {isDir ? 'folder' : 'plik'} {filename}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmToggle} autoFocus variant="outlined">
              {translate('T_GENERAL_CANCEL')}
            </Button>
            <Button onClick={handleDelete} variant="contained" color="primary">
              {translate('T_GENERAL_CONFIRM')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  // komponent
  function AddFolderButton({ callbackadd, folder, ...props }) {
    const [state, setState] = useState({ isConfirmOpen: false })
    const [name, setName] = useState('')
    const handleChange = event => {
      setName(event.target.value)
    }

    const handleConfirmToggle = () => {
      setState(state => ({
        ...state,
        isConfirmOpen: !state.isConfirmOpen,
      }))
    }

    const handleDelete = () => {
      if (callbackadd) {
        callbackadd(name, folder)
      }
      setState(state => ({
        ...state,
        isConfirmOpen: !state.isConfirmOpen,
      }))
    }
    return (
      <>
        {/* button open */}

        <Button {...props} onClick={handleConfirmToggle}>
          <CreateNewFolderOutlinedIcon />
          <Typography variant="h6" noWrap style={{ marginLeft: 6 }}>
            Dodaj folder
          </Typography>
        </Button>

        {/* dialog with add sht */}
        <Dialog open={state.isConfirmOpen} fullWidth={true} maxWidth="sm">
          <DialogTitle onClose={handleConfirmToggle}>
            {translate('Nowy folder')}
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              Dodawanie folderu w katalogu <strong>{folder}</strong>
            </DialogContentText>
            <TextField
              id="standard-name"
              label="Nazwa katalogu"
              value={name}
              onChange={handleChange}
              maxLength="64"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmToggle} autoFocus variant="outlined">
              {translate('T_GENERAL_CANCEL')}
            </Button>
            <Button onClick={handleDelete} variant="contained" color="primary">
              {translate('T_GENERAL_CONFIRM')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  // komponent zastepujacy domyslny guzik customowym
  function ButtonOpenComponentReplace(props) {
    if (ButtonOpenComponent) {
      return React.createElement(ButtonOpenComponent, props)
    }
    return (
      <IconButton
        color="primary"
        size="medium"
        variant="outlined"
        onClick={props.onClick}
        disabled={props.disabled}
      >
        <PermMediaIcon />
        {/* Open File manager */}
      </IconButton>
    )
  }

  const refreshList = () => {
    setListFeczing(true)
    const controller = new AbortController()
    const { signal } = controller

    const urles = `/api/filemanager/list/tree`
    const data = { path: basePath }

    fetchDataHandleAuthError(
      urles,
      'POST',
      { signal, body: JSON.stringify(data) },
      response => {
        setListFeczing(false)
        setTree(response)
      },
      error => {
        setListFeczing(false)
        if (error.response?.title === 'AbortError') {
          return
        }
        notification('error', error.response.message ?? ' ', 'Błąd')
      }
    )
    return () => controller.abort()
  }

  const filesList = useCallback(path => {
    setFilesFeczing(true)
    const controller = new AbortController()
    const { signal } = controller

    const urles = `/api/filemanager/list/files`
    const data = { path }

    fetchDataHandleAuthError(
      urles,
      'POST',
      { signal, body: JSON.stringify(data) },
      response => {
        setFilesFeczing(false)
        if (response.files.length) {
          setRows(response.files.sort((a, b) => (a.size < b.size ? -1 : 1)))
        } else {
          setRows([])
        }
      },
      error => {
        setFilesFeczing(false)
        if (error.response?.title === 'AbortError') {
          return
        }
        notification('error', error.response.message ?? ' ', 'Błąd')
      }
    )
    return () => controller.abort()
  }, [])

  const treeClick = useCallback(
    path => {
      setOfmPath(path)
      filesList(path)
    },
    [filesList]
  )

  const onUploadOk = httpStstus => {
    if (httpStstus === 200) {
      filesList(ofmPath)
    }
  }

  const expandTree = (level, path) => {
    var existtr =
      opentree[level] && opentree[level].includes(path) ? true : false
    let tr = JSON.parse(JSON.stringify(opentree))
    if (existtr) {
      tr[level] = tr[level].filter(e => e !== path)
      setOpenTree(tr)
    } else {
      if (!tr[level]) {
        tr[level] = []
      }
      tr[level].push(path)
      setOpenTree(tr)
    }
  }

  const makeTree = useCallback(
    (elem, parentPath, level) => {
      return Object.keys(elem).map((path, i) => {
        return (
          <div key={`tree-div-${level}-${i}`} className={classes.nestedList}>
            <ListItem button key={`tree-${level}-${i}`}>
              {Object.keys(elem[path]).length === 0 ? (
                <ExpandLess style={{ opacity: 0 }} />
              ) : opentree[level]?.includes(path) ? (
                <ExpandLess onClick={() => expandTree(level, path)} />
              ) : (
                <ExpandMore onClick={() => expandTree(level, path)} />
              )}
              <ListItemIcon
                style={{ minWidth: 32 }}
                key={`tree-icon-${level}-${i}`}
                onClick={() => treeClick(`${parentPath}${path}`)}
              >
                <FolderIcon />
              </ListItemIcon>
              <ListItemText
                primary={path}
                key={`tree-text-${level}-${i}`}
                onClick={() => treeClick(`${parentPath}${path}`)}
                // style={{ lineBreak: 'anywhere' }}
              />
            </ListItem>

            {path && Object.keys(elem[path]).length > 0 && (
              <Collapse
                in={
                  opentree[level] && opentree[level].includes(path)
                    ? true
                    : false
                }
                timeout="auto"
                unmountOnExit
                key={`tree-collapse-${level}-${i}`}
              >
                <List
                  component="div"
                  disablePadding
                  key={`tree-sub-${level}-${i}`}
                >
                  {makeTree(elem[path], `${parentPath}${path}/`, level + 1)}
                </List>
              </Collapse>
            )}
          </div>
        )
      })
    },
    // eslint-disable-next-line
    [opentree]
  )

  const deleteFile = (file, path, isDir) => {
    const controller = new AbortController()
    const { signal } = controller

    const urles = `/api/filemanager/files/delete`
    const data = { path, file }

    fetchDataHandleAuthError(
      urles,
      'POST',
      { signal, body: JSON.stringify(data) },
      response => {
        if (isDir) refreshList()
        filesList(path)
      },
      error => {
        if (error.response?.title === 'AbortError') {
          return
        }
        notification('error', error.response.message ?? ' ', 'Błąd')
      }
    )
    return () => controller.abort()
  }

  const addFolder = (name, path) => {
    const controller = new AbortController()
    const { signal } = controller

    const urles = `/api/filemanager/list/newfolder`
    const data = { path, name }

    fetchDataHandleAuthError(
      urles,
      'POST',
      { signal, body: JSON.stringify(data) },
      response => {
        refreshList()
        filesList(path)
        notification(
          'success',
          `Folder ${name} dodano poprawnie`,
          'Dodawanie folderu'
        )
      },
      error => {
        if (error.response?.title === 'AbortError') {
          return
        }
        notification('error', error.response.message ?? ' ', 'Błąd')
      }
    )
    return () => controller.abort()
  }

  return (
    <React.Fragment>
      <ButtonOpenComponentReplace
        onClick={handleClickOpen}
        disabled={disabled}
      />

      <Dialog
        open={open}
        fullScreen={fullScreen}
        onClose={handleClose}
        maxWidth="md" //if fullwidth true
        fullWidth={true}
        classes={{
          paper: clsx(
            classes.flexColumn,
            classes.paperDialgoMd,
            !fullScreen ? classes.paperDialog : null
          ),
        }}
      >
        <DialogTitle container="div">
          <Grid item xs={12} container alignItems="center" direction="row">
            <Grid item xs={6}>
              <Typography className={classes.dialogTitle}>
                File manager
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={6}
              wrap="nowrap"
              justifyContent="flex-end"
              alignItems="center"
              direction="row"
            >
              <IconButton onClick={handleFullScreen}>
                <FullscreenIcon />
              </IconButton>

              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <AppBar
          position="static"
          style={{ color: 'black', background: 'none' }}
        >
          <Toolbar style={{ gap: 20 }}>
            <AddFolderButton
              edge="start"
              color="inherit"
              folder={ofmPath}
              callbackadd={addFolder}
            />
            <Button
              onClick={() => setisUploadDialogOpen(!isUploadDialogOpen)}
              edge="start"
              color="inherit"
            >
              {isUploadDialogOpen ? (
                <>
                  <ReorderOutlinedIcon />
                  <Typography variant="h6" noWrap style={{ marginLeft: 6 }}>
                    Wyświetl listę
                  </Typography>
                </>
              ) : (
                <>
                  <UploadIcon />
                  <Typography variant="h6" noWrap style={{ marginLeft: 6 }}>
                    Dodaj pliki
                  </Typography>
                </>
              )}
            </Button>

            {/* // narazie off szukaj */}
            {false && (
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Szukaj…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                />
              </div>
            )}
          </Toolbar>
        </AppBar>

        {/* //kontent */}
        <Grid container style={{ flexGrow: 1 }}>
          {/* lista */}
          <Grid item xs={3} style={{ paddingRight: 5 }}>
            {listFeczing ? (
              <Grid
                container
                justifyContent="center"
                alignContent="center"
                alignItems="center"
                style={{ padding: 20 }}
              >
                <Loader />
              </Grid>
            ) : (
              <List
                component="nav"
                style={{ width: '100%', overflowX: 'hidden' }}
              >
                <ListItem button key={`tree-${0}-${0}-main`}>
                  <ListItemIcon
                    style={{ minWidth: 32 }}
                    key={`tree-icon-${0}-${0}`}
                  >
                    <FolderIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Folder główny'}
                    key={`tree-text-${0}-${0}`}
                    onClick={() => treeClick(basePath)}
                  />
                </ListItem>
                {tree.map(elem => {
                  return makeTree(elem, basePath, 1)
                })}
              </List>
            )}
          </Grid>

          {/* // pliki */}
          <Grid item xs={9} className={classes.flexColumn}>
            <Toolbar style={{ display: 'flex', paddingLeft: 0 }}>
              <Breadcrumbs
                aria-label="breadcrumb"
                maxItems={4}
                itemsAfterCollapse={3}
                itemsBeforeCollapse={1}
                classes={{ separator: classes.breadArrow }}
              >
                <FolderIcon
                  style={{ marginRight: '10px', cursor: 'pointer' }}
                  onClick={() => treeClick(basePath)}
                />
                {ofmPath
                  .split('/')
                  .slice(1)
                  .map((bread, k) => {
                    return (
                      <div key={`bread-${k}`}>
                        <Button
                          onClick={() =>
                            treeClick(
                              `${ofmPath
                                .split('/')
                                .slice(0, k + 2)
                                .join('/')}`
                            )
                          }
                        >
                          {bread}
                        </Button>
                      </div>
                    )
                  })}
              </Breadcrumbs>
              <IconButton
                style={{ position: 'absolute', right: '10px' }}
                onClick={handleShowGrid}
              >
                {showGrid ? <ReorderOutlinedIcon /> : <AppsOutlinedIcon />}
              </IconButton>
            </Toolbar>

            <div className={classes.containerList}>
              {isUploadDialogOpen ? (
                <OFMUploadDialog
                  ofmPath={ofmPath}
                  callbackUploadFinish={onUploadOk}
                />
              ) : (
                <TableContainer className={classes.containerListFIles}>
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />

                  {showGrid && (
                    <div
                      style={{ paddingTop: 25, paddingBottom: 25 }}
                      className={classes.containerListFIlesGrid}
                    >
                      {filesFeczing ? (
                        <Grid
                          container
                          justifyContent="center"
                          alignContent="center"
                          alignItems="center"
                          style={{ padding: 20 }}
                        >
                          <Loader />
                        </Grid>
                      ) : (
                        stableSort(rows, getComparator(order, orderBy))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((file, k) => {
                            return (
                              <div
                                key={file.name}
                                className={classes.imageThumb}
                              >
                                <div key={file.name}>
                                  <div
                                    className={classes.imageThumbImgContainer}
                                    key={file.name}
                                  >
                                    {file.thumb &&
                                    imagesTypes.includes(
                                      file.name
                                        .split('.')
                                        .pop()
                                        .toLowerCase()
                                    ) ? (
                                      <img
                                        alt=""
                                        className={classes.imageThumbImg}
                                        src={`${process.env.REACT_APP_API_ENTRYPOINT}${file.thumb}`}
                                        // src={`https://picsum.photos/150/${100 + (k % 10) * 10}?random=${file.name}`}
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          width: 150,
                                          textAlign: 'center',
                                        }}
                                        className={classes.imageThumbImg}
                                      >
                                        {/* folder czy plik inny niz obrazek ? */}
                                        {file.f === true ? (
                                          <FolderSheetIcon
                                            style={{
                                              width: 100,
                                              height: 'auto',
                                            }}
                                          />
                                        ) : (
                                          <DescriptionOutlinedIcon
                                            style={{
                                              width: 100,
                                              height: 'auto',
                                            }}
                                          />
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  <Grid
                                    container
                                    justifyContent="space-between"
                                  >
                                    <Grid item xs={12}>
                                      <div className={classes.thumbItemTitle}>
                                        {file.name}
                                      </div>
                                      <div className={classes.thumbItemSize}>
                                        {imagesTypes.includes(
                                          file.name
                                            .split('.')
                                            .pop()
                                            .toLowerCase()
                                        ) &&
                                        file.w &&
                                        file.h
                                          ? `${file.w}x${file.h}`
                                          : null}
                                      </div>
                                      <div className={classes.thumbItemSize}>
                                        {file.size > 0 &&
                                          humanFileSize(file.size)}
                                      </div>
                                    </Grid>
                                  </Grid>

                                  <div className={classes.imageDetailsHover}>
                                    <Grid
                                      container
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      {wantPickUp && file.f === false && (
                                        <Grid
                                          item
                                          container
                                          xs={12}
                                          direction="column"
                                          justifyContent="center"
                                          alignItems="stretch"
                                        >
                                          <Button
                                            startIcon={<CheckOutlinedIcon />}
                                            color={'inherit'}
                                            size="small"
                                            variant="contained"
                                            onClick={() =>
                                              handlePickUp(file.name)
                                            }
                                          >
                                            <span>wybierz</span>
                                          </Button>
                                        </Grid>
                                      )}

                                      <Grid
                                        item
                                        container
                                        xs={12}
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="stretch"
                                      >
                                        {file.f === true ? (
                                          <Button
                                            startIcon={<FolderSheetIcon />}
                                            color={'inherit'}
                                            size="small"
                                            variant="contained"
                                            onClick={() =>
                                              treeClick(
                                                `${makeProperPath(ofmPath)}${
                                                  file.name
                                                }`
                                              )
                                            }
                                          >
                                            <span>przejdź</span>
                                          </Button>
                                        ) : (
                                          <Button
                                            startIcon={<GetAppIcon />}
                                            color={'inherit'}
                                            size="small"
                                            variant="contained"
                                            component={'a'}
                                            style={{
                                              width: 'calc(100% - 20px)',
                                              margin: 10,
                                            }}
                                            href={`${
                                              process.env
                                                .REACT_APP_API_ENTRYPOINT
                                            }/uploads/filemanager${
                                              ofmPath !== '/' ? ofmPath : ''
                                            }/${file.name}`}
                                            target="_blank"
                                            download={file.name}
                                          >
                                            <span>pobierz</span>
                                          </Button>
                                        )}
                                      </Grid>

                                      <Grid
                                        item
                                        container
                                        xs={12}
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        {addOptionsButton && (
                                          <Button
                                            variant="contained"
                                            size={'small'}
                                          >
                                            <MoreVertOutlinedIcon />
                                          </Button>
                                        )}
                                        <DeleteButton
                                          variant="contained"
                                          filename={file.name}
                                          path={ofmPath}
                                          isDir={file.f}
                                          size={'small'}
                                          callbackdelete={deleteFile}
                                        />
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                      )}
                    </div>
                  )}

                  {!showGrid && (
                    <div className={classes.containerListFIlesTable}>
                      <Table>
                        <TableBody style={{ height: 'auto' }}>
                          {filesFeczing ? (
                            <Grid
                              container
                              justifyContent="center"
                              alignContent="center"
                              alignItems="center"
                              style={{ padding: 20 }}
                            >
                              <Loader />
                            </Grid>
                          ) : (
                            stableSort(rows, getComparator(order, orderBy))
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map(file => (
                                <TableRow key={file.name}>
                                  <TableCell component="th" scope="row">
                                    <span className={classes.thumbItemTitle}>
                                      {file.name}
                                    </span>
                                  </TableCell>
                                  <TableCell
                                    style={{ width: 160 }}
                                    align="right"
                                  >
                                    {file.f === true ? (
                                      <Button
                                        style={{ minWidth: 10 }}
                                        onClick={() =>
                                          treeClick(
                                            `${makeProperPath(ofmPath)}${
                                              file.name
                                            }`
                                          )
                                        }
                                      >
                                        <FolderSheetIcon />
                                      </Button>
                                    ) : (
                                      <>
                                        <span className={classes.thumbItemSize}>
                                          {humanFileSize(file.size)}
                                        </span>
                                        <Button
                                          style={{ minWidth: 10 }}
                                          component={'a'}
                                          href={`${
                                            process.env.REACT_APP_API_ENTRYPOINT
                                          }/uploads/filemanager${
                                            ofmPath !== '/' ? ofmPath : ''
                                          }/${file.name}`}
                                          target="_blank"
                                          download={file.name}
                                        >
                                          <GetAppIcon />
                                        </Button>
                                      </>
                                    )}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width:
                                        wantPickUp && addOptionsButton
                                          ? 154
                                          : wantPickUp || addOptionsButton
                                          ? 114
                                          : 74,
                                    }}
                                  >
                                    <Grid container>
                                      {wantPickUp && file.f === false ? (
                                        <Button
                                          style={{ minWidth: 10 }}
                                          onClick={() =>
                                            handlePickUp(file.name)
                                          }
                                        >
                                          <CheckOutlinedIcon />
                                        </Button>
                                      ) : (
                                        wantPickUp && (
                                          <div style={{ minWidth: 40 }}></div>
                                        )
                                      )}
                                      <DeleteButton
                                        style={{ minWidth: 10 }}
                                        filename={file.name}
                                        path={ofmPath}
                                        isDir={file.f}
                                        callbackdelete={deleteFile}
                                      />
                                      {addOptionsButton && (
                                        <Button style={{ minWidth: 10 }}>
                                          <MoreVertOutlinedIcon />
                                        </Button>
                                      )}
                                    </Grid>
                                  </TableCell>
                                </TableRow>
                              ))
                          )}

                          {/*{emptyRows > 0 && !filesFeczing && (*/}
                          {/*  <TableRow style={{ height: 53 * emptyRows }}>*/}
                          {/*    <TableCell colSpan={6} />*/}
                          {/*  </TableRow>*/}
                          {/*)}*/}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                  <Table className={classes.table}>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          labelRowsPerPage={'Pokaż'}
                          rowsPerPageOptions={[
                            5,
                            10,
                            25,
                            {
                              label: 'Wszystkie',
                              value: rows.length > 0 ? rows.length : -1,
                            },
                          ]}
                          colSpan={3}
                          count={rows.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          // ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              )}
            </div>
          </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  )
}
