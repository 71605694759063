import React from 'react'
// import PropTypes from 'prop-types'
import { Loader } from 'core/components/Loader'
import { makeStyles } from '@material-ui/styles'
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined'
import { notification } from 'core/_helpers/notification'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { Link } from 'react-router-dom'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import routesPage from 'core/pages/Pages/routes'
import routesExh from 'pages/Exhibitions/routes'
import routesHappen from 'pages/Happens/routes'
import routesHappenEdu from 'pages/EducationMuseum/routes'
import routesHappenEduhome from 'pages/EducationHome/routes'

const useStyles = makeStyles({
  imgContainer: {
    width: 200,
  },
  img: {
    maxHeight: 100,
    width: '100%',
    height: 'auto',
  },
  listTitle: {
    marginTop: 7,
  },
})

export const WhereIs = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
  uri = null,
}) => {
  const [open, setOpen] = React.useState(false)
  const [isFeczing, setIsFeczing] = React.useState(false)

  const [resPages, setPages] = React.useState(null)

  const onClickedCloseDialog = () => {
    setOpen(!open)
  }
  const onClick = () => {
    setOpen(true)
    setIsFeczing(true)

    //fetch ...........
    fetchDataHandleAuthError(
      uri,
      'POST',
      { body: JSON.stringify({ uuid: resource.uuid }) }, //'2023-05-01'
      statusChangeSucces,
      statusChangeError
    )
  }

  const statusChangeSucces = res => {
    setIsFeczing(false)
    if (res) {
      setPages(res)
    } else {
      setPages(null)
    }
    // notification('success', 'Tworzenie przebiegło pomyślnie.', 'T_SYNC_SUCCESS')
  }
  const statusChangeError = res => {
    setIsFeczing(false)
    notification('error', `Pobieranie listy nie powiodło się.\r\n`, 'Error')
  }

  const classes = useStyles()
  const hasResPages = () => {
    return (
      resPages &&
      (resPages.pages?.length ||
        resPages.exhibitions?.length ||
        resPages.happens?.length ||
        resPages.edu?.length ||
        resPages.eduhome?.length)
    )
  }

  return (
    <div>
      <IconButton
        color="primary"
        size="medium"
        variant="outlined"
        onClick={onClick}
        // disabled={props.disabled}
      >
        <FindInPageOutlinedIcon />
      </IconButton>
      <Dialog open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle onClose={onClickedCloseDialog}>
          {translate('Strony na których znajdują się bloki')}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText className={classes.confirmText}>
            {hasResPages() ? (
              <div>
                {/* ------------------------------------------------------------------------------------- */}
                {resPages.pages.length ? (
                  <Grid container direction="column">
                    <div className={classes.listTitle}>Strony:</div>
                    {resPages.pages.map((item, k) => {
                      return (
                        <div>
                          {k + 1}.
                          <Link
                            to={routesPage().edit.path.replace(
                              ':id',
                              item.uuid
                            )}
                            key={`assdass-key${item.uuid}`}
                          >
                            {item.title}
                          </Link>
                        </div>
                      )
                    })}{' '}
                  </Grid>
                ) : null}
                {/* ------------------------------------------------------------------------------------- */}
                {resPages.exhibitions.length ? (
                  <Grid container direction="column">
                    <div className={classes.listTitle}>Wystawy:</div>
                    {resPages.exhibitions.map((item, k) => {
                      return (
                        <div>
                          {k + 1}.
                          <Link
                            to={routesExh().edit.path.replace(':id', item.uuid)}
                            key={`assdass-key${item.uuid}`}
                          >
                            {item.title}
                          </Link>
                        </div>
                      )
                    })}{' '}
                  </Grid>
                ) : null}
                {/* ------------------------------------------------------------------------------------- */}
                {resPages.happens.length ? (
                  <Grid container direction="column">
                    <div className={classes.listTitle}>Wydarzenia:</div>
                    {resPages.happens.map((item, k) => {
                      return (
                        <div>
                          {k + 1}.
                          <Link
                            to={routesHappen().edit.path.replace(':id', item.uuid)}
                            key={`assdass-key${item.uuid}`}
                          >
                            {item.title}
                          </Link>
                        </div>
                      )
                    })}{' '}
                  </Grid>
                ) : null}
                {/* ------------------------------------------------------------------------------------- */}
                {resPages.edu.length ? (
                  <Grid container direction="column">
                    <div className={classes.listTitle}>Wydarzenia edukacyjne:</div>
                    {resPages.edu.map((item, k) => {
                      return (
                        <div>
                          {k + 1}.
                          <Link
                            to={routesHappenEdu().edit.path.replace(':id', item.uuid)}
                            key={`assdass-key${item.uuid}`}
                          >
                            {item.title}
                          </Link>
                        </div>
                      )
                    })}{' '}
                  </Grid>
                ) : null}
                {/* ------------------------------------------------------------------------------------- */}
                {resPages.eduhome.length ? (
                  <Grid container direction="column">
                    <div className={classes.listTitle}>Artykuły edukacyjne:</div>
                    {resPages.eduhome.map((item, k) => {
                      return (
                        <div>
                          {k + 1}.
                          <Link
                            to={routesHappenEduhome().edit.path.replace(':id', item.uuid)}
                            key={`assdass-key${item.uuid}`}
                          >
                            {item.title}
                          </Link>
                        </div>
                      )
                    })}{' '}
                  </Grid>
                ) : null}
                {/* ------------------------------------------------------------------------------------- */}
              </div>
            ) : isFeczing ? (
              <Loader />
            ) : (
              'Brak stron'
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleConfirmToggle} variant="outlined">
            {translate('T_GENERAL_CANCEL')}
          </Button> */}
          <Button
            onClick={onClickedCloseDialog}
            variant="contained"
            color="primary"
          >
            {translate('Ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

WhereIs.propTypes = {}
