import React from 'react'
import { Route } from 'react-router-dom'
import { PhonelinkSetupOutlined } from '@material-ui/icons'
import { Settings } from './Settings'

const routes = () => ({
  index: {
    title: 'T_MODULE_SETTINGS',
    type: Route,
    render: props => <Settings {...props} />,
    path: '/settings',
    exact: true,
    icon: PhonelinkSetupOutlined,
  },
})

export default routes
