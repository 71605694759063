export const commonConstants = {
  INIT_RELOAD: 'COMMON_INIT_RELOAD',
  INIT_PAGE_MENU_RELOAD: 'COMMON_INIT_PAGE_MENU_RELOAD',
  SIDEBAR_TOGGLE: 'COMMON_SIDEBAR_TOGGLE',
  ADD_CUSTOM_BREADCRUMBS: 'ADD_CUSTOM_BREADCRUMBS',
  SET_CURRENT_TAB: 'SET_CURRENT_TAB',
  SET_CURRENT_RESOURCE: 'SET_CURRENT_RESOURCE',
  SET_CURRENT_PARENT_RESOURCE: 'SET_CURRENT_PARENT_RESOURCE',
  RESET_CURRENT_RESOURCES: 'RESET_CURRENT_RESOURCES',
}
