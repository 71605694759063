import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'

export const textPerson = () => ({
  name: 'textPerson',
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_PERSON',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
      },
    },
    elements: {
      type: 'collection',
      endpoint: '/api/block_elements',
      definition: 'BlockElement-blockElement.read',
      description: 'T_GENERAL_BLOCK_TYPE_TEXT_PERSON_ADD',
      additionalProperties: {
        $ref:
          '#/definitions/BlockElement-blockElement.write_blockElement.create',
      },
      titleAccessor: function(resource, handleSuccess) {
        if (
          resource.translations &&
          resource.translations[process.env.REACT_APP_LOCALE].title
        )
          return resource.translations[process.env.REACT_APP_LOCALE].title
        if (resource['@titleAccessor']) return resource['@titleAccessor']

        const setResource = res => {
          resource['@titleAccessor'] =
            res.translations && res.translations[process.env.REACT_APP_LOCALE]
              ? res.translations[process.env.REACT_APP_LOCALE].title ?? '--'
              : '--'
          if (handleSuccess) {
            handleSuccess(resource)
          }
        }
        if (resource.person) {
          fetchDataHandleAuthError(resource.person, 'GET', null, setResource)
        } else {
          return '--' //translate('T_GENERAL_MISSING_TITLE').replace('<','‹ ').replace('>',' ›')
        }
      },
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              hint: 'T_GENERAL_BLOCK_TYPE_TEXT_PERSON_TITLE_HINT',
            },
          },
        },
        person: {
          type: 'resource',
          endpoint: `/api/person_items?pagination=false`,
          titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
          label: 'T_BLOCK_ELEMENT_LABEL_PERSON',
        },
        stat: {},
      },
    },
    anchor: {},
  },
})
