import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Title } from 'core/components/Title'
import SyncIcon from '@material-ui/icons/Sync'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import { Loader } from 'core/components/Loader'
import { notification } from 'core/_helpers/notification'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.5em',
  },
  buttons: {
    '&> *': {
      marginRight: 10,
    },
    '&> *:last-child': {
      marginRight: 0,
    },
  },
  buttonTitle: {
    marginLeft: '.7em',
  },
})

export const CollectionHeader = ({
  children,
  buttonTitle,
  hideButton = false,
  additionalButtons = null,
  classes = {},
}) => {
  const defaultClasses = useStyles()

  const [btnDisable, setDisable] = React.useState(false)

  const statusChangeSucces = res => {
    setDisable(false)
    notification(
      'success',
      'Synchronizacja przebiegła pomyślnie.',
      'T_SYNC_SUCCESS'
    )
  }
  const statusChangeError = res => {
    setDisable(false)
    notification('error', `Synchronizacja nie powiodła się.\r\n${(res.response.detail??'')}`, 'T_SYNC_ERROR')
  }

  const onClicked = () => {
    setDisable(true)
    //fetch ...........
    fetchDataHandleAuthError(
      '/api/iart_sync',
      'POST',
      { body: JSON.stringify({ date: null }) }, //'2023-05-01'
      statusChangeSucces,
      statusChangeError
    )
  }

  return (
    <div className={clsx(defaultClasses.root, classes.root)}>
      <Title>{children}</Title>
      {!hideButton && (
        <div className={clsx(defaultClasses.buttons, classes.buttons)}>
          <Button
            onClick={onClicked}
            disabled={btnDisable}
            variant="contained"
            color="primary"
            size="medium"
          >
            { btnDisable ? <Loader/> : <SyncIcon /> }
            <span
              className={clsx(defaultClasses.buttonTitle, classes.buttonTitle)}
            >
              {btnDisable ? 'Przetwarzanie...' : buttonTitle}
            </span>
          </Button>
          {additionalButtons}
        </div>
      )}
    </div>
  )
}

CollectionHeader.propTypes = {
  children: PropTypes.node.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  hideButton: PropTypes.bool,
  additionalButtons: PropTypes.node,
  classes: PropTypes.shape({
    root: PropTypes.string,
    buttons: PropTypes.string,
    buttonTitle: PropTypes.string,
  }),
}
