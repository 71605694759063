import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { EditableCell as OriginalEditableCell } from 'core/components/table/cells/editable/EditableCell'

export const EditableCell = ({
  resource,
  accessor,
  propertySchema,
  tableState,
  tableStateDispatch,
}) => {
  const profile = useSelector(state => state.profile)

  return (
    <OriginalEditableCell
      resource={resource}
      accessor={accessor}
      propertySchema={propertySchema}
      disabled={profile.group === resource[process.env.REACT_APP_RESOURCE_ID]}
      tableState={tableState}
      tableStateDispatch={tableStateDispatch}
    />
  )
}

EditableCell.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.string,
  propertySchema: PropTypes.shape({
    validate: PropTypes.arrayOf(PropTypes.string),
  }),
  tableState: PropTypes.object,
  tableStateDispatch: PropTypes.func.isRequired,
}
