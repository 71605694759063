import React, { useMemo } from 'react'
import { Paper } from 'core/components/Paper'
// import { useSelector } from 'react-redux'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
// import { operations } from 'core/components/table/_columns/operations'
import { operations } from './table/cells/operations'
import { translate } from 'core/_helpers/translate'
// import { publish } from 'core/components/table/_mass'
import { StatusCell } from './table/cells/StatusCell'
import schema from '_schema/order'
import routes from './routes'
import { DateTimeCell } from './table/cells/DateTimeCell'
import { PriceLtGtFilter } from 'core/components/table/filters/PriceLtGtFilter'
import { OrderStatusFilter } from 'core/components/table/filters/OrderStatusFilter'
import { DateFilter } from 'core/components/table/filters/DateFilter'
import { ColorCell } from './table/cells/ColorCell'
import { StringFilter } from 'core/components/table/filters/StringFilter'
import { TypeCell } from './table/cells/TypeCell'
import { HasTicketFilter } from 'core/components/table/filters/HasTicketFilter'

export const Collection = () => {
  // const profile = useSelector(state => state.profile)
  const columns = useMemo(
    () => [
      {
        header: translate('Numer zamówienia'),
        accessor: `orderNumber`,
        filterName: 'orderNumber',
        filterable: true,
        sortable: true,
        sorterName: 'orderNumber',
        width: '15%',
      },
      {
        header: translate('Data'),
        accessor: `createdAt`,
        sortable: true,
        sorterName: 'createdAt',
        Cell: DateTimeCell,
        // width: '20%',
      },
      {
        header: translate('Email'),
        accessor: `email`,
        filterName: 'email',
        filterable: true,
        sortable: true,
        sorterName: 'email',
        // width: '20%',
      },
      {
        header: 'Kwota',
        accessor: `floatPrice`,
        sortable: true,
        sorterName: 'totalPrice',
        width: '11%',
        Cell: ColorCell,
      },
      {
        header: 'Płatność',
        accessor: `paymentTitle`,
        sortable: true,
        sorterName: 'paymentTitle',
        width: '10%',
      },
      {
        header: 'Typ',
        accessor: `partStatus`,
        Cell: TypeCell,
        width: '5%',
      },
      {
        header: 'Status',
        accessor: `partStatus`,
        Cell: StatusCell,
        width: '10%',
      },
      {
        ...operations(`orderNumber`, true, true, false, true, false),
        width: '7%',
      },
    ],
    []
  )

  const customFilters = [
    {
      name: 'parts.items.isTicket',
      title: 'Typ zamówienia',
      value: null,
      Filter: HasTicketFilter,
    },
    {
      name: 'surname',
      title: 'Nazwisko',
      value: null,
      Filter: StringFilter,
    },
    {
      name: 'firm',
      title: 'Firma',
      value: null,
      Filter: StringFilter,
    },
    {
      name: 'totalPrice[gte]',
      title: 'Kwota od',
      value: null,
      extraparams: { base: 100 },
      Filter: PriceLtGtFilter,
    },
    {
      name: 'totalPrice[lte]',
      title: 'Kwota do',
      value: null,
      extraparams: { base: 100 },
      Filter: PriceLtGtFilter,
    },
    {
      name: 'parts.orderStat',
      title: 'Status zamówienia',
      value: null,
      Filter: OrderStatusFilter,
    },
    {
      name: 'createdAt[after]',
      title: 'Data od',
      value: null,
      Filter: DateFilter,
    },
    {
      name: 'createdAt[before]',
      title: 'Data do',
      value: null,
      Filter: DateFilter,
    },
    {
      name: 'parts.items.title',
      title: 'Tytuł produktu',
      value: null,
      Filter: StringFilter,
    },
  ]

  return (
    <Paper>
      <CollectionHeader buttonTitle={''} buttonPath={''} hideButton={true}>
        {translate('Orders')}
        &nbsp;
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ id: 'desc' }}
        orderable={false}
        isRowLinkable={true}
        editPath={routes().show.path}
        autoWidth={false}
        customFilters={customFilters}
        isRowSelectable={false}
      />
    </Paper>
  )
}
