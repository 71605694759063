import packagePricelistSchema from 'core/_schema/packagePricelist'
export const pricelistBlock = (photoEndpoint, thumbEndpoint) => ({
  name: 'pricelistBlock',
  label: 'Blok z cennikiem',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        subtitle: {},
      },
    },
    buttonOne: {
      label: 'Pokazuj "Kup bilet"',
    },
    buttonTwo: {
      label: 'Pokazuj "Przejdź do biletów"',
    },
    photo: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      validate: ['maxSize'],
      thumbs: thumbEndpoint && {
        endpoint: thumbEndpoint,
      },
    },
    packagePricelist: {
      type: 'resource',
      endpoint: `${packagePricelistSchema.endpoint}?pagination=false&embeded=false`,
      titleAccessor: 'title',
      description: 'Pakiet cennika',
    },
    elements: {
      type: 'collection',
      description: 'lub załącz elementy cennika',
      endpoint: '/api/happen_block_elements',
      definition: 'HappenBlockElement-happenBlockElement.read',
      additionalProperties: {
        $ref:
          '#/definitions/HappenBlockElement-happenBlockElement.write_happenBlockElement.create',
      },
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
            priceString: {
              label: 'Cena/opis',
            },
          },
        },
        isHeader: {
          label: 'Wyswietl jako nagłówek',
        },
        stat: {},
      },
    },
    anchor: {},
  },
})
