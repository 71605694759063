import React from 'react'
import schema from '_schema/shortcut'
import routes from './routes'
import { Form } from 'core/pages'
import { addProperties } from './_helpers/addProperties'
import { useSelector } from 'react-redux'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const profile = useSelector(state => state.profile)
  const iri = `${schema.endpoint}/${match.params.id}`
  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={false}
      width={750}
      modifyProperties={properties =>
        addProperties(profile, properties)
      }
      // sidebarTitleAccessor="pageTitle"
      // SidebarComponent={Sidebar}
    />

  )
}
