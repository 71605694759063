import React, { useMemo, useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { ActiveDot, Avatar } from 'core/pages/Messenger/components'
import { isAuthor } from 'core/pages/Messenger/_helpers/isAuthor'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { translate } from 'core/_helpers/translate'
import schema from 'core/_schema/messenger'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 15,
    '&> *': {
      marginRight: 15,
    },
  },
  lastMessage: {
    color: theme.elements.messenger.timestamp.color,
    marginTop: 5,
    fontSize: '.9em',
  },
}))

export const RecipientCell = ({ resource, editPath }) => {
  const profile = useSelector(state => state.profile)
  const recipient = useMemo(
    () =>
      resource.participantA[process.env.REACT_APP_RESOURCE_ID] ===
      profile[process.env.REACT_APP_RESOURCE_ID]
        ? resource.participantB
        : resource.participantA,
    [resource, profile]
  )

  const history = useHistory()

  const onClick = useCallback(() => {
    editPath &&
      history.push(
        editPath.replace(':id', recipient[process.env.REACT_APP_RESOURCE_ID])
      )
  }, [history, editPath, recipient])

  const [lastMessage, setLastMessage] = useState(null)

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      `${schema.endpoint.messages}?recipient.${
        process.env.REACT_APP_RESOURCE_ID
      }=${recipient[process.env.REACT_APP_RESOURCE_ID]}&createdAt=${moment()
        .add(5, 'minutes')
        .format('YYYY-MM-DDTHH:mm:ss')},1`,
      'GET',
      { signal },
      response => {
        if (!response['hydra:member'].length) {
          return
        }

        setLastMessage(response['hydra:member'][0])
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [recipient, setLastMessage])

  const classes = useStyles()

  return (
    <div onClick={onClick} className={classes.root}>
      <Avatar name={recipient.fullName} />{' '}
      <div>
        {recipient.fullName} <ActiveDot recipient={recipient} />
        {lastMessage && (
          <div className={classes.lastMessage}>
            {isAuthor(lastMessage.author, profile)
              ? `${translate('T_MODULE_MESSENGER_YOU')}:`
              : ''}{' '}
            {lastMessage.text}
          </div>
        )}
      </div>
    </div>
  )
}

RecipientCell.propTypes = {
  resource: PropTypes.object.isRequired,
  editPath: PropTypes.string,
}
