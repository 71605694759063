import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Paper } from 'core/components/Paper'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { operations } from 'core/components/table/_columns/operations'
import { translate } from 'core/_helpers/translate'
import schema from 'core/_schema/translatorEntry'
import routes from './routes'
import { exportselected } from './table/exportselected'
import { ExportImportCards } from './table/exportimportcard'
import { TitleCell } from './table/cells/TitleCell'

export const Collection = () => {
  const profile = useSelector(state => state.profile)
  const columns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_CODE'),
        accessor: 'code',
        filterable: true,
        sortable: true,
        width: '25%',
      },
      {
        header: `${translate('T_GENERAL_TRANSLATION')} PL`,
        accessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        filterName: 'translations.title',
        filterable: true,
        sortable: false,
        width: '17%',
        Cell: TitleCell,
      },
      {
        header: `${translate('T_GENERAL_TRANSLATION')} EN`,
        accessor: `translations.en.title`,
        width: '17%',
        Cell: TitleCell,
      },
      // {
      //   header: `${translate('T_GENERAL_TRANSLATION')} HE`,
      //   accessor: `translations.he.title`,
      //   width: '16%',
      //   Cell: TitleCell,
      // },
      {
        ...operations(
          `translations.${process.env.REACT_APP_LOCALE}.title`,
          true,
          true,
          false,
          true
        ),
        width: '13%',
      },
    ],
    []
  )

  const [reloadInvoke, setReloadInvoke] = React.useState(true)
  const reloadInvokeFunction = val => {
    setReloadInvoke(val)
  }

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_TRANSLATOR_ENTRIES_NEW_BUTTON')}
        buttonPath={routes().new.path}
      >
        <>
          {translate('T_MODULE_TRANSLATOR_ENTRIES')}
          {profile.isOpenform && (
            <ExportImportCards reloadInvokeFunction={reloadInvokeFunction} />
          )}
        </>
      </CollectionHeader>
      {reloadInvoke && (
        <SchemableCollection
          component={CollectionTable}
          path={`paths.${schema.endpoint}.get`}
          endpoint={schema.endpoint}
          columns={columns}
          customResourceSchema={schema.resource}
          defaultSorters={{ ord: 'desc' }}
          orderable={true}
          isRowLinkable={true}
          editPath={routes().edit.path}
          autoWidth={false}
          selectable={
            profile.isOpenform
              ? [exportselected.go(), exportselected.delete()]
              : [exportselected.delete()]
          }
        />
      )}
    </Paper>
  )
}
