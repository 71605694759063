import adminGroupSchema from './adminGroup'

const schema = {
  endpoint: '/api/admins',
  resource: {
    definition: 'Admin.AdminInputDTO-admin.write',
    properties: {
      fullName: {
        validate: ['required', 'length(3)'],
      },
      email: {
        validate: ['required', 'email'],
      },
      phone: {},
      password: {
        type: 'passwordRepeat',
        description: 'T_FORM_FIELD_NEW_PASSWORD',
        hint: {
          origin: 'T_FORM_FIELD_HINT_ENTER_NEW_PASSWORD',
          repeat: 'T_FORM_FIELD_HINT_REPEAT_NEW_PASSWORD',
        },
        validate: ['required', 'password'],
      },
      group: {
        type: 'resource',
        endpoint: `${adminGroupSchema.endpoint}?order[createdAt]=desc&pagination=false`,
        titleAccessor: 'title',
      },
    },
  },
}

export default schema
