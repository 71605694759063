const schema = {
  endpoint: '/api/discount_codes',
  resource: {
    definition: 'DiscountCode-discountCode.read',
    properties: {

      code: {
        validate: ['required'],
      },

      floatRate: {
        type: 'number',
        minValue: 0,
        maxValue: 100,
        defaultValue: 0.0,
        validate: ['required'],
        label: 'Wartość',
        endAdornment: '%',
      },

      floatPrice: {
        type: 'number',
        minValue: 0,
        defaultValue: 0.0,
        validate: ['required'],
        label: 'Minimalna kwota'
      },

      globalLimit: {
        type: 'integer',
        minValue: 0,
        defaultValue: 0,
        hint: 'wpisz 0 lub puste pole aby wyłaczyć',
        label: 'Limit użyć - globalny'
      },

      startDate:{
        type: 'date',
        label: 'Data rozpoczęcia',
      },

      endProperDate:{
        type: 'date',
        label: 'Data zakończenia (włącznie)',
      },

      categories: {
        type: 'multipleResourceNotCategory',
        endpoint: `/api/product_categories_form?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,//'uuid',
      },

      products: {
        type: 'multipleResource',
        endpoint: `/api/products_form?pagination=false&exists[versionable]=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,//'uuid',
      },

      stat: {
        // description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
