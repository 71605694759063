import { StringType } from './StringType'
import { IntegerType } from './IntegerType'
import { NumberType } from './NumberType'
import { TinymceType } from './TinymceType'
import { Tinymce2Type } from './Tinymce2Type'
import { Tinymce3Type } from './Tinymce3Type'
import { TinymcewithfootnotesType } from './TinymcewithfootnotesType'
import { PasswordRepeatType } from './PasswordRepeatType'
import { BooleanType } from './BooleanType'
import { ResourceType } from './ResourceType'
import { MultipleResourceType } from './MultipleResourceType'
import { DateType } from './DateType'
import { DateTimeType } from './DateTimeType'
import { MapType } from './MapType'
import { TranslationType } from './translation/TranslationType'
import { FileType } from './file/FileType'
import { ImageType } from './ImageType'
import { YoutubeType } from './YoutubeType'
import { ChoiceType } from './ChoiceType'
import { MediaType, MediaImageType, MediaYoutubeType } from './media'
import { CollectionType } from './CollectionType'
import { SlugType } from './SlugType'
import { EmbeddedcollectionType } from './EmbeddedcollectionType'
import { ResourceTagType } from './ResourceTagType'
import { OrderStatus } from './other/OrderStatus'
import { TablecollectionType } from './TablecollectionType'
import { CollectionPackageEmbededType } from './CollectionPackageEmbededType'
import { ResourceAttrType } from './other/ResourceAttrType'
import { ResourceCategoryType } from './other/ResourceCategoryType'
import { MultiChoiceType } from './MultiChoiceType'
import { ResourceIksorisType } from './other/ResourceIksorisType'
import { PreviewType } from './PreviewType'
import { PasazTagAjaxType } from './other/PasazTagAjaxType'
import { DateRangeType } from './other/DateRangeType'
import { ResourceOrderCountryType } from './other/ResourceOrderCountryType'
import { MultipleResourceNotCategoryType } from './MultipleResourceNotCategoryType'
import { AutocompleteType } from './AutocompleteType'


export const fields = {
  string: StringType,
  integer: IntegerType,
  number: NumberType,
  textarea: StringType,
  tinymce: TinymceType,
  tinymce2: Tinymce2Type,
  tinymce3: Tinymce3Type,
  tinymcewithfootnotes: TinymcewithfootnotesType,
  password: StringType,
  passwordRepeat: PasswordRepeatType,
  boolean: BooleanType,
  resource: ResourceType,
  multipleResource: MultipleResourceType,
  date: DateType,
  datetime: DateTimeType,
  map: MapType,
  translation: TranslationType,
  file: FileType,
  image: ImageType,
  youtube: YoutubeType,
  choice: ChoiceType,
  multichoice: MultiChoiceType,
  'media[type]': MediaType,
  'media[image]': MediaImageType,
  'media[youtube]': MediaYoutubeType,
  collection: CollectionType,
  slug: SlugType,
  preview: PreviewType,
  embeddedcollection: EmbeddedcollectionType,
  resourcetag: ResourceTagType,
  orderStatus: OrderStatus,
  tablecollection: TablecollectionType,
  collectionpackageembeded: CollectionPackageEmbededType,
  resourceAttr: ResourceAttrType,
  resourceCategory: ResourceCategoryType,
  resourceIksoris: ResourceIksorisType,
  pasaztagajax: PasazTagAjaxType,
  dateRange: DateRangeType,
  resourceOrderCountry: ResourceOrderCountryType,
  multipleResourceNotCategory: MultipleResourceNotCategoryType,
  autocomplete: AutocompleteType
}
