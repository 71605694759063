import React from 'react'
import { prop } from 'core/_helpers/prop'
// import { makeStyles } from '@material-ui/styles'
// import { translate } from 'core/_helpers/translate'
import { Chip } from '@material-ui/core'

// const useStyles = makeStyles({
//   title: {
//     marginLeft: 0,
//     textDecoration: 'none',
//     color: 'inherit',
//   },
// })

export const TypeBadgeCell = ({
  resource,
  accessor,
  // isRowLinkable = false,
  // editPath = null,
}) => {
  // const classes = useStyles()

  const getlabeltranslate = () => {
    switch (resource['@type']) {
      case 'Page':
        // public const PAGE_TYPE_NEWS = 'news';
        // public const PAGE_TYPE_PROJECT = 'project';
        // public const PAGE_TYPE_DEFAULT = 'page';
        if (resource.type === 'page') {
          return 'Strona'
        }
        if (resource.type === 'project') {
          return 'Projekt'
        }
        if (resource.type === 'news') {
          return 'Aktualność'
        }
        break
      case 'Happen':
        // public const HAPPEN_TYPE_EDUCATIONMUSEUM = 'educationMuseum';
        // public const HAPPEN_TYPE_EDUCATIONHOME = 'educationHome';
        // public const HAPPEN_TYPE_DEFAULT = 'happen';
        if (resource.type === 'happen') {
          return 'Wydarzenie'
        }
        if (resource.type === 'educationHome') {
          return 'Wydarzenie'
        }
        if (resource.type === 'educationMuseum') {
          return 'Artykuł edukacja'
        }
        break
      default:
        return prop(resource, accessor)
    }

    return prop(resource, accessor)
  }

  return <Chip color="primary" size="small" label={getlabeltranslate()} />
}
