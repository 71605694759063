import React, { useState, useEffect } from 'react'
//import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  InputLabel,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
  Grid,
  FormControl,
  Input,
  InputAdornment,
} from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/styles'
import { translate } from 'core/_helpers/translate'
import { DialogTitleWithClose } from 'core/components/DialogTitleWithClose'
import { CreateOutlined } from '@material-ui/icons'
import { Loader } from 'core/components/Loader'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import HomeIcon from '@material-ui/icons/Home'
import Link from '@material-ui/core/Link'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 15,
  },
  label: {
    position: 'relative',
    transform: 'none',
    display: 'inline-flex',
    color: theme.palette.text.secondary,
    marginRight: 5,
    verticalAlign: 'middle',
  },
  labelButton: {
    cursor: 'pointer',
  },
  dialogContent: {
    marginBottom: 15,
  },

  rootList: {
    flexGrow: 1,
    maxWidth: 752,
  },
  listTitle: {},
  listItem: {
    paddingLeft: 0,
  },
}))

export const PasazTagAjaxType = ({
  name,
  label,
  endpoint,
  endpointTags,
  classes = {},
  value,
  setValue,
}) => {
  const [valueUuid, setValueUuid] = useState(value ? value.split('/')[3] : null)
  const defaultClasses = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const [results, setResults] = useState([])
  const [results2, setResults2] = useState({})
  const [isFetching, setIsFetching] = useState(true)
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const [newSelectedName, setnewSelectedName] = useState(null)
  const [searchTag, setSearchTag] = useState('')

  const handleToggleOpen = () => {
    setIsOpen(state => !state)
  }

  const handleChange = (value, title) => {
    setValueUuid(value)
    setnewSelectedName(title)
    setValue(name, `${endpointTags}/${value}`)
  }

  const handleSetSearchTag = event => {
    setSearchTag(event.target.value ?? '')
  }

  const handleSearchTag = () => {
    setIsFetching(true)
    const controller = new AbortController()
    const { signal } = controller

    setBreadcrumbs([])

    fetchDataHandleAuthError(
      `${endpoint}?word=${searchTag ?? ''}`,
      'GET',
      { signal },
      response => {
        setResults(response['hydra:member'] ?? [])
        setIsFetching(false)
      },
      error => {
        setIsFetching(false)
        if (error.response.title === 'AbortError') {
          return
        }
        notification(
          'error',
          error.response.detail ?? 'Error',
          error.response.title ?? null
        )
      }
    )
  }

  useEffect(
    () => {
      if (searchTag) {
        results.forEach(element => {
          handlegetbreadcrumbs(element)
        })
      }
    },
    // eslint-disable-next-line
    [results]
  )

  const handlegetbreadcrumbs = item => {
    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      `${endpoint}?bread=${item.uuid ?? ''}`,
      'GET',
      { signal },
      response => {
        let res = response['hydra:member'] ?? []
        let nowyRes = new Array()
        nowyRes[`${item.uuid}`] = res
        setResults2(state => ({ ...state, ...nowyRes }))
      },
      error => {
        setIsFetching(false)
        if (error.response.title === 'AbortError') {
          return
        }
        notification(
          'error',
          error.response.detail ?? 'Error',
          error.response.title ?? null
        )
      }
    )
  }

  useEffect(
    () => {
      if (!isOpen) return

      setBreadcrumbs([])
      setSearchTag('')
      setResults2({})
      setIsFetching(true)

      const controller = new AbortController()
      const { signal } = controller

      fetchDataHandleAuthError(
        valueUuid ? `${endpoint}?item=${valueUuid}` : endpoint,
        'GET',
        { signal },
        response => {
          setResults(response['hydra:member'] ?? [])
          setIsFetching(false)
        },
        error => {
          setIsFetching(false)
          if (error.response.title === 'AbortError') {
            return
          }
          notification(
            'error',
            error.response.detail ?? 'Error',
            error.response.title ?? null
          )
        }
      )

      if (valueUuid) {
        fetchDataHandleAuthError(
          `${endpoint}?bread=${valueUuid}`,
          'GET',
          { signal },
          response => {
            if (response['hydra:member']) {
              setBreadcrumbs(response['hydra:member'])
            }
          },
          error => {
            setIsFetching(false)
            if (error.response.title === 'AbortError') {
              return
            }
            notification(
              'error',
              error.response.detail ?? 'Error',
              error.response.title ?? null
            )
          }
        )
      }

      return () => controller.abort()
    },
    // eslint-disable-next-line
    [isOpen]
  )

  const handleLoadTags = (parent, titleBreadcrumb) => {
    setIsFetching(true)
    setResults2({})
    setSearchTag('')
    const controller = new AbortController()
    const { signal } = controller

    if (titleBreadcrumb && parent) {
      let br = []
      let find = false
      for (let i = 0; i < breadcrumbs.length; i++) {
        if (!find)
          br.push({ uuid: breadcrumbs[i].uuid, title: breadcrumbs[i].title })
        if (breadcrumbs[i].uuid === parent) {
          find = true
        }
      }

      if (!find) br.push({ uuid: parent, title: titleBreadcrumb })
      setBreadcrumbs(br)
    }

    if (!parent) {
      setBreadcrumbs([])
    }

    fetchDataHandleAuthError(
      `${endpoint}?parent=${parent ?? ''}`,
      'GET',
      { signal },
      response => {
        setResults(response['hydra:member'] ?? [])
        setIsFetching(false)
      },
      error => {
        setIsFetching(false)
        if (error.response.title === 'AbortError') {
          return
        }
        notification(
          'error',
          error.response.detail ?? 'Error',
          error.response.title ?? null
        )
      }
    )
  }

  return (
    <div className={clsx(defaultClasses.root, classes.root)}>
      <InputLabel className={clsx(defaultClasses.label, classes.label)}>
        <span style={label.color && { color: label.color }}>
          {translate(label.text || label)}
        </span>
      </InputLabel>
      <Tooltip title={translate('Zmień')}>
        <span>
          <IconButton
            color="primary"
            onClick={handleToggleOpen}
            disabled={false}
          >
            <CreateOutlined
              className={clsx(defaultClasses.labelButton, classes.labelButton)}
            />
          </IconButton>
          {newSelectedName}
        </span>
      </Tooltip>
      <Dialog
        onClose={handleToggleOpen}
        open={isOpen}
        fullWidth={false}
        maxWidth="lg"
      >
        <DialogTitleWithClose onClose={handleToggleOpen}>
          <div>
            <span style={{ verticalAlign: 'top', marginRight: 20 }}>
              {translate(label.text || label)}
            </span>

            <FormControl
              style={{ verticalAlign: 'middle' }}
              className={clsx(classes.margin, classes.textField)}
            >
              <InputLabel htmlFor="standard-adornment-password">
                Szukaj tag
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type="text"
                value={searchTag}
                onChange={handleSetSearchTag}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleSearchTag}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
        </DialogTitleWithClose>
        <DialogContent
          className={clsx(defaultClasses.dialogContent, classes.dialogContent)}
        >
          <div className={defaultClasses.rootList}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Link
                  component="button"
                  onClick={anysht => handleLoadTags(null, null)}
                >
                  <HomeIcon color="primary" />
                </Link>
                {breadcrumbs.map(breadcrumb => {
                  return (
                    <Link
                      key={breadcrumb.uuid}
                      component="button"
                      onClick={anysht =>
                        handleLoadTags(breadcrumb.uuid, breadcrumb.title)
                      }
                    >
                      <KeyboardArrowRightIcon
                        size="small"
                        fontSize="small"
                        style={{ verticalAlign: 'middle' }}
                      />
                      <span style={{ verticalAlign: 'middle' }}>
                        {breadcrumb.title}
                      </span>
                    </Link>
                  )
                })}
                <div>
                  {isFetching ? (
                    <Loader />
                  ) : (
                    <List dense={true}>
                      {results.map(result => {
                        return (
                          <ListItem
                            button
                            dense
                            key={result.uuid}
                            className={defaultClasses.listItem}
                            onClick={sthsht =>
                              handleChange(
                                result.uuid,
                                result.translations.pl.title
                              )
                            }
                          >
                            <ListItemIcon
                              style={{
                                paddingRight: 5,
                                minWidth: 5,
                                color:
                                  valueUuid === result.uuid
                                    ? '#e55'
                                    : 'inherit',
                              }}
                            >
                              <AddCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={result.translations.pl.title}
                              secondary={
                                results2[result.uuid] &&
                                results2[result.uuid].length > 0
                                  ? results2[result.uuid].map(el => {
                                      return `/${el.title}`
                                    })
                                  : null
                              }
                              style={{
                                color:
                                  valueUuid === result.uuid
                                    ? '#e55'
                                    : 'inherit',
                              }}
                            />
                            {result.hasChildren > 0 && (
                              <ListItemSecondaryAction>
                                <IconButton
                                  edge="end"
                                  onClick={anysht =>
                                    handleLoadTags(
                                      result.uuid,
                                      result.translations.pl.title
                                    )
                                  }
                                >
                                  <ArrowRightAltIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            )}
                          </ListItem>
                        )
                      })}
                    </List>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

PasazTagAjaxType.propTypes = {}
