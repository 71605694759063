const schema = {
  endpoint: '/api/translator_entries',
  resource: {
    definition: 'TranslatorEntry-translatorEntry.read',
    properties: {
      code: {
        validate: ['required'],
      },
      translations: {
        type: 'translation',
        properties: {
          title: {
            type: 'textarea',
          },
        },
      },
    },
  },
}

export default schema
