import React from 'react'
import { Route } from 'react-router-dom'
import PhotoAlbumOutlinedIcon from '@material-ui/icons/PhotoAlbumOutlined'
import StorageOutlinedIcon from '@material-ui/icons/StorageOutlined'
import { Collection } from './Collection'
import { SchemableComponent } from 'core/components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { VersionCollection } from './VersionCollection'
import { VersionView } from './VersionView'
import { addRoutesConfig } from 'core/_helpers/addRoutesConfig'
import schema from '_schema/sobiesciana'

import { Collection as AttributeCollection} from './Attributes/Collection'
import { New as AttributeNew} from './Attributes/New'
import { Edit as AttributeEdit} from './Attributes/Edit'

const basePath = '/sobiescianas'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: PhotoAlbumOutlinedIcon,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        path: `${basePath}/new`,
        exact: true,
      },
      attribute_index: {
        subtitle: 'T_LIST',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={AttributeCollection}
            {...props}
          />
        ),
        path: `${basePath}/parent/:pid/attributes`,
        exact: true,
        icon: StorageOutlinedIcon,
      },
      attribute_new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={AttributeNew} {...props} />
        ),
        path: `${basePath}/parent/:pid/attributes/new`,
        exact: true,
      },
      attribute_edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={AttributeEdit}
            uniqueKey={true}
            {...props}
          />
        ),
        path: `${basePath}/parent/:pid/attributes/edit/:id`,
        exact: true,
      },
      version_collection: {
        subtitle: 'T_GENERAL_RECORD_VERSION_COLLECTION',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={VersionCollection}
            {...props}
          />
        ),
        path: `${basePath}/:id/versions`,
        exact: true,
      },
      version_view: {
        subtitle: 'T_GENERAL_RECORD_VERSION_VIEW',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={VersionView}
            {...props}
          />
        ),
        path: `${basePath}/:id/version`,
        exact: true,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={Edit}
            uniqueKey={true}
            {...props}
          />
        ),
        path: `${basePath}/:id`,
        exact: true,
      },
    },
    {
      title: 'Sobiescianas',
      resourceAccess: schema.endpoint,
      indexPath: basePath,
      // parent: `${basePath}/:id`,
    }
  )

export default routes
