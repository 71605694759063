import packageLogotypeSchema from 'core/_schema/packageLogotype'
import photoSchema from 'core/_schema/photo'

export const packageLogotype = () => ({
  name: 'packageLogotype',
  label: 'T_GENERAL_BLOCK_TYPE_PACKAGE_LOGOTYPE',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
      },
    },
    elements: {
      type: 'embeddedcollection',
      endpoint: '/api/exhibition_block_elements',
      definition: 'ExhibitionBlockElement-exhibitionBlockElement.read',
      description: 'T_GENERAL_BLOCK_TYPE_TEXT_LOGOTYPES_ADD',
      statable: true,
      additionalProperties: {
        $ref:
          '#/definitions/ExhibitionBlockElement-exhibitionBlockElement.write_exhibitionBlockElement.create',
      },
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              label: 'Subtitle',
            },
          },
        },
        packageLogotype: {
          type: 'resource',
          endpoint: `${packageLogotypeSchema.endpoint}?pagination=false&embeded=false`,
          titleAccessor: 'title',
        },

        packageLogotypeEmbeded: {
          type: 'collectionpackageembeded',
          endpoint: '/api/package_logotypes',
          definition: 'PackageLogotype-packageLogotype.read',
          description: 'lub załącz zdjęcia',
          dialogTitle: 'Zdjęcia',
          titleAccessor: 'title',
          additionalProperties: {
            $ref:
              '#/definitions/PackageLogotype-packageLogotype.write_packageLogotype.create',
          },

          properties: {
            items: {
              type: 'embeddedcollection',
              endpoint: '/api/package_logotype_items',
              definition: 'PackageLogotypeItem-packageLogotypeItem.read',
              description: '',
              statable: true,
              additionalProperties: {
                $ref:
                  '#/definitions/PackageLogotypeItem-packageLogotypeItem.write_packageLogotypeItem.create',
              },

              titleAccessor: function(resource, handleSuccess) {
                if (resource.translations[process.env.REACT_APP_LOCALE].title) {
                  return resource.translations[process.env.REACT_APP_LOCALE]
                    .title
                } else {
                  return '--' //translate('T_GENERAL_MISSING_TITLE').replace('<','‹ ').replace('>',' ›')
                }
              },

              properties: {
                translations: {
                  type: 'translation',
                  properties: {
                    title: {},
                    description: {
                      type: 'textarea',
                    },
                    link: {},
                    alt: {
                      description: 'T_GENERAL_ALT',
                    },
                  },
                },
                photo: {
                  type: 'image',
                  description: 'T_GENERAL_MEDIA_PHOTO',
                  endpoint: photoSchema.endpoint.single,
                  endpointMultiple: photoSchema.endpoint.multiple,
                  thumbs: {
                    endpoint: '/api/package_logotype_item_thumbs',
                  },
                  validate: ['maxSize'],
                  accept: 'image/jpg,image/jpeg,image/png,image/webp,.svg'
                },
              },
            },
          },
        },

        //stat: {}
      },
    },
  },
})
