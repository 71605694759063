export const textWithImage = (photoEndpoint, thumbEndpoint, anchor = true) => {
  let b = {
    name: 'textWithImage',
    label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_IMAGE',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          text: {
            type: 'tinymce',
          },
          author: {},
          alt: {},
          buttonTitle: {},
          buttonLink: {},
        },
      },
      photo: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoEndpoint,
        validate: ['maxSize'],
        thumbs: thumbEndpoint && {
          endpoint: `${thumbEndpoint}`, // + use eg. ?name[]=main&name[]=main2
        },
        ofmEnable: true,
      },
      blockPosition: {
        type: 'choice',
        choices: {
          left: 'T_GENERAL_BLOCK_POSITION_LEFT',
          left_vertical: 'T_GENERAL_BLOCK_POSITION_LEFT_VERTICAL',
          right: 'T_GENERAL_BLOCK_POSITION_RIGHT',
          full: 'T_GENERAL_BLOCK_POSITION_FULL',
        },
        validate: ['required'],
      },
      anchor: {},
    },
  }

  if (!anchor) {
    delete b.properties.anchor
  }
  return b
}
