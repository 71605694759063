import { configureStore } from './store'

export const store = configureStore()

store.subscribe(() => {
  if (store.getState().auth.data?.token) {
    localStorage.setItem('token', store.getState().auth.data.token)
  } else {
    localStorage.removeItem('token')
  }
})
