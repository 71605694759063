export const recalculateConfig = (original, config, direction = 'frame') => {
  if (original.clientWidth === original.naturalWidth) {
    return {
      ...config,
      ...getMaxDimensions(
        config.minWidth,
        config.minHeight,
        original.naturalWidth,
        original.naturalHeight
      ),
    }
  }

  const factor =
    direction === 'frame'
      ? original.clientWidth / original.naturalWidth
      : original.naturalWidth / original.clientWidth

  const minWidth = Math.round(factor * config.minWidth)
  const minHeight = Math.round(factor * config.minHeight)

  return {
    ...config,
    minWidth,
    minHeight,
    ...getMaxDimensions(
      minWidth,
      minHeight,
      original.clientWidth,
      original.clientHeight
    ),
  }
}

const getMaxDimensions = (minWidth, minHeight, imageWidth, imageHeight) => ({
  maxWidth:
    (imageWidth / minWidth) * minHeight > imageHeight
      ? Math.round((imageWidth * imageHeight) / minHeight)
      : imageWidth,
  maxHeight:
    (imageWidth / minWidth) * minHeight > imageHeight
      ? imageHeight
      : Math.round((imageHeight * imageWidth) / minWidth),
})
