import { combineReducers } from 'redux'
import { authReducer } from 'core/_reducers'
import { commonReducer } from 'core/_reducers'
import { collectionReducer } from 'core/_reducers'
import { profileReducer } from 'core/_reducers'
import { schemaReducer } from 'core/_reducers'

const reducers = combineReducers({
  auth: authReducer,
  common: commonReducer,
  collection: collectionReducer,
  profile: profileReducer,
  schema: schemaReducer,
})

export default reducers
