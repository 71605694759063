const schema = {
  endpoint: '/api/sobiesciana_attributes',
  resource: {
    definition: 'SobiescianaAttribute-sobiescianaAttribute.read',
    properties: {

      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Nazwa/tytuł',
            hint: 'jesli wybrany filtr, nazwa nie jest potrzebna',
          },
          value: {
            label: 'Dowolna wartość',
            hint: 'jeśli nie wybrany filtr i tagi, można wpisać',
          },
        },
      },

      filter: {
        type: 'resourceAttr',
        endpoint: `/api/sobiesciana_filters_form_attr?pagination=false`,
        titleAccessor: `title`,//'uuid',
        label: 'Filtr przypisany do pola',
        name2: 'tags',
        label2: 'Tagi przypisane',
        titleAccessor2: `translations.${process.env.REACT_APP_LOCALE}.title`,//'uuid',
        endpoint2: `/api/tag_structs_form_attr?pagination=false`,
      },

      stat: {},
    },
  },
}

export default schema
