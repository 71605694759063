import {
  text,
  // textQuote,
  // textWithVideo,
  // textWithAudio,
  // textWithImage,
  // textColumn, --- nie ma tu
  // textVisit, --- nie ma tu
  // textVisit2,
  packageFile,
  // packageGallery,
  // textLinks, --- nie ma tu
  // packageFaq,

  // textQuoteSection, --- nie ma tu
  // textWithBackground
} from 'core/_schema/_blocks'

// import textElements from 'pages/Happens/_blocks'
// import textNews from 'pages/Happens/_blocks'
// import textPerson from 'pages/Happens/_blocks'
// import textCrew from 'pages/Happens/_blocks'

import {
  // textElements,
  // textNews,
  // textPerson,
  textCrew,
  packageLogotype
} from 'core/pages/Bip/_blocks'

const schema = (photoEndpoint, thumbEndpoint, fileEndpoint) => ({
  types: [
    text(false),
    // textQuote(),
    // textWithVideo(photoEndpoint, thumbEndpoint),
    // textWithImage(photoEndpoint, thumbEndpoint),
    // textWithBackground(photoEndpoint, thumbEndpoint),
    // textWithAudio(fileEndpoint),
    packageFile(false),
    // packageGallery(),
    // textElements(),
    // packageFaq(),
    packageLogotype(),
    // textPerson(),
    textCrew(),
    // textVisit2(),
    // textNews(),
  ],
})

export default schema
