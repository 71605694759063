import React from 'react'
import PropTypes from 'prop-types'
import { Avatar as OriginalAvatar } from '@material-ui/core'

export const Avatar = ({ name }) => {
  const initials = name
    .split(' ')
    .map(word => word.charAt(0).toUpperCase())
    .join('')

  return (
    <OriginalAvatar style={{ backgroundColor: `#${intToRGB(hash(name))}` }}>
      {initials}
    </OriginalAvatar>
  )
}

const hash = str => {
  let hash = 0

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  return hash
}

const intToRGB = i => {
  let c = (i & 0x00ffffff).toString(16).toUpperCase()

  return '00000'.substring(0, 6 - c.length) + c
}

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
}
