import React from 'react'
import { useHistory } from 'react-router-dom'
import { prop } from 'core/_helpers/prop'
import { makeStyles } from '@material-ui/styles'
import { options } from 'core/components/form/fields/other/OrderStatus'

const useStyles = makeStyles({
  image: {
    display: 'flex',
    alignItems: 'center',
  },
  imgContainer: {
    width: 200,
  },
  img: {
    maxHeight: 100,
    width: '100%',
    height: 'auto',
  },
  title: {
    marginLeft: '1.0em',
  },
})

export const ColorCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
}) => {
  const history = useHistory()

  const onClick = () => {
    if (isRowLinkable) {
      editPath &&
        history.push(
          editPath.replace(':id', resource[process.env.REACT_APP_RESOURCE_ID])
        )
    }
  }

  const classes = useStyles()

  const red = () => {
    if (
      resource.partStatus === options['STATUS_ABORT'] ||
      resource.partStatus === options['STATUS_NEW'] ||
      resource.partStatus === options['STATUS_WAIT_FORPAY']
    ) {
      return true
    }
    return false
  }

  return (
    <div onClick={onClick} className={classes.image}>
      <div className={classes.title}>
        <span style={{ color: red() ? '#e55' : '#5e5' }}>
          {prop(resource, accessor)} zł
        </span>
      </div>
    </div>
  )
}
