//te są wspólne z innymi encjami
import {
  text,
  textQuote,
  textWithVideo,
  textWithAudio,
  textWithImage,
  // textColumn, --- nie ma tu
  // textVisit, --- nie ma tu
  textVisit2,
  packageFile,
  packageGallery,
  // textLinks, --- nie ma tu
  packageFaq,
  // textQuoteSection, --- nie ma tu
  textWithBackground,
  formsetBlock,
  sketchfab,
  imageGigapixel,
  happenBlock,
  educationBlock,
  productBlock,
  exhibitionBlock,
  sobiescianaBlock,
  pasazBlock,
  iartcollectionBlock,
} from 'core/_schema/_blocks'

//te bloki są tylko w happens i educationhame+museum
import {
  anchorBlock,
  textElements,
  textNews,
  textPerson,
  textCrew,
  packageLogotype,
  pricelistBlock,
} from 'pages/Happens/_blocks'

const schema = (photoEndpoint, thumbEndpoint, fileEndpoint, happenBlockElement = null) => ({
  types: [
    anchorBlock(),
    text(),
    textQuote(),
    textWithVideo(photoEndpoint, thumbEndpoint),
    textWithImage(photoEndpoint, thumbEndpoint),
    textWithBackground(photoEndpoint, thumbEndpoint),
    textWithAudio(fileEndpoint, photoEndpoint, thumbEndpoint),
    packageFile(),
    packageGallery(),
    textElements(),
    packageFaq(),
    packageLogotype(),
    imageGigapixel(photoEndpoint, thumbEndpoint),
    textPerson(),
    textCrew(),
    pricelistBlock(photoEndpoint, thumbEndpoint),
    textVisit2(),
    textNews(),
    sketchfab(),
    formsetBlock(),

    happenBlock(happenBlockElement), //blok proponowane wydarzenia
    educationBlock(happenBlockElement), //blok proponowana oferta edukacyjna
    productBlock(happenBlockElement), //blok polecane produkty
    exhibitionBlock(happenBlockElement), //blok powiazane wystawy
    sobiescianaBlock(happenBlockElement), //blok polecane z sobiesciany
    pasazBlock(happenBlockElement), // blok powiązane z Pasażu Wiedzy
    iartcollectionBlock(happenBlockElement), //blok Powiązane obiekty z kolekcji iart
  ],
})

export default schema
