export const addProperties = (
  profile,
  properties,
  resource,
  schema
) => {
  let modifiedProperties = JSON.parse(JSON.stringify(properties))

  if (!profile.isModerator) {
    delete modifiedProperties.stat
  }

  return modifiedProperties
}
