import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'
import { Restore } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { DialogLoader } from 'core/components/DialogLoader'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  withIcon: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: 'inherit',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  withoutIcon: {
    width: '100%',
    textDecoration: 'none',
    cursor: 'pointer',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  disabled: {
    color: theme.palette.disabled,
  },
}))

export const RestoreVersion = ({
  version,
  editPath,
  disabled = false,
  onSuccess = null,
  isIcon = false,
}) => {
  const [isFetching, setIsFetching] = useState(false)

  const history = useHistory()

  const handleClick = () => {
    if (disabled) {
      return
    }

    setIsFetching(true)

    fetchDataHandleAuthError(
      version.versionable['@id'] || version.versionable,
      'PATCH',
      {
        body: JSON.stringify({
          restoreVersionUuid: version[process.env.REACT_APP_RESOURCE_ID],
        }),
      },
      response => {
        setIsFetching(false)

        notification(
          'success',
          'T_FORM_RECORD_VERSION_RESTORED',
          'T_FORM_SUCCESS'
        )
        onSuccess && onSuccess()

        history.push(
          editPath.replace(':id', response[process.env.REACT_APP_RESOURCE_ID])
        )
      },
      error => {
        setIsFetching(false)

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const classes = useStyles()

  return (
    <>
      {disabled ? (
        isIcon ? (
          <Restore className={classes.disabled} disabled />
        ) : (
          <span className={classes.disabled}>
            {translate('T_GENERAL_VERSION_RESTORE')}
          </span>
        )
      ) : (
        <div
          onClick={handleClick}
          className={isIcon ? classes.withIcon : classes.withoutIcon}
        >
          {isIcon ? (
            <Tooltip title={translate('T_GENERAL_VERSION_RESTORE')}>
              <Restore />
            </Tooltip>
          ) : (
            translate('T_GENERAL_VERSION_RESTORE')
          )}
        </div>
      )}
      <DialogLoader
        isOpen={isFetching}
        title={translate('T_GENERAL_VERSION_RESTORING')}
      />
    </>
  )
}

RestoreVersion.propTypes = {
  version: PropTypes.shape({
    [process.env.REACT_APP_RESOURCE_ID]: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    versionable: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({
        '@id': PropTypes.string.isRequired,
      }),
    ]).isRequired,
  }).isRequired,
  editPath: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  isIcon: PropTypes.bool,
}
