const schema = {
  endpoint: '/api/places',
  extraStoreCollectionId: 'api_places_store_coll_id_form',
  resource: {
    definition: 'Place-place.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Nazwa',
          },
          // address: {
          //   type: 'textarea',
          // }
        },
      },
      // map:{
      //   type: 'map',
      //   label: 'Mapa',
      //   validate: ['required'],
      // },
      extraTitle: {
        hint: 'dodatkowy dopisek , widoczny tylko w PA'
      },
      residence: {
        type: 'resource',
        endpoint: `/api/residences?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
        label: 'Rezydencja lokalizacja',
        hint: 'jeśli wybrane, linkuje do planu rezydencji'
      },
      residenceItem: {
        type: 'resource',
        endpoint: `/api/residence_items?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
        label: 'Pomieszczenie',
        hint: 'jeśli wybrane miejsce linkuje do tego pomieszczenia'
      },
      stat: {},
    },
  },
}

export default schema
