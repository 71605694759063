import React from 'react'
import { Form } from 'core/pages'
import { translate } from 'core/_helpers/translate'
import { EmbeddedCollection } from 'core/components/embedded'
import schema from 'core/_schema/packagePricelist'
import routes from './routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      width={600}
    >
      <EmbeddedCollection
        endpoint={schema.subresources.item.endpoint}
        pid={match.params.id}
        parentIri={iri}
        properties={schema.subresources.item.properties}
        definitionSchema={definitions[schema.subresources.item.definition]}
        headerTitle={translate('Elementy cennika')}
        expandTitle={translate('T_MODULE_PACKAGE_FILE_ITEMS_EXPAND_TITLE')}
        panelTitle={translate('Pozycja')}
        statable={true}
      />
    </Form>
  )
}
