import { take, call } from 'redux-saga/effects'
import { authConstants } from 'core/_constants'
import { schemaActions, profileActions } from 'core/_actions'

export function* authFlow() {
  while (true) {
    yield take([
      authConstants.LOGIN_SUCCESS,
      authConstants.LOGIN_RESTORE_SUCCESS,
    ])
    yield call(schemaActions.fetch)
    yield call(profileActions.fetch)
  }
}
