const schema = {
  endpoint: '/api/package_pricelists',
  resource: {
    definition: 'PackagePricelist-packagePricelist.read',
    properties: {
      title: {
        validate: ['required'],
      },
    },
  },
  subresources: {
    item: {
      endpoint: '/api/package_pricelist_items',
      definition: 'PackagePricelistItem-packagePricelistItem.read',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
            priceString: {
              label: 'Cena/opis',
            },
          },
        },
        isHeader: {
          label: 'Wyswietl jako nagłówek',
        },
      },
    },
  },
}

export default schema
